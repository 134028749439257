import {CABINET_APPS_PER_PAGE} from '@api/cabinet';

interface ISiteStructure {
  path: string;
  query?: Record<string, any>;
  title: string;
  children?: ISiteStructure[];
}

export const siteStructure: ISiteStructure = {
  path: '/',
  title: 'Главная',
  children: [
    {
      path: '/catalog/',
      title: 'Каталог',
    },
    {
      path: '/calculator/',
      title: 'Калькулятор',
    },
    {
      path: '/specialoffers/',
      title: 'Спецпредложения',
    },
    {
      path: '/sale/catalog/',
      title: 'Б/У имущество',
    },
    {
      path: '/providers/catalog/',
      title: 'Поставщики и партнеры',
      children: [
        {
          path: '/providers/catalog/',
          title: 'Каталог поставщиков и партнеров',
          children: [
            {
              path: '/providers/:id/',
              title: '',
            },
            {
              path: '/agents/:id/',
              title: '',
            },
          ],
        },
        {
          path: '/providers/partnership/',
          title: 'Как стать поставщиком',
        },
        {
          path: '/providers/agentship/',
          title: 'Как стать агентом',
        },
        {
          path: 'https://www.rosagroleasing.ru/cabinet/v2/auth/',
          title: 'Личный кабинет',
        },
      ],
    },
    {
      path: '/company/',
      title: 'О компании',
      children: [
        {
          path: '/company/smi/',
          title: 'Пресс-центр',
          children: [
            {
              path: '/company/smi/news/',
              title: 'Новости компании',
            },
            {
              path: '/company/smi/photo/',
              title: 'Фотоматериалы',
            },
            {
              path: '/company/smi/publications/',
              title: 'Все публикации о нас',
            },
            {
              path: '/company/smi/video/',
              title: 'Видео-сюжеты',
            },
          ],
        },
        {
          path: '/company/directors/',
          title: 'Органы управления',
        },
        {
          path: '/company/career/',
          title: 'Карьера',
        },
        {
          path: '/company/tenders/',
          title: 'Закупка товаров и услуг',
        },
        {
          path: '/company/events/',
          title: 'Календарь мероприятий',
        },
        {
          path: '/company/contacts/',
          title: 'Контакты',
        },
        {
          path: '/company/virtual-office/',
          title: 'Виртуальный офис',
        },
        {
          path: '/company/championship/',
          title: 'Чемпионаты по пахоте',
          children: [
            {
              path: '/company/championship/item/',
              title: 'Чемпионат',
            },
          ],
        },
        {
          path: '/company/history/',
          title: 'История компании',
        },
        {
          path: '/company/esg/',
          title: 'Устойчивое развитие',
        },
        {
          path: '/company/blog/',
          title: 'Блог',
          children: [
            {
              path: '/company/blog/articles/',
              title: 'Статьи',
            },
          ],
        },
        {
          path: '/company/filials/',
          title: 'Филиалы',
        },
      ],
    },
    {
      path: '/client/',
      title: 'Клиентам',
      children: [
        {
          path: '/client/docs/',
          title: 'Центр документов',
        },
        {
          path: '/client/services/',
          title: 'Онлайн-сервисы',
          children: [
            // {
            //   path: '/client/services/checkmail/',
            //   title: 'Проверить статус письма',
            // },
            {
              path: '/client/services/feedback/',
              title: 'Оставить отзыв',
            },
            {
              path: '/client/services/app/',
              title: 'Мобильное приложение',
            },
            {
              path: '/client/services/callback/',
              title: 'Заказать обратный звонок',
            },
            {
              path: '/client/services/fines/',
              title: 'Проверить штрафы ГИБДД',
            },
          ],
        },
        {
          path: '/client/faq/',
          title: 'Частые вопросы',
        },
        {
          path: '/client/wikileas/',
          title: 'О лизинге просто',
        },
        {
          path: '/client/measures/',
          title: 'Региональные меры поддержки',
        },
      ],
    },
    {
      path: '/investors/',
      title: 'Инвесторам',
      children: [
        {
          path: '/investors/metrics/',
          title: 'Ключевые показатели',
        },
        {
          path: '/investors/bonds/',
          title: 'Облигации',
        },
        {
          path: '/investors/ratings/',
          title: 'Кредитные рейтинги',
        },
        {
          path: '/investors/reports/',
          title: 'Раскрытие информации',
        },
        {
          path: '/investors/insiders/',
          title: 'Инсайдерам',
        },
        {
          path: '/investors/events/',
          title: 'Календарь событий',
        },
      ],
    },
    // {
    //   path: '/extraServices/',
    //   title: 'Доп. услуги',
    //   children: [
    //     // {
    //     //   path: '/extraServices/insurance/',
    //     //   title: 'Страхование',
    //     // },
    //     // {
    //     //   path: '/extraServices/fosagrocalc/',
    //     //   title: 'Калькулятор удобрений',
    //     // },
    //   ],
    // },
    {
      path: '/compare/',
      title: 'Сравнение',
    },
    {
      path: '/favorite/',
      title: 'Избранное',
    },
    {
      path: '/shoppingCart/',
      title: 'Оформление техники',
    },
    {
      path: '/documentflow/',
      title: 'Электронный документооборот',
    },
    {
      path: '/cabinet/v2/',
      title: 'Личный кабинет',
      children: [
        {
          path: '/cabinet/v2/client/dashboard/',
          title: 'Личный кабинет клиента',
          children: [
            {path: '/cabinet/v2/client/history/', title: 'История'},
            {
              path: '/cabinet/v2/client/apps/',
              query: {
                items_per_page: CABINET_APPS_PER_PAGE,
              },
              title: 'Заявки',
              children: [
                {
                  path: '/cabinet/v2/client/deal/create/',
                  title: 'Новая заявка',
                },
              ],
            },
            {path: '/cabinet/v2/client/sale/', title: 'Распродажа б/у'},
            {path: '/cabinet/v2/client/documents/', title: 'Документы'},
            {
              path: '/cabinet/v2/client/orders/',
              title: 'Договоры',
              children: [
                {
                  path: '/cabinet/v2/client/orders/:id/',
                  title: 'Договор :id',
                  children: [
                    {
                      path: '/cabinet/v2/client/orders/:id/requests/:objectId/',
                      title: 'Запрос на субаренду',
                    },
                    {
                      path: '/cabinet/v2/client/orders/:id/notifications/:objectId/',
                      title: 'Уведомление о субаренде',
                    },
                    {
                      path: '/cabinet/v2/client/orders/:id/confirmations/:objectId/',
                      title: 'Подтверждение субаренды',
                    },
                  ],
                },
              ],
            },
            {path: '/cabinet/v2/client/services/', title: 'Онлайн-сервисы'},
            {path: '/cabinet/v2/client/supplies/', title: 'Поставки'},
            {path: '/cabinet/v2/client/help/', title: 'Помощь'},
          ],
        },
        {
          path: '/cabinet/v2/supplier/dashboard/',
          title: 'Личный кабинет поставщика',
          children: [
            {
              path: '/cabinet/v2/supplier/products/',
              title: 'Номенклатура и цены',
              children: [
                {
                  path: '/cabinet/v2/supplier/products/',
                  title: 'Номенклатура',
                },
                {
                  path: '/cabinet/v2/supplier/products/?tab=reviews',
                  title: 'Отзывы',
                },
                {
                  path: '/cabinet/v2/supplier/products/?tab=consultant',
                  title: 'Консультант',
                },
                {
                  path: '/cabinet/v2/supplier/products/?tab=pricelevel',
                  title: 'Ценовые соглашения',
                  children: [
                    {
                      path: '/cabinet/v2/supplier/products/pricelevel/:id/',
                      title: 'Ценовое соглашение :id',
                    },
                  ],
                },
                {
                  path: '/cabinet/v2/supplier/products/?tab=interests',
                  title: 'Сделки',
                  children: [
                    {
                      path: '/cabinet/v2/supplier/products/interests/:id/',
                      title: 'Сделка :id',
                    },
                  ],
                },
              ],
            },
            {
              path: '/cabinet/v2/supplier/interests/',
              title: 'Сделки',
              children: [],
            },
            {
              path: '/cabinet/v2/supplier/deliveries/',
              title: 'Поставки',
              children: [
                {
                  path: '/cabinet/v2/supplier/deliveries/?tab=contracts',
                  title: 'Договоры купли-продажи',
                  children: [
                    {
                      path: '/cabinet/v2/supplier/deliveries/contracts/:id/',
                      title: 'Документы',
                    },
                  ],
                },
                {
                  path: '/cabinet/v2/supplier/deliveries/?tab=shipping',
                  title: 'Отгрузки',
                  children: [
                    {
                      path: '/cabinet/v2/supplier/deliveries/shipping/:id/',
                      title: 'Отгрузка :id',
                    },
                  ],
                },
                {
                  path: '/cabinet/v2/supplier/deliveries/?tab=complaints',
                  title: 'Рекламации',
                  children: [
                    {
                      path: '/cabinet/v2/supplier/deliveries/complaints/:id/',
                      title: 'Рекламация :id',
                    },
                  ],
                },
              ],
            },
            {
              path: '/cabinet/v2/supplier/content/',
              title: 'Поставщик',
              children: [
                {
                  path: '/cabinet/v2/supplier/content/?tab=card',
                  title: 'Карточка поставщика',
                },
                {
                  path: '/cabinet/v2/supplier/content/?tab=documents',
                  title: 'Документы',
                },
                {
                  path: '/cabinet/v2/supplier/content/?tab=stores',
                  title: 'Склады',
                },
              ],
            },
            {
              path: '/cabinet/v2/supplier/help/',
              title: 'Помощь',
            },
          ],
        },
        {
          path: '/cabinet/v2/agent/dashboard/',
          title: 'Личный кабинет агента',
          children: [
            {
              path: '/cabinet/v2/agent/contractors/',
              title: 'Мои контрагенты',
              children: [
                {
                  path: '/cabinet/v2/agent/contractors/new/',
                  title: 'Новый контрагент',
                },
                {
                  path: '/cabinet/v2/agent/contractors/:id/',
                  title: ':id',
                },
              ],
            },
            {
              path: '/cabinet/v2/agent/applications/',
              title: 'Заявки',
              children: [
                {
                  path: '/cabinet/v2/agent/applications/:id/',
                  title: 'Заявка :id',
                },
              ],
            },
            {
              path: '/cabinet/v2/agent/offers/',
              title: 'Коммерческие предложения',
              children: [
                {
                  path: '/cabinet/v2/agent/offers/:id/',
                  title: 'Коммерческое предложение :id',
                },
                {
                  path: '/cabinet/v2/agent/offers/calculator/',
                  title: 'Калькулятор',
                },
                {
                  path: '/cabinet/v2/agent/offers/create/',
                  title: 'Рассчитать коммерческое предложение',
                },
              ],
            },
            {
              path: '/cabinet/v2/agent/help/',
              title: 'Помощь',
            },
          ],
        },
        {
          path: '/cabinet/v2/factoring-supplier/registers',
          title: 'Факторинг',
          children: [
            {
              path: '/cabinet/v2/factoring-supplier/registers/',
              title: 'Реестры',
            },
            // {
            //   path: '/cabinet/v2/factoring-supplier/financing/',
            //   title: 'Финансирование',
            // },
            // {
            //   path: '/cabinet/v2/factoring-supplier/documents/',
            //   title: 'Документы по сделке',
            // },
            {
              path: '/cabinet/v2/factoring-supplier/invoice/',
              title: 'Счета на оплату',
            },
            // {
            //   path: '/cabinet/v2/factoring-supplier/reports/',
            //   title: 'Отчеты',
            // },
          ],
        },
        {
          path: '/cabinet/v2/factoring-customer/registers',
          title: 'Факторинг',
          children: [
            {
              path: '/cabinet/v2/factoring-customer/registers/',
              title: 'Реестры',
            },
          ],
        },
      ],
    },
  ],
};

const sectionsNotInHeader = [
  '/compare/',
  '/favorite/',
  '/shoppingCart/',
  '/documentflow/',
  '/cabinet/v2/',
];

export const headerCategoryMenuBaseItems =
  siteStructure.children?.filter(item => !sectionsNotInHeader.includes(item.path)) || [];
