import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {beneficiaryQuestionnaireWizardAPI} from '@api/beneficiaryQuestionnaire';
import {addBeneficiaryQuestionnaireSeparators, formatBeneficiaryQuestionnaireField} from '@utils/formatters';
import {ICabAppsWizardField} from '@api/types/CabAppsWizard';
import { NextRouter } from 'next/router';

export interface BeneficiaryQuestionnaireWizard {
  step: string | null;
  maxstep: number | null;
  nextstep: number | null;
  steps: Record<string, string>;
}

export interface IBeneficiaryQuestionnaireWizardInitialState {
  loading: typeof IDLE | typeof PENDING;
  stepsAmount: number;
  wizard: BeneficiaryQuestionnaireWizard;
  id_object: string;
  stepNow: number;
  canSES: boolean | null;
  ctrl: '';
  screens: Record<string, any>;
  formedDocument: null | {
    src: string;
    title: string;
  };
  CSPUI: {
    size: string;
    isdangeroussize: string;
    api_method: string;
    id_object: string;
    entity: string;
    backURL: string;
    cancelURL: string;
  } | null;
}

const initialState: IBeneficiaryQuestionnaireWizardInitialState = {
  loading: IDLE,
  stepsAmount: 0,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    steps: {},
  },
  id_object: '',
  stepNow: 1,
  canSES: null,
  ctrl: '',
  formedDocument: null,
  screens: {},
  CSPUI: null
  
};

export const beneficiaryQuestionnaireWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params: Record<string, any>;
    postData: Record<string, unknown>;
    wrapperKey: string;
    nextStepRequest?: boolean;
    id_object?: string;
    router: NextRouter;
  },
  {state: RootState}
>(
  'beneficiaryQuestionnaireWizard/fetch',
  async ({params, postData, wrapperKey, id_object, router}, thunkAPI) => {
    const {beneficiaryQuestionnaireWizard} = thunkAPI.getState();
    const data = await beneficiaryQuestionnaireWizardAPI.beneficiaryQuestionnaireWizard({
      params: {
        id_object: id_object ?? beneficiaryQuestionnaireWizard.id_object ?? undefined,
        ...params,
      },
      postData,
      wrapperKey,
      router
    });

    
    if (Number(data?.wizard?.step) !== Number(params.step)) {
      const newParams = {
        id_object: data?.wizard?.id_object ?? id_object ?? beneficiaryQuestionnaireWizard.id_object,
        step: data?.wizard?.step,
      };
      thunkAPI.dispatch(
        beneficiaryQuestionnaireWizardThunk({
          params: newParams,
          postData: {},
          wrapperKey: '',
          router
        }),
      );
    }
    return {...data, prevStep: params?.step ?? null};
  },
);

const beneficiaryQuestionnaireWizardSlice = createSlice({
  name: 'beneficiaryQuestionnaireWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },


    changeIdObject(state, action: PayloadAction<string>) {
      state.id_object = action.payload;
    },

    setInitialValues(state, action) {
      const {payload} = action;
      state.screens[state.stepNow].initialValues = payload.values;
    },
    dropState(state) {
      state.stepNow = initialState.stepNow;
      state.id_object = initialState.id_object;
      state.screens = initialState.screens;
      state.wizard = initialState.wizard;
      state.ctrl = initialState.ctrl;
      state.CSPUI = initialState.CSPUI
    },
  },
  extraReducers: builder => {
    builder.addCase(beneficiaryQuestionnaireWizardThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      beneficiaryQuestionnaireWizardThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        if (payload?.wizard?.step) {
          state.stepNow = payload?.wizard?.step;
        }
        state.wizard = payload?.wizard;
        if (payload.wizard?.id_object) {
          state.id_object = payload.wizard.id_object.toString();
        }
        state.stepsAmount = Object.keys(payload?.wizard?.steps ?? {}).length;

        if (!state.screens[state.stepNow]) {
          state.screens[state.stepNow] = {};
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.initialValuesMapper(payload?.FormBuilder?.items) || {};
        } else {
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.changeInitialValuesMapper(
              state.screens[state.stepNow].initialValues,
              payload?.FormBuilder?.items,
            ) || {};
        }
        state.screens[state.stepNow].ctrl = payload?.FormBuilder?.form_name;
        state.screens[state.stepNow].items =
          payload?.FormBuilder?.items.map((item: ICabAppsWizardField) =>
            formatBeneficiaryQuestionnaireField(item),
          ) ?? [];

        state.screens[state.stepNow].items = addBeneficiaryQuestionnaireSeparators(state.screens[state.stepNow].items, state.stepNow);

        state.canSES = payload.canSES;

        if (payload?.CardBuilder?.items[0]?.items?.value) {
          const document = payload?.CardBuilder?.items[0]?.items?.value;
          state.formedDocument = {title: document.title, src: document.src};
        }
        if (payload?.CSPUI) {
          state.CSPUI = payload.CSPUI
        }

        if (payload?.prevStep && payload?.wizard?.step && (Number(payload?.prevStep) === Number(payload?.wizard?.step))) {
          state.loading = IDLE;
        }
      },
    );
    builder.addCase(beneficiaryQuestionnaireWizardThunk.rejected, state => {
      state.loading = IDLE;
    });
  },
});

// Extract the action creators object and the reducer
const {actions, reducer} = beneficiaryQuestionnaireWizardSlice;

// Extract and export each action creator by name
export const {changeStepNow, changeIdObject, setInitialValues, dropState} =
  actions;

// Export the reducer, either as a default or named export
export default reducer;
