import React, {FC} from 'react';
import Image from 'next/legacy/image';
import ReactHtmlParser from 'react-html-parser';
import dynamic from 'next/dynamic';
import {ModalCloseBtn, ModalHeaderWrapper, Title} from './ModalHeader.styles';

interface ModalHeaderProps {
  title: string;
  onClose: () => void;
  style?: Record<string, any>;
  closeBtnStyle?: Record<string, any>;
  closeTestID?: string;
  className?: string;
  titleFontSize?: number;
  closeBtnWidth?: number;
  closeBtnHeight?: number;
  hideCloseBtn?: boolean;
}

export interface ModalHeaderStyledProps {
  titleFontSize: number;
}

const ModalHeader: FC<ModalHeaderProps> = ({
  title,
  onClose,
  style,
  closeBtnStyle,
  closeTestID,
  className,
  titleFontSize = 36,
  closeBtnHeight = 21,
  closeBtnWidth = 21,
  hideCloseBtn,
}) => {
  return (
    <ModalHeaderWrapper style={style} className={className}>
      <Title titleFontSize={titleFontSize}>{ReactHtmlParser(title)}</Title>
      {!hideCloseBtn && (
        <ModalCloseBtn onClick={onClose} style={closeBtnStyle} data-testid={closeTestID}>
          <Image
            src={'/assets/icons/closeBtn.svg'}
            layout="intrinsic"
            width={closeBtnWidth}
            height={closeBtnHeight}
            alt="Закрыть"
          />
        </ModalCloseBtn>
      )}
    </ModalHeaderWrapper>
  );
};

export default dynamic(() => Promise.resolve(ModalHeader), {ssr: false});
