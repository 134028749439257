import {ICabAppsWizardField, IWizardChoosedProduct} from '@api/types/CabAppsWizard';
import {FormikValues} from 'formik';
import {v4 as uuid} from 'uuid';
import router from 'next/router';
import {NATS_ENV} from '@config/nats';
import {getCookie, hasCookie, setCookie} from 'cookies-next';
import {AnalyticEventParams} from '@api/types/AnalyticEvents';
import {differenceInMinutes} from 'date-fns';
import {CLIENT_UUID, SESSION_TIMESTAMP, SESSION_UUID} from '@constants/paramNames';
export function scrollToContainer(containerID: string) {
  document.querySelector(containerID)!.scrollIntoView({behavior: 'smooth'});
}

export const getBase64 = async (
  file: Blob,
  cb?: (base64: string | ArrayBuffer | null) => void,
): Promise<string | ArrayBuffer | null> => {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      cb && cb(reader.result);
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export async function getBinary(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject(error);
    };
    reader.readAsBinaryString(file);
  });
}

export const checkFieldValues = (
  values: FormikValues,
  fieldsSection: ICabAppsWizardField[],
): boolean => {
  return fieldsSection
    .map(field => values[field.field]?.length !== 0 && values[field.field] !== '0')
    .filter(_ => _).length >= 2
    ? true
    : false;
};

export function newAbortSignal(timeoutMs: number) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

export const getTotalRoboAppProductsSum = (products: IWizardChoosedProduct[]) => {
  let totalSum = 0;
  products?.map(item => {
    if (item.complect.length !== 0) {
      item.complect.map(
        complectItem => (totalSum += complectItem.price * complectItem.quantity),
      );
    }
    totalSum += item.price * item.quantity;
  });
  return totalSum;
};

export const promiseAllSettledProps = (
  promises: Array<Promise<any>>,
): Promise<Array<any | null>> => {
  return new Promise(res => {
    Promise.allSettled(promises).then(result => {
      const mappedResult = result.map(el =>
        el.status === 'fulfilled' ? el.value : null,
      );
      res(mappedResult);
    });
  });
};

export type TDeviceType = 'MOBILE' | 'TABLET' | 'DESKTOP';

export const getDeviceType = () : TDeviceType => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'TABLET';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'MOBILE';
  }
  return 'DESKTOP';
};

export async function sendClientParamsNATSAPI(clientParams: {
  [key: string]:
    | string
    | number
    | boolean
    | null
    | {[key: string]: string | number | boolean | null};
}) {
  try {
    const response = await fetch('/api/nats/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(clientParams),
    });
    const result = await response.json();
  } catch (err) {
    console.log(err);
  }
}

export async function sendClientParamsNATS(
  eventType = 'load',
  params?: Partial<AnalyticEventParams>,
) {
  const clientParams: {
    [key: string]:
      | string
      | number
      | boolean
      | null
      | {[key: string]: string | number | boolean | null};
  } = {};
  if (typeof window !== 'undefined') {
    const userID = getCookie('userID') as string;
    const yaClientId = getCookie('_ym_uid') as string;

    if (!hasCookie(CLIENT_UUID)) {
      setCookie(CLIENT_UUID, uuid(), {
        maxAge: 31536000
      });
    }

    if (hasCookie(SESSION_UUID) && hasCookie(SESSION_TIMESTAMP)) {
      const timestamp = new Date(Number(getCookie(SESSION_TIMESTAMP)));
      if (differenceInMinutes(new Date(), timestamp) > 30) {
        setCookie(SESSION_UUID, uuid());
      }
    } else {
      setCookie(SESSION_UUID, uuid());
    }

    setCookie(SESSION_TIMESTAMP, +new Date());
    clientParams.guid = uuid();
    clientParams.clientId = String(getCookie(CLIENT_UUID));
    clientParams.sessionId = String(getCookie(SESSION_UUID));
    // common params
    clientParams.location = router.asPath;
    clientParams.userAgent = navigator.userAgent;
    clientParams.screenWidth = window.innerWidth;
    clientParams.screenHeight = window.innerHeight;
    clientParams.cookieEnabled = navigator.cookieEnabled;
    //clientParams.cookie = document.cookie;
    clientParams.environment = NATS_ENV;
    clientParams.datetimeClient = Math.round(Date.now() / 1000);

    clientParams.channel = !!userID ? 'AUTHENTICATED' : 'ANONYMOUS';
    clientParams.userId = userID || null;
    clientParams.deviceType = getDeviceType();
    clientParams.eventType = eventType;
    clientParams.eventName = params?.eventName || eventType;
    clientParams.eventCategory = params?.eventCategory || null;
    clientParams.versionRelease = '1.4';
    clientParams.params = {
      ...params?.eventParams,
      ym_client_id: yaClientId,
    };

    // load params
    if (eventType === 'load') {
      clientParams.performance = {
        start: Math.round(window.performance.timing.fetchStart / 1000),
        end: Math.round(window.performance.timing.loadEventEnd / 1000),
      };
    }

    clientParams.geolocation = null;
    clientParams.exp_groups = getCookie('experiments') || '';

    // if ('geolocation' in navigator) {
    //   await new Promise<void>(res => {
    //     navigator.geolocation.getCurrentPosition(
    //       position => {
    //         clientParams.geolocation = {
    //           latitude: String(position.coords.latitude),
    //           longitude: String(position.coords.longitude),
    //         };
    //         res();
    //       },
    //       () => {
    //         res();
    //       },
    //     );
    //   });
    // }

    sendClientParamsNATSAPI(clientParams);
  }
}

export const copyText = (text: string) => {
  try {
    navigator.clipboard.writeText(text);
    return 'Скопировано!';
  } catch (e) {
    const error = e as unknown as Error;
    return error.message;
  }
};

export const isString = (val: unknown): val is string => typeof val === 'string';

export const convertStrBoolToBool = (str: string | boolean): boolean => {
  if (str === 'true' || str === '1' || str === true) {
    return true;
  }

  return false;
};

export const convertStrBoolToNumber = (str: string | boolean | number): number => {
  if (str === 'true' || str === '1' || str === true || str === 1) {
    return 1;
  }

  return 0;
};
