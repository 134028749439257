export enum RegFormTypes {
  person = 'person', 
  organization = 'organization'
};
export enum RegSteps {
  phone = 1,
  phoneConfirm = 2,
  email = 3,
  emailConfirm = 4,
  organization = 5,
  person = 6,
  account = 7,
  agreements = 8
};