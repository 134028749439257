import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {
  formatCreditDeclarationField,
  groupCreditDeclarationCheckboxes,
} from '@utils/formatters';
import {ICabAppsWizardField} from '@api/types/CabAppsWizard';
import {creditDeclarationWizardAPI} from '@api/creditDeclaration';
import { NextRouter } from 'next/router';

export interface CreditDeclarationWizard {
  step: string | null;
  maxstep: number | null;
  nextstep: number | null;
  steps: Record<string, string>;
}

export interface ICreditDeclarationInitialState {
  loading: typeof IDLE | typeof PENDING;
  stepsAmount: number;
  wizard: CreditDeclarationWizard;
  stepNow: number;
  stepsHistory: number[];
  canSES: boolean | null;
  ctrl: '';
  screens: Record<string, any>;
  formedDocument: null | {
    src: string;
    title: string;
  };
  CSPUI: {
    size: string;
    isdangeroussize: string;
    api_method: string;
    id_object: string;
    entity: string;
    backURL: string;
    cancelURL: string;
    message?: string;
  } | null;
}

const initialState: ICreditDeclarationInitialState = {
  loading: IDLE,
  stepsAmount: 0,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    steps: {},
  },
  stepsHistory: [],
  stepNow: 1,
  canSES: null,
  ctrl: '',
  formedDocument: null,
  screens: {},
  CSPUI: null,
};

export const creditDeclarationWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params: Record<string, any>;
    postData: Record<string, unknown>;
    wrapperKey: string;
    nextStepRequest?: boolean;
    router: NextRouter;
  },
  {state: RootState}
>('creditDeclarationWizard/fetch', async ({params, postData, wrapperKey, router}, thunkAPI) => {
  const data = await creditDeclarationWizardAPI.creditDeclarationWizard({
    params: {
      ...params,
    },
    postData,
    wrapperKey,
    router
  });

  if (data?.wizard?.step !== params.step) {
    const newParams = {
      step: Number(data?.wizard?.step),
    };
    thunkAPI.dispatch(
      creditDeclarationWizardThunk({
        params: newParams,
        postData: {},
        wrapperKey: '',
        router
      }),
    );
  }
  return {...data, prevStep: params?.step ?? null};
});

const creditDeclarationWizardSlice = createSlice({
  name: 'creditDeclarationWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },
    setStepsHisotry(state, action) {
      state.stepsHistory = action.payload;
    },

    setInitialValues(state, action) {
      const {payload} = action;
      state.screens[state.stepNow].initialValues = payload.values;
    },
    dropState(state) {
      state.stepNow = initialState.stepNow;
      state.screens = initialState.screens;
      state.wizard = initialState.wizard;
      state.ctrl = initialState.ctrl;
      state.CSPUI = initialState.CSPUI;
    },
  },
  extraReducers: builder => {
    builder.addCase(creditDeclarationWizardThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      creditDeclarationWizardThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        if (payload?.wizard?.step) {
          state.stepNow = Number(payload?.wizard?.step);
        }
        state.wizard = payload?.wizard;
        state.stepsAmount = Object.keys(payload?.wizard?.steps ?? {}).length;

        const withGroupedCheckboxes = groupCreditDeclarationCheckboxes(
          payload?.FormBuilder?.items ?? [],
        );

        state.screens[state.stepNow] = {};
        state.screens[state.stepNow].initialValues =
          requestWizardMappings.initialValuesMapper(withGroupedCheckboxes) || {};
        state.screens[state.stepNow].ctrl = payload?.FormBuilder?.form_name;
        state.screens[state.stepNow].items =
          payload?.FormBuilder?.items.map((item: ICabAppsWizardField) =>
            formatCreditDeclarationField(item),
          ) ?? [];

        state.screens[state.stepNow].items = groupCreditDeclarationCheckboxes(
          state.screens[state.stepNow].items,
        );

        state.canSES = payload.canSES;

        if (payload?.CardBuilder?.items[0]?.items?.value) {
          const document = payload?.CardBuilder?.items[0]?.items?.value;
          state.formedDocument = {title: document.title, src: document.src};
        }
        if (payload?.CSPUI) {
          state.CSPUI = payload.CSPUI;
        }

        if (
          payload?.prevStep &&
          payload?.wizard?.step &&
          Number(payload?.prevStep) === Number(payload?.wizard?.step)
        ) {
          state.loading = IDLE;
        }
      },
    );
    builder.addCase(creditDeclarationWizardThunk.rejected, state => {
      state.loading = IDLE;
    });
  },
});

// Extract the action creators object and the reducer
const {actions, reducer} = creditDeclarationWizardSlice;

// Extract and export each action creator by name
export const {changeStepNow, setInitialValues, setStepsHisotry, dropState} = actions;

// Export the reducer, either as a default or named export
export default reducer;
