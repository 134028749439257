import {RootState} from '@store';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  query: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
  },
});

export const searchQuery = (state: RootState) => state.search.query;

export const {setSearchQuery} = searchSlice.actions;

export default searchSlice.reducer;
