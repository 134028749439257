import {getCabinetNotifications} from '@api/cabinetNotifications';
import Toast from '@components/atoms/Toast';
import CabBreadcrumbs from '@components/molecules/CabBreadcrumbs';
import pageWithDefaultBreadcrumbs from '@components/molecules/CabBreadcrumbs/pageWithDefaultBreadcrumbs';
import {ENABLE_NEW_CABINET} from '@config/cabinet';
import {useAppDispatch, useAuth, useNotifications} from '@hooks';
import {toast} from 'react-toastify';
import {getAccountThunk} from '@store/slices/user';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {FC, useEffect} from 'react';
import Geolocation from './Geolocation';
import {getCatalogCategoriesThunk} from '@store/slices/catalog';
import {DesktopHeaderWrapper, MobileHeaderWrapper} from './Header.styles';

const DesktopHeader = dynamic(() => import('./DesktopHeader'));
const CabHeader = dynamic(() => import('./CabHeader'));
const MobileHeader = dynamic(() => import('./MobileHeader'));
const AuthModal = dynamic(() => import('@components/organisms/CabinetAuth2/AuthModal'), {
  ssr: false,
});
const RecoverPasswordModal = dynamic(
  () => import('@components/organisms/CabinetAuth2/RecoverPasswordModal'),
  {ssr: false},
);

const Header: FC = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  

  const { isAuth } = useAuth();
  useNotifications(isAuth);

  const urlPathWithQuery = `${router.pathname}/${
    router.query.tab ? `?tab=${router.query.tab}` : ''
  }`;

  const hasBreadcrumbs = pageWithDefaultBreadcrumbs.has(urlPathWithQuery);

  const isRegistration = (
    router.asPath.startsWith('/cabinet/v2/reg') ||
    router.asPath.startsWith('/cabinet/v2/auth') ||
    router.asPath.startsWith('/pd')
  );

  const isCabinetLayout = (
    router.asPath.startsWith('/cabinet/v2') && 
    !isRegistration &&
    ENABLE_NEW_CABINET
  );

  useEffect(() => {
    dispatch(getAccountThunk());
  }, []);

  useEffect(() => {
    if (!isCabinetLayout) {
      dispatch(getCatalogCategoriesThunk({}));
    }
  }, [isCabinetLayout]);
  
  return (
    <>
      {isCabinetLayout ? (
          <>
            <CabHeader />
            {hasBreadcrumbs && <CabBreadcrumbs />}
          </>
        ) : (
          <>
            <MobileHeaderWrapper>
              <MobileHeader isAuth={isAuth} />
            </MobileHeaderWrapper>
            <DesktopHeaderWrapper>
              <DesktopHeader isAuth={isAuth} isRegistration={isRegistration} />
            </DesktopHeaderWrapper>
          </>
        )}

      {ENABLE_NEW_CABINET && (
        <>
          <AuthModal />
          <RecoverPasswordModal />
        </>
      )}
      <Geolocation />
    </>
  );
};

export default Header;
