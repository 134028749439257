import {Breadcrumb, ExtendedBreadcrumb} from '@api/types/Breadcrumbs';
import {useWindowDimensions} from '@hooks/useWindowDimensions';
import {siteStructure} from '@mockData/siteStructure';
import {CabinetGlobalContainer} from '@styles/pages/_app.styles';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/router';
import {Container, Delimiter} from './index.styles';

const CabBreadcrumbComponent = dynamic(() => import('@components/atoms/CabBreadcrumb'), {
  ssr: false,
});

interface BreadcrumbsProps {
  breadcrumbs?: Array<Breadcrumb>;
  allLinks?: boolean;
  replacements?: Record<string, string>;
  pathReplacements?: Record<string, string>;
  deleteLastBreadcrumb?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs: propBreadcrumbs,
  allLinks,
  replacements,
  pathReplacements,
  deleteLastBreadcrumb = false,
}) => {
  const router = useRouter();

  let breadcrumbs = [];

  const filteredBreadCrumbs = propBreadcrumbs?.filter(crumb => crumb.path);

  if (filteredBreadCrumbs) {
    breadcrumbs = filteredBreadCrumbs;
  } else {
    let currentLongestCrumbPath = siteStructure.path;
    let currentCrumbsList: any = [siteStructure];

    const searchForMatchingCrumb = (
      crumbs: Breadcrumb[],
      higherLevelCrumbsList: Breadcrumb[],
    ) => {
      crumbs.forEach(crumb => {
        const crumbsList = [...higherLevelCrumbsList, crumb];
        const crumbRegExp = new RegExp(
          '^' + crumb.path.replace(/:\w+/g, `\\d+`).replace(/\?/g, '\\?'),
        );

        // оставить asPath из-за динамического роута с id
        // наименование активного таба не менять или заменить везде,
        // чтобы он был только для страниц с табами - для Breadcrumbs
        const urlPathWithQuery = `${router.asPath.split('?')[0].split('#')[0]}${
          router.query.tab ? `?tab=${router.query.tab}` : ''
        }`;

        if (
          crumbRegExp.test(urlPathWithQuery) &&
          crumb.path.length >= currentLongestCrumbPath.length
        ) {
          currentLongestCrumbPath = crumb.path;
          currentCrumbsList = crumbsList;
        }
        if (crumb.children) {
          searchForMatchingCrumb(crumb.children, crumbsList);
        }
      });
    };

    searchForMatchingCrumb(siteStructure.children || [], currentCrumbsList);
    breadcrumbs = currentCrumbsList;
  }

  const crumbPaths = breadcrumbs.map((item: Breadcrumb) => item.path);

  const pathParams = Object.entries(replacements ?? {});
  const pathReplacementsParams = Object.entries(pathReplacements ?? {});
  const hasParams = pathParams.length > 0;
  const hasPathReplacementsParams = pathReplacementsParams.length > 0;

  const modifiedCrumbs = breadcrumbs
    .filter(
      (item: Breadcrumb, idx: number) =>
        !(crumbPaths.slice(idx + 1).includes(item.path) || !item.title) &&
        item.path !== '/cabinet/v2/' &&
        item.path !== '/',
    )
    .map(({children, ...crumb}: Breadcrumb, idx: number, array: Breadcrumb[]) => {
      if (deleteLastBreadcrumb && idx === array.length - 1) {
        return null;
      }

      return {
        ...crumb,
        title:
          hasParams && crumb.title
            ? pathParams.reduce((acc, [key, value]) => {
                return acc.replace(`:${key}`, value as string);
              }, crumb.title)
            : crumb.title,
        path:
          hasPathReplacementsParams && crumb.path
            ? pathReplacementsParams.reduce((acc, [key, value]) => {
                return acc.replace(`:${key}`, value as string);
              }, crumb.path)
            : crumb.path,
        hasArrow: deleteLastBreadcrumb
          ? idx !== array.length - 2 && array.length > 2
          : idx !== array.length - 1 && array.length > 1,
        isLink:
          allLinks ||
          idx !== array.length - 1 ||
          array.length === 1 ||
          router.pathname !== router.pathname.split('?')[0],
      };
    });

  const {width} = useWindowDimensions();

  const isSingleCrumb = modifiedCrumbs.length === 1;

  const hideBreadcrumbs =
    modifiedCrumbs[0].title === 'Личный кабинет клиента' && modifiedCrumbs.length === 1;

  if (router.asPath.startsWith('/cabinet/v2/supplier/products')) {
    modifiedCrumbs[1].title = 'Номенклатура';
  }

  if (router.asPath.startsWith('/cabinet/v2/supplier/dashboard')) {
    modifiedCrumbs[0].title = 'Личный кабинет поставщика';
  } else if (router.asPath.startsWith('/cabinet/v2/agent/dashboard')) {
    modifiedCrumbs[0].title = 'Личный кабинет агента';
  } else {
    modifiedCrumbs[0].title = width !== null && width <= 680 ? 'ЛК' : 'Личный кабинет';
  }

  return (
    <>
      <CabinetGlobalContainer>
        <Container hideBreadcrumbs={hideBreadcrumbs}>
          {modifiedCrumbs.map((crumb: ExtendedBreadcrumb, idx: number) => (
            <CabBreadcrumbComponent {...crumb} key={idx} isSingleCrumb={isSingleCrumb} />
          ))}
        </Container>
      </CabinetGlobalContainer>
      <Delimiter />
    </>
  );
};

export default Breadcrumbs;
