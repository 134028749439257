import {bodyFormDataBuilder} from '@utils/formatters';
import apiClient from './entities/apiClient';
import {AxiosInstance} from 'axios';
import {transformAccreditationValues} from './mappings/accreditation';
import {getCookie} from 'cookies-next';

export const accreditationDocuments = async (
  {
    postData,
    wrapperKey,
  }: {
    postData?: {[x: string]: string | any};
    wrapperKey?: string;
  },
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;

    let bodyFormData = null;

    if (postData && wrapperKey) {
      bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    }

    const {data} = await curAPIClient.post('/AccreditationDocuments', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const accreditation = async (
  endpoint = 'AccreditationCounterAgent',
  params?: Record<string, any>,
) => {
  const config = {
    middleware: {
      withAuth: true,
    },
  };

  try {
    const res = params
      ? await apiClient.post(
          endpoint,
          JSON.stringify({
            ...transformAccreditationValues(params),
            token: getCookie('authToken'),
          }),
          {
            ...config,
          },
        )
      : await apiClient.get(endpoint, config);
    const {data} = res;
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const addAccreditationFileContainer = async (id_document: string) => {
  try {
    const res = await apiClient.get(`/AccreditationDocuments/Add/${id_document}`, {
      middleware: {
        withAuth: true,
      },
    });

    const {data} = res;

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const checkAccreditationDocumentsReady = async () => {
  try {
    const res = await apiClient.get(`/AccreditationSign`, {
      middleware: {
        withAuth: true,
      },
    });

    const {data} = res;

    return data;
  } catch (e) {
    console.log(e);
  }
};

export const checkAccreditationCompleted = async () => {
  try {
    const res = await apiClient.get(`/AccreditationSign/Check`, {
      middleware: {
        withAuth: true,
      },
    });

    const {data} = res;

    return data;
  } catch (e) {
    console.log(e);
  }
};
