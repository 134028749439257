import deviceInfo from './deviceInfo';
import pageInfo from './pageInfo';
import catalog from './catalog';
import search from './search';
import ui from './ui';
import chat from './chat';
import user from './user';
import requestWizard from './requestWizard';
import streetAutocomplete from './streetAutocomplete';
import digitalSign from './digitalSign';
import regData from './regData';
import cabServices from './cabServices';
import newAttorneyWizard from './newAttorneyWizard';
import contactPersonsAttorney from './contactPersonsAttorney';
import cabinetContactAttorneyWizard from './cabinetAttorneyWizard';
import cabinetDocuments from './cabinetDocuments';
import cabOrderRequestWizard from './cabOrderRequestWizard';
import cabOrderNotificationWizard from './cabOrderNotificationWizard';
import cabOrderConfirmationWizard from './cabOrderConfirmationWizard';
import roboDealWizard from './roboDealWizard';
import roboAgentDealWizard from './roboAgentDealWizard';
import nrsWizard from './nrsWizard';
import beneficiaryQuestionnairies from './beneficiaryQuestionnairies';
import beneficiaryQuestionnaireWizard from './beneficiaryQuestionnaireWizard';
import creditDeclarationWizard from './creditDeclarationWizard';
import geolocation from './geolocation';
import accreditation from './accreditation';
import workDays from './workDays';

export {
  deviceInfo,
  pageInfo,
  catalog,
  search,
  ui,
  chat,
  user,
  requestWizard,
  streetAutocomplete,
  digitalSign,
  regData,
  cabServices,
  newAttorneyWizard,
  contactPersonsAttorney,
  cabinetContactAttorneyWizard,
  cabinetDocuments,
  cabOrderRequestWizard,
  cabOrderNotificationWizard,
  cabOrderConfirmationWizard,
  roboDealWizard,
  roboAgentDealWizard,
  nrsWizard,
  beneficiaryQuestionnairies,
  beneficiaryQuestionnaireWizard,
  creditDeclarationWizard,
  geolocation,
  accreditation,
  workDays
};
