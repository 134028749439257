import {useRouter} from 'next/router';
import {LeaveFeedbackButtonWrapper} from './LeaveFeedbackButton.styles';

const LeaveFeedbackButton = () => {
  const router = useRouter();
  return (
    <LeaveFeedbackButtonWrapper
      title="Оставить отзыв"
      onClick={() => {
        router.push(`/client/services/feedback/`);
      }}
    />
  );
};

export default LeaveFeedbackButton;
