import {ICalcSettingsState} from '@components/molecules/ProductCard/ProductCardCalc';
import {CatalogProductCalcResult, SaleProductResponse} from '@api/types/CatalogProduct';
import {SaleCatalogQueryParams} from '@api/types/SaleCatalog';
import {
  CalcGetPaymentOptions,
  CatalogQueryParams,
  CatalogTag,
  QueryParam,
} from '@api/types/Catalog';
import {IStockItemProduct} from './types/StockCatalog';
import {
  getPageProperties,
  getSpecialOffersParamsTypes,
  setDynamicParams,
} from '@utils/mapper';
import {FilterItem} from './types/Filter';
import apiClient from '@api/entities/apiClient';
import {format} from 'date-fns';
import {promiseAllSettledProps} from '@utils/common';
import {getNomenclatureBanners} from './main';
import {IPageBannerData} from './types/Banners';
import {getCatalogCategories} from './header';
import {CatalogCategoryProps} from '@pages/catalog/[...slug]';

export const getCatalog = async (
  staticQuery: CatalogQueryParams,
  dynamicQuery: QueryParam,
) => {
  const staticKeys = Object.keys(staticQuery);
  const filteredQuery = setDynamicParams(dynamicQuery, staticKeys);

  const formattedParams = {
    id_category: staticQuery.id,
    '1[by]': staticQuery.by,
    '1[sort]': staticQuery.sort,
    nPaginator: staticQuery.nPaginator,
    page: staticQuery.page,
    id_provider: staticQuery.id_provider,
    id_product_filter: staticQuery.id_product_filter,
    clientID: staticQuery.clientID,
    token: staticQuery.token,
    source: staticQuery.source,
    clientSearchDateTime: staticQuery.clientSearchDateTime,
    ...filteredQuery,
  };

  const {data} = await apiClient.get(`/CatalogV2`, {
    params: formattedParams,
  });

  return data;
};

export const getMonthlyOfferProducts = async (id?: number, width?: number) => {
  const {data} = await apiClient.get(`/GetMonthlyOfferProducts`, {
    params: {
      id_category: id,
      width: width ? width : 1000,
    },
  });
  return data;
};

export const getSpecialOfferProducts = async (id?: number, width?: number) => {
  const {data} = await apiClient.get(`/GetSpecialOfferProducts`, {
    params: {
      id_category: id,
      width: width ? width : 1000,
    },
  });
  return data;
};

export const getBuyNowProducts = async (id?: number) => {
  const {data} = await apiClient.get(`/GetBuyNowProducts`, {
    params: {
      id_category: id,
    },
  });
  return data;
};

export const getProductFilters = async (id?: number) => {
  const {data} = await apiClient.get(`/GetProductFilters`, {
    params: {
      id_category: id,
    },
  });
  return data;
};

// export const getCatalogSubcategory = async (
//   id: number,
//   by?: string,
//   sort?: string,
//   supplier?: string,
//   seller?: string,
//   fiasCode?: string,
//   title?: string,
//   vendorCode?: string,
//   priceFrom?: string,
//   priceTo?: string,
//   isspecialoffer?: string,
//   powerfrom?: string,
//   powerto?: string,
// ) => {
//   const {data} = await apiClient.get(`/Catalog`, {
//     params: {
//       id_category: id,
//       '1[by]': by,
//       '1[sort]': sort,
//       '1[filter][id_CRMSupplier]': supplier,
//       '1[filter][Seller]': seller,
//       '1[filter][fiascode]': fiasCode,
//       '1[filter][title]': title,
//       '1[filter][vendorCode]': vendorCode,
//       '1[filter][price][from]': priceFrom,
//       '1[filter][price][to]': priceTo,
//       '1[filter][isspecialoffer]': isspecialoffer,
//       '1[filter][power][from]': powerfrom,
//       '1[filter][power][to]': powerto,
//     },
//   });
//   return data;
// };

export const getCatalogProduct = async (
  id: number,
  token?: string,
  clientid?: string,
) => {
  const {data} = await apiClient.get(`/CatalogProduct`, {
    params: {
      id_product: id,
      width: 1000,
      token,
      clientid,
    },
  });

  return data;
};

export const getViewedProducts = async (clientid: string, token?: string) => {
  const {data} = await apiClient.get(`/ViewedProducts`, {
    params: {
      clientid,
      token
    },
  });

  return data;
};

export const getProductCalcResult = async (
  value: ICalcSettingsState,
  {signal}: {signal?: AbortSignal} = {},
) => {
  const {data}: {data: CatalogProductCalcResult} = await apiClient.get(`/CalcOnProduct`, {
    params: {
      ...value,
    },
    signal,
  });
  return data;
};


export const getSaleCatalogV1 = async (
  staticQuery: SaleCatalogQueryParams,
  dynamicQuery: QueryParam,
) => {
  const staticKeys = Object.keys(staticQuery);
  const filteredQuery = setDynamicParams(dynamicQuery, staticKeys);

  const formattedParams = {
    '1[by]': staticQuery.by,
    '1[sort]': staticQuery.sort,
    nPaginator: staticQuery.nPaginator,
    page: staticQuery.page,
    ...filteredQuery,
  };

  const {data}: {data: any} = await apiClient.get(`/SaleCatalogV2`, {
    params: formattedParams,
  });

  return data;
};



export const getSaleCatalog = async (params: QueryParam) => {
  const {data}: {data: any} = await apiClient.get(`/v2/SaleCatalog`, {
    params,
  });

  return data;
};

export const getSaleRequisites = async () => {
  const {data} = await apiClient.get(`/GetSaleRequisites`);
  return data;
};

export const getSaleTexts = async () => {
  const {data} = await apiClient.get(`/GetSaleTexts`);
  return data;
};

export const getSaleDocuments = async () => {
  const {data} = await apiClient.get(`/GetSaleDocuments`);
  return data;
};

export const getSaleSpecialOfferProducts = async () => {
  const {data} = await apiClient.get(`/GetSaleSpecialOfferProducts`);
  return data;
};

export const getSaleSubcategories = async () => {
  const {data} = await apiClient.get(`/GetSaleSubcategories`);
  return data;
};

export const getSaleProduct = async (id: number) => {
  const {data}: {data: SaleProductResponse} = await apiClient.get(`/SaleCatalogProduct`, {
    params: {
      id_product: id,
    },
  });
  return data;
};
export const getSimilarSaleProduct = async (id: number) => {
  const {data}: {data: SaleProductResponse} = await apiClient.get(`/GetSimilarSaleProduct`, {
    params: {
      id_product: id,
    },
  });
  return data;
};

export const getMultipleCatalogProducts = async (args: {
  IDs: number[];
  width?: number;
  nPaginator?: number;
  currentPage?: number;
  items_per_page?: number;
  sortQuery?: Record<string, any>;
  filterQuery?: Record<string, any>;
  clientID?: string;
  token?: string;
  source?: string;
  clientSearchDateTime?: string;
}) => {
  const {data} = await apiClient.get(`/CatalogV2`, {
    params: {
      favorites: args.IDs,
      width: args.width,
      ...args.sortQuery,
      ...args.filterQuery,
      nPaginator: args.nPaginator,
      page: args.currentPage,
      items_per_page: args.items_per_page,
      clientID: args.clientID,
      token: args.token,
      source: args.source,
      clientSearchDateTime: args.clientSearchDateTime,
    },
  });
  return (
    {
      favItems: data.Grid.items.GridCollection.items
        ? data.Grid.items.GridCollection.items
        : [],
      totalItems: data.Grid?.count_collection ? data.Grid.count_collection : null,
      sortData: data.Grid.items.GridHeader,
      nPaginator: data.Grid.Paginator?.nPaginator ? data.Grid.Paginator.nPaginator : 1,
      formName: data.Grid.form_name ?? 1,
    } || {
      favItems: [],
      totalItems: null,
      sortData: data.Grid.items.GridHeader,
    }
  );
};

export const getMultipleSaleCatalogProducts = async (args: {
  IDs: number[];
  width?: number;
  currentPage?: number;
  nPaginator?: number;
  items_per_page?: number;
  sortQuery?: Record<string, any>;
  filterQuery?: Record<string, any>;
}) => {
  const {data} = await apiClient.get(`/SaleCatalogV2`, {
    params: {
      favorites: args.IDs,
      width: args.width,
      page: args.currentPage,
      items_per_page: args.items_per_page,
      nPaginator: args.nPaginator,
      ...args.sortQuery,
      ...args.filterQuery,
    },
  });
  return (
    {
      favItems: data.Grid.items.GridCollection.items
        ? data.Grid.items.GridCollection.items
        : [],
      totalItems: data.Grid?.count_collection ? data.Grid.count_collection : null,
      sortData: data.Grid.items.GridHeader,
      nPaginator: data.Grid.Paginator?.nPaginator ? data.Grid.Paginator.nPaginator : 1,
      formName: data.Grid.form_name ?? 1,
      filterItems: Object.values(data.Grid.items.GridFilter.items) as FilterItem[],
    } || {
      favItems: [],
    }
  );
};

export const getStockCatalog = async (path: 'objects' | 'src', params: QueryParam) => {
  const {data}: {data: any} = await apiClient.get(`/v2/StockCatalog`, {
    params: {
      path,
      ...params
    },
  });
  return data;
};

export const getStockCatalogItem = async (id: number) => {
  const res: {data: {Product: IStockItemProduct; error_message?: string}} =
    await apiClient.get(`/StockCatalogProduct`, {
      params: {
        id_product: id,
      },
    });

  return res.data;
};

export const getBorderConditions = async (value: string) => {
  const {data}: {data: CalcGetPaymentOptions} = await apiClient.get(
    `/GetBorderConditions`,
    {
      params: {
        guid_category: value,
      },
    },
  );
  return data;
};

export const getProductCompare = async (items: number[], width?: number) => {
  const {data} = await apiClient.get(`/v2/ProductCompare`, {
    params: {
      product_list: items,
    },
  });

  return data;
};


export const getCatalogFilterPages = async (id_page?: number) => {
  const params: Record<string, any> = {};

  if (id_page) params.id_page = id_page;

  const {data} = await apiClient.get(`/GetCatalogFilterPages`, {params});

  return data;
};

export const getCatalogParamsByAlias = async (uri?: string) => {
  const {data} = await apiClient.get(`/GetCatalogParamsByAlias`, {params: {uri}});

  return data;
};

export const checkCatalogAliasExists = async (params: {
  uri: string;
  id_CRMSupplier: string;
  Seller: string;
  fiascode: string;
  id_category: string;
}) => {
  const {data} = await apiClient.get(`/CheckCatalogAliasExists`, {params});

  return data;
};

export const getCatalogProps = async (
  url: string,
  userFiascode: string,
  clientID: string,
  token: string,
): Promise<Partial<CatalogCategoryProps>> => {
  const res = {
    categories: [],
    subcategory: null,
    filterData: null,
    category: null,
    categoryData: {},
    currentCategoryID: '0',
    subcategoryId: '0',
    tags: [],
    h1: 'Каталог техники',
    catalogItems: [],
    sortData: null,
    paginatorData: {
      paginator: 1,
      totalItems: 0,
      pageSize: 25,
    },
    countCollection: '0',
    categoryText: {
      title: null,
      description: null,
      text: null,
    },
    monthlyOfferProducts: [],
    monthlyOfferSupplier: null,
    specialOfferProducts: [],
    buyNowProducts: [],
    sellers: [],
    specialOffersParamsTypes: [],
    pageProperties: {},
    url: '',
    categoryURL: '',
    firstPositionBanners: [],
    productFilterId: null,
    showNoProductsMessage: false,
    notFound: false,
  };

  try {
    const urlObj = new URL(url.replaceAll(/(\w)(\/\/)/g, '$1/'));
    const slugString = urlObj.pathname.replace('/catalog/', '');
    const slugs = slugString
      .split('/')
      .filter(slug => slug)
      .map(slug => slug.replace('/', ''));

    const [categoriesRes, aliasData] = await promiseAllSettledProps([
      getCatalogCategories(),
      getCatalogParamsByAlias(`/${['catalog', ...slugs].join('/')}/`),
    ]);


    const categories = categoriesRes.categories ?? [];

    const query: Record<string, any> = {};

    for (const key of urlObj.searchParams.keys()) {
      query[key] =
        urlObj.searchParams.getAll(key).length > 1
          ? urlObj.searchParams.getAll(key)
          : urlObj.searchParams.get(key);
    }

    if (aliasData?.success && aliasData?.filters) {
      Object.entries(aliasData.filters).forEach(([key, value]) => {
        query[key] = value;
      });
    }

    const categoryObj = categories?.find((category: {uri: string | undefined}) => {
      return category.uri === slugs[0];
    });

    const subCategoryObj =
      slugs.length > 1
        ? categoryObj?.items.find(
            (subcategory: {
              uri: string | undefined;
              items: Array<{uri: string | undefined}>;
            }) => {
              return subcategory.uri === `${slugs[0]}/${slugs[1]}`;
            },
          )
        : null;

    const currentCategoryID = aliasData?.filters?.id_category ?? subCategoryObj?.id ?? categoryObj?.id;

    const categoryURL = subCategoryObj?.uri ?? categoryObj?.uri;

    const productFilters = await getProductFilters(Number(currentCategoryID));

    const findFilter = (filters: Partial<CatalogTag[]>): CatalogTag | undefined => {
      for (const filter of filters) {
        if (filter && filter.friendly_url === urlObj.pathname) return filter;
        else if (filter && filter.children.length) {
          const res = findFilter(filter.children);
          if (res) return res;
        }
      }
    };



    const filter = productFilters?.success
      ? findFilter(productFilters?.productFiltersList)
      : null;

    if (
      !aliasData?.success
    ) {
      res.notFound = true;
    }
    const productFilterId = filter?.id_product_filter
      ? +filter?.id_product_filter
      : query.id_product_filter
      ? +query.id_product_filter
      : null;

    const nowDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    if (
      (aliasData?.filters?.fiascode === aliasData?.originalFilters?.fiascode) === null &&
      !query.fiascode &&
      userFiascode
    ) {
      query.fiascode = userFiascode;
    }

    const catalogPromise = getCatalog(
      {
        id: Number(currentCategoryID ?? 0) || 0,
        by: query.by ? String(query.by) : '',
        sort: query.sort ? String(query.sort) : '',
        nPaginator: query.nPaginator ? +query.nPaginator : 1,
        page: query.page ? +query.page : 1,
        id_product_filter: productFilterId,
        clientID,
        token,
        source: urlObj.pathname,
        clientSearchDateTime: nowDate,
      },
      {...query},
    );

    const [catalog, monthlyOffer, specialOffer, buyNowProducts, pageProperties] =
      await promiseAllSettledProps([
        catalogPromise,
        getMonthlyOfferProducts(Number(currentCategoryID ?? 0)),
        getSpecialOfferProducts(Number(currentCategoryID ?? 0)),
        getBuyNowProducts(Number(currentCategoryID ?? 0)),
        getPageProperties(filter?.old_url?.slice(0, -1) || urlObj.pathname, true),
      ]);

    if (catalog?.error_message?.startsWith('Категория не найдена')) {
      return {notFound: true};
    }

    const specialOffersParamsTypes = getSpecialOffersParamsTypes(specialOffer?.products);

    if (!catalog?.CatalogMenuSidebar?.items) {
      console.error('req params static - ', {
        id: Number(currentCategoryID),
        by: query.by ? String(query.by) : '',
        sort: query.sort ? String(query.sort) : '',
        nPaginator: query.nPaginator ? +query.nPaginator : 1,
        page: query.page ? +query.page : 1,
      });
      console.error('req params query - ', {...query});
      console.error(catalog);
      throw new Error('res[0].CatalogMenuSidebar.items is empty');
    }
    if (!catalog?.Grid?.items) {
      console.error('req params static - ', {
        id: Number(currentCategoryID),
        by: query.by ? String(query.by) : '',
        sort: query.sort ? String(query.sort) : '',
        nPaginator: query.nPaginator ? +query.nPaginator : 1,
        page: query.page ? +query.page : 1,
      });
      console.error('req params query - ', {...query});
      console.error(catalog);
      throw new Error('res[0].Grid.items is empty');
    }

    const {pageBanners} = await getNomenclatureBanners(currentCategoryID || 0);

    const getBanner = (id: string) => {
      const banner = pageBanners.filter((item: IPageBannerData) => {
        return item.position === id;
      });
      return banner ? banner : null;
    };
    const firstPositionBanners = getBanner('1');

    if (aliasData?.meta) {
      aliasData.meta.canonical_url = aliasData.meta.canonical ?? '';
    }


    return {
      ...res,
      categories: catalog?.CatalogMenuSidebar?.items || [],
      subcategory:
        catalog?.CatalogMenuSidebar?.items?.find(
          (e: {id: string | string[] | undefined}) => e.id === categoryObj?.id,
        ) || null,
      filterData: catalog?.Grid?.items?.GridFilter || null,
      originalFilters: aliasData?.originalFilters ?? null,
      category: categoryObj?.id || null,
      categoryData: categoryObj ?? null,
      currentCategoryID: currentCategoryID || null,
      subcategoryId: currentCategoryID ?? null,
      tags: productFilters?.productFiltersList || [],
      h1:
        aliasData?.meta?.h1_title ||
        pageProperties?.details?.h1 ||
        catalog?.h1 ||
        'Каталог техники',
      catalogItems: catalog?.Grid?.items?.GridCollection?.items || [],
      sortData: catalog?.Grid.items.GridHeader || null,
      paginatorData: {
        paginator: catalog?.Paginator || null,
        totalItems: catalog?.Grid.count_collection || null,
        pageSize: catalog?.Grid.items_pp || null,
      },
      countCollection: catalog?.Grid?.count_collection || '0',
      categoryText: {
        title: aliasData?.meta?.h1_title || catalog?.category || null,
        description: aliasData?.meta?.h1_text || catalog?.description || null,
        text: catalog?.text_2 || null,
      },
      monthlyOfferProducts: monthlyOffer?.products || [],
      monthlyOfferSupplier: monthlyOffer?.supplier || null,
      specialOfferProducts: specialOffer?.products || [],
      buyNowProducts: buyNowProducts?.products || [],
      sellers: catalog?.Sellers,
      specialOffersParamsTypes: specialOffersParamsTypes || [],
      pageProperties: aliasData?.meta
        ? {...pageProperties.meta, ...aliasData.meta}
        : pageProperties?.meta ?? {},
      url: `/catalog/${slugString}`,
      categoryURL: categoryURL ?? '',
      firstPositionBanners,
      productFilterId,
      showNoProductsMessage: Boolean(aliasData?.showNoProductsMessage),
    };
  } catch (e) {
    console.log('Catalog Props error', e);
    return {
      notFound: true,
    };
  }
};
