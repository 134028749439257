const parseJSON = <T>(str: string | null) => {
  if (!str) {
    return null;
  }

  try {
    return JSON.parse(str) as T;
  } catch (e) {
    return null;
  }
};

export default parseJSON;
