import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  deviceInfo,
  pageInfo,
  catalog,
  search,
  ui,
  chat,
  user,
  requestWizard,
  streetAutocomplete,
  digitalSign,
  regData,
  cabServices,
  newAttorneyWizard,
  roboDealWizard,
  roboAgentDealWizard,
  contactPersonsAttorney,
  cabinetContactAttorneyWizard,
  cabinetDocuments,
  cabOrderRequestWizard,
  nrsWizard,
  cabOrderNotificationWizard,
  cabOrderConfirmationWizard,
  beneficiaryQuestionnairies,
  beneficiaryQuestionnaireWizard,
  creditDeclarationWizard,
  geolocation,
  accreditation,
  workDays
} from './slices';

export const makeStore = () =>
  configureStore({
    reducer: {
      deviceInfo,
      pageInfo,
      catalog,
      search,
      ui,
      chat,
      user,
      requestWizard,
      newAttorneyWizard,
      streetAutocomplete,
      digitalSign,
      regData,
      cabinetDocuments,
      cabServices,
      roboDealWizard,
      roboAgentDealWizard,
      contactPersonsAttorney,
      cabinetContactAttorneyWizard,
      cabOrderRequestWizard,
      nrsWizard,
      cabOrderNotificationWizard,
      cabOrderConfirmationWizard,
      beneficiaryQuestionnairies,
      beneficiaryQuestionnaireWizard,
      creditDeclarationWizard,
      geolocation,
      accreditation,
      workDays
    },
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware();
      if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger as any);
      }
      return middlewares;
    },
  });

const store = makeStore();

export {store};
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
