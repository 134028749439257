import styled from 'styled-components';

export const DesktopHeaderWrapper = styled.div`
  @media (max-width: 698px) {
    display: none;
  }
`;

export const MobileHeaderWrapper = styled.div`
  display: none;
  @media (max-width: 698px) {
    display: block;
    background: white;
  }
`;
