import {theme} from '@styles/theme';
import styled from 'styled-components';

export const FooterMenuWrapper = styled.menu`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 30px;
  border-bottom: 1px solid ${({theme}) => theme.colors.blueGray};
  padding-bottom: 34.5px;
  margin-bottom: 20px;
  @media (max-width: 950px) {
    row-gap: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
  }
  @media (max-width: 550px) {
    row-gap: 10px;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
`;

export const List = styled.ul`
  flex: 0 0 25%;
  @media (max-width: 950px) {
    flex: 0 0 calc(50% - 20px);
    justify-self: center;
    text-align: center;
  }
  @media (max-width: 550px) {
    flex: 0 0 calc(50% - 20px);
  }
  @media (max-width: 470px) {
    flex: 0 0 calc(100% - 10px);
  }
`;

export const ListTitle = styled.li`
  a {
    font-family: ${theme.fonts.headline};
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${theme.colors.darkText};

    &:hover {
      font-weight: 600;
    }
  }
`;

export const ListItem = styled.li`
  width: 100%;
  a {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const TextLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  a {
    color: ${theme.colors.gray};
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 888px) {
    justify-content: space-around;
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  @media (max-width: 470px) {
    justify-content: space-between;
  }
`;
