import apiClient from '@api/entities/apiClient';
import {AxiosInstance} from 'axios';
import {AuthCSPActions} from './types/AuthCSPActions';
import {trackAuthEvent} from '@utils/analytics';
import {getCookie} from 'cookies-next';
import {isEmpty} from 'lodash';
import {Certificate} from 'crypto-pro';

export const authByLogin = async (login: string, password: string) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('login', login);
    bodyFormData.append('password', password);
    const {data} = await apiClient.post('/getauth', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const authByInn = async (
  inn: string,
  token: string,
  captcha: string,
  id?: string,
) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('inn', inn);
    bodyFormData.append('g-recaptcha-response', token);
    id && bodyFormData.append('id', id);
    const {data} = await apiClient.post('/GetAuthINN', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getAuthByRequisites = async ({
  inn,
  ogrn,
  kpp,
  code,
  token,
}: {
  inn: string;
  ogrn: string;
  kpp?: string;
  code?: string;
  token: string;
}) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('inn', inn);
    bodyFormData.append('ogrn', ogrn);
    bodyFormData.append('g-recaptcha-response', token);
    kpp && bodyFormData.append('kpp', kpp);
    code && bodyFormData.append('code', code);
    const {data} = await apiClient.post('/GetAuthByRequisites', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      auth: false,
      message: e,
      success: false,
    };
  }
};

export const createOrganizationAccount = async ({
  inn,
  ogrn,
  kpp,
  token,
  captcha,
}: {
  inn: string;
  ogrn: string;
  kpp?: string;
  token: string;
  captcha?: string;
}) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('inn', inn);
    bodyFormData.append('ogrn', ogrn);
    bodyFormData.append('g-recaptcha-response', token);
    kpp && bodyFormData.append('kpp', kpp);
    captcha && bodyFormData.append('captcha', captcha);
    const {data} = await apiClient.post('/CreateAccount', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const createPersonAccount = async (ssrAPIClient?: AxiosInstance) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/FL/Get', {});
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const companySearch = async (query: string) => {
  try {
    const {data} = await apiClient.get('/CompanySearch', {
      params: {
        query,
        //local: true
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const registrationGetCode = async ({
  field,
  value,
  token,
  captcha,
}: {
  field: 'email' | 'phone';
  value: string;
  token?: string;
  captcha?: string;
}) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('field', field);
    bodyFormData.append('value', value);
    token && bodyFormData.append('g-recaptcha-response', token);
    captcha && bodyFormData.append('captcha', captcha);
    const {data} = await apiClient.post('/Registration/GetCode', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const registrationCheckCode = async ({
  field,
  value,
  code,
  token,
  captcha,
}: {
  field: 'email' | 'phone';
  value: string;
  code: string;
  token?: string;
  captcha?: string;
}) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('field', field);
    bodyFormData.append('value', value);
    bodyFormData.append('code', code);
    token && bodyFormData.append('g-recaptcha-response', token);
    captcha && bodyFormData.append('captcha', captcha);
    const {data} = await apiClient.post('/Registration/CheckCode', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getCounteragentData = async (
  mode: 'profile' | 'registration',
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/CounteragentDataForm', {
      params: {
        mode,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const checkCounteragentData = async (ssrAPIClient?: AxiosInstance) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/CounteragentDataForm/CheckReady', {
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getDirectorData = async (
  mode: 'profile' | 'registration',
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/PersonalDataForm', {
      params: {
        mode,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getPersonalData = async (ssrAPIClient?: AxiosInstance) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/FLDataForm', {
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getAgreements = async ({ismobile}: {ismobile?: boolean}, ssrAPIClient?: AxiosInstance) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/Registration/GetAgreements', {
      params: {ismobile},
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const getAgreementPDF = async (
  {id_document, cert}: {id_document: string; cert: Certificate},
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/Registration/GetPDF', {
      params: {
        id_document,
        ...cert,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const setSignedData = async (
  {id_document, data}: {id_document: string; data: string},
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;

    const resp = await curAPIClient.post(
      '/Registration/SetSignedData',
      JSON.stringify({id_document, data, token: String(getCookie('authToken')) || ''}),
    );
    return resp.data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const checkAgreements = async (
  params: {ids_document?: string[]; cert?: string},
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const url = isEmpty(params) ? '/CheckAgreements' : '/Registration/CheckAgreements';
    const {data} = await curAPIClient.get(url, {
      params,
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const checkRegistration = async (ssrAPIClient?: AxiosInstance) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const {data} = await curAPIClient.get('/Registration/CheckRegistration', {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const postCounteragentData = async (
  {
    postData,
    mode,
  }: {
    postData: {[x: string]: any};
    mode: 'profile' | 'registration';
  },
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const bodyFormData = new URLSearchParams();
    for (const field in postData) {
      bodyFormData.append(field, postData[field]);
    }
    const {data} = await curAPIClient.post('/CounteragentDataForm', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        mode,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const postDirectorData = async (
  {
    postData,
    mode,
  }: {
    postData: {[x: string]: any};
    mode: 'profile' | 'registration';
  },
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const bodyFormData = new URLSearchParams();
    for (const field in postData) {
      bodyFormData.append(field, postData[field]);
    }
    const {data} = await curAPIClient.post('/PersonalDataForm', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        mode,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const postPersonalData = async (
  postData: {[x: string]: any},
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const bodyFormData = new URLSearchParams();
    for (const field in postData) {
      bodyFormData.append(field, postData[field]);
    }
    const {data} = await curAPIClient.post('/FLDataForm', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const postUserData = async (
  postData: {[x: string]: any},
  ssrAPIClient?: AxiosInstance,
) => {
  try {
    const curAPIClient = ssrAPIClient ?? apiClient;
    const bodyFormData = new URLSearchParams();
    for (const field in postData) {
      bodyFormData.append(field, postData[field]);
    }
    const {data} = await curAPIClient.post('/UserDataForm', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const authByInnConfirm = async (
  inn: string,
  token: string,
  captcha: string,
  code: string,
  id?: string,
) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('inn', inn);
    bodyFormData.append('g-recaptcha-response', token);
    bodyFormData.append('captcha', captcha);
    bodyFormData.append('code', code);
    id && bodyFormData.append('id', id);
    const {data} = await apiClient.post('/GetAuthINN', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const authBySms = async (phone: string, token: string) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('phone', phone);
    bodyFormData.append('g-recaptcha-response', token);
    const {data} = await apiClient.post('/GetAuthSMS', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const authBySmsConfirm = async (phone: string, token: string, code: string) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('phone', phone);
    bodyFormData.append('g-recaptcha-response', token);
    bodyFormData.append('code', code);
    const {data} = await apiClient.post('/GetAuthSMS', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const authByCSP = async (
  method: AuthCSPActions,
  params?: {
    signedMessage?: string;
    requisites?: {
      inn: string;
      m4d_number: string;
    };
    id_query?: string;
  },
) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('method', method);
    if (params) {
      const {signedMessage, requisites, id_query} = params;
      signedMessage && bodyFormData.append('dataCSP', signedMessage);
      if (requisites) {
        bodyFormData.append('inn', requisites.inn);
        bodyFormData.append('m4d_number', requisites.m4d_number);
      }
      if (id_query) {
        bodyFormData.append('id_query', id_query);
      }
    }

    const {data} = await apiClient.post('/AuthCSP', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const recoverPassword = async (any: string, token: string, code?: string) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('any', any);
    bodyFormData.append('g-recaptcha-response', token);
    if (code) {
      bodyFormData.append('code', code);
    }
    const {data} = await apiClient.post('/SetPassword', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      token: false,
      expired: '',
      account: [],
      groups: [],
    };
  }
};

export const recoverPasswordConfirm = async (
  any: string,
  code: string,
  password: string,
  token: string,
) => {
  try {
    const bodyFormData = new URLSearchParams();
    bodyFormData.append('any', any);
    bodyFormData.append('code', code);
    bodyFormData.append('password', password);
    bodyFormData.append('g-recaptcha-response', token);
    const {data} = await apiClient.post('/SetPassword', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    };
  }
};

export const generatePassword = async () => {
  try {
    const {data} = await apiClient.get('/GenPassword');
    return data;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    };
  }
};

export const getAccountForms = async (
  params: Record<string, any>,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  try {
    const {data} = await curAPIClient.get('/SetAccountWizard', {
      params,
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    };
  }
};

export const checkAccountPassType = async (pass_type: string) => {
  const {data} = await apiClient.post('/SetAccountWizard/checkPassType', null, {
    params: {pass_type},
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const setAccountInformation = async (
  params?: any,
  postData?: Record<string, any>,
  wrapperKey?: any,
) => {
  try {
    const bodyFormData = new URLSearchParams();
    if (postData && wrapperKey) {
      for (const field in postData) {
        if (postData[field] === true) {
          bodyFormData.append(`${wrapperKey}[${field}]`, '1');
          continue;
        }
        if (postData[field] === false) {
          bodyFormData.append(`${wrapperKey}[${field}]`, '0');
          continue;
        }
        if (
          typeof postData[field]?.guid !== 'undefined' ||
          typeof postData[field]?.code !== 'undefined'
        ) {
          const dataKladr =
            typeof postData[field] === 'string'
              ? JSON.parse(postData[field])
              : JSON.parse(JSON.stringify(postData[field]));
          if ('text' in dataKladr) delete dataKladr['text'];
          bodyFormData.append(`${wrapperKey}[${field}]`, JSON.stringify(dataKladr));
          continue;
        }
        bodyFormData.append(`${wrapperKey}[${field}]`, postData[field]);
      }
    }
    const {data} = await apiClient.post(
      '/SetAccountWizard',
      postData && wrapperKey ? bodyFormData : {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );

    if (data?.visited !== 1 && params.step === 1) {
      const res = await apiClient.post(
        '/SetAccountWizard',
        postData && wrapperKey ? bodyFormData : {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          middleware: {
            withAuth: true,
          },
        },
      );

      return res.data;
    }
    return data;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    };
  }
};

export const changeRegInfo = async (
  type: string,
  value: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append(type, value);
  try {
    const {data} = await curAPIClient.post('/RegInfoChange', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
    return {
      success: false,
    };
  }
};
