import { theme } from "@styles/theme";
import styled from "styled-components";

export const BranchDirectionModalMapWrapper = styled.div`
  background-color: ${theme.colors.white};
  position: relative;
  overflow: hidden;
  z-index: 0;
`;

export const MapWrapper = styled.div`
  width: 100%;
  font-size: 0;
  line-height: 0;
  display: block;
`;
