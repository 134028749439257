export const markets = [
  {
    icon: '/assets/icons/AppStore.svg',
    link: 'https://apps.apple.com/us/app/%D1%80%D0%BE%D1%81%D0%B0%D0%B3%D1%80%D0%BE%D0%BB%D0%B8%D0%B7%D0%B8%D0%BD%D0%B3/id1579641262#?platform=iphone',
    title: 'AppStore',
  },
  {
    icon: '/assets/icons/GP.svg',
    link: 'https://play.google.com/store/apps/details?id=ru.rosagroleasing.mobile',
    title: 'Google Play',
  },
  {
    icon: '/assets/icons/nashstore.svg',
    link: 'https://store.nashstore.ru/store/62bbfbcdfb3ed32a91915666',
    title: 'NashStore',
  },
  {
    icon: '/assets/icons/rustore.svg',
    link: 'https://apps.rustore.ru/app/ru.rosagroleasing.mobile',
    title: 'RuStore',
  },
];
