import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@hooks";
import { getCookie } from 'cookies-next';
import { getIsAuthThunk } from '@store/slices/user';

export const useAuth = () => {
	const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => Boolean(state.user.isAuth));

	const authToken = getCookie('authToken');

	useEffect(() => {
    dispatch(getIsAuthThunk({}));
  }, [authToken]);

	return { isAuth, authToken };
}