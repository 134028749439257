import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@store';
import {useWindowDimensions} from './useWindowDimensions';
import { useAuth } from './useAuth';
import { useNotifications } from './useNotifications';
import { useObserver } from './useObserver';
import { useHeaderBreadcrumbsAnalytic } from './useHeaderBreadcrumbsAnalytic';
import { useMangoOnlineChatCustomization } from './useMangoOnlineChatCustomization';

const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export {
    useAppDispatch, 
    useAppSelector, 
    useWindowDimensions, 
    useAuth, 
    useNotifications,
    useObserver,
    useHeaderBreadcrumbsAnalytic,
    useMangoOnlineChatCustomization
};
