import Image from 'next/image';
import {FC} from 'react';
import {ToastTransition, Zoom} from 'react-toastify';
import {ImageWrapper, StyledToastContainer} from './CustomToastContainer.styles';

interface CustomToastContainerProps {
  position?:
    | 'top-right'
    | 'top-center'
    | 'top-left'
    | 'bottom-right'
    | 'bottom-center'
    | 'bottom-left';
  hideProgressBar?: boolean;
  containerId?: string;
  autoClose?: number | false;
  transition?: ToastTransition | undefined;
  testID?: string;
}

const CustomToastContainer: FC<CustomToastContainerProps> = ({
  position,
  hideProgressBar = true,
  containerId,
  autoClose = false,
  transition = Zoom,
  testID,
}) => {
  return (
    <StyledToastContainer
      position={position}
      hideProgressBar={hideProgressBar}
      containerId={containerId}
      icon={false}
      autoClose={autoClose}
      data-testid={testID}
      closeButton={
        <ImageWrapper>
          <Image
            src="/assets/icons/closeBtnV2.svg"
            alt="Закрыть"
            width={12}
            height={12}
          />
        </ImageWrapper>
      }
      transition={transition}
      newestOnTop
    />
  );
};

export default CustomToastContainer;
