import {ICartItem} from '@api/types/Cart';
import {Breadcrumb} from '@api/types/Breadcrumbs';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {headerCategoryMenuBaseItems} from '@mockData/siteStructure';
import {clone} from 'lodash';
import {getCatalogCategories} from '@api/header';
import {RootState} from '@store';
import {getCatalogProps} from '@api/catalog';
import { getCookie } from 'cookies-next';
import { USER_FIASCODE } from '@constants/cookies';
import { CLIENT_UUID } from '@constants/paramNames';
import { CatalogCategoryProps } from '@pages/catalog/[...slug]';

interface CatalogState {
  favoriteItems: number[];
  favoriteSaleItems: number[];
  cartItems: ICartItem[];
  cartItemsIDs: number[];
  headerCategoryMenuItems: Breadcrumb[];
  compareItems: number[];
  catalogPagePropsLoading: boolean;
  catalogPageProps: CatalogCategoryProps | null;
}

const initialState: CatalogState = {
  favoriteItems: [],
  favoriteSaleItems: [],
  cartItems: [],
  cartItemsIDs: [],
  headerCategoryMenuItems: headerCategoryMenuBaseItems,
  compareItems: [],
  catalogPageProps: null,
  catalogPagePropsLoading: false
};

interface CatalogCategory {
  id: string;
  title: string;
  url: string;
  uri: string;
}

interface SaleCatalogMenu {
  title: string;
  url: string;
}

export const getCatalogPropsThunk = createAsyncThunk<any, string>(
  'getCatalogPropsThunk/fetch',
  async (url) => {

    const userFiascode = String(getCookie(USER_FIASCODE)) ?? '';

    const clientID = getCookie(CLIENT_UUID)
      ? String(getCookie(CLIENT_UUID))
      : '';

    const token = getCookie('authToken')
      ? String(getCookie('authToken'))
      : '';

    return await getCatalogProps(url, userFiascode, clientID, token);
  },
);

export const getCatalogCategoriesThunk = createAsyncThunk<any, any, {state: RootState}>(
  'getCatalogCategoriesThunk/fetch',
  async ({}, thunkAPI) => {
    const {catalog} = thunkAPI.getState();
    if (
      catalog?.headerCategoryMenuItems?.find(item => 
        item.path.startsWith('/catalog') || 
        item.path.startsWith('/sale/cata')
      )
    ) return await getCatalogCategories();

    return {categories: [], sale_categories: []};
  },
);

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    addToFavorite(state, action: PayloadAction<number | number[]>) {
      Array.isArray(action.payload)
        ? (state.favoriteItems = action.payload)
        : state.favoriteItems.push(action.payload);
      if (localStorage.getItem('favItems') === JSON.stringify(action.payload)) return;
      localStorage.setItem('favItems', JSON.stringify(state.favoriteItems));
    },
    removeFromFavorite(state, action: PayloadAction<number>) {
      state.favoriteItems = state.favoriteItems.filter(
        (itemID: number) => itemID !== action.payload,
      );
      localStorage.setItem('favItems', JSON.stringify(state.favoriteItems));
    },
    addToFavoriteSale(state, action: PayloadAction<number | number[]>) {
      Array.isArray(action.payload)
        ? (state.favoriteSaleItems = action.payload)
        : state.favoriteSaleItems.push(action.payload);
      if (localStorage.getItem('favSaleItems') === JSON.stringify(action.payload)) return;
      localStorage.setItem('favSaleItems', JSON.stringify(state.favoriteSaleItems));
    },
    removeFromFavoriteSale(state, action: PayloadAction<number>) {
      state.favoriteSaleItems = state.favoriteSaleItems.filter(
        (itemID: number) => itemID !== action.payload,
      );
      localStorage.setItem('favSaleItems', JSON.stringify(state.favoriteSaleItems));
    },
    addToCart(state, action: PayloadAction<ICartItem | ICartItem[]>) {
      if (Array.isArray(action.payload)) {
        state.cartItems = action.payload;
        state.cartItemsIDs = action.payload.map(item => {
          return item.id_product;
        });
      }
      if (
        !Array.isArray(action.payload) &&
        !state.cartItemsIDs.includes(action.payload.id_product)
      ) {
        state.cartItems.push(action.payload);
        state.cartItemsIDs.push(action.payload.id_product);
      }
      if (
        !Array.isArray(action.payload) &&
        state.cartItemsIDs.includes(action.payload.id_product)
      ) {
        const updatedItems: {id_product: number; string: number}[] | any =
          state.cartItems.map(el =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            el.id_product === action.payload.id_product
              ? clone(action.payload)
              : clone(el),
          );
        state.cartItems = updatedItems;
      }
      if (!Array.isArray(action.payload) && action.payload.quantity <= 0) {
        state.cartItems = state.cartItems.filter(item => item.quantity > 0);
        state.cartItemsIDs = state.cartItems.map(item => {
          return item.id_product;
        });
      }
      if (localStorage.getItem('cartItems') === JSON.stringify(action.payload)) return;
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action: PayloadAction<number | number[]>) {
      if (Array.isArray(action.payload)) {
        state.cartItems = state.cartItems.filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item => !action.payload?.includes(item.id_product),
        );
        state.cartItemsIDs = state.cartItems.map(item => {
          return item.id_product;
        });
      } else {
        state.cartItems = state.cartItems.filter(
          item => item.id_product !== action.payload,
        );
        state.cartItemsIDs = state.cartItems.map(item => {
          return item.id_product;
        });
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    addToCompare(state, action: PayloadAction<number | number[]>) {
      Array.isArray(action.payload)
        ? (state.compareItems = action.payload)
        : state.compareItems.push(action.payload);
      if (localStorage.getItem('compareItems') === JSON.stringify(action.payload)) return;
      localStorage.setItem('compareItems', JSON.stringify(state.compareItems));
    },
    removeFromCompare(state, action: PayloadAction<number>) {
      state.compareItems = state.compareItems.filter(
        (itemID: number) => itemID !== action.payload,
      );
      localStorage.setItem('compareItems', JSON.stringify(state.compareItems));
    },
    setCatalogPropsLoading(state, action: PayloadAction<boolean>) {
      state.catalogPagePropsLoading = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getCatalogCategoriesThunk.fulfilled, (state, action) => {
      const categories = action?.payload?.categories
        .filter((item: {count: string}) => Number(item.count))
        .map((item: {title: any; uri: any}) => ({
          title: item.title,
          path: `/catalog/${item.uri}/`,
        }));

      const saleCategories = action?.payload?.sale_categories.map(
        (item: {title: any; url: any}) => ({
          title: item.title,
          path: item.url,
        }),
      );

      const catalogItem = state.headerCategoryMenuItems.find(
        (item: Breadcrumb) => item.path === '/catalog/',
      );
      if (catalogItem) {
        catalogItem.children = categories;
      }

      const saleCatalogItem = state.headerCategoryMenuItems.find(
        (item: Breadcrumb) => item.path === '/sale/catalog/',
      );
      if (saleCatalogItem) {
        saleCatalogItem.children = saleCategories;
      }
    });
    builder.addCase(getCatalogPropsThunk.fulfilled, (state, action) => {
      state.catalogPagePropsLoading = false;
      state.catalogPageProps = action.payload;
    });
    builder.addCase(getCatalogPropsThunk.pending, (state, action) => {
      state.catalogPagePropsLoading = true;
    });
  },
});

export const {
  addToFavorite,
  removeFromFavorite,
  addToFavoriteSale,
  removeFromFavoriteSale,
  addToCart,
  removeFromCart,
  addToCompare,
  removeFromCompare,
  setCatalogPropsLoading
} = catalogSlice.actions;
export default catalogSlice.reducer;
