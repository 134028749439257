import {theme} from '@styles/theme';
import styled from 'styled-components';

export const ToTopButtonWrapper = styled.a`
  width: 50px;
  height: 50px;
  border: 1px solid ${theme.colors.brandGreenV2};
  border-radius: 4px;
  background-color: ${theme.colors.white};
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s;
  @media only screen and (orientation: portrait) {
    width: 40px;
    height: 40px;
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    width: 40px;
    height: 40px;
  }
  &:hover {
    background-color: ${theme.colors.lightGreen};
    color: ${theme.colors.brandGreenV2};
  }
  &:hover::before {
    ${theme.filters.brandGreenV2};
  }
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background: url('/assets/icons/arrowToTop.svg');
    background-repeat: no-repeat;
    background-size: 10px 5px;
    background-position: center;
    transition: all 0.25s;
    @media only screen and (orientation: portrait) {
      width: 40px;
      height: 40px;
    }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      width: 40px;
      height: 40px;
    }
  }
`;
