import apiClient from '@api/entities/apiClient';
import {IUTMData, IUTMQuery} from '@api/types/UTM';

export const setUTM = async (query: IUTMQuery) => {
  const {data} = await apiClient.get<IUTMData>(`/SetUTM`, {
    params: query,
  });
  if (data.success) {
    return data;
  }
};
