const pageWithDefaultBreadcrumbs = new Set([
  '/cabinet/v2/client/dashboard/',
  '/cabinet/v2/client/history/',
  '/cabinet/v2/client/apps/',
  '/cabinet/v2/client/sale/',
  '/cabinet/v2/client/documents/',
  '/cabinet/v2/client/orders/',
  '/cabinet/v2/client/services/',
  '/cabinet/v2/client/supplies/',
  '/cabinet/v2/client/help/',
  '/cabinet/v2/client/deal/create/',
  '/cabinet/v2/agent/contractors/new/',
  '/cabinet/v2/agent/contractors/',
  '/cabinet/v2/supplier/dashboard/',
  '/cabinet/v2/supplier/products/',
  '/cabinet/v2/supplier/deliveries/',
  '/cabinet/v2/supplier/content/',
  '/cabinet/v2/supplier/help/',
  '/cabinet/v2/supplier/products/?tab=nomenclature',
  '/cabinet/v2/supplier/products/?tab=pricelevel',
  '/cabinet/v2/supplier/products/?tab=reviews',
  '/cabinet/v2/supplier/products/?tab=consultant',
  '/cabinet/v2/supplier/products/?tab=interests',
  '/cabinet/v2/supplier/products/?tab=offers',
  '/cabinet/v2/supplier/content/?tab=card',
  '/cabinet/v2/supplier/content/?tab=documents',
  '/cabinet/v2/supplier/content/?tab=stores',
  '/cabinet/v2/supplier/deliveries/?tab=contracts',
  '/cabinet/v2/supplier/deliveries/?tab=shipping',
  '/cabinet/v2/supplier/deliveries/?tab=complaints',
  '/cabinet/v2/agent/help/',
  '/cabinet/v2/agent/dashboard/',
  '/cabinet/v2/agent/offers/',
  '/cabinet/v2/agent/offers/calculator/',
  '/cabinet/v2/agent/offers/create/',
  '/cabinet/v2/agent/applications/',
  '/cabinet/v2/factoring-customer/registers/',
  '/cabinet/v2/factoring-supplier/registers/',
  '/cabinet/v2/factoring-supplier/financing/',
  '/cabinet/v2/factoring-supplier/invoice/',
  '/cabinet/v2/factoring-supplier/documents/',
  '/cabinet/v2/factoring-supplier/reports/',
]);

export default pageWithDefaultBreadcrumbs;
