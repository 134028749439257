import apiClient from '@api/entities/apiClient';
import {bodyFormDataBuilder} from '@utils/formatters';
import { NextRouter } from 'next/router';

export const getBeneficiaryQuestionnairies = async (params?: Record<string, string | number>) => {
  const {data} = await apiClient.get('/CRMBeneficiaryQuestionnairies', {
    params,
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const createBeneficiaryQuestionnarie = async() => {
  const {data} = await apiClient.get(`/CRMBeneficiaryQuestionnairies/Create`, {
    middleware: {
      withAuth: true,
    },
  });

  return data;
}

export const deleteBeneficiaryQuestionnarie = async(id: number) => {
  const {data} = await apiClient.get(`/CRMBeneficiaryQuestionnairies/Delete/${id}`, {
    middleware: {
      withAuth: true,
    },
  });

  return data;
}

export const cancelBeneficiaryQuestionnarie = async(id: number) => {
  const {data} = await apiClient.get(`/CRMBeneficiaryQuestionnairies/Cancel/${id}`, {
    middleware: {
      withAuth: true,
    },
  });

  return data;
}

const beneficiaryQuestionnaireWizard = async ({
  params,
  postData,
  wrapperKey,
  router
}: {
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
  router: NextRouter
}) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    const {data} = await apiClient.post(
      '/CRMBeneficiaryQuestionnaireWizard',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    if (data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    return data;
  } catch (e) {
    console.log('Beneficiary questionnaire wizard error ', e);
    throw e;
  }
};

export const beneficiaryQuestionnaireWizardAPI = {
  beneficiaryQuestionnaireWizard,
};
