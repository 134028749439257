import React, {FC} from 'react';
import ModalHeader from '@components/atoms/ModalHeader';
import {useWindowDimensions} from '@hooks';
import {Map, Placemark, YMaps} from '@pbe/react-yandex-maps';
import {
  BranchDirectionModalMapWrapper,
  MapWrapper,
} from './BranchDirectionModalMap.styles';

interface BranchDirectionModalMapProps {
  title?: string;
  setModalOpen: (state: boolean) => void;
  latitude: string;
  longitude: string;
}

const BranchDirectionModalMap: FC<BranchDirectionModalMapProps> = ({
  title = 'Схема проезда',
  setModalOpen,
  latitude,
  longitude,
}) => {
  const {width} = useWindowDimensions();


  return (
    <BranchDirectionModalMapWrapper>
      <ModalHeader
        title={title}
        onClose={() => setModalOpen(false)}
        titleFontSize={width! <= 535 ? 20 : 36}
        closeBtnHeight={width! <= 535 ? 14 : 21}
        closeBtnWidth={width! <= 535 ? 14 : 21}
      />
      <MapWrapper>
        <YMaps>
          <Map
            state={{
              center: [+longitude, +latitude],
              zoom: 17,
            }}
            defaultOptions={{
              maxZoom: 20,
              minZoom: 3,
            }}
            height={width! < 450 ? 'calc(100vh - 190px)' : 'calc(100vh - 250px)'}
            style={{maxHeight: '60vh', height: width! < 450 ? 'calc(100vh - 190px)' : 'calc(100vh - 250px)', width: '80vw', maxWidth: '1130px'}}
            >
            
            <Placemark
              geometry={[+longitude, +latitude]}
              defaultOptions={{
                iconLayout: 'default#image',
                iconImageHref: '/assets/icons/locationIconFilled.svg',
                iconImageOffset: [-13, -18],
                iconImageSize: [27, 36],
              }}
            />
          </Map>
        </YMaps>
      </MapWrapper>
    </BranchDirectionModalMapWrapper>
  );
};

export default BranchDirectionModalMap;
