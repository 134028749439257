export const dynamicPaths = [
  {
    type: 'catalog_product',
    idRegExp: /^\/catalog\/item\/(\d+)\/$/,
  },
  {
    type: 'catalog_category',
    uriRegExp: /^\/catalog\/([\w-]+)\/$/,
  },
  {
    type: 'catalog_subcategory',
    uriRegExp: /^\/catalog\/([\w-]+)\/([\w-]+)\/$/,
  },
  {
    type: 'special_offer',
    idRegExp: /^\/specialoffers\/(\d+)\/$/,
  },
  {
    type: 'sale_catalog_product',
    idRegExp: /^\/sale\/(\d+)\/$/,
  },
  {
    type: 'prod_complex_object',
    idRegExp: /^\/sale\/objects\/(\d+)\/$/,
  },
  {
    type: 'provider',
    idRegExp: /^\/providers\/(\d+)\/$/,
  },
  {
    type: 'agent',
    idRegExp: /^\/agents\/(\d+)\/$/,
  },
  {
    type: 'news_item',
    idRegExp: /^\/company\/smi\/news\/(\d+)\/$/,
  },
  {
    type: 'photo',
    idRegExp: /^\/company\/smi\/photo\/(\d+)\/$/,
  },
  {
    type: 'publication',
    idRegExp: /^\/company\/smi\/publications\/(\d+)\/$/,
  },
  {
    type: 'video',
    idRegExp: /^\/company\/smi\/video\/(\d+)\/$/,
  },
  {
    type: 'contacts_item',
    idRegExp: /^\/company\/contacts\/(\d+)\/$/,
  },
  {
    type: 'championship',
    idRegExp: /^\/company\/championship\/item\/(\d+)\/$/,
  },
  {
    type: 'championship_category',
    idRegExp: /^\/company\/championship\/(\d+)\/$/,
  },
  {
    type: 'championship_category_gallery',
    idRegExp: /^\/company\/championship\/(\d+)\/galleries\/$/,
  },
  {
    type: 'championship_category_video',
    idRegExp: /^\/company\/championship\/(\d+)\/videos\/$/,
  },
  {
    type: 'championship_category_gallery_item',
    idRegExp: /^\/company\/championship\/\d+\/galleries\/(\d+)\/$/,
  },
  {
    type: 'championship_category_video_item',
    idRegExp: /^\/company\/championship\/\d+\/videos\/(\d+)\/$/,
  },
  {
    type: 'blog_article',
    idRegExp: /^\/company\/blog\/articles\/(\d+)\/$/,
  },
  {
    type: 'career_item',
    idRegExp: /^\/company\/career\/(\d+)\/$/,
  },
];
