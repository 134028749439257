import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {clientDocumentEdit} from '@api/cabinetDocuments';
import {IWizardField} from '@api/types/Wizard';
import {requestWizardMappings} from '@api/mappings/requestWizard';

interface CabinetDocumentsSliceState {
  stepSES: number;
  id_object: string;
  code: string;
  loading: boolean;
  wrapperKey: string;
  fields: IWizardField[] | null;
  initialValues: Record<string, any>;
}

const initialState: CabinetDocumentsSliceState = {
  stepSES: 0,
  id_object: '',
  code: '',
  loading: false,
  wrapperKey: '',
  fields: [],
  initialValues: {},
};

export const cabinetDocThunk = createAsyncThunk<
  Record<string, any>,
  {id_object: string; postData?: Record<string, any>; wrapperKey?: string},
  {state: RootState}
>('cabinetDocumentEdit/fetch', async ({id_object, postData, wrapperKey}, thunkAPI) => {
  const data = await clientDocumentEdit({
    id_document: id_object,
    postData,
    wrapperKey,
  });
  return data;
});

const cabinetDocumentsSlice = createSlice({
  name: 'cabinetDocuments',
  initialState,
  reducers: {
    setStepSES(state, action: PayloadAction<number>) {
      state.stepSES = action.payload;
    },
    setId(state, action: PayloadAction<string>) {
      state.id_object = action.payload;
    },
    setCodeFromSms(state, action: PayloadAction<string>) {
      state.code = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(cabinetDocThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      cabinetDocThunk.fulfilled,
      (state, action: PayloadAction<Record<string, unknown> | undefined>) => {
        const {payload} = action;

        state.loading = false;
        state.fields = Object.values((payload?.items as IWizardField[]) ?? []);
        state.wrapperKey = payload?.form_name as string;
        state.initialValues =
          requestWizardMappings.initialValuesMapper(payload?.items as IWizardField[]) ||
          {};
      },
    );
  },
});

export const {setStepSES, setId, setCodeFromSms} = cabinetDocumentsSlice.actions;

export default cabinetDocumentsSlice.reducer;
