import {
  addToFavorite,
  addToFavoriteSale,
  addToCart,
  addToCompare,
} from '@store/slices/catalog';
import {getFileWithCode, getPages} from '@store/slices/ui';
import {setToken} from '@store/slices/user';
import {AppDispatch} from '@store';
import {getCookie, hasCookie} from 'cookies-next';
import { getGeo, setGeo } from '@store/slices/geolocation';

export const initializeStoreItemsFromLocalStorage = (dispatch: AppDispatch) => {
  if (localStorage.getItem('favItems') !== null) {
    dispatch(addToFavorite(JSON.parse(localStorage.getItem('favItems')!)));
  } else {
    localStorage.setItem('favItems', JSON.stringify([]));
  }
  if (localStorage.getItem('favSaleItems') !== null) {
    dispatch(addToFavoriteSale(JSON.parse(localStorage.getItem('favSaleItems')!)));
  } else {
    localStorage.setItem('favSaleItems', JSON.stringify([]));
  }
  if (localStorage.getItem('cartItems') !== null) {
    dispatch(addToCart(JSON.parse(localStorage.getItem('cartItems')!)));
  } else {
    localStorage.setItem('cartItems', JSON.stringify([]));
  }
  if (localStorage.getItem('compareItems') !== null) {
    dispatch(addToCompare(JSON.parse(localStorage.getItem('compareItems')!)));
  } else {
    localStorage.setItem('compareItems', JSON.stringify([]));
  }
  if (window !== undefined && hasCookie('authToken')) {
    dispatch(setToken(String(getCookie('authToken'))));
  }
  if (localStorage.getItem('geo') !== null) {
    dispatch(setGeo(JSON.parse(localStorage.getItem('geo')!)));
  } else {
    dispatch(getGeo())
  }
};

export const getFilesWithCodeDuringInitialize = async (
  dispatch: AppDispatch,
  codes: string[],
) => {
  for await (const code of codes) {
    dispatch(getFileWithCode({code}));
  }
};

let retryCount: number;

export const getPagesDuringInitialize = async (dispatch: AppDispatch) => {
  try {
    console.log('Получаем список страниц на клиенте...');
    retryCount = 0;
    dispatch(getPages());
    console.log('Кэш списка страниц на клиенте обновлён:', new Date().toISOString());
  } catch (error) {
    console.error('Произошла ошибка при загрузке страниц на клиенте:', error);
    console.log(retryCount);
    retryCount++;
    const retryDelay = Math.min(2 ** retryCount * 1000, 3600000);
    console.log(
      `Повторная загрузка страниц на клиенте через ${retryDelay / 1000} секунд...`,
    );
    setTimeout(getPagesDuringInitialize, retryDelay);
  }
};
