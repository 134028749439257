import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IDLE, PENDING} from '@constants/loadingState';
import {RootState} from '@store';

import {CabServicesComplaintDetail} from '@api/types/CabinetServices';
import {getCabinetComplaint} from '@api/cabinetServices';

const initialValues: CabServicesComplaintDetail = {
  loading: IDLE,
  id: '-',
  code: '-',
  text: '-',
  ResponseContent: '-',
  id_File: {
    title: '',
    src: '',
    mime_type: '',
  },
  datePost: '',
};

export const servicesComplaintDetailThunk = createAsyncThunk<
  Record<string, any>,
  {
    id_complaint: string;
  },
  {state: RootState}
>(
  'servicesComplaintDetail/getCabinetComplaint/fetch',
  async ({id_complaint}, thunkAPI) => {
    return await getCabinetComplaint(id_complaint);
  },
);

const servicesComplaintDetailSlice = createSlice({
  name: 'servicesComplaintDetail',
  initialState: initialValues,
  reducers: {
    setCode(state, action) {
      state.code = action.payload;
    },
    setText(state, action) {
      state.text = action.payload;
    },
    setResponseContent(state, action) {
      state.ResponseContent = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(servicesComplaintDetailThunk.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(servicesComplaintDetailThunk.fulfilled, (state, action) => {
      const {payload} = action;
      if (payload?.success) {
        state.loading = IDLE;
        state.code = payload?.code;
        state.text = payload?.text;
        state.ResponseContent = payload?.ResponseContent;
        state.datePost = payload?.datePost;
        state.id_File = {
          title: payload?.id_File?.title || '',
          src: payload?.id_File?.src || '',
          mime_type: payload?.id_File?.mime_type || '',
        };
      }
    });
    builder.addCase(servicesComplaintDetailThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
  },
});

const {reducer, actions} = servicesComplaintDetailSlice;

export const {setCode, setText, setResponseContent} = actions;

export default reducer;
