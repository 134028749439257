import React, {FC, useEffect} from 'react';
import {
  Backdrop,
  ModalContainer,
  ModalContent,
  ModalWindowWrapper,
  ModalWrapper,
} from './ModalWindow.styles';
import {useAppDispatch, useAppSelector} from '@hooks';
import {setScrollWithoutModal} from '@store/slices/ui';

export interface ModalWindowContentPadding {
  desktop: string;
  mobile: string;
}

export interface ModalWindowProps {
  children: React.ReactNode;
  isOpen: boolean;
  top?: string;
  left?: string;
  testID?: string;
  width?: string;
  height?: string;
  scrollLock?: boolean;
  borderRadius?: string;
  background?: string;
  padding?: ModalWindowContentPadding;
  overflowIsVisible?: boolean;
  className?: string;
  noMargins?: boolean;
}

const ModalWindow: FC<ModalWindowProps> = ({
  children,
  isOpen,
  left,
  top,
  testID,
  width,
  height,
  scrollLock = false,
  overflowIsVisible,
  borderRadius,
  background,
  padding,
  className,
  noMargins,
}) => {
  // const {scrollWithoutModal} = useAppSelector(state => state.ui);
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   const removeScrollLock = () => {
  //     document.documentElement.style.scrollBehavior = 'auto';
  //     document.body.classList.remove('noscroll');
  //     document.body.style.top = '';
  //     window.scrollTo({left: 0, top: scrollWithoutModal, behavior: 'auto'});
  //     dispatch(setScrollWithoutModal(0));
  //     document.documentElement.style.scrollBehavior = 'smooth';
  //   }
  //   if (isOpen) {
  //     dispatch(setScrollWithoutModal(window.scrollY));
  //     document.body.style.top = `-${window.scrollY}px`;
  //     document.body.classList.add('noscroll');
  //   } else if (scrollWithoutModal) {
  //     removeScrollLock();
  //   }
  //   return () => {
  //     removeScrollLock();
  //   };
  // }, [isOpen]);

  return (
    <ModalWrapper isOpen={isOpen} className={className}>
      <ModalContainer
        isOpen={isOpen}
        top={top}
        left={left}
        data-testid={testID}
        width={width}
        height={height}
        scrollLock={scrollLock}
        borderRadius={borderRadius}
        overflowIsVisible={overflowIsVisible}
        noMargins={noMargins}>
        <ModalWindowWrapper
          isOpen={isOpen}
          onClick={e => e.stopPropagation()}
          top={top}
          left={left}>
          <ModalContent
            isOpen={isOpen}
            borderRadius={borderRadius}
            background={background}
            padding={padding}>
            {children}
          </ModalContent>
        </ModalWindowWrapper>
      </ModalContainer>
      <Backdrop
        isOpen={isOpen}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </ModalWrapper>
  );
};

export default ModalWindow;
