import {IAppsWizardInitialState, ICabAppsWizardField} from '@api/types/CabAppsWizard';
import {stepNames} from '@constants/requestWizard';
import {ISetRegDataItems} from '@api/types/CabinetContacts';
import parseJSON from '@utils/parseJSON';

interface ListItem {
  List: {items: {selected: any; value: any; checked?: string}[]};
}
interface FileItem {
  Files: {items: {title: string; id: string}[]};
}

const addFieldNamePrefix = (fieldName: string, prefix: string) => {
  return `${prefix}[${fieldName}]`;
};

const groupMonths = (items: any[]) => {
  const firstMonthFieldIndex = items?.findIndex(
    item => item.type === 'checkbox' && item.group === 'months',
  );

  if (typeof firstMonthFieldIndex === 'undefined' || firstMonthFieldIndex === -1)
    return items;
  const monthsLength = items.filter(
    item => item.type === 'checkbox' && item.group === 'months',
  ).length;
  const lastMonthFieldIndex = firstMonthFieldIndex + monthsLength - 1;
  const checkboxes = items.slice(firstMonthFieldIndex, lastMonthFieldIndex + 1);
  return [
    ...items.slice(0, firstMonthFieldIndex),
    {
      type: 'checkbox_group',
      field: 'months',
      checkboxes,
    },
    ...items.slice(lastMonthFieldIndex + 1),
  ];
};

const initialValuesMapper = (items: any[]) => {
  if (items && items.length > 0) {
    return items.reduce(
      (
        result: any,
        currentItem: {
          field: string;
          value: string;
          type: string;
          token: any;
          items: ListItem | FileItem;
          group?: string;
        },
      ) => {
        if (
          currentItem.field &&
          (currentItem.value || currentItem.value === '') &&
          currentItem.type !== 'separator'
        ) {
          result = {...result, [currentItem.field]: currentItem.value};
        }
        if (currentItem.type === 'csfr-token') {
          result = {...result, [currentItem.field]: currentItem.token};
        }
        if (currentItem.type === 'multiselect') {
          const multiselectValues: any[] = [];
          if ('List' in currentItem.items && currentItem.items.List.items) {
            currentItem.items.List.items.map(
              (checkbox: {selected: any; value: any; checked?: string}) => {
                if (checkbox.selected || Boolean(Number(checkbox?.checked))) {
                  multiselectValues.push(checkbox.value);
                }
              },
            );
          }
          result = {
            ...result,
            ['multiselect--' + currentItem.field]: multiselectValues,
          };
        }
        if (
          (currentItem.type === 'kladr' || currentItem.type === 'dyntable') &&
          currentItem.value
        ) {
          result = {
            ...result,
            [currentItem.field]: parseJSON(currentItem.value),
          };
        }
        if (currentItem.type === 'file_ajax') {
          if ('Files' in currentItem.items && currentItem.items?.Files?.items) {
            result = {...result, [currentItem.field]: currentItem.items.Files.items};
          } else {
            result = {...result, [currentItem.field]: []};
          }
        }
        if (currentItem.type === 'checkbox' && currentItem.value === '') {
          if (/_ne$/.test(currentItem.field)) {
            result = {...result, [currentItem.field]: true};
          } else {
            result = {...result, [currentItem.field]: false};
          }
        }
        if (currentItem.type === 'checkbox' && currentItem.group === 'months') {
          const months = result.months ?? {};
          result = {
            ...result,
            months: {...months, [currentItem.field]: currentItem.value},
          };
        }
        if (
          currentItem.type === 'cbtext' &&
          (currentItem.field === 'field_000_3' || currentItem.field === 'field_000_2')
        ) {
          result = {
            ...result,
            [currentItem.field]: !!currentItem.value
              ? parseJSON(currentItem.value)
              : {
                  ischecked: false,
                  text: '',
                },
          };
        }
        return result;
      },
      {},
    );
  }
};

const changeInitialValuesMapper = (
  stateItems: {[s: string]: unknown} | ArrayLike<unknown>,
  responseItems: any,
) => {
  let copyResponseItems: any = null;
  if (responseItems) {
    copyResponseItems = JSON.parse(JSON.stringify(responseItems));
  } else {
    copyResponseItems = null;
  }
  const stateItemsArray = Object.entries(stateItems);
  if (
    stateItemsArray &&
    copyResponseItems &&
    stateItemsArray.length > 0 &&
    copyResponseItems.length > 0
  ) {
    let changedInitialValues: any = stateItemsArray.reduce(
      (result: any[], currentItem) => {
        const field = currentItem[0];
        const value =
          typeof currentItem[1] === 'object'
            ? JSON.stringify(currentItem[1])
            : currentItem[1];
        const findItem = copyResponseItems.find(
          (el: {field: string}) => el.field === field,
        );
        const findIndex = copyResponseItems.findIndex(
          (el: {field: string}) => el.field === field,
        );
        if (findItem && findItem?.type !== 'csfr-token') {
          findItem.value = typeof value === 'boolean' ? (value ? '1' : '0') : value;
          result = [...result, findItem];
          copyResponseItems.splice(findIndex, 1);
        } else if (findItem?.type === 'csfr-token') {
          result = [...result, findItem];
          copyResponseItems.splice(findIndex, 1);
        }
        return result;
      },
      [],
    );
    changedInitialValues = [...changedInitialValues, ...copyResponseItems];
    return initialValuesMapper(changedInitialValues);
  }
};

const initialValuesRegDataMapper = (items: any[]) => {
  if (items && items.length > 0) {
    return items.reduce(
      (
        result: any,
        currentItem: {
          field: string;
          value: {text: string; json: string; title: string; id: string} | string;
          type: string;
          token: any;
          items: {List: {items: {selected: any; value: any}[]}};
        },
      ) => {
        if (!currentItem.field) {
          return result;
        }
        if (currentItem.type === 'kladr' && currentItem.value) {
          try {
            let value: any = '';
            value = JSON.parse(String(currentItem.value));
            result[currentItem.field] = value;
            return result;
          } catch (e) {
            result[currentItem.field] = '';
            return result;
          }
        }
        if (/kladr/.test(currentItem.field) && typeof currentItem.value === 'object') {
          let value: any = '';
          if (currentItem.value?.json) {
            value = JSON.parse(currentItem.value.json);
            value['text'] = currentItem.value.text;
          }
          result[currentItem.field] = value;
        } else if (currentItem.field === 'id_UListItem') {
          const value =
            typeof currentItem.value === 'string'
              ? currentItem.value
              : currentItem.value?.title ?? '';
          result[currentItem.field] = value;
        } else {
          result[currentItem.field] = currentItem.value === null ? '' : currentItem.value;
        }
        return result;
      },
      {},
    );
  }
};

const changeInitialValuesRegDataMapper = (
  stateItems: {[s: string]: unknown} | ArrayLike<unknown>,
  responseItems?: ISetRegDataItems[][],
) => {
  const copyResponseItems = [...(responseItems ?? [])];
  const stateItemsArray = Object.entries(stateItems);
  if (
    stateItemsArray &&
    copyResponseItems &&
    stateItemsArray.length > 0 &&
    copyResponseItems.length > 0
  ) {
    let changedInitialValues: any = stateItemsArray.reduce(
      (result: any[], currentItem) => {
        const value =
          typeof currentItem[1] === 'object'
            ? JSON.stringify(currentItem[1])
            : currentItem[1];
        let findItem;
        for (const el of responseItems ?? []) {
          findItem = el.find(el => el.field === currentItem[0]);
          if (findItem) {
            break;
          }
        }
        if (findItem) {
          findItem.value =
            typeof value === 'boolean' ? (value ? '1' : '0') : (value as string);
          result.push(findItem);
        }
        return result;
      },
      [],
    );
    changedInitialValues = [...changedInitialValues, ...copyResponseItems];
    return initialValuesMapper(changedInitialValues);
  }
};

const screensMapper = (items: ICabAppsWizardField[]) => {
  let localTotalArr: any[] = [];
  let localSubArr: any[] = [];

  if (items && items.length > 0) {
    items.map((item, index) => {
      if (item.type && item.type !== 'csfr-token') {
        if (item.type === 'separator' && localSubArr.length > 0) {
          localTotalArr = [...localTotalArr, localSubArr];
          localSubArr = [item];
        } else {
          localSubArr = [...localSubArr, item];
        }
        if (index === items.length - 1 && localSubArr.length > 0) {
          localTotalArr = [...localTotalArr, localSubArr];
        }
      }
    });
  }

  return localTotalArr;
};
const checkFormName = (data: {
  ctrl: any;
  PublicGrid: {items: {GridFilter: {items: any}; GridCollection: {items: any}}};
  Documents: {items: {Document: any}};
  canSES: any;
}) => {
  switch (data.ctrl) {
    case stepNames.stepTwo:
      return {
        ...data,
        FormBuilder: {
          form_name: data.ctrl,
          items: [
            {type: 'filterList', items: data.PublicGrid.items.GridFilter.items},
            {
              type: 'separator',
              title: '',
              field: 'separator_1',
              value: '',
            },
            {
              type: 'productList',
              items: data.PublicGrid.items.GridCollection.items,
            },
          ],
        },
      };
    case stepNames.stepEight:
      return {
        ...data,
        FormBuilder: {
          form_name: data.ctrl,
          items: [
            {
              type: 'downloadRequest',
              doc: {...data?.Documents?.items?.Document, canSes: data.canSES},
            },
          ],
        },
      };

    default:
      return data;
  }
};

// Pagination
const getPaginationParams = ({
  state,
  page,
  resetPagination,
}: {
  state: IAppsWizardInitialState;
  page?: number;
  resetPagination: boolean;
}) => {
  const paginationParams: Partial<{nPaginator: string; page: number}> | null = {};
  if (!resetPagination && state.pagination.lastLoadedPage !== null) {
    paginationParams.nPaginator = state.pagination.paginator ?? '1';
    paginationParams.page = page ?? state.pagination.lastLoadedPage + 1;
  }
  return paginationParams;
};

// Sorting
const getSortingParams = ({
  state,
  resetSorting,
}: {
  state: IAppsWizardInitialState;
  resetSorting: boolean;
}) => {
  const sortingParams: any = {};
  const prefix: string = state.meta.formName;
  const sorting = state.sort.values;
  if (!resetSorting) {
    if (sorting.sort) {
      sortingParams[`${prefix}[by]`] = sorting.by;
    }
    if (sorting.by) {
      sortingParams[`${prefix}[sort]`] = sorting.sort;
    }
  }
  return sortingParams;
};

interface IFiltersMapping {
  params: Record<string, any>;
  prefix: string;
  name: string;
  value: Record<string, any>;
}

// Filters
const setFilteringParamsForTextType = ({
  params,
  prefix,
  name,
  value,
}: IFiltersMapping) => {
  params[`${prefix}[filter][${name}]`] = value;
};

const setFilteringParamsForCheckboxType = ({
  params,
  prefix,
  name,
  value,
}: IFiltersMapping) => {
  params[`${prefix}[filter][${name}]`] = value ? 1 : 0;
};

const setFilteringParamsForSliderType = ({
  params,
  prefix,
  name,
  value,
}: IFiltersMapping) => {
  if (value?.from && value?.to) {
    params[`${prefix}[filter][${name}][from]`] = value.from;
    params[`${prefix}[filter][${name}][to]`] = value.to;
  }
};

const setFilteringParamsForSelectType = ({
  params,
  prefix,
  name,
  value,
}: IFiltersMapping) => {
  if (value?.value) {
    params[`${prefix}[filter][${name}]`] = value.value;
  }
};

const setFilteringParamsForTreeType = ({
  params,
  prefix,
  name,
  value,
}: IFiltersMapping) => {
  const values = value
    ? Object.values(value)
        .flat()
        .map((el: {value: string}) => el.value)
    : [];
  params[`${prefix}[filter][${name}]`] = values;
};

const filtersMap: Record<string, any> = {
  text: setFilteringParamsForTextType,
  checkbox: setFilteringParamsForCheckboxType,
  slider: setFilteringParamsForSliderType,
  select: setFilteringParamsForSelectType,
  tree: setFilteringParamsForTreeType,
};

const getFilteringParams = ({
  state,
  resetFilters,
}: {
  state: IAppsWizardInitialState;
  resetFilters: boolean;
}) => {
  const filteringParams = {};
  const prefix = state.meta.formName;
  const filtering = state.filters.values;
  if (!resetFilters) {
    for (const [name, value] of Object.entries(filtering)) {
      const field = state.filters.list.find(field => field.field === name);
      if (!field) {
        continue;
      }
      const paramsSetter = filtersMap[field.type];
      if (typeof paramsSetter !== 'function') {
        continue;
      }
      paramsSetter({
        params: filteringParams,
        prefix,
        name,
        value,
      });
    }
  }
  return filteringParams;
};

export const requestWizardMappings = {
  checkFormName,
  addFieldNamePrefix,
  initialValuesMapper,
  screensMapper,
  changeInitialValuesMapper,
  initialValuesRegDataMapper,
  changeInitialValuesRegDataMapper,
  getPaginationParams,
  getSortingParams,
  getFilteringParams,
  groupMonths,
};
