import { NextRouter } from 'next/router';
import apiClient from './entities/apiClient';
import {Dispatch, SetStateAction} from 'react';

const cabinetAttorneyWizard = async ({
  params,
  postData,
  wrapperKey,
  router
}: {
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
  router: NextRouter
}) => {
  try {
    const bodyFormData = new FormData();
    for (const field in postData) {
      //   if (field === 'm4d_image_client') continue;
      if (postData[field] === false) {
        bodyFormData.append(`${wrapperKey}[${field}]`, '0');
        continue;
      }
      if (field === 'm4d_image_client' && postData[field].length !== 0) {
        bodyFormData.append(`${wrapperKey}[${field}][]`, postData[field][0].id);
        continue;
      }
      bodyFormData.append(`${wrapperKey}[${field}]`, postData[field]);
    }

    const {data} = await apiClient.post('/CabinetAttorneyWizard', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    if (data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    return data;
  } catch (e) {
    console.log('Cabinet attorney wizard error ', e);
    throw e;
  }
};

const uploadAttorneyFile = async ({
  file,
  id_object,
  setProgress,
  router
}: {
  file: File,
  id_object: string,
  setProgress: Dispatch<SetStateAction<number>>,
  router: NextRouter
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetContactSetM4DAttorneyControl:AttorneyWizardControl:AjaxFileUpload',
    );
    bodyFormData.append('field', 'm4d_image_client');
    bodyFormData.append('file[0]', file);
    const {
      data: {items, error, success, errcode},
    } = await apiClient.post('/CabinetAttorneyWizard', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        id_object,
        step: 2,
      },
      middleware: {
        withAuth: true,
      },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });
    if (errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    if (success) {
      return {
        title: items[0]?.name ?? '',
        id: items[0]?.id ?? '',
        mimeType: items[0]?.mimeType ?? file.type
      };
    } else {
      return {
        title: '',
        id: '',
        error,
      };
    }
  } catch (e) {
    console.log('Error upload file ', e);
    throw e;
  }
};

export const cabinetAttorneyWizardAPI = {
  cabinetAttorneyWizard,
  uploadAttorneyFile,
};
