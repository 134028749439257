import {getBeneficiaryQuestionnairies} from '@api/beneficiaryQuestionnaire';
import {ISortItem} from '@api/types/Sort';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import capitalize from 'lodash/capitalize';

export interface IBeneficiaryQuestionnairiesFilter {
  field: string;
  title: string;
  type: string;
  list?: Record<string, string | number>[];
}

interface IBeneficiaryQuestionnairiesState {
  loading: typeof IDLE | typeof PENDING;
  actionLoading: typeof IDLE | typeof PENDING;
  wizard: any;
  items: [];
  fields: [];
  filters: IBeneficiaryQuestionnairiesFilter[];
  sortables: ISortItem[];
  pagination: {
    pages: number;
    count: 0;
  };
  can_create: boolean;
  error_message: string;
  listParams: Record<string, string | number>;
  titles: Record<string, string>;
}

const initialState: IBeneficiaryQuestionnairiesState = {
  loading: IDLE,
  actionLoading: IDLE,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    steps: {},
  },
  items: [],
  fields: [],
  filters: [],
  sortables: [],
  pagination: {
    pages: 0,
    count: 0,
  },
  can_create: false,
  error_message: '',
  listParams: {},
  titles: {},
};

export const beneficiaryQuestionnairiesThunk = createAsyncThunk<
  Record<string, unknown>,
  {params?: Record<string, string | number>},
  {state: RootState}
>('beneficiaryQuestionnairies/fetch', async ({params}) => {
  const data = await getBeneficiaryQuestionnairies(params);

  return {
    ...data,
    listParams: params ?? {},
  };
});

const beneficiaryQuestionnairiesSlice = createSlice({
  name: 'beneficiaryQuestionnairies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(beneficiaryQuestionnairiesThunk.pending, state => {
      state.loading = PENDING;
    });

    builder.addCase(
      beneficiaryQuestionnairiesThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        const {payload} = action;
        state.loading = IDLE;
        state.filters = payload.filters.map(
          (filter: IBeneficiaryQuestionnairiesFilter) => {
            if (filter.type === 'select' && filter.list) {
              return {
                ...filter,
                items: [
                  {title: 'Не выбрано', value: '0'},
                  ...filter.list.map((item: Record<string, string | number>) => ({
                    value: String(item.id),
                    title: item.title,
                  })),
                ],
              };
            }
            if (filter.field === 'code') {
              return {
                ...filter,
                placeholder: 'Введите номер',
              };
            }
            return filter;
          },
        );
        state.listParams = payload.listParams ?? {};
        state.sortables = payload.sortables.map((item: ISortItem) => ({
          ...item,
          sortable: '1',
          sort:
            state.listParams?.sort === item.field ? state.listParams.direction : 'asc',
          active: state.listParams?.sort === item.field,
        }));
        state.items = payload.items;
        state.pagination = {
          pages: payload.pages,
          count: payload.count,
        };

        state.can_create = payload.can_create;
        state.error_message = payload.error_message;

        if (payload.titles) {
          Object.entries(payload.titles).forEach(([key, value]) => {
            if (value) {
              state.titles[key] = capitalize(value as string);
            }
          });
        }
      },
    );
  },
});

export default beneficiaryQuestionnairiesSlice.reducer;
