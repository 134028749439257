import {checkIsWork} from './../../api/chat';
import {getChatAuth, getChatMessages, setChatAuth, setChatMessage} from '@api/chat';
import {IChatForm, IChatConsultant} from '@api/types/Chat';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ChatSliceState {
  chatIsOpen: boolean;
  isWork: boolean;
  isBusy: boolean;
  busyText: string;
  chatToken: string | null;
  messages: any[];
  authSuccess: boolean;
  errorMessage: string | null;
  consultant: IChatConsultant;
  questionSuccess: boolean;
  firstGetMessages: boolean;
  lastMessageID: string;
}

const initialState: ChatSliceState = {
  chatIsOpen: false,
  chatToken: null,
  isWork: false,
  isBusy: false,
  busyText: '',
  messages: [],
  authSuccess: false,
  errorMessage: null,
  consultant: {name: 'Росагролизинг', photo: '', position: ''},
  questionSuccess: false,
  firstGetMessages: false,
  lastMessageID: '0',
};

export const fetchSetAuth = createAsyncThunk(
  'chat/fetchSetAuth/fetch',
  async (params: IChatForm) => {
    try {
      return await setChatAuth(params);
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

export const fetchGetAuth = createAsyncThunk(
  'chat/fetchGetAuth/fetch',
  async (chatToken: string) => {
    try {
      return await getChatAuth(chatToken);
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

export const fetchSetChatMessage = createAsyncThunk(
  'chat/fetchSetChatMessage/fetch',
  async ({
    chatToken,
    message,
    idMessageLast,
  }: {
    chatToken: string;
    message: string;
    idMessageLast: number;
  }) => {
    try {
      return await setChatMessage(chatToken, message, idMessageLast);
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

export const fetchGetChatMessages = createAsyncThunk(
  'chat/fetchGetChatMessages/fetch',
  async ({chatToken, idMessageLast}: {chatToken: string; idMessageLast: number}) => {
    try {
      return await getChatMessages(chatToken, idMessageLast);
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

export const fetchCheckIsWork = createAsyncThunk(
  'chat/fetchCheckIsWork/fetch',
  async () => {
    try {
      return await checkIsWork();
    } catch (e: any) {
      throw new Error(e);
    }
  },
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    toggleChatOpen(state) {
      state.chatIsOpen = !state.chatIsOpen;
    },
    setChatOpen(state) {
      state.chatIsOpen = true;
    },
    setChatClose(state) {
      state.chatIsOpen = false;
    },
    setChatToken(state, action: PayloadAction<string>) {
      state.chatToken = action.payload;
    },
    chatLogout() {
      localStorage.removeItem('chatToken');
    },
    setQuestionSuccess(state, action: PayloadAction<boolean>) {
      state.questionSuccess = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSetAuth.fulfilled, (state, action) => {
      const {payload} = action;
      state.chatToken = payload?.chat_token;
      state.messages = [];
      // don't move down
      if (
          !payload.error_message &&
          !state?.isWork &&
          payload?.chat_token.length !== 0 &&
          payload?.success
      ) {
        state.questionSuccess = true;
      }
      state.isWork = payload?.isWork;
      state.isBusy = payload?.isBusy;
      state.busyText = payload?.BusyText;
      state.authSuccess = payload?.success;
      state.errorMessage = payload?.error_message;
      if (payload?.chat_token) {
        localStorage.setItem('chatToken', payload?.chat_token);
      }
    });
    builder.addCase(fetchGetAuth.fulfilled, (state, action) => {
      const {payload} = action;
      state.chatToken = payload?.chat_token;
      state.messages = [];
      state.isWork = payload?.isWork;
      state.isBusy = payload?.isBusy;
      state.busyText = payload?.BusyText;
      state.authSuccess = payload?.success;
      state.errorMessage = payload?.error_message;
    });
    builder.addCase(fetchSetChatMessage.fulfilled, (state, action) => {
      const {payload} = action;
      if (state.messages.length.toString() !== payload?.messages.toString()) {
        state.messages = payload?.messages;
      }
      state.isWork = payload?.isWork;
      state.isBusy = payload?.isBusy;
      state.busyText = payload?.BusyText;
      state.errorMessage = payload?.error_message;
    });
    builder.addCase(fetchGetChatMessages.fulfilled, (state, action) => {
      const {payload} = action;
      if (state.messages.length.toString() !== payload?.messages.toString()) {
        state.messages = payload?.messages;
      }
      state.isWork = payload?.isWork;
      state.isBusy = payload?.isBusy;
      state.busyText = payload?.BusyText;
      state.errorMessage = payload?.error_message;
      if (payload?.consultant?.name) {
        state.consultant = payload.consultant;
      } else {
        state.consultant = {name: 'Росагролизинг', photo: '', position: ''};
      }
      if (payload?.messages.length > 0 && state.lastMessageID === '0') {
        state.lastMessageID =
          String(+payload?.messages.at(-1)?.id - 1) || state.lastMessageID;
      }
    });
    builder.addCase(fetchCheckIsWork.fulfilled, (state, action) => {
      const {payload} = action;
      state.isWork = payload?.isWork;
      state.isBusy = payload?.isBusy;
      state.busyText = payload?.BusyText;
      state.errorMessage = payload?.error_message;
    });
  },
});

export const {
  setChatClose,
  toggleChatOpen,
  setChatToken,
  setQuestionSuccess,
  setChatOpen,
} = chatSlice.actions;
export default chatSlice.reducer;
