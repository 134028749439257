import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {DEV_PROXY_SERVER_ENABLE} from '@config/proxy';
import {getCookie} from 'cookies-next';

const baseURL = DEV_PROXY_SERVER_ENABLE
  ? process.env.DEV_PROXY_SERVER_URL
  : process.env.API_BASE_URL;

const createApiClient = (ssrToken?: string) => {
  const client = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: false
  });

  client.interceptors.request.use(
    async (request: AxiosRequestConfig<any>) => {
      const token = ssrToken || getCookie('authToken');
      const tr_token = getCookie('__atr');
      if (token && request.middleware?.withAuth) {
        if (!request.params) {
          request.params = {};
        }
        request.params.token = token;
        if (tr_token) {
          request.params.tr = tr_token;
        }
      }
      return request;
    },
    (error: any) => {
      Promise.reject(error);
    },
  );

  client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

  return client;
};

let apiClient: AxiosInstance;

export const getApiClient = (token?: string) => {
  if (typeof window == 'undefined') {
    // server, never reuse apiClient, create new
    return createApiClient(token);
  } else {
    // browser, reuse apiClient
    if (!apiClient) {
      apiClient = createApiClient();
    }
    return apiClient;
  }
};

export default apiClient = getApiClient();
