import localFont from 'next/font/local';
import {css, keyframes} from 'styled-components';

export const exo2 = localFont({
  src: [
    {
      path: '../../public/fonts/Exo2-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Exo2-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Exo2-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Exo2-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const roboto = localFont({
  src: [
    {
      path: '../../public/fonts/Roboto-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Roboto-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Roboto-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Roboto-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const rubik = localFont({
  src: [
    {
      path: '../../public/fonts/Rubik-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Rubik-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
  ],
});

const separatorStyle = `
  left: 0;
  right: 0;
  content: '';
  position: absolute;
  background-color: #DBDBDB;
  height: 0.5px;
  min-height: 0.5px;
  @-moz-document url-prefix() {
    height: 1px;
    min-height: 1px;
  }`;

export const theme = {
  colors: {
    white: '#FFF',
    softWhite: '#fafafa',
    lightBG: '#F6F6F6',
    darkV2: '#2B2A2A',
    brandGreen: '#0E755B',
    brandGreenV2: '#00755A',
    brandGreenV2Aplha5: 'rgba(0, 117, 90, .5)',
    greenBG: '#E6F1EF',
    yellowBG: '#FFFDE6',
    brandYellow: '#F9D55E',
    brandYellowV2: '#FCF37B',
    lightGreen: '#66AC9C',
    blueGray: '#CECFDA',
    paleGray: '#F9F9FA',
    darkPurple: '#190B28',
    lavender: '#685762',
    opalRed: '#EB5E55',
    darkRed: '#750000',
    pinkish: '#F896D8',
    lightBlue: '#A0DDFF',
    violetBlue: '#8093F1',
    violetBlueLight: '#E3ECFA',
    littleTransparentWhite: 'rgba(255, 255, 255, 0.6)',
    gray: '#555555',
    lightGray: '#F9F9FA',
    lightGrayV2: '#DBDBDB',
    lightGrayV3: '#8F8F8F',
    lightGrayV4: '#EBEBEB',
    darkGray: '#282828',
    darkGreen: '#005642',
    darkText: '#2B2A2A',
    blackHalfOpacity: 'rgba(0, 0, 0, 0.5);',
    blackOpacity02: 'rgba(0, 0, 0, 0.2)',
    blackOpacity03: 'rgba(0, 0, 0, 0.3)',
    blackOpacity06: 'rgba(0, 0, 0, 0.6);',
    lightYellow: '#FFFDE5',
    bgGray: '#FAFAFA',
    scrollbar: '#ededed',
    scrollbarThumb: '#b1b1b1',
    paleGreen: '#E6F1EF',
    orange: '#FFC700',
    orangeBG: 'rgb(255, 249, 229)',
    opalRedBG: 'rgb(253, 238, 238)',
    brandGreenV2BG: 'rgb(229, 241, 238)',
    blue: '#529FF9',
    blueBG: '#E6F0FF',
    blueBGV2: '#D4E5FF',
    redBG: '#FDDCDA',
    tealIce: '#D2EFE8',
    birderRead: '#F0F0F0',
  },
  fonts: {
    headline: exo2.style.fontFamily,
    subline: roboto.style.fontFamily,
    rubik: rubik.style.fontFamily,
  },
  filters: {
    white:
      'filter: invert(100%) sepia(0%) saturate(7464%) hue-rotate(111deg) brightness(98%) contrast(108%);',
    brandGreenV2:
      'filter: brightness(0) saturate(100%) invert(30%) sepia(44%) saturate(1476%) hue-rotate(128deg) brightness(98%) contrast(101%);',
    brandYellowV2:
      'filter: invert(97%) sepia(87%) saturate(689%) hue-rotate(346deg) brightness(97%) contrast(104%);',
    darkText:
      'filter: invert(13%) sepia(4%) saturate(247%) hue-rotate(314deg) brightness(97%) contrast(89%);',
    lightGreen:
      'filter: invert(69%) sepia(24%) saturate(545%) hue-rotate(116deg) brightness(86%) contrast(90%);',
    gray: 'filter: brightness(0) saturate(100%) invert(31%) sepia(0%) saturate(0%) hue-rotate(218deg) brightness(99%) contrast(87%);',
    black:
      'filter: invert(0%) sepia(7%) saturate(13%) hue-rotate(317deg) brightness(86%) contrast(100%);',
    opalRed:
      'filter: invert(47%) sepia(72%) saturate(568%) hue-rotate(314deg) brightness(93%) contrast(98%);',
    lightBlue:
      'filter: invert(53%) sepia(74%) saturate(1686%) hue-rotate(191deg) brightness(102%) contrast(95%);',
    red: 'filter: invert(47%) sepia(9%) saturate(5680%) hue-rotate(322deg) brightness(101%) contrast(85%)',
  },
  rounded: '4px',
  flexColumn: `display: flex;
                flex-direction: column;`,
  flexSpaceBetween: `display: flex;
                     justify-content: space-between;`,
  flexAlignItemsCenter: `display: flex;
                          align-items: center;`,
  flexCenter: `display: flex;
               align-items: center;
               justify-content: center;`,
  topSeparator: `
  position: relative;
    :before {
      top: 0;
      ${separatorStyle}
    }
  `,
  bottomSeparator: `
  position: relative;
    :before {
      bottom: 0;
      ${separatorStyle}
    }
  `,
};

export const fontStyles = {
  title1_mob: `
    font-family: ${theme.fonts.headline};
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.25px;
    color: ${theme.colors.darkText};
  `,
  title2: `
    font-family: ${theme.fonts.headline};
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.25px;
    color: ${theme.colors.darkText};
    @media (max-width: 520px) {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  title2_mob: `
    font-family: ${theme.fonts.headline};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.25px;
  `,
  title3: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: ${theme.colors.darkText};
  `,
  title3_mob: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0px;
    color: ${theme.colors.darkText};
    text-transform: none;
  `,
  title3_1: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  `,
  title4: `
    font-family: ${theme.fonts.headline};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 2px;
    color: ${theme.colors.darkText};
    text-transform: uppercase;
  `,
  title5: `
    font-family: ${theme.fonts.headline};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.colors.darkText};
  `,
  mainText1: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
  `,
  mainText2: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: ${theme.colors.darkText};
  `,
  mainText3: `
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
  `,
  additional: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: ${theme.colors.gray};
  `,
  small: `
    font-family: ${theme.fonts.subline};
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${theme.colors.darkText};
  `,
  M3_label_large: `
    font-family: ${theme.fonts.subline};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  `,
};

export const blinkingOpacity = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
`;

export const loadingFormAnimation = css`
  animation: 3s linear 1s infinite ${blinkingOpacity};
`;

export const handleStyleSlider = {
  backgroundColor: `${theme.colors.white}`,
  border: `2px solid ${theme.colors.brandYellow}`,
  boxShadow: 'none',
  height: '20px',
  width: '20px',
  marginTop: '-8px',
  opacity: '1',
};

export type ThemeT = typeof theme;
