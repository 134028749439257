import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface IWorkDay {
  date: string;
  time: {from: string; to: string};
}

interface WorkDaysState {
  days: IWorkDay[];
}

const initialState: WorkDaysState = {
  days: [],
};

const workDaysSlice = createSlice({
  name: 'workDays',
  initialState,
  reducers: {
    setWorkDays(state, action: PayloadAction<IWorkDay[]>) {
      state.days = action.payload;
    },
  },
});

export const {setWorkDays} = workDaysSlice.actions;

export default workDaysSlice.reducer;
