export const transformNewFields = (fields: any[]) => {
  return fields?.map((field: Record<string, any>) => {
    if (['select', 'multiselect'].includes(field.type)) {
      const list = Array.isArray(field?.list)
        ? field.list
        : Object.entries(field.list).map(([value, title]) => ({title, value}));
      return {
        ...field,
        items: {
          List: {
            items:
              list.map((item: Record<string, any>) => {
                if (item?.checked && Number(item.checked)) {
                  return {
                    ...item,
                    selected: 1,
                  };
                }
                return item;
              }) ?? [],
          },
        },
      };
    }
    if (field.type === 'file_ajax') {
      const files = field?.files?.file ? [field?.files?.file] : field?.files;
      const images = field?.images ?? [];
      return {
        ...field,
        items: {
          Files: {
            items: !files.length && images.length ? images : files ?? [],
          },
        },
      };
    }
    if (field.type === 'text' && field.fromto) {
      const value_from  = field?.value.from ?? null;
      const value_to = field?.value.to ?? null;
      return {
        ...field,
        type: 'slider',
        value_from,
        value_to,
      }
    }
    return field;
  });
};
