import DownloadFileByCode from '@components/atoms/DownloadFileByCode';
import {useEffect, useState} from 'react';
import {
  ButtonWrapper,
  LinkTest,
  StyledButton,
  TextWrapper,
  Wrapper,
} from './WeUseCookie.styles';

const WeUseCookie = () => {
  const [cookie, setCookie] = useState(true);

  useEffect(() => {
    const storage = localStorage.getItem('isCookieSumbit');

    if (storage) {
      setCookie(true);
    } else {
      setCookie(false);
    }
  }, []);

  const setSumbitCookie = () => {
    setCookie(true);
    localStorage.setItem('isCookieSumbit', 'true');
  };

  return (
    <Wrapper isAccepted={cookie}>
      <TextWrapper>
        С целью повышения качества работы сайта{' '}
        <LinkTest
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.rosagroleasing.ru">
          https://www.rosagroleasing.ru
        </LinkTest>{' '}
        АО «Росагролизинг»{' '}
        <LinkTest
          href="https://www.rosagroleasing.ru/doc/81090"
          target="_blank"
          rel="noopener noreferrer"
          download>
          информирует Вас об использовании файлов Сookie.
        </LinkTest>{' '}
        Настоящим свободно, своей волей и в своем интересе даю{' '}
        <DownloadFileByCode code="soglasie_na_obrabotku_personalnykh_dannykh">
          согласие на обработку своих персональных данных
        </DownloadFileByCode>{' '}
        при использовании сайта{' '}
        <LinkTest
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.rosagroleasing.ru">
          https://www.rosagroleasing.ru
        </LinkTest>
      </TextWrapper>
      <ButtonWrapper>
        <StyledButton title="Я Принимаю" variant="filled" onClick={setSumbitCookie} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default WeUseCookie;
