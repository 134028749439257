import apiClient from '@api/entities/apiClient';

export const getTownCode = async (id_region: string, query: string) => {
  const {data} = await apiClient.get('/FiasCityByRegion', {
    params: {
      id_region,
      query,
    },
  });

  return data;
};

export const getStreetCode = async (id_region: string, query: string) => {
  const {data} = await apiClient.get('/FiasStreetByCity', {
    params: {
      id_region,
      query,
    },
  });

  return data;
};

export const getKladrData = async (code: string) => {
  const {data} = await apiClient.get('/FiasStreetByCode', {
    params: {
      code,
    },
  });

  return data;
};

export const getAddressSuggestions = async (query: string) => {
  const {data} = await apiClient.get('/AddressSearch', {
    params: {
      query,
    },
  });
  return data;
};



export const getFiasAsText = async (code: string) => {
  const {data} = await apiClient.get('/FiasAsText', {
    params: {
      code,
    },
  });

  return data;
};
