import {IPageIDs} from './types/MainPage';
import apiClient from '@api/entities/apiClient';
import {IPageUrlData} from './types/Pages';
import { AxiosInstance } from 'axios';

export const getMainPage = async () => {
  const {data} = await apiClient.get(`/Main`);
  return data;
};

export const getCalcResult = async (value: any, signal?: AbortSignal) => {
  const {data}: {data: any} = await apiClient.get(`/Calc`, {
    params: {
      ...value,
    },
    signal,
  });
  return data;
};

export const getCalcInitData = async (
  { isshtp }: { isshtp?: boolean },
  ssrAPIClient?: AxiosInstance, 
  signal?: AbortSignal
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data}: {data: any} = await curAPIClient.get(`/CalcInit`, {
    params: {
      isshtp
    },
    signal,
  });
  return data;
};

export const getCalcListCategory = async () => {
  const {data} = await apiClient.get(`/CalcListCategory`);
  return data;
};

export const getPageID = async (route: string) => {
  const {data}: {data: IPageIDs} = await apiClient.get(`/GetPageIDs`);
  const pageID = data.pages.filter(page => page.url === route)[0]?.id;
  return pageID ?? 0;
};

export const getPageIDs = async () => {
  const {data}: {data: IPageIDs} = await apiClient.get(`/GetPageIDs`);
  return data;
};

// Deprecated, use instead getPageInfo()
export const getPageDetail = async (pageID: number | string) => {
  const {data} = await apiClient.get(`/GetPageDetail`, {
    params: {
      id: pageID,
    },
  });
  return data;
};
// Deprecated, use instead getPageInfo()
export const getPageMeta = async (pageData: IPageUrlData) => {
  const {data} = await apiClient.get(`/GetPageMeta`, {
    params: pageData,
  });
  return data;
};

export const getPageInfo = async (pageData: IPageUrlData) => {
  const {data} = await apiClient.get(`/GetPageInfo`, {
    params: pageData,
  });
  return data;
};

export const getDynamicSitemap = async () => {
  const {data} = await apiClient.get(`/GetDynamicSitemap`);
  return data;
};

export const getSiteMap = async () => {
  const {data} = await apiClient.get(`/GetMapV2`);
  return data;
};

export const getLeasingAbout = async () => {
  const {data} = await apiClient.get(`/LeasingAbout`);
  return data;
};

export const getPageBanners = async (pageID: number | string) => {
  const {data} = await apiClient.get(`/GetPageBanners`, {
    params: {
      page_id: pageID,
    },
  });
  return data;
};

export const getNomenclatureBanners = async (pageID: number | string) => {
  const {data} = await apiClient.get(`/GetNomenclatureBanners`, {
    params: {
      id_pagemapper: pageID,
    },
  });
  return data;
};

export const getFileInfoByCode = async (code: string, signal?: AbortSignal) => {
  const {data} = await apiClient.get('/GetDocByCode', {
    params: {
      code: code,
    },
    ...(signal && {signal: signal}),
  });
  return data;
};

function downloadFile(str: string, title: string, type: string) {
  const file = b64toBlob(str, type);
  const imgURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.download = title;
  link.href = imgURL;
  link.click();
}

function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, 'pot', {type: contentType});
}

export const getFile = async (file_id: number) => {
  try {
    const {
      data: {title, content, mime_type, success},
    } = await apiClient.get('/GetFile', {
      params: {
        id: file_id,
      },
      middleware: {
        withAuth: true,
      },
    });

    downloadFile(content, title, mime_type);

    if (success) {
      return {
        title: title || '',
        content: content || '',
        mime_type: mime_type || 'application/pdf',
      };
    } else {
      return {
        title: '',
        content: '',
        mime_type: 'application/pdf',
      };
    }
  } catch (e) {
    console.log(e);
    return {
      title: '',
      content: '',
      mime_type: 'application/pdf',
    };
  }
};


export const getUTPByCode = async (code = 'main') => {
  const {data} = await apiClient.get(`/GetUTPByCode?code=${code}`);
  return data;
}


export const getCatalogPromo = async ({page, items_per_page = 20} : {page?: number, items_per_page?: number}) => {
  const {data} = await apiClient.get(`/CatalogPromo`, {
    params: {
      page,
      items_per_page
    }
  });
  return data;
}

