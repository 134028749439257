import {ParsedUrlQuery} from 'querystring';
import {QueryComplaintStatus} from './types/OnlineServices';
import apiClient from '@api/entities/apiClient';
import {getCookie} from 'cookies-next';
import {UTMUID} from '@constants/cookies';

export const getCabinetFines = async (query: ParsedUrlQuery) => {
  const {data} = await apiClient.get(`/GetCabinetFines`, {
    params: {
      ...query,
    },
  });
  return data;
};

export const getWorkDaysForCallback = async () => {
  const {data} = await apiClient.get(`/GetWorkDays`);
  return data;
};

export const setCallback = async (values: object) => {
  const uid = getCookie(UTMUID);
  const bodyFormData = new URLSearchParams();
  const entries: Array<string[]> = Object.entries(values);
  for (const [key, value] of entries) {
    bodyFormData.append(key, value);
  }
  const {data} = await apiClient.post(`/SetCall`, bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {uid},
  });
  return data;
};

export const setAgentCallback = async (values: object) => {
  const uid = getCookie(UTMUID);
  const bodyFormData = new URLSearchParams();
  const entries: Array<string[]> = Object.entries(values);
  for (const [key, value] of entries) {
    bodyFormData.append(key, value);
  }
  const {data} = await apiClient.post(`/SetAgentshipCall`, bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {uid},
  });
  return data;
};

export const setTechCallback = async (values: object) => {
  const bodyFormData = new URLSearchParams();
  const entries: Array<string[]> = Object.entries(values);
  for (const [key, value] of entries) {
    bodyFormData.append(key, value);
  }
  bodyFormData.append('accept_terms', '1');
  const {data} = await apiClient.post(`/PostFeedback`, bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return data;
};

export const getRegions = async () => {
  const {data} = await apiClient.get(`/ListRegions`);
  return data;
};

export const getFAQList = async (type?: number) => {
  const {data} = await apiClient.get(`/GetFAQList`, {
    params: {type: type},
  });
  return data;
};

export const setFAQMark = async (body: {
  id: string;
  'g-recaptcha-response': string;
  id_mark: 'good' | 'bad';
}) => {
  const {data} = await apiClient.get(`/SetFAQMark`, {
    params: {...body},
  });
  return data;
};

export const getComplaintStatus = async (query: QueryComplaintStatus) => {
  const {data} = await apiClient.get(`/GetComplaintStatus`, {
    params: query,
  });

  return data;
};



export const setMarketingOfferCall = async (values: object) => {
  const uid = getCookie(UTMUID);
  const bodyFormData = new URLSearchParams();
  const entries: Array<string[]> = Object.entries(values);
  for (const [key, value] of entries) {
    bodyFormData.append(key, value);
  }
  const {data} = await apiClient.post(`/SetMarketingOfferCall`, bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {uid},
  });
  return data;
};