import styled from 'styled-components';
import {ModalWindowContentPadding, ModalWindowProps} from './ModalWindow';

export const ModalWrapper = styled.div<ModalWindowProps>`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: center;
  height: ${({isOpen}) => (isOpen ? '100%' : '0%')};
  width: ${({isOpen}) => (isOpen ? '100%' : '0%')};
  z-index: ${({isOpen}) => (isOpen ? '999999' : '-1')};
`;

export const ModalContainer = styled.div<ModalWindowProps>`
  display: flex;
  align-items: flex-start;
  opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
  max-width: 1400px;
  width: ${({width}) => (width ? width : 'fit-content')};
  height: ${({height}) => (height ? height : 'fit-content')};
  z-index: ${({isOpen}) => (isOpen ? '9999' : '-1')};
  overflow-y: ${({scrollLock, overflowIsVisible}) =>
    overflowIsVisible ? 'visible' : scrollLock ? 'hidden' : 'auto'};
  margin: ${({noMargins}) => (noMargins ? 0 : '0 39px')};
  border-radius: ${({borderRadius}) => (borderRadius ? borderRadius : '8px')};
  max-height: calc(100% - 25px);

  @media (max-width: 550px) {
    margin: ${({noMargins}) => (noMargins ? 0 : '0 15px;')};
  }

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 400px) {
    overflow-y: none;
  }
`;

export const ModalWindowWrapper = styled.div<ModalWindowProps>`
  position: static;
  width: inherit;
  height: fit-content;
  display: flex;
  max-height: calc(100% - 30px);
  align-items: center;
  max-width: calc(100vw - 30px);
  @media (max-height: 895px) and (max-width: 1000px) {
    display: block;
  }
  @media (max-height: 760px) {
    display: block;
  }
`;

export const Backdrop = styled.div<{isOpen: boolean}>`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.222);
  width: 1000vh;
  height: 120%;
  transition: all 0.15s;
  display: ${({isOpen}) => (isOpen ? '' : 'none')};
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: ${({isOpen}) => (isOpen ? '9' : '-1')};
`;

export const ModalContent = styled.div<{
  isOpen: boolean;
  borderRadius?: string;
  background?: string;
  padding?: ModalWindowContentPadding;
}>`
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  width: inherit;
  max-height: calc(100% - 30px);
  max-width: 100%;
  padding: ${({isOpen, padding}) =>
    isOpen ? (!!padding ? padding.desktop : '30px 20px 20px 20px') : '0'};
  border-radius: ${({borderRadius}) => (borderRadius ? borderRadius : '4px')};
  background-color: ${({background}) => (background ? background : '#fff')};
  @media (max-width: 550px) {
    padding: ${({isOpen, padding}) =>
      isOpen ? (!!padding ? padding.mobile : '20px 16px') : '0'};
  }
`;
