import {theme} from '@styles/theme';
import styled from 'styled-components';

export const Container = styled.div<{hideBreadcrumbs?: boolean}>`
  ${theme.flexAlignItemsCenter};
  gap: 10px;
  height: 48px;
  @media (max-width: 681px) {
    display: ${({hideBreadcrumbs}) => (hideBreadcrumbs ? 'none' : 'flex')};
  }

  @media (max-width: 520px) {
    height: 32px;
    gap: 6px;
  }
`;

export const Delimiter = styled.div`
  ${theme.bottomSeparator};
`;
