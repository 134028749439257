import FooterCopyright from '@components/molecules/FooterCopyright';
import FooterMenu from '@components/molecules/FooterMenu';
import {GlobalContainer} from '@styles/pages/_app.styles';
import {FooterWrapper} from './Footer.styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <GlobalContainer>
        <FooterMenu />
        <FooterCopyright />
      </GlobalContainer>
    </FooterWrapper>
  );
};

export default Footer;
