import Button from "@components/atoms/Button";
import { theme } from "@styles/theme";
import styled from "styled-components";

export const Title = styled.p`
  font-family: ${theme.fonts.headline};
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.25px;
  color: ${theme.colors.darkText};
  margin-bottom: 30px;
  text-align: center;

  @media screen and (max-width: 520px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const City = styled.span`
  color: ${theme.colors.brandGreen};
`;

export const Wrapper = styled.div`
  padding: 20px 30px 30px 30px;
  min-width: 510px;
  max-width: 80vw;
  box-sizing: border-box;
  @media screen and (max-width: 600px) {
	min-width: 80vw;
  }
  @media screen and (max-width: 520px) {
    padding: 0;
  }
`;

export const Btns = styled.div`
  display: flex;
  gap: 30px;
  @media screen and (max-width: 520px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Btn = styled(Button)`
  flex: 1;
  font-size: 16px;
  padding: 15px 0;
  width: 100%;
`;
