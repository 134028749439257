import {theme} from '@styles/theme';
import styled from 'styled-components';

export const FooterCopyrightWrapper = styled.div`
  display: flex;
  align-items: start;
  column-gap: 30px;
  row-gap: 20px;
  @media (max-width: 888px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0;
  }
`;

export const AboutText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  flex: 1 1 40%;
  margin-bottom: 5px;
  @media (max-width: 888px) {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  p {
    display: inline-block;
  }
`;

export const ContactText = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: ${({theme}) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  flex: 1 1 13%;
  margin-bottom: 20px;
  a {
    color: ${({theme}) => theme.colors.brandGreenV2};
  }
  @media (max-width: 888px) {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 30px;
    p {
      margin: 0 auto;
    }
  }
`;

export const Links = styled.div`
  a {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: ${({theme}) => theme.colors.darkGray};
    margin-bottom: 10px;
  }
  @media (max-width: 888px) {
    max-width: 462px;
    width: 100%;
  }
  @media (max-width: 470px) {
    max-width: fit-content;
  }
`;

export const LogoImageWrapper = styled.div`
  width: 100%;
  height: 32px;
  position: relative;
  cursor: pointer;
  a {
    margin-bottom: 0;
    font-size: 0;
    line-height: 0;
  }
  &:hover {
    & img {
      filter: brightness(115%);
    }
  }
`;

export const IconImageWrapper = styled.div`
  min-width: 16px;
  min-height: 16px;
  position: relative;
  cursor: pointer;
  a {
    margin-bottom: 0;
    font-size: 0;
    line-height: 0;
  }
  &:hover {
    & img {
      filter: brightness(150%);
    }
  }
`;

export const MobileAppLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
  row-gap: 10px;
  margin-bottom: 10px;
  @media (max-width: 888px) {
    grid-template-columns: repeat(4, 1fr);
    max-width: 462px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  @media (max-width: 470px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 19px;
  }
`;

export const CopyrightText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  flex: 1 1 100%;
  @media (max-width: 888px) {
    text-align: center;
  }
`;

export const SocialLinks = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 12px 20px;
  display: flex;
  gap: 14px;
  @media (max-width: 888px) {
    margin: 0 auto;
    width: fit-content;
  }
`;

export const CopyrightInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  @media (max-width: 888px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const PolicyFiles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  @media (max-width: 888px) {
    margin-top: 30px;
  }
`;
