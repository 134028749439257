import React, {useCallback, useEffect, useState} from 'react';
import ModalWindow from '../ModalWindow';
import styled from 'styled-components';
import {theme} from '@styles/theme';
import {useAppDispatch, useAppSelector, useWindowDimensions} from '@hooks';
import Button from '@components/atoms/Button';
import {
  setConfirmModalActive,
  setGeo,
  setSelectModalActive,
} from '@store/slices/geolocation';
import ModalHeader from '@components/atoms/ModalHeader';
import Image from 'next/image';
import {debounce} from 'lodash';
import {getGeoByCity} from '@api/geo';
import Skeleton from 'react-loading-skeleton';

import {
  Wrapper,
  SearchInput,
  SearchWrapper,
  ResultsWrapper,
  Results,
  City,
  Region,
  Result,
  NoResults,
  NoResultsText,
} from './GeoSelect.styles';

interface GeoResult {
  fiascode: string;
  region_fias_id: string;
  city: string;
  region_with_type: string;
}

const GeoSelect = ({isModalVisible}: {isModalVisible: boolean}) => {
  const dispatch = useAppDispatch();
  const {width} = useWindowDimensions();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setResults([]);
    setQuery(e.target.value);
    if (e.target.value.length > 3) getResults(e.target.value);
    else setLoading(false);
  };

  const getResults = useCallback(
    debounce(async (query: string) => {
      const res = await getGeoByCity(query);
      if (res.item) {
        setResults(Array.isArray(res.item) ? res.item : [res.item]);
      }
      setLoading(false);
    }, 300),
    [],
  );

  return (
    <ModalWindow isOpen={isModalVisible} height="none">
      <Wrapper>
        <ModalHeader
          title="Выберите город"
          onClose={() => dispatch(setSelectModalActive(false))}
          style={{width: '100%'}}
          closeBtnWidth={!width || width <= 550 ? 14 : 21}
          closeBtnHeight={!width || width <= 550 ? 14 : 21}
        />
        <SearchWrapper>
          <SearchInput placeholder="Не введено" value={query} onChange={handleChange} />
          <Image
            src="/assets/searchIconInput.svg"
            width={17.5}
            height={17.5}
            alt="Иконка поиска"
          />
        </SearchWrapper>
        <ResultsWrapper>
          <Results>
            {results.length > 0 &&
              !loading &&
              results.map((result: GeoResult, idx) => (
                <Result
                  key={idx + '-' + result.region_fias_id}
                  onClick={() => {
                    dispatch(
                      setGeo({
                        fiascode: result.fiascode,
                        city: result.city,
                        region: result.region_with_type,
                        region_fias_id: result.region_fias_id,
                      }),
                    );
                    dispatch(setSelectModalActive(false));
                    dispatch(setConfirmModalActive(false));
                  }}>
                  <City>{result.city}</City>
                  <Region>{result.region_with_type}</Region>
                </Result>
              ))}
          </Results>
          {loading && <Skeleton height={25} count={3} style={{marginBottom: 30}} />}
          {query.length > 3 && (!results || results.length === 0) && !loading && (
            <NoResults>
              <NoResultsText>Ничего не найдено</NoResultsText>
            </NoResults>
          )}
        </ResultsWrapper>
      </Wrapper>
    </ModalWindow>
  );
};

export default GeoSelect;
