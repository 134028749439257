import '@components/atoms/CustomDatePicker/CustomDatePickerStyles.css';
import CustomToastContainer from '@components/atoms/CustomToastContainer';
import BottomBtns from '@components/molecules/BottomBtns';
import '@components/molecules/Pagination/PaginationWrapper.css';
import WeUseCookie from '@components/molecules/WeUseCookie';
import Footer from '@components/organisms/Footer';
import Header from '@components/organisms/Header';
import {MainLayout} from '@components/templates';
import {Config} from '@config/api';
import {store} from '@store';
import {theme} from '@styles/theme';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import 'keen-slider/keen-slider.min.css';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import {useRouter} from 'next/router';
import 'rc-slider/assets/index.css';
import {useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import 'react-loading-skeleton/dist/skeleton.css';
import {Provider} from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import {YMInitializer} from 'react-yandex-metrika';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import {useMangoOnlineChatCustomization} from '@hooks';
import {BottomBtnsWrapper} from './../styles/pages/_app.styles';
import '@enhances/with-resolvers';
import {sendClientParamsNATS} from '@utils/common';
import { useCalltouchAnalytics } from '@hooks/useCalltouchAnalytics';

const ChatWindow = dynamic(() => import('@components/organisms/ChatWindow'));

const GlobalStyle = createGlobalStyle`
    html, body {
      height: -webkit-fill-available;
      min-height: -moz-available; 
    }
    body {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      background-color: ${theme.colors.white};
      min-width: 303px;
      width: 100%;
      min-height: 100vh;
      overflow-y: scroll;
      font-family: ${theme.fonts.subline};
    }
    /* Avoid Chrome to see Safari hack */
    @supports (-webkit-touch-callout: none) {
      body {
        /* The hack for Safari */
        min-height: -webkit-fill-available;
      }
    }
    
    
    h1, h2, h3 {
      font-size: inherit;
      margin: 0;
      font-weight: inherit;
      margin-block: 0;
      margin-inline: 0;
    }
    
    p {
      margin: 0;
    }
    a {
      text-decoration: none;
      color: ${theme.colors.brandGreenV2};
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0px;
    }
    input {
      margin: 0;
      padding: 0;
    }
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    
    input[type="range" i] {
      --webkitProgressPercent: 0%;
      -webkit-appearance: none;
      appearance: none;
      cursor: pointer;
      top: 95%;
      left: 2%;
      height: 2px;
      -webkit-appearance: none;   
    }
    
    input[type="range"]::-webkit-slider-runnable-track {
      height: 2px;
      background-image: linear-gradient(
        90deg,
        ${theme.colors.brandGreenV2} var(--webkitProgressPercent),
      transparent var(--webkitProgressPercent)
      );
    }
    
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: -10px;
      border-radius: 50%;
      background: ${theme.colors.brandGreenV2};
      border: 2px solid #00755A;
      box-shadow: 0px 2px 4px #00755A;
      height: 20px;
      width: 20px;
    }
    
    
    input[type="range"]::-webkit-progress-value {
      background-color: ${theme.colors.brandGreenV2};
    }   
    
    /******** Firefox ********/
    
    input[type="range"]::-moz-range-track {
      background: transparent;
      height: 2px;
    }
    
    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: -10px;
      border-radius: 50%;
      background: ${theme.colors.brandGreenV2};
      border: 2px solid ${theme.colors.brandGreenV2};
      box-shadow: 0px 2px 4px ${theme.colors.brandGreenV2};
      height: 20px;
      width: 20px; 
    }

    input[type="range"]::-moz-range-progress {
      background-color: ${theme.colors.brandGreenV2}; 
    }
    
    /****************/
    
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="checkbox"] {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: inherit !important;
    }
    ul, menu {
      list-style: none;
      padding-inline-start: 0;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .keen-slider:not([data-keen-slider-disabled]) {
      width: auto;
    }
    
    #nprogress .bar {
      background: linear-gradient(90deg, rgba(0,117,90,1) 0%, rgba(249,213,94,1) 100%);
      height: 3px;
    }
    #nprogress .peg {
      box-shadow: 0 0 10px rgba(0,117,90,1), 0 0 5px rgba(249,213,94,1);
      opacity: 0;
    }
    // убираем с экрана бейдж google recaptcha
    .grecaptcha-badge { 
      visibility: hidden;
      display: none !important;
    }
    .noscroll { 
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
    }
    .cabsidenoscroll {
      overflow: hidden;
    }
    .ymaps-2-1-79-gototech {
      display: none !important;
    }
    
    .ymaps-2-1-79-balloon__close-button {
      opacity: 1 !important;
      background: url('/assets/icons/closeBtn.svg') no-repeat !important;
      background-size: 11px 11px !important;
      background-position: 5px 20px !important;
    }
    .ymaps-2-1-79-balloon__tail {
      display: none !important;
    }
    .ymaps-2-1-79-balloon__layout {
      border-radius: 4px !important;
      box-shadow: none !important;
      border: none;
    }
    .ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content {
      margin-right: 0px !important;
    }
    `;
// export const ModalHeader = styled.div<{width?: string}>`
//   position: sticky;
//   top: 0px;
//   background-color: white;
//   z-index: 99;
//   width: ${({width}) => (width ? `${width}` : '100%')};
//   display: flex;
//   justify-content: space-between;
//   padding-top: 30px;
//   ${ModalCloseBtn} {
//     margin-top: -20px;
//   }
// `;

const queryClient = new QueryClient();

const App = ({Component, pageProps, props}: any) => {
  const router = useRouter();
  const shouldHideFooter = router.pathname.startsWith('/cabinet');

  useEffect(() => {
    // added custom --vw css property (actual 1% width without scrollbar)
    function setVw() {
      const vw = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty('--vw', `${vw}px`);
    }
    setVw();
    window.addEventListener('resize', setVw);
  }, []);

  sendClientParamsNATS('load');
  useMangoOnlineChatCustomization();
  useCalltouchAnalytics();
  

  const redirectedURL = props?.redirectedURL
    ? Config.BASE_URL?.endsWith('/')
      ? props.redirectedURL
      : `/${props.redirectedURL}`
    : undefined;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width" />
        {Config.ROBOTS_ENV !== 'PROD' && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        {/* <link rel="icon" type="image/x-icon" sizes="16x16" href="/favicon.ico" /> */}
        <link rel="icon" type="image/svg+xml" sizes="16x16" href="/favicon.svg" />
        {(Boolean(pageProps?.pageProperties?.canonical_url) ||
          Boolean(redirectedURL)) && (
          <link
            rel="canonical"
            href={`${Config.BASE_URL}${
              pageProps?.pageProperties?.canonical_url ?? redirectedURL
            }`}
          />
        )}
      </Head>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GoogleReCaptchaProvider
              reCaptchaKey={`${Config.GOOGLE_RECAPTCHA_SITEKEY}`}
              scriptProps={{
                async: false,
                defer: false,
                appendTo: 'head',
                nonce: undefined,
              }}>
              <MainLayout>
                <CustomToastContainer autoClose={10000} />
                <GlobalStyle />
                <Header />
                <Component {...pageProps} />
              </MainLayout>
              {!shouldHideFooter && (
                <>
                  <BottomBtnsWrapper>
                    {/* <ChatButton /> */}
                    <BottomBtns />
                  </BottomBtnsWrapper>
                  <ChatWindow />
                  <Footer />
                </>
              )}
              <WeUseCookie />
              <YMInitializer
                accounts={[23267446]}
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                  ecommerce: 'dataLayer',
                }}
              />
            </GoogleReCaptchaProvider>
          </ThemeProvider>
        </Provider>
      </QueryClientProvider>
    </>
  );
};

App.getInitialProps = async ({ctx}: {ctx: any}) => {
  const redirectedURL =
    ctx.query.redirectedURL && ctx.query.redirectedURL !== 'null'
      ? String(ctx.query.redirectedURL).slice(1)
      : null;
  return {
    props: {
      redirectedURL,
    },
  };
};

export default App;
