import {IChatForm} from '@api/types/Chat';
import apiClient from '@api/entities/apiClient';

export const setChatAuth = async (chatFormData: IChatForm) => {
  const {data} = await apiClient.get(`/SetChatAuth`, {
    params: chatFormData,
  });
  return data;
};

export const getChatAuth = async (chatToken: string) => {
  const {data} = await apiClient.get(`/GetChatAuth`, {
    params: {chat_token: chatToken},
  });
  return data;
};

export const setChatMessage = async (
  chatToken: string,
  message: string,
  isMessageLast: number,
) => {
  const {data} = await apiClient.get(`/SetChatMessage`, {
    params: {chat_token: chatToken, message: message, id_message_last: isMessageLast},
  });
  return data;
};

export const getChatMessages = async (chatToken: string, isMessageLast: number) => {
  const {data} = await apiClient.get(`/GetChatMessages`, {
    params: {chat_token: chatToken, id_message_last: isMessageLast},
  });
  return data;
};

export const checkIsWork = async () => {
  const {data} = await apiClient.get(`/IsWorkDay`);
  return data;
};
