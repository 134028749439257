import styled from 'styled-components';
import {theme} from '@styles/theme';
import {ModalHeaderStyledProps} from './ModalHeader';

export const ModalCloseBtn = styled.div<{top?: string; right?: string}>`
  cursor: pointer;
  min-width: 21px;
  min-height: 21px;
  padding: 5px;
  margin-top: 0.6em;
  @media (max-width: 535px) {
    min-width: 14px;
    min-height: 14px;
    display: flex;
    align-items: flex-end;
    img {
      width: 14px;
      height: 14px;
    }
  }
`;

export const ModalHeaderWrapper = styled.div`
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 30px;
  border-radius: 4px 4px 0 0;
  @media (max-width: 550px) {
    padding-bottom: 22px;
  }
`;

export const Title = styled.div<ModalHeaderStyledProps>`
  font-family: ${theme.fonts.headline};
  font-weight: 400;
  font-size: ${({titleFontSize}) => `${titleFontSize}px`};
  letter-spacing: -0.25px;
  color: ${theme.colors.darkGray};
  max-width: 90%;
  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
