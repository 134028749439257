import apiClient from '@api/entities/apiClient';
import {AxiosInstance} from 'axios';

export const canSesAgreement = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/CanSESAgreement', {
    params: {},
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const sesAgreement = async (step: number, id?: string) => {
  const params = id ? {step, id_Contact: id} : {step};
  const {data} = await apiClient.get('/SESAgreement', {
    /* _override_phone в params:
            для теста вписать свой номер, например:
            _override_phone: '+79504620430' */
    params: {...params},
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const setCodeFromSms = async (code: string, id_object: string) => {
  const {data} = await apiClient.get('/CabinetApplicationSignSES', {
    params: {
      code,
      id_object,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const cspLog = async (message: string, token: string) => {
  try {
    const {data} = await apiClient.post('/CSPLog', JSON.stringify({message, token}));
    return data;
  } catch (e) {
    console.log('CSPLog request error ', e);
    throw e;
  }
};
