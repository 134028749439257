import {AnalyticEventNames, RegistrationStepNames} from '@api/types/AnalyticEvents';

export const ACCOUNT_TYPE_FOR_ANALYTICS: Record<string, string> = {
  legalEnt: 'company',
  entrepreneur: 'entrepreneur',
  individual: 'private-person',
} as const;

export const PASSWORD_OPTIONS_FOR_ANALYTICS: Record<string, string> = {
  yourPass: 'own',
  generatePass: 'generate',
} as const;

export const CONFIRMATION_STEP_NAMES: Record<number, RegistrationStepNames> = {
  2: RegistrationStepNames.ConfirmationMethodChoice,
  3: RegistrationStepNames.EmailConfirmationMethod,
  4: RegistrationStepNames.PhoneConfirmationMethod,
} as const;

export const SHOWN_FORM_STEP_TO_ANALYTIC_STEP: Record<
  number,
  {
    eventName: AnalyticEventNames;
    params: {
      [key: string]: string | number;
    };
    withLegalEntity: boolean;
  }
> = {
  1: {
    eventName: AnalyticEventNames.ChooseLegalEntityShown,
    params: {
      step: 5,
      step_name: RegistrationStepNames.ChooseLegalEntity,
    },
    withLegalEntity: false,
  },
  2: {
    eventName: AnalyticEventNames.CompanyInfoShown,
    params: {
      step: 6,
      step_name: RegistrationStepNames.CompanyInfo,
    },
    withLegalEntity: true,
  },
  3: {
    eventName: AnalyticEventNames.HeadInfoShown,
    params: {
      step: 7,
      step_name: RegistrationStepNames.HeadInfo,
    },
    withLegalEntity: true,
  },
  4: {
    eventName: AnalyticEventNames.ConfirmationShown,
    params: {
      step: 8,
      step_name: RegistrationStepNames.Confirmation,
    },
    withLegalEntity: true,
  },
  5: {
    eventName: AnalyticEventNames.ActivationShown,
    params: {
      step: 9,
      step_name: RegistrationStepNames.Activation,
    },
    withLegalEntity: true,
  },
  6: {
    eventName: AnalyticEventNames.SuccessShown,
    params: {
      step: 10,
      step_name: RegistrationStepNames.Success,
    },
    withLegalEntity: true,
  },
} as const;

export const CHANGED_FORM_STEP_TO_ANALYTIC_STEP: Record<
  number,
  {
    forwardEventName: AnalyticEventNames;
    backEventName: AnalyticEventNames;
    params: {
      [key: string]: string | number | boolean;
    };
    withLegalEntity: boolean;
  }
> = {
  1: {
    forwardEventName: AnalyticEventNames.ConfirmLegalEntity,
    backEventName: AnalyticEventNames.BackClick,
    params: {
      step: 5,
      step_name: RegistrationStepNames.ChooseLegalEntity,
    },
    withLegalEntity: false,
  },
  2: {
    forwardEventName: AnalyticEventNames.SendCompanyInfo,
    backEventName: AnalyticEventNames.BackClick,
    params: {
      step: 6,
      step_name: RegistrationStepNames.CompanyInfo,
    },
    withLegalEntity: true,
  },
  3: {
    forwardEventName: AnalyticEventNames.SendHeadInfo,
    backEventName: AnalyticEventNames.BackClick,
    params: {
      step: 7,
      step_name: RegistrationStepNames.HeadInfo,
    },
    withLegalEntity: true,
  },
  4: {
    forwardEventName: AnalyticEventNames.SendConfirmationCode,
    backEventName: AnalyticEventNames.BackClick,
    params: {
      step: 8,
      step_name: RegistrationStepNames.Confirmation,
    },
    withLegalEntity: true,
  },
  5: {
    forwardEventName: AnalyticEventNames.SendConfirmationCode,
    backEventName: AnalyticEventNames.BackClick,
    params: {
      step: 9,
      step_name: RegistrationStepNames.Activation,
    },
    withLegalEntity: true,
  },
} as const;
