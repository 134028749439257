import {theme} from '@styles/theme';
import styled from 'styled-components';

export const GlobalContainer = styled.div`
  max-width: 1130px;
  padding: 0 39px;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 690px;
  }
  @media (max-width: 747px) {
    padding: 0 15px;
  }
  @media (max-width: 320px) {
    max-width: 290px;
  }
`;

export const CabinetGlobalContainer = styled.div`
  max-width: 1194px;
  width: calc(100% - 86px);
  padding: 0 43px;
  margin: 0 auto;
  @media (max-width: 680px) {
    padding: 0 16px;
    width: calc(100% - 32px);
  }
`;

export const FieldWrapper = styled.div`
  ${theme.flexColumn};
  flex: 1 1 100%;
  @media (max-width: 725px) {
    width: 100%;
  }
`;

export const ErrMessage = styled.div`
  color: ${theme.colors.darkRed};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  padding: 5px;
`;

export const BottomBtnsWrapper = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 2.5%;
  right: 2.5%;
  display: flex;
  gap: 8px;
  align-items: end;
  pointer-events: none;
`;
