import { bodyFormDataBuilder } from "@utils/formatters";
import apiClient from "./entities/apiClient";
import { NextRouter } from "next/router";

const creditDeclarationWizard = async ({
	params,
	postData,
	wrapperKey,
	router
}: {
	params: any,
	postData?: {[key: string]: string | any},
	wrapperKey?: string,
	router: NextRouter
  }) => {
	try {
	  const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
	  const {data} = await apiClient.post(
		'/CRMCreditDeclarationWizard',
		bodyFormData,
		{
		  headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		  },
		  params: {
			...params,
		  },
		  middleware: {
			withAuth: true,
		  },
		},
	  );
	  if (data.errcode === 'agreements') {
		router.push('/pd/');
		return;
	  }
	  return data;
	} catch (e) {
	  console.log('Credit declaration wizard error ', e);
	  throw e;
	}
  };
  
  export const creditDeclarationWizardAPI = {
	creditDeclarationWizard,
  };
  