import apiClient from '@api/entities/apiClient';
import {ICabinetNotifications} from '@api/types/CabinetNotifications';

export const getCabinetNotifications = async (
) => {
  try {
    const {data} = await apiClient.get<ICabinetNotifications>('/GetUserNotifications', {
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log('Error get cabinet notifications ', e)
  }
};
