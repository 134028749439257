import {getFilialsByCoordinates} from '@api/geo';
import BranchDirectionModalMap from '@components/molecules/Branches/BranchDirectionModalMap';
import GeoConfirm from '@components/molecules/Geolocation/GeoConfirm';
import GeoSelect from '@components/molecules/Geolocation/GeoSelect';
import ModalWindow from '@components/molecules/ModalWindow';
import {useAppSelector} from '@hooks';
import {useEffect, useRef, useState} from 'react';
import {ShowBranchBtn} from './Geolocation.styles';

interface Branch {
  id: string;
  address: string;
  longitude: string;
  latitude: string;
}

const Geolocation = () => {
  const {isConfirmModalActive, isSelectModalActive, geo} = useAppSelector(
    state => state.geolocation,
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [branch, setBranch] = useState<Branch | null>(null);
  const geoRef = useRef(geo);

  const checkForBranch = async (region_fias_id: string) => {
    const res = await getFilialsByCoordinates(region_fias_id);
    if (res?.items?.length) {
      setModalOpen(true);
      setBranch(res.items[0]);
    }
  };
  useEffect(() => {
    if (geoRef.current && geo?.region_fias_id) checkForBranch(geo?.region_fias_id);
    geoRef.current = geo;
  }, [geo?.region_fias_id]);

  return (
    <>
      <GeoConfirm isModalVisible={isConfirmModalActive} />
      <GeoSelect isModalVisible={isSelectModalActive} />
      {branch && (
        <ModalWindow isOpen={modalOpen} scrollLock>
          <BranchDirectionModalMap
            setModalOpen={setModalOpen}
            latitude={branch?.latitude ?? ''}
            longitude={branch?.longitude ?? ''}
            title={`В вашем регионе есть филиал АО "Росагролизинг" по адресу ${branch?.address}`}
          />
          <ShowBranchBtn
            title="Показать"
            variant="filled"
            fontSize={16}
            style={{padding: '15px 92px', margin: '30px auto 0'}}
            onClick={() => {
              setModalOpen(false);
            }}
            link={`/company/filials/${branch?.id}`}
          />
        </ModalWindow>
      )}
    </>
  );
};

export default Geolocation;
