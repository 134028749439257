import apiClient from '@api/entities/apiClient';
import {bodyFormDataBuilder} from '@utils/formatters';
import {Dispatch, SetStateAction} from 'react';

const cabinetNotifySubleasingContract = async (
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
  csrfToken?: {field: string; token: string},
) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    if (csrfToken && bodyFormData) {
      bodyFormData.append(`${wrapperKey}[${csrfToken?.field}]}`, `${csrfToken?.token}`);
    }
    const response = await apiClient.post(
      '/CabinetNotifySubleasingContract',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

const sendActiveNomenclature = async (params: any, activeNomenclatureId: string) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetOrderSubleasingNomenclaturesControl:CabinetOrderSubleasing3Control:SetNomenclatureAction',
    );
    bodyFormData.append('id_nomenclature', activeNomenclatureId);
    const response = await apiClient.post(
      '/CabinetNotifySubleasingContract',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

const getCheckReadyActionProgress = async (params: any) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetOrderSubleasingCheckCounteragentControl:CabinetOrderSubleasing3Control:CheckReadyAction',
    );
    const responses = await apiClient.post(
      '/CabinetNotifySubleasingContract',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    return responses.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

const uploadFileForNotificationWizard = async (
  file: File,
  id_object: string | string[] | undefined,
  id_order: string,
  setProgress: Dispatch<SetStateAction<number>>,
) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetOrderSubleasingContractControl:CabinetOrderSubleasing3Control:AjaxFileUpload',
    );
    bodyFormData.append('field', 'contract_image');
    bodyFormData.append('file[0]', file);
    const {
      data: {items, error, success},
    } = await apiClient.post('/CabinetNotifySubleasingContract', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        id_object,
        id_order,
        step: 5,
      },
      middleware: {
        withAuth: true,
      },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });
    if (success) {
      return {
        title: items[0]?.name ?? '',
        id: items[0]?.id ?? '',
      };
    } else {
      return {
        title: '',
        id: '',
        error,
      };
    }
  } catch (e) {
    console.log('Error upload file ', e);
    throw e;
  }
};

export const cabOrderNotificationWizardAPI = {
  cabinetNotifySubleasingContract,
  sendActiveNomenclature,
  getCheckReadyActionProgress,
  uploadFileForNotificationWizard,
};
