import apiClient from '@api/entities/apiClient';
import {bodyFormDataBuilder} from '@utils/formatters';

const cabinetSubleasingNewRequest = async (
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
  csrfToken?: {field: string; token: string},
) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    if (csrfToken && bodyFormData) {
      bodyFormData.append(`${wrapperKey}[${csrfToken?.field}]}`, `${csrfToken?.token}`);
    }
    const response = await apiClient.post('/CabinetSubleasingNewRequest', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return response.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

const sendActiveNomenclature = async (params: any, activeNomenclatureId: string) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetOrderSubleasingNomenclaturesControl:CabinetOrderSubleasing1Control:SetNomenclatureAction',
    );
    bodyFormData.append('id_nomenclature', activeNomenclatureId);
    const response = await apiClient.post('/CabinetSubleasingNewRequest', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return response.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

const getCheckReadyActionProgress = async (params: any) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetOrderSubleasingCheckCounteragentControl:CabinetOrderSubleasing1Control:CheckReadyAction',
    );
    const responses = await apiClient.post('/CabinetSubleasingNewRequest', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return responses.data;
  } catch (e) {
    console.log('Cabinet subleasing wizard error ', e);
    throw e;
  }
};

export const cabOrderRequestWizardAPI = {
  cabinetSubleasingNewRequest,
  sendActiveNomenclature,
  getCheckReadyActionProgress,
};
