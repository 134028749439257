import {
  IRoboDealWizardState,
  RoboDealCRMProduct,
  RoboDealWizardMethodAction,
} from '@api/types/CabRoboDealWizard';
import {IDLE, PENDING} from '@constants/loadingState';

const checkFormName = (data: {
  ctrl: any;
  Grid: {
    text_notice: string;
    text_region: string;
    items: {GridFilter: {items: any}; GridCollection: {items: any}};
  };
  Documents: {items: {Document: any}};
  canSES: any;
  FormBuilder?: any;
}) => {
  if (data?.FormBuilder) {
    return data;
  }
  if (!data?.FormBuilder && !data.Grid) {
    return data;
  }
  switch (data.ctrl) {
    default:
      return {
        ...data,
        FormBuilder: {
          form_name: data.ctrl,
          items: [
            {type: 'filterList', items: data.Grid.items.GridFilter.items},
            {
              type: 'separator',
              title: '',
              field: 'separator_1',
              value: '',
            },
            {
              type: 'productList',
              items: data.Grid.items.GridCollection.items,
            },
            {
              type: 'infoboxes',
              items: [
                {title: data.Grid.text_notice, bstype: 'success'},
                {title: data.Grid.text_region, bstype: 'info'},
              ],
            },
          ],
        },
      };
  }
};

const methodActions = (
  state: IRoboDealWizardState,
  method: RoboDealWizardMethodAction,
  payload: Record<string, any> | undefined,
  fetchStatus: 'pending' | 'fulfilled',
) => {
  switch (method) {
    case 'getCRMProductDataAction':
      if (fetchStatus === 'pending') {
        state.catalogLoading = PENDING;
        state.productLoading = IDLE;
      }
      if (fetchStatus === 'fulfilled') {
        state.CRMProduct = null;
        state.CRMProduct = payload as RoboDealCRMProduct;
        state.CRMProduct.choosedCRMPriceID = payload?.CRMPrices?.[0]?.id ?? null;
        state.CRMProduct.quantity = 1;
        state.catalogLoading = IDLE;
      }
      break;
    case 'getCRMProductComplectAction':
      if (fetchStatus === 'pending') {
        state.catalogLoading = PENDING;
        state.productLoading = IDLE;
      }
      if (fetchStatus === 'fulfilled') {
        state.productComplect = payload?.complect;
        state.productSumm = String(parseFloat(payload?.base));
        state.catalogLoading = IDLE;
      }
      break;
    case 'setQuantityProductsAction':
      if (fetchStatus === 'pending') {
        state.productLoading = PENDING;
        state.loading = IDLE;
      }
      if (fetchStatus === 'fulfilled') {
        state.productLoading = IDLE;
      }
    case 'removeProductAction':
      if (fetchStatus === 'pending') {
        state.productLoading = PENDING;
        state.loading = IDLE;
      }
      if (fetchStatus === 'fulfilled') {
        state.productLoading = IDLE;
      }
    case 'setProductsAction':
      if (fetchStatus === 'pending') {
        state.productLoading = PENDING;
        state.loading = IDLE;
      }
      if (fetchStatus === 'fulfilled') {
        state.productLoading = IDLE;
      }
    case 'setRejectAction':
      if (fetchStatus === 'pending') {
        state.loading = IDLE;
        state.isFormatingOrderStep = false;
        state.isCabinetDealOrderSigningStep = true;
      }
      if (fetchStatus === 'fulfilled') {
        state.isCabinetDealOrderSigningStep = true;
        state.isFormatingOrderStep = false;
        state.loading = IDLE;
      }
    case 'CheckReadyAction':
      return;
  }
};

const getSortingParams = ({
  state,
  resetSorting,
}: {
  state: IRoboDealWizardState;
  resetSorting: boolean;
}) => {
  const sortingParams: any = {};
  const prefix: string = state.meta.formName;
  const sorting = state.sort.values;
  if (!resetSorting) {
    if (sorting.sort) {
      sortingParams[`${prefix}[by]`] = sorting.by;
    }
    if (sorting.by) {
      sortingParams[`${prefix}[sort]`] = sorting.sort;
    }
  }
  return sortingParams;
};

const getPaginationParams = ({
  state,
  page,
  resetPagination,
}: {
  state: IRoboDealWizardState;
  page: number | undefined;
  resetPagination: boolean;
}) => {
  const paginationParams: Partial<{nPaginator: string; page: number}> | null = {};
  if (!resetPagination && state.pagination.lastLoadedPage !== null) {
    paginationParams.nPaginator = state.pagination.paginator ?? '1';
    paginationParams.page = page ?? state.pagination.lastLoadedPage + 1;
  }
  return paginationParams;
};

const setLoading = (
  state: IRoboDealWizardState,
  args: Record<string, any>,
  loadingState: string,
) => {
  const argsKeys = Object.keys(args);

  if (argsKeys.includes('formationUpdate')) {
    state.formationLoading = loadingState;
    return;
  }
  if (argsKeys.includes('catalogUpdate')) {
    state.catalogLoading = loadingState;
    return;
  }
  state.loading = loadingState;
  return;
};

export const roboDealWizardMappings = {
  checkFormName,
  methodActions,
  getSortingParams,
  getPaginationParams,
  setLoading,
};
