export const enum AnalyticEventNames {
	//Register Events
	PageView = 'all_view',
	RegistrationStart = 'cabinet-reg_view-reg-start',
	PasswordTypeChoice = 'cabinet-reg_choose-password-type',
	RefreshPassswordClick = 'cabinet-reg_click-refresh-password',
	AgreementChoice = 'cabinet-reg_choose-agreement',
	SignInClick = 'cabinet-reg_click-enter',
	SignUpClick = 'cabinet-reg_click-registrate',
	ConfirmationMethodChoiceShown = 'cabinet-reg_view-choose-method',
	ConfirmationMethodChoice = 'cabinet-reg_choose-method',
	ConfirmationMethodConfirmClick = 'cabinet-reg_click-confirm',
	ConfirmationMethodBackClick = 'cabinet-reg_click-back',
	EmailConfirmationMethodShown = 'cabinet-reg_view-email-method',
	PhoneConfirmationMethodShown = 'cabinet-reg_view-phone-method',
	ConfirmEmailCode = 'cabinet-reg_click-confirm-email-code',
	ConfirmPhoneCode = 'cabinet-reg_click-confirm-phone-code',
	ConfirmEmailCodeSuccess = 'cabinet-reg_enter-email-code',
	ConfirmPhoneCodeSuccess = 'cabinet-reg_enter-phone-code',
	SendNewPhoneCode = 'cabinet-reg_click-phone-new-code',
	EmailProblemTooltipShown = 'cabinet-reg_click-show-notification',
	ConfirmationProblemTooltipShown = 'cabinet-reg_click-show-notification',
	ChooseLegalEntityShown = 'cabinet-reg_view-choose-legal-entity',
	LegalEntityChoice = 'cabinet-reg_choose-legal-entity',
	ConfirmLegalEntity = 'cabinet-reg_click-confirm-legal-entity',
	SendCompanyInfo = 'cabinet-reg_click-send-company-info',
	SendHeadInfo = 'cabinet-reg_click-send-head-info',
	BackClick = 'cabinet-reg_click-back',
	CompanyInfoShown = 'cabinet-reg_view-company-info',
	HeadInfoShown = 'cabinet-reg_view-head-info',
	ConfirmationShown = 'cabinet-reg_view-confirmation', 
	SendConfirmationCode = 'cabinet-reg_click-confirm',
	ConfirmationCodeEntered = 'cabinet-reg_enter-code',
	ActivationShown = 'cabinet-reg_view-activation',
	ActivationChoice = 'cabinet-reg_choose-activation',
	SuccessShown = 'cabinet-reg_view-success',
	SuccessClick = 'cabinet-reg_click-success',

	//	Registration v2 events
	ShowRegForm = 'cabinet-reg_show-reg-form',
	ChooseRegMethod = 'cabinet-reg_choose-reg-method',
	ChooseSertificate = 'cabinet-reg_show-choose-sertificate',
	ClickRegistrate = 'cabinet-reg_click-registrate',
	ClickRegistrationFL = 'cabinet-reg_click-registration-fl',
	ClickAuthorization = 'cabinet-reg_click-authorization',
	ShowResult = 'cabinet-reg_show-result',
	ShowNotification = 'cabinet-reg_show-notification',
	ClickCheckECP = 'cabinet-reg_click-check-ecp',
	ShowEnterCode = 'cabinet-reg_show-enter-code',
	ShowIncorrectCode = 'cabinet-reg_show-incorrect-code',
	ClickResend = 'cabinet-reg_click-resend',
	ClickRegistration = 'cabinet-reg_click-registration',

	ViewConfirmPhone = 'cabinet-reg_view-confirm-phone',
	ClickGetCode = 'cabinet-reg_click-get-code',
	ChooseAgreement = 'cabinet-reg_choose-agreement',
	ClickConfirm = 'cabinet-reg_click-confirm',
	ClickResendCode = 'cabinet-reg_click-resend-code',
	ClickShowNotification = 'cabinet-reg_click-show-notification',
	ConfirmEmail = 'cabinet-reg_show-confirm-email',

	ViewCompanyInfo = 'cabinet-reg_view-company-info',
	ClickSendCompanyInfo = 'cabinet-reg_click-send-company-info',
	RegViewSuccess = 'cabinet-reg_view-success',

	ViewCreateLogin = 'cabinet-reg_view-create-login',
	ChoosePasswordType = 'cabinet-reg_choose-password-type',
	ClickRefreshPassword = 'cabinet-reg_click-refresh-password',
	ClickCreateLogin = 'cabinet-reg_click-create-login',
	ClickSkip = 'cabinet-reg_click-skip',

	ViewAgreement = 'cabinet-reg_view-agreement',
	ClickCopyLink = 'cabinet-reg_click-copy-link',
	ClickAddHeadInfo = 'cabinet-reg_click-add-head-info',
	ClickSignAgreement = 'cabinet-reg_click-sign-agreement',
	ClickSkipAgreement = 'cabinet-reg_click-skip-agreement',
	ViewHeadInfo = 'cabinet-reg_view-head-info',
	ClickSendHeadInfo = 'cabinet-reg_click-send-head-info',

	//Auth events
	AuthFormShown = 'cabinet-auth_show-auth-form',
	ChooseAuthMethod = 'cabinet-auth_choose-auth-method',
	AuthEnterClick = 'cabinet-auth_click-enter',
	AuthRegisterClick = 'cabinet-auth_click-registration',
	AuthResult = 'cabinet-auth_show-result',
	ForgotPasswordClick = 'cabinet-auth_click-forgot-password',
	AuthNotificationShown = 'cabinet-auth_show-notification',
	RestorePasswordShown = 'cabinet-auth_show-restore-password',
	RestorePasswordConfirmClick = 'cabinet-auth_click-restore-password',
	RestorePasswordEnterCodeShown = 'cabinet-auth_show-enter-code',
	EmailNotReceivedClick = 'cabinet-auth_click-email-not-recieved',
	RestorePasswordEnterCodeClick = 'cabinet-auth_click-enter-code',
	NewPasswordShown = 'cabinet-auth_show-new-password',
	NewPasswordTypeChange = 'cabinet-auth_choose-password-type',
	SetNewPasswordClick = 'cabinet-auth_click-set-password',
	RestorePasswordRefreshClick = 'cabinet-auth_click-refresh-password',
	EmailNotReceivedShown = 'cabinet-auth_show-email-not-received',
	CheckEmailClick = 'cabinet-auth_click-check-email',
	ResendCodeClick = 'cabinet-auth_click-resend-code',
	CheckECPClick = 'cabinet-auth_click-check-ecp',
	ChooseCertificateClick = 'cabinet-auth_show-choose-sertificate',
	EnterWithECPClick = 'cabinet-auth_click-enter-ecp',
	RegisterByECPFormShown = 'cabinet-auth_show-registration-lkk',
	RegisterByECPFormClick = 'cabinet-auth_click-registration-lkk',
	RegisterByECPRequisitesShown = 'cabinet-auth_show-registration-ka',
	CreateM4DRequestClick = 'cabinet-auth_click-add-company',
	AuthAfterRegisterByECP = 'cabinet-auth_show-result',

	//	RoboApp
	CabinetDealCreateChooseDelivery = 'cabinet-deal-create_choose-delivery',
	CabinetDealCreateShowEstimateDelivery = 'cabinet-deal-create_show-estimate-delivery',
	CabinetDealCreateClickEstimateDelivery = 'cabinet-deal-create_click-estimate-delivery',
	CabinetDealCreateChooseDeliveryType = 'cabinet-deal-create_choose-delivery-type',

	//Info events
	AddPOAShown = 'cabinet-info_view-add-procuration',
	POATypeChoice = 'cabinet-info_choose-procuration-type',
	POATypeConfirm = 'cabinet-info_click-confirm-procuration-type',
	M4DFormShown = 'cabinet-info_view-add-procuration-info',
	M4DFormSubmit = 'cabinet-info_click-save-procuration',
	M4DFormResult = 'cabinet-info_show-notification',

	MainPageView = 'main-page_view',
	MainPageShowCatalog = 'main-page_show-catalog',
	MainPageClickCategory = 'main-page_click-category',
	MainPageClickGoToCatalog = 'main-page_click-go-to-catalog',
	MainPageShowPreferentialLeasing = 'main-page_show-preferential-leasing',
	MainPageClickLeaveApplication = 'main-page_click-leave-application',
	MainPageShowCalculator = 'main-page_show-calculator',
	MainPageShowSpecialOffer = 'main-page_show-special-offer',
	MainPageClickAllSpecialOffer = 'main-page_click-all-special-offer',
	MainPageClickSpecialOffer = 'main-page_click-special-offer',
	MainPageShowServices = 'main-page_show-services',
	MainPageClickService = 'main-page_click-service',
	MainPageClickAllServices = 'main-page_click-all-services',
	MainPageShowSuppliers = 'main-page_show-suppliers',
	MainPageClickAllSuppliers = 'main-page_click-all-suppliers',
	MainPageClickSupplier = 'main-page_click-supplier',
	MainPageShowBlog = 'main-page_show-blog',
	MainPageClickAllArticles = 'main-page_click-all-articles',
	MainPageClickArticle = 'main-page_click-article',
	MainPageShowHeaderLink = 'main-page_show-header-link',
	MainPageClickSearch = 'main-page_click-search',
	MainPageEndlessFeedShown = 'main-page_show-endless-feed',
	MainPageEndlessFeedItemClicked = 'main-page_click-endless-feed-item',
	MainPageEndlessFeedShowMoreClicked = 'main-page_click-endless-feed-more',
	MainPageBannerShown = 'main-page_show-banner',
	MainPageBannerClicked = 'main-page_click-banner',
	MainPageBannerArrowClicked = 'main-page_click-next',
	MainPageCalcNewResult = 'main-page_get-new-monthly-pay',
	MainPageCompactCalcBtnClicked = 'main-page_click-calculate',
	MainPageCalltouchWidgetShown = 'main-page_show-banner',
	MainPageCalltouchWidgetBtnClicked = 'main-page_click-banner',

	CatalogView = 'catalog_view',

	CabinetDealCreateViewChoosenItem = 'cabinet-deal-create_view-choosen-item',
	CabinetDealCreateClickAddItem = 'cabinet-deal-create_click-add-item',
	CabinetDealCreateClickDeleteItem = 'cabinet-deal-create_click-delete-item',
	CabinetDealCreateClickGoTo = 'cabinet-deal-create_click-go-to',
	CabinetDealCreateShowItemCatalog = 'cabinet-deal-create_show-item-catalog',
	CabinetDealCreateClickSearch = 'cabinet-deal-create_click-search',
	CabinetDealCreateClickReset = 'cabinet-deal-create_click-reset',
	CabinetDealCreateViewCreateConditions = 'cabinet-deal-create_view-create-conditions',
	CabinetDealCreateShowCreateConditions = 'cabinet-deal-create_show-create-conditions',
	CabinetDealCreateShowEndCreateConditions = 'cabinet-deal-create_show-end-create-conditions',
	CabinetDealCreateNext = 'cabinet-deal-create_next',
	CabinetDealCreateViewCreateContract = 'cabinet-deal-create_view-create-contract',
	CabinetDealCreateShowCreateContract = 'cabinet-deal-create_show-create-contract',
	CabinetDealCreateShowEndCreateContract = 'cabinet-deal-create_show-end-create-contract',


	// Accreditation
	DashboardAccreditationBlockShown = 'cabinet-supplier_show-accreditation',
	DashboardAccreditationBtnClicked = 'cabinet-supplier_click-start',
	AccreditationCounteragentScreenShown = 'supplier-accreditation_view-fill-company-info',
	AccreditationContinueClicked = 'supplier-accreditation_click-continue',
	AccreditationFieldError = 'supplier-accreditation_show-notification',
	AccreditationQuestioningScreenShown = 'supplier-accreditation_view-form',
	AccreditationDealerChosen = 'supplier-accreditation_choose-status-dealer',
	AccreditationProducerChosen = 'supplier-accreditation_choose-status-producer',
	AccreditationContactScreenShown = 'supplier-accreditation_view-contact-person',
	AccreditationContactClearClicked = 'supplier-accreditation_click-clear',
	AccreditationDocumentsScreenShown = 'supplier-accreditation_view-documents',
	AccreditationPDAgreementClicked = 'supplier-accreditation_choose-agreement-pd',
	AccreditationSignScreenShown = 'supplier-accreditation_view-sign',
	AccreditationSignBtnClicked = 'supplier-accreditation_click-sign',
	AccreditationNoCSPMessageShown = 'supplier-accreditation_show-no-ecp',
	AccreditationCheckCSPClicked = 'supplier-accreditation_click-check-ecp',
	AccreditationPostponeSignClicked = 'supplier-accreditation_click-skip-sign',
	AccreditationSuccessScreenShown = 'supplier-accreditation_view-success',
	AccreditationBackToCabinetClicked = 'supplier-accreditation_click-go-to-lk',
	DashboardAccreditationModalShown = 'cabinet-supplier_show-complete-accreditation',
	DashboardAccreditationModalContinueClicked = 'cabinet-supplier_click-continue',


	//ML

	SupplierPredictionResult = 'ml_get-supplier-role',


	//Catalog Item
	SimilarNewBlockShown = 'catalog-item_show-similar-new',
	SimilarNewShowMoreClicked = 'catalog-item_click-all-similar-new',
	SimilarNewProductClicked = 'catalog-item_click-similar-new',
	SimilarUsedBlockShown = 'catalog-item_show-similar-used',
	SimilarUsedShowMoreClicked = 'catalog-item_click-all-similar-used',
	SimilarUsedProductClicked = 'catalog-item_click-similar-used',
	HolderTechBlockShown = 'catalog-item_show-similar-used-warehouse',
	HolderTechShowMoreClicked = 'catalog-item_click-all-similar-used-warehouse',
	HolderTechProductClicked = 'catalog-item_click-similar-used-warehouse',
	FavoritesBtnClicked = 'catalog-item_click-add-to-favorites',
	ComparisonBtnClicked = 'catalog-item_click-add-to-comparison',

	//CallbackForm

	CallbackFormShown = 'callback_view',
	CallbackINNEntered = 'callback_enter-inn',
	CallbackPhoneEntered = 'callback_enter-phone',
	CallbackFIOEntered = 'callback_enter-fio',
	CallbackEmailEntered = 'callback_enter-email',
	CallbackCheckboxToggled = 'callback_choose-agreement-pd',
	CallbackSubmitted = 'callback_click-send',
	CallbackSuccessShown = 'callback_show-result',
	CallbackAccountLoaded= 'callback_load-fields',


}	


export const enum AnalyticEventCategories {
	All = 'all',
	CabinetAuth = 'cabinet-auth',
	CabinetReg = 'cabinet-reg',
	CabinetInfo = 'cabinet-info',
	MainPage = 'main-page',
	Catalog = 'catalog',
	CabinetDealCreate = 'cabinet-deal-create',
	DashboardAccreditation = 'cabinet-supplier',
	Accreditation = 'supplier-accreditation',
	ML = 'ml',
	CatalogItem = 'catalog-item',
	Callback = 'catalog-item'
}

export const enum RegistrationStepNames {
	Start = 'start',
	ConfirmationMethodChoice = 'choose_method',
	EmailConfirmationMethod = 'email-method',
	PhoneConfirmationMethod = 'phone-method',
	ChooseLegalEntity = 'choose-legal-entity',
	CompanyInfo = 'company-info',
	HeadInfo = 'head-info',
	Confirmation = 'confirm',
	Activation = 'activate',
	Success = 'success',
	EnterCode = 'enter_code',
	ConfirmPhone = 'confirm_phone',
	ConfirmEmail = 'confirm_email',
	Login = 'login',
	Agreement = 'agreement'
}

export const enum AuthStepNames {
	Main = 'main',
	RestorePassword = 'restore_password',
	NewPassword = 'new_password',
	EmailNotReceived = 'email_not_received',
	RegByLKKByECP = 'reg_lkk',
	RegKAByEcp = 'reg_ka'
}

export const enum AccreditationStepNames {
	CounterAgent =  'fill_company_info',
	Questioning = 'form',
	Contact = 'contact-person',
	Documents = 'documents',
	Sign = 'sign',
	Success = 'success'

}


export interface AnalyticEventParams {
	eventName: AnalyticEventNames, 
	eventCategory: AnalyticEventCategories, 
	eventParams: {
		[key: string]: string | string[] | number | boolean | null;
	}
}

