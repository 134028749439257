import { getCabinetNotifications } from '@api/cabinetNotifications';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Toast from '@components/atoms/Toast';

export const useNotifications = (isAuth: boolean) => {
  const router = useRouter();

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (isAuth && router.pathname.startsWith('/cabinet')) {
      const checkNotifications = async () => {
        const response = await getCabinetNotifications();
        if (response?.notifications?.length) {
          response.notifications.forEach(el => {
            toast.warning(
              <Toast 
                title={el?.title ?? ''} 
                text={el?.body ?? ''} 
                type="info" />,
              { autoClose: false },
            );
          });
        }
      };
      checkNotifications();
      interval = setInterval(checkNotifications, 30000);
    }
    return () => clearInterval(interval);
  }, [router.pathname, isAuth]);
};
