import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';
import {setUTM} from '@api/utm';
import {getCookie, setCookie} from 'cookies-next';
import {UTMUID} from '@constants/cookies';

export const useUTMMarks = () => {
  const initialized = useRef(false);
  const router = useRouter();
  const {utm_source, utm_medium, utm_campaign, utm_content, utm_term} = router.query;
  const uid = getCookie(UTMUID);

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initialized.current = true;
    if (utm_source || utm_medium || utm_campaign || utm_content || utm_term) {
      setUTM({utm_source, utm_medium, utm_campaign, utm_content, utm_term, uid})
        .then(data => {
          if (data?.uid) {
            const expires = new Date();
            const time = expires.getTime();
            const expireTime = time + 1000 * 3600 * 24 * 30;
            expires.setTime(expireTime);
            setCookie(UTMUID, data.uid, {expires});
          }
        })
        .catch(e => {
          console.log('Error setUTM marks ', e);
        });
    }
  }, []);
};
