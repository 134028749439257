import {nrsWizardAPI} from '@api/nrsWizard';
import {requestWizardAPI} from '@api/cabAppsWizard';
import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {INRSWizardInitialState} from '@api/types/NRSWizard';

const initialState: INRSWizardInitialState = {
  loading: IDLE,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    id_object: null,
    steps: {},
    isavailable: true,
    url: '',
    state: null,
  },
  id_object: null,
  stepNow: null,
  regions: [],
  groupFields: {},
  screens: {},
  CSPUI: null,
  signDocs: null,
  error: null,
};

export const nrsWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params: any[] | Record<string, any>;
    postData: Record<string, unknown>;
    wrapperKey: string;
  },
  {state: RootState}
>('nrsWizard/fetch', async ({params, postData, wrapperKey}, thunkAPI) => {
  const {nrsWizard} = thunkAPI.getState();
  let data = await nrsWizardAPI.nrsWizard(
    {
      id_object: nrsWizard?.id_object,
      ...params,
    },
    postData,
    wrapperKey,
  );

  if (data?.wizard?.step) {
    data = {
      step: data?.wizard?.step,
      id_object: nrsWizard?.id_object,
      ...(await nrsWizardAPI.nrsWizard(
        {
          step: data?.wizard?.step,
          id_object: nrsWizard?.id_object,
        },
        {},
        wrapperKey,
      )),
    };
  }

  return requestWizardMappings.checkFormName({...data});
});

const nrsWizardSlice = createSlice({
  name: 'nrsWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow! = action.payload;
    },
    changeIdObject(state, action) {
      state.id_object = action.payload;
    },
    changeInitialValue(state, action) {
      const {payload} = action;
      state.screens[payload.step].initialValues = payload.values;
    },
    clearScreens(state, action) {
      state.screens = {};
      state.id_object = null;
      state.stepNow = null;
    },
    deleteServerError(state, action) {
      const {stepNow, subStep, field} = action.payload;
      const fieldsOnScreen = state.screens[stepNow].items[subStep];

      fieldsOnScreen.map((fieldOnScreen: {field: any; error: any}) => {
        if (fieldOnScreen.field === field) {
          delete fieldOnScreen.error;
        }
      });
      state.screens[stepNow].items[subStep] = fieldsOnScreen;
    },
  },
  extraReducers: builder => {
    builder.addCase(nrsWizardThunk.pending, (state, action) => {
      const {
        meta: {arg},
      } = action;
      state.loading = PENDING;
    });
    builder.addCase(
      nrsWizardThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {
          payload,
          meta: {arg},
        } = action;
        state.loading = IDLE;
        state.wizard = payload?.wizard;
        state.regions = payload?.regions;


        if (!payload.success) {
          state.error = payload.error_message;
        }
        if (payload.id_object) {
          state.id_object = payload.id_object;
        }
        if (payload.step) {
          state.stepNow! = String(payload.step);
        }

        if (payload.CSPUI) {
          state.CSPUI = payload.CSPUI;
          state.signDocs = payload.CardBuilder.items;
        }

        const groupFields = [];
        for (const key in payload?.FormBuilder?.items) {
          const field = payload.FormBuilder.items[key];
          if (field.group) {
            groupFields.push(field.group);
          }
        }
        groupFields.map(group => {
          for (const key in payload.FormBuilder.items) {
            const field = payload.FormBuilder.items[key];
            if (field.type === 'select') {
              for (const i in field.items?.List?.items) {
                const selectField = field.items?.List?.items[i];
                if (selectField.value === group) {
                  state.groupFields[group] = field.field;
                }
              }
            }
          }
        });
        if (!arg.catalogUpdate) {
          if (!state.screens[state.stepNow!]) {
            state.screens[state.stepNow!] = {};
            state.screens[state.stepNow!].ctrl = payload?.FormBuilder?.form_name;
            state.screens[state.stepNow!].initialValues =
              requestWizardMappings.initialValuesMapper(payload?.FormBuilder?.items) ||
              {};
          } else {
            state.screens[state.stepNow!].initialValues =
              requestWizardMappings.changeInitialValuesMapper(
                state.screens[state.stepNow!]?.initialValues,
                payload?.FormBuilder?.items,
              ) || {};
          }
          state.screens[state.stepNow!].items = payload?.FormBuilder?.items;
        }
      },
    );
    builder.addCase(nrsWizardThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
  },
});

const {actions, reducer} = nrsWizardSlice;

export const {
  changeStepNow,
  clearScreens,
  changeIdObject,
  changeInitialValue,
  deleteServerError,
} = actions;

export default reducer;
