import apiClient from '@api/entities/apiClient';
import {bodyFormDataBuilder} from '@utils/formatters';
import { NextRouter } from 'next/router';
import {Dispatch, SetStateAction} from 'react';

const attorneyCreateWizard = async ({
  params,
  postData,
  wrapperKey,
  router
}: {
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
  router: NextRouter
}) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    const response = await apiClient.post(
      '/CabinetSupplyAttorneyCreateWizard',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    if (response.data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    return response.data;
  } catch (e) {
    console.log('Cabinet attorney wizard error ', e);
    throw e;
  }
};

const attorneySignRequest = async ({
  params,
  postData,
  router
}: {
  params: any,
  postData?: {[key: string]: string | any},
  router: NextRouter
}) => {
  try {
    const bodyFormData = new URLSearchParams();
    for (const field in postData) {
      bodyFormData.append(field, postData[field]);
    }
    const {data} = await apiClient.post(
      '/CabinetSupplyAttorneyCreateWizard',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
        },
        middleware: {
          withAuth: true,
        },
      },
    );
    if (data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const uploadFileForNewAttorney = async ({
  file,
  id_object,
  setProgress,
  router
}: {
  file: File,
  id_object: string,
  setProgress: Dispatch<SetStateAction<number>>,
  router: NextRouter
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append(
      'action',
      'CabinetSupplyAttorneyWizardStep3Control:CabinetSupplyAttorneyWizardControl:AjaxFileUpload',
    );
    bodyFormData.append('field', 'Scans');
    bodyFormData.append('file[0]', file);
    const {
      data: {items, error, success, errcode},
    } = await apiClient.post('/CabinetSupplyAttorneyCreateWizard', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        'CabinetSupplyAttorneyWizardStep3Control[submit_form]': 1,
        step: 3,
        id_object,
      },
      middleware: {
        withAuth: true,
      },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });
    if (errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    if (success) {
      return {
        title: items[0]?.name ?? '',
        id: items[0]?.id ?? '',
      };
    } else {
      return {
        title: '',
        id: '',
        error,
      };
    }
  } catch (e) {
    console.log('Error upload file ', e);
    throw e;
  }
};

export const attorneyWizardAPI = {
  attorneyCreateWizard,
  uploadFileForNewAttorney,
  attorneySignRequest,
};
