import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {accreditation} from '@api/cabAccreditation';
import {transformNewFields} from '@api/mappings/common';
import {transformAccreditationFields} from '@api/mappings/accreditation';
import {getFiasAsText} from '@api/streetAutocomplete';

interface IAccreditationInitialState {
  loading: string;
  loadingCheck: boolean;
  method: string;
  wizard: {
    step: string | null;
    maxstep: number | null;
    nextstep: number | null;
    id_object: number | null;
    steps: any;
    isavailable: boolean;
    is_available_edit_package: boolean;
    url: string;
  };
  stepNow: number | null;
  screens: any;
  requestError: string | null;
  documents: {
    dpu: string;
  }[];
  company_address_legal: {guid: string} | null;
}

const initialState: IAccreditationInitialState = {
  loading: PENDING,
  loadingCheck: false,
  method: 'AccreditationCounteragent',
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    id_object: null,
    steps: [],
    isavailable: true,
    is_available_edit_package: false,
    url: '',
  },
  stepNow: null,
  screens: {},
  requestError: null,
  documents: [],
  company_address_legal: null,
};

export const accreditationThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    endpoint?: string;
    params?: Record<string, any>;
    goToPrev?: boolean;
    isDocs?: boolean;
    isSign?: boolean;
  },
  {state: RootState}
>(
  'accreditation/fetch',
  async (
    {endpoint, params, goToPrev = false, isDocs = false, isSign = false},
    thunkAPI,
  ) => {
    let data = await accreditation(endpoint, params);
    const initialAccreditationData = data?.accreditation ? data?.accreditation[0] : null;
    if (data.success) {
      if (
        !goToPrev &&
        !isDocs &&
        !isSign &&
        data.success &&
        (!endpoint || endpoint !== data?.accreditation[0]?.method) &&
        data?.accreditation[0]?.wizard?.step
      ) {
        data = await accreditation(data?.accreditation[0]?.method);
      }
      if (isSign) {
        data = await accreditation('AccreditationSign');
      }

      if (initialAccreditationData && !data?.accreditation) {
        data.accreditation = [initialAccreditationData];
      }
      if (data.company_address_legal) {
        try {
          const addressObj = JSON.parse(data.company_address_legal);
          const res = await getFiasAsText(addressObj?.guid);
          if (res.success) {
            data.company_address_legal = {...addressObj, address: res.address};
          }
        } catch (e) {
          console.log(e);
        }
      }
      if (isDocs) {
        data.accreditation[0].wizard.step = 4;
        data.accreditation[0].method = 'AccreditationDocuments';
      }
    }

    return {...data, goToPrev};
  },
);

const accreditationSlice = createSlice({
  name: 'accreditation',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow! = action.payload;
      state.method = state?.wizard?.steps?.find(
        (step: Record<string, any>) => step?.step === action.payload,
      )?.method;
    },

    changeInitialValue(state, action) {
      const {payload} = action;
      state.screens[payload.step].initialValues = payload.values;
    },
    deleteServerError(state, action) {
      const {stepNow, subStep, field} = action.payload;
      const fieldsOnScreen = state.screens[stepNow].items[subStep];

      fieldsOnScreen.map((fieldOnScreen: {field: any; error: any}) => {
        if (fieldOnScreen.field === field) {
          delete fieldOnScreen.error;
        }
      });
      state.screens[stepNow].items[subStep] = fieldsOnScreen;
    },
    setLoading(state, action) {
      state.loading = action.payload ? PENDING : IDLE;
    },
  },
  extraReducers: builder => {
    builder.addCase(accreditationThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      accreditationThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        state.requestError = payload?.error_message ?? null;

        state.loading = IDLE;
        if (payload?.accreditation) {
          if (!payload?.goToPrev) {
            state.method = payload?.accreditation[0]?.method;
            state.wizard = payload?.accreditation[0]?.wizard;
            state.stepNow! = Number(payload?.accreditation[0]?.wizard?.step);
          }

          if (payload?.fields) {
            const cardFields = payload?.card
              ? payload?.card.reduce(
                  (arr: [], section: {title: string; fields: Record<string, any>[]}) => {
                    return [
                      ...arr,
                      {type: 'separator', title: section.title},
                      ...section.fields.map(field => ({
                        ...field,
                        type: 'text',
                        readonly: true,
                      })),
                    ];
                  },
                  [],
                )
              : [];

            const tempFormBuilderItemsArr = [
              ...cardFields,
              ...Object.values(payload?.fields),
            ];

            if (!state.screens[state.stepNow!]) {
              state.screens[state.stepNow!] = {};
              state.screens[state.stepNow!].initialValues =
                requestWizardMappings.initialValuesMapper(
                  transformAccreditationFields(
                    transformNewFields(tempFormBuilderItemsArr),
                    state.stepNow ?? 1,
                  ),
                ) || {};
            } else {
              state.screens[state.stepNow!].initialValues =
                requestWizardMappings.changeInitialValuesMapper(
                  state.screens[state.stepNow!]?.initialValues,
                  transformAccreditationFields(
                    transformNewFields(tempFormBuilderItemsArr),
                    state.stepNow ?? 1,
                  ),
                ) || {};
            }
            state.screens[state.stepNow!].items = transformAccreditationFields(
              transformNewFields(tempFormBuilderItemsArr),
              state.stepNow ?? 1,
            );
          }
        }
        const errorsList = payload?.errors_list ?? [];

        if (errorsList.length && state?.screens[state.stepNow!]?.items) {
          const errorsListObj = errorsList.reduce(
            (obj: Record<string, any>, item: Record<string, any>) => {
              if (item.prop) {
                return {...obj, [item.prop]: item.error};
              }
              return obj;
            },
            {},
          );

          state.screens[state.stepNow!].items = state.screens[state.stepNow!].items.map(
            (item: Record<string, any>) => {
              if (errorsListObj[item.field]) {
                return {
                  ...item,
                  error: errorsListObj[item.field],
                };
              }
              return item;
            },
          );
        }

        if (payload?.package) {
          state.stepNow! = 5;
        }

        if (payload?.documents) {
          state.documents! = payload?.documents;
        }

        if (payload?.company_address_legal) {
          state.company_address_legal = payload.company_address_legal;
        }
      },
    );
    builder.addCase(accreditationThunk.rejected, state => {
      state.loadingCheck = false;
    });
  },
});

const {actions, reducer} = accreditationSlice;

export const {changeStepNow, changeInitialValue, deleteServerError, setLoading} = actions;

export default reducer;
