import {cabinetAttorneyWizardAPI} from '@api/cabinetAttorneyWizard';
import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IWizardField} from '@api/types/Wizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import { NextRouter } from 'next/router';

interface IContactAttorneyWizardState {
  loading: typeof IDLE | typeof PENDING;
  id_object: string;
  stepNow: string;
  wrapperKey: string;
  initialValues: null | Record<string, any>;
  initialErrors: Record<string, string>;
  contactID: string | null;
  title: string | null;
  error_message: string | null;
  csrfToken?: {field: string; token: string};
  attorneyTypesOptions: {title: string; value: string}[];
  fileSizeMax: number;
}

const initialState: IContactAttorneyWizardState = {
  loading: IDLE,
  id_object: '',
  stepNow: '1',
  wrapperKey: '',
  initialValues: {
    m4d_number: '',
    inn: '',
    snils: '',
  },
  initialErrors: {},
  contactID: null,
  title: null,
  error_message: null,
  csrfToken: undefined,
  attorneyTypesOptions: [],
  fileSizeMax: 15728640,
};

export const cabinetAttorneyWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params: any[] | Record<string, any>;
    postData?: Record<string, unknown>;
    wrapperKey?: string;
    csrfToken?: {field: string; token: string};
    router: NextRouter;
  },
  {state: RootState}
>(
  'cabinetAttorneyWizard/fetch',
  async ({params, postData, wrapperKey, csrfToken, router}, thunkAPI) => {
    if (postData && csrfToken) {
      postData[csrfToken.field] = csrfToken.token;
    }
    let data = await cabinetAttorneyWizardAPI.cabinetAttorneyWizard({
      params: {
        ...params,
      },
      postData,
      wrapperKey,
      router
    });
    if (data?.wizard?.step) {
      data = {
        step: data?.wizard?.step,
        id_object: data?.wizard?.id_object,
        ...(await cabinetAttorneyWizardAPI.cabinetAttorneyWizard({
          params: {
            step: data?.wizard?.step,
            id_object: data?.wizard?.id_object,
          },
          postData,
          wrapperKey,
          router
        })),
      };
    }
    return data;
  },
);

const cabinetContactAttorneyWizard = createSlice({
  name: 'cabinetContactAttorneyWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },
    setInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const {payload} = action;
      state.initialValues = payload.values;
    },
    dropState(state) {
      state.stepNow = initialState.stepNow;
      state.id_object = initialState.id_object;
    },
  },
  extraReducers: builder => {
    builder.addCase(cabinetAttorneyWizardThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      cabinetAttorneyWizardThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        const formBuilder = payload?.FormBuilder;
        state.initialErrors = {};
        if (formBuilder) {
          state.wrapperKey = formBuilder?.form_name ?? '';
          const csrfItem = formBuilder?.items?.find(
            (el: any) => el?.type === 'csfr-token',
          );
          const scansItem = formBuilder?.items?.find(
            (el: any) => el?.type === 'file_ajax',
          );
          if (csrfItem) {
            state.csrfToken = {field: csrfItem?.field, token: csrfItem?.token};
          }
          if (scansItem) {
            state.fileSizeMax = scansItem?.max_size ?? 0;
          }

          const attorneyTypesSelect = formBuilder?.items?.find(
            (el: any) => el?.code === 'attorney_type',
          );
          if (attorneyTypesSelect) {
            state.attorneyTypesOptions = attorneyTypesSelect?.items?.List?.items.map(
              (el: any) => ({title: el.title, value: el.value}),
            );
          }

          formBuilder?.items
            .flat()
            .filter((item: IWizardField) => !!item.error)
            .forEach((item: IWizardField) => {
              state.initialErrors[item.field] = item.error;
            });
        }

        if (payload?.step) {
          state.stepNow = payload?.step;
        }
        if (payload?.id_object) {
          state.id_object = payload.id_object;
        }
        if (formBuilder?.items.length) {
          state.initialValues = requestWizardMappings.initialValuesMapper(
            formBuilder.items,
          );
        }
        state.loading = IDLE;
      },
    );
    builder.addCase(cabinetAttorneyWizardThunk.rejected, state => {
      state.loading = IDLE;
    });
  },
});

const {actions, reducer} = cabinetContactAttorneyWizard;

export const {changeStepNow, setInitialValues, dropState} = actions;

export default reducer;
