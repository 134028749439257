import {getGeoByIp} from '@api/geo';
import { USER_FIASCODE } from '@constants/cookies';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { setCookie } from 'cookies-next';

interface Geolocation {
  fiascode: string;
  region: string;
  city: string;
  region_fias_id?: string;
  
}

interface GeolocationState {
  isConfirmModalActive: boolean;
  isSelectModalActive: boolean;
  geo: Geolocation | null;
}

const initialState: GeolocationState = {
  isConfirmModalActive: false,
  isSelectModalActive: false,
  geo: null,
};

export const getGeo = createAsyncThunk('getGeoByCity/fetch', async () => {
  const res = await getGeoByIp();
  if (res.success && res.item) {
    return res.item;
  }
  return null;
});

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setConfirmModalActive(state, action) {
      state.isConfirmModalActive = action.payload;
    },
    setSelectModalActive(state, action) {
      state.isSelectModalActive = action.payload;
    },
    setGeo(state, action: PayloadAction<Geolocation | null>) {
      state.geo = action.payload;
      if (localStorage.getItem('geo') === JSON.stringify(action.payload)) return;
      localStorage.setItem('geo', JSON.stringify(state.geo));
      setCookie(USER_FIASCODE, state.geo?.fiascode);
      state.isConfirmModalActive = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(getGeo.fulfilled, (state, action) => {
      geolocationSlice.caseReducers.setGeo(state, action);
    });
  },
});

export const {setConfirmModalActive, setSelectModalActive, setGeo} = geolocationSlice.actions;
export default geolocationSlice.reducer;
