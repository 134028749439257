import {useEffect} from 'react';

export const useMangoOnlineChatCustomization = () => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
        const chat = document.getElementById('mgo-mcw-widget');
        if (chat) {
          const position = '2.5%';
          const width = '50px';
          const gap = '8px';
          chat.style.transition = 'right 0.5s ease-in';
          chat.style.right = `calc(${position} + ${width} + ${gap})`;
          chat.style.bottom = position;
          observer.disconnect();
        }
    });
    observer.observe(document.documentElement, {
      attributes: false,
      characterData: false,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, []);
};
