import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const Wrapper = styled.div<{isAccepted: boolean}>`
  display: flex;
  gap: 24px;
  align-items: center;
  min-width: 80%;
  padding: 24px;

  position: fixed;
  bottom: 15%;
  left: 50%;

  transform: translateX(-50%);
  background: ${theme.colors.white};
  box-shadow: 8px 40px 56px rgba(108, 112, 116, 0.12), 0px 2px 1px rgba(15, 69, 148, 0.08);
  border-radius: 12px;

  z-index: ${({isAccepted}) => (isAccepted ? -10 : 1000)};
  opacity: ${({isAccepted}) => (isAccepted ? 0 : 1)};
  transition: opacity ease-in-out 0.3s;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 24px 15px;
  }
`;

export const TextWrapper = styled.p`
  font-family: ${theme.fonts.subline};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.darkV2};

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkTest = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.colors.brandGreenV2};
`;

export const StyledButton = styled(Button)`
  padding: 15px 35px;
`;
