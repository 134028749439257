import {requestWizardMappings} from '@api/mappings/requestWizard';
import {getAccountForms, setAccountInformation, checkAccountPassType} from '@api/user';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IDLE, PENDING} from '@constants/loadingState';
import {CabinetAccountData, CabinetRegData} from '@api/types/CabinetApps';
import {RootState} from '@store';
import { deleteCookie, setCookie } from 'cookies-next';
import {checkAuth, getRegData, getUserFromCabinet} from '@api/cabinet';
import { RegFormTypes, RegSteps } from '@components/molecules/CabinetReg2/types';

interface UserSliceState {
  token: string | null;
  expired: string;
  account: CabinetAccountData | null;
  groups: any[];
  loading: string;
  recoverStep: 1;
  regions: {code: string; title: string}[];
  isAuth: boolean | null;
  accountLoading: typeof IDLE | typeof PENDING;
  regDataLoading: typeof IDLE | typeof PENDING;
  isAuthLoading: typeof IDLE | typeof PENDING;
  accountForm: {
    wizard: Record<string, string>;
    form_name: string;
    screens: Record<string, any>;
    stepNow: number;
    subStep: number;
    hasErrors: boolean;
  };
  regData: CabinetRegData | null;
}

export const getAccountThunk = createAsyncThunk('user/getAccount', 
  async (params, thunkAPI) => {
    const data = await getUserFromCabinet();
    return {
      ...data.account,
    };
});

export const getRegDataThunk = createAsyncThunk<
  CabinetRegData,
  any,
  {state: RootState}
>('user/getRegData', async (params, thunkAPI) => {
  const {user} = thunkAPI.getState();

  if (!user.regData) {
    const res = await getRegData();
    return {
      iscompleted: res.iscompleted,
      clientType: res.clientType
    }
  } else {
    return user.regData;
  }
});

export const getIsAuthThunk = createAsyncThunk<boolean, any, {state: RootState}>(
  'user/getIsAuth',
  async () => {
    const res = await checkAuth();
    return Promise.resolve(res.success);
  },
);

export const getAccountFormsThunk = createAsyncThunk<
  Record<string, any>,
  Record<string, any>
>('user/getAccountForms', async params => {
  return await getAccountForms(params);
});

export const setAccountInformationThunk = createAsyncThunk<
  Record<string, any>,
  {
    params: Record<string, any>;
    postData: Record<string, any>;
    wrapperKey: string;
  }
>('user/setAccountInformation', async ({params, postData, wrapperKey}) => {
  return await setAccountInformation(params, postData, wrapperKey);
});

export const updateRegistrationPassportData = createAsyncThunk(
  'user/updateRegistrationPassportData',
  async ({pass_type, step}: {pass_type: string; step: number}, thunkAPI) => {
    const data = await checkAccountPassType(pass_type);
    return {
      ...data,
      step,
    };
  },
);

export const logoutUser = createAsyncThunk('user/logoutUser', async (params, thunkAPI) => {
  deleteCookie('authToken');
  deleteCookie('__at');
  deleteCookie('userID');
  thunkAPI.dispatch(resetUser());
});

export const clearRegData = createAsyncThunk('user/clearRegData', async (params, thunkAPI) => {
  sessionStorage.clear();
  thunkAPI.dispatch(setFormName(RegFormTypes.organization));
  thunkAPI.dispatch(changeStepNow(RegSteps.phone));
});

export const setRegTypeThunk = createAsyncThunk('user/setRegTypeThunk', async (
  regType: RegFormTypes, 
  thunkAPI
) => {
  sessionStorage.setItem('reg_type', regType);
  thunkAPI.dispatch(setFormName(regType));
});

export const setRegStepThunk = createAsyncThunk('user/setRegStepThunk', async (
  step: RegSteps, 
  thunkAPI
) => {
  sessionStorage.setItem('reg_step', String(step));
  thunkAPI.dispatch(changeStepNow(step));
});

const accountFormSetData = (
  state: UserSliceState,
  payload: Record<string, any>,
  meta: Record<string, any>,
) => {
  state.loading = IDLE;
  state.accountForm.wizard = payload.wizard;
  state.accountForm.hasErrors = Boolean(Number(payload.FormBuilder?.form_errors));
  if (payload.regions) state.regions = payload.regions;
  if (
    !state.accountForm.screens[state.accountForm.stepNow] ||
    Object.values(state.accountForm.screens[state.accountForm.stepNow].initialValues)
      .length === 0
  ) {
    state.accountForm.screens[state.accountForm.stepNow] = {};
    state.accountForm.screens[state.accountForm.stepNow].ctrl = payload?.ctrl;
    state.accountForm.screens[state.accountForm.stepNow].initialValues =
      requestWizardMappings.initialValuesMapper(payload?.FormBuilder?.items) || {};
  } else {
    state.accountForm.screens[state.accountForm.stepNow].initialValues =
      requestWizardMappings.changeInitialValuesMapper(
        state.accountForm.screens[state.accountForm.stepNow].initialValues,
        payload?.FormBuilder?.items,
      ) || {};
    state.accountForm.screens[state.accountForm.stepNow].ctrl = payload?.ctrl;
  }
  state.accountForm.screens[state.accountForm.stepNow].items =
    payload?.FormBuilder?.items;
  if (payload.wizard?.step) state.accountForm.stepNow = +payload.wizard.step;
};

const initialState: UserSliceState = {
  token: null,
  expired: '',
  account: null,
  groups: [],
  loading: PENDING,
  recoverStep: 1,
  regions: [],
  isAuth: false,
  accountLoading: PENDING,
  regDataLoading: PENDING,
  isAuthLoading: PENDING,
  accountForm: {
    wizard: {},
    form_name: '',
    screens: {},
    stepNow: 1,
    subStep: 0,
    hasErrors: false,
  },
  regData: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setRegions(state, action: PayloadAction<{code: string; title: string}[]>) {
      state.regions = action.payload;
    },
    changeFormInitialValue(state, action) {
      const {payload} = action;
      state.accountForm.screens[payload.step].initialValues = payload.values;
    },
    changeStepNow(state, action) {
      state.accountForm.stepNow = action.payload;
    },
    setFormName(state, action) {
      state.accountForm.form_name = action.payload;
    },
    setAccountForm(state, action) {
      if (!state.accountForm.screens[action.payload.stepNow]) {
        state.accountForm.screens[action.payload.stepNow] = {};
      }
      state.accountForm.screens[action.payload.stepNow].isPD = action.payload.isPD;
      state.accountForm.screens[action.payload.stepNow].fields = action.payload.fields;
    },
    deleteServerError(state, action) {
      const {stepNow, field} = action.payload;
      const fieldsOnScreen = state.accountForm.screens[stepNow].items;

      fieldsOnScreen.map((fieldOnScreen: {field: any; error: any}) => {
        if (fieldOnScreen.field === field) {
          delete fieldOnScreen.error;
        }
      });
      state.accountForm.screens[stepNow].items = fieldsOnScreen;
    },
    setAccount(state, action) {
      state.account = action.payload;
    },
    setIsAuth(state, action) {
      state.isAuth = action.payload;
    },
    resetUser() {
      return {...initialState, accountLoading: IDLE};
    },
    // добавлено исключительно для теста, пользоваться для разработки некорректно
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccountThunk.pending, (state, action) => {
      state.accountLoading = PENDING;
    });
    builder.addCase(getAccountThunk.fulfilled, (state, {payload}) => {
      state.loading = IDLE;
      state.accountLoading = IDLE;
      state.account = payload;
      !!payload?.id && setCookie('userID', payload.id);
    });
    builder.addCase(getRegDataThunk.pending, (state, action) => {
      state.regDataLoading = PENDING;
    });
    builder.addCase(getRegDataThunk.fulfilled, (state, {payload}) => {
      state.regDataLoading = IDLE;
      state.regData = payload;
    });
    builder.addCase(getIsAuthThunk.pending, (state, action) => {
      state.isAuthLoading = PENDING;
    });
    builder.addCase(getIsAuthThunk.fulfilled, (state, {payload}) => {
      state.isAuthLoading = IDLE;
      state.isAuth = payload;
    });
    builder.addCase(getAccountFormsThunk.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(getAccountFormsThunk.fulfilled, (state, action) => {
      const {payload, meta} = action;
      accountFormSetData(state, payload, meta);
    });
    builder.addCase(getAccountFormsThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(setAccountInformationThunk.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(setAccountInformationThunk.fulfilled, (state, action) => {
      const {payload, meta} = action;
      accountFormSetData(state, payload, meta);

      if (
        !payload.wizard.step &&
        state.accountForm?.screens[state.accountForm.stepNow]?.ctrl !== payload.ctrl
      )
        state.accountForm.stepNow++;

      state.loading = IDLE;
    });
    builder.addCase(setAccountInformationThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(updateRegistrationPassportData.fulfilled, (state, action) => {
      const {step} = action.payload;
      if (action.payload.items && state.accountForm.screens[step]?.items) {
        action.payload.items.forEach((field: any) => {
          const item = state.accountForm.screens[step].items.find(
            (item: any) => item.field === field.field,
          );
          if (item) {
            item.required = field.required;
          }
        });
      }
    });
  },
});

export const {
  setToken,
  changeFormInitialValue,
  changeStepNow,
  deleteServerError,
  setLoading,
  setAccount,
  setFormName,
  setAccountForm,
  setIsAuth,
  setRegions,
  resetUser,
} = userSlice.actions;
export default userSlice.reducer;
