import {IWizardField} from '@api/types/Wizard';
import {Dispatch, SetStateAction} from 'react';
import {isUndefined} from 'lodash';
import {IRoboDealWizardState} from '@api/types/CabRoboDealWizard';

export const findFieldsAndSetCondValues = async (
  fields: Record<string, any>,
  setConditionValues: Dispatch<SetStateAction<Record<string, any>>>,
  id_app: string,
) => {
  const rpt = fields?.find((field: IWizardField) => field.field === 'RepaymentType');
  const adv = fields?.find((field: IWizardField) => field.field === 'advancePay');
  const term = fields?.find((field: IWizardField) => field.field === 'orderPeriod');
  const canBase = fields?.find(
    (field: IWizardField) => field.field === 'isRoboDelayPays',
  );
  const canAdvToPay = fields?.find(
    (field: IWizardField) => field.field === 'isRoboDelayAdvToPay',
  );
  const canAdv = fields?.find(
    (field: IWizardField) => field.field === 'isRoboDelayAdvance',
  );
  const seasonMonthsFields = fields?.filter(
    (field: IWizardField) => field.repayment_code === '9',
  );

  const regressionCoefs = fields?.find(
    (field: IWizardField) => field.field === 'RegressionCoef',
  );

  setConditionValues(prev => {
    const prevIdAppCondValues = prev[id_app];
    return {
      ...prev,
      ...(prevIdAppCondValues
        ? {[id_app]: prevIdAppCondValues}
        : {
            [id_app]: {
              ...(rpt !== undefined && {
                rpt: {
                  value: rpt.value,
                  options: rpt.items.List.items,
                  code: rpt.items.List.items.find(
                    (item: {value: any}) => rpt.value === item.value,
                  )?.code,
                },
              }),
              ...(adv !== undefined && {
                adv: {value: adv.value, min: +adv.min, max: +adv.max},
              }),
              ...(term !== undefined && {
                term: {value: term.value, min: +term.min, max: +term.max},
              }),
              ...(canBase !== undefined && {
                canBase: {value: canBase.value, title: canBase.title},
              }),
              ...(canAdvToPay !== undefined && {
                canAdvToPay: {value: canAdvToPay.value, title: canAdvToPay.title},
              }),
              ...(canAdv !== undefined && {
                canAdvToPay: {value: canAdv.value, title: canAdv.title},
              }),
              id_app: rpt?.group ?? null,
              ...(seasonMonthsFields !== undefined &&
                seasonMonthsFields.length !== 0 && {
                  seasonMonthsFields,
                  months: {},
                }),
              ...(regressionCoefs !== undefined && {
                regressionCoefs: {
                  value: regressionCoefs.value,
                  options: regressionCoefs.items.List.items,
                  code: regressionCoefs.items.List.items.find(
                    (item: {value: any}) => regressionCoefs.value === item.value,
                  )?.code,
                },
              }),
            },
          }),
    };
  });
};

export const determineCurrentStep = (
  payload: Record<string, any>,
  state: IRoboDealWizardState,
) => {
  if (
    isUndefined(payload?.FormBuilder) &&
    !state.isCabinetDealManualConditionsControlStep &&
    payload.states
  ) {
    console.log(
      'Boolean(Number(payload.states.isrobo))',
      payload,
      payload.states,
      payload.states.isrobo,
      !!payload.states.isrobo,
    );
    if (
      state.stepNow === '3' &&
      Boolean(Number(payload?.states?.isrobo)) &&
      !Boolean(Number(payload?.states?.isready))
    ) {
      state.isFormatingConditionsStep = true;
    }
  }

  if (
    payload?.Documents &&
    Boolean(Number(payload.states.iscontract)) &&
    !Boolean(Number(payload.states.iscontractsigned)) &&
    !Boolean(Number(payload.states.isdocs)) &&
    !Boolean(Number(payload.states.isCommission))
  ) {
    state.isCabinetDealOrderSigningStep = true;
    state.isFormatingOrderStep = false;
  } else {
    state.isCabinetDealOrderSigningStep = false;
  }

  if (
    payload?.FormBuilder?.form_name === 'CabinetDealWizardRoboDocumentsControl' &&
    Boolean(Number(payload.states.iscontractsigned)) &&
    Boolean(Number(payload.FormBuilder.ispackageready))
  ) {
    state.isDocumentUploadStep = true;
    state.isFormatingOrderStep = false;
  } else {
    state.isDocumentUploadStep = false;
  }

  if (
    payload?.FormBuilder?.form_name === 'CabinetDealWizardRoboFinishControl' ||
    (!isUndefined(payload.Documents) && !isUndefined(payload.Conditions) && !payload.states.isCommission)
  ) {
    if (!isUndefined(payload.Documents) && !isUndefined(payload.Conditions)) {
      state.roboFinishConditions = Object.values(payload.Conditions.items);
    }
    state.isCabinetDealWizardRoboFinishControl = true;
    state.isFormatingOrderStep = false;
  } else {
    state.isCabinetDealWizardRoboFinishControl = false;
  }

  if (
    isUndefined(payload?.FormBuilder) &&
    isUndefined(payload?.Documents) &&
    isUndefined(payload.Conditions)
  ) {
    if (state.stepNow === '6' && !Boolean(Number(payload?.states?.iscontract))) {
      state.isFormatingOrderStep = true;
    }
  }

  if (payload?.FormBuilder?.form_name === 'CabinetDealCreditDeclarationControl') {
    state.isCabinetDealCreditDeclarationStep = true;
  } else {
    state.isCabinetDealCreditDeclarationStep = false;
  }

  if (
    payload?.FormBuilder &&
    payload?.FormBuilder?.form_name === 'CabinetDealConditionsControl'
  ) {
    state.isCabinetDealConditionsControlStep = true;
    state.isFormatingConditionsStep = false;
  } else {
    state.isCabinetDealConditionsControlStep = false;
  }

  if (payload?.FormBuilder?.form_name === 'CabinetDealManualWizardStep3Control') {
    state.isFormatingConditionsStep = false;
    state.isCabinetDealManualConditionsControlStep = true;
  } else {
    state.isCabinetDealManualConditionsControlStep = false;
  }

  if (payload?.FormBuilder?.form_name === 'CabinetBookIncomeExpensesControl') {
    state.isCabinetBookIncomeExpensesControlStep = true;
  } else {
    state.isCabinetBookIncomeExpensesControlStep = false;
  }

  if (payload?.FormBuilder?.form_name === 'CabinetDealWizardRoboCRMBeneficiaryQuestionnaireControl') {
    state.isBeneficiaryQuestionnaireStep = true;
  } else {
    state.isBeneficiaryQuestionnaireStep = false;
  }

  if (payload?.CSPUI) {
    state.cardBuilderItems = payload?.CardBuilder?.items;
    state.CSPUI = payload?.CSPUI;
    state.isCSPUIStep = true;
  } else {
    state.isCSPUIStep = false;
  }

  if (!Boolean(Number(payload?.states?.isrobo)) && payload?.Documents) {
    state.isSignManualStep = true;
  } else {
    state.isSignManualStep = false;
  }

  if (
    payload?.FormBuilder?.form_name === 'CabinetDealWizardRoboDocumentsControl' &&
    !Boolean(Number(payload.FormBuilder.ispackageready))
  ) {
    state.isFormationPackageOfDocumentsOrder = true;
    state.isFormatingOrderStep = false;
  } else if (
    payload?.FormBuilder?.form_name === 'CabinetDealWizardRoboDocumentsControl' &&
    Boolean(Number(payload.FormBuilder.ispackageready))
  ) {
    state.ispackageready = payload.FormBuilder.ispackageready;
    state.isFormationPackageOfDocumentsOrder = false;
  }

  if (
    payload?.Documents &&
    Boolean(Number(payload.states.iscontract)) &&
    Boolean(Number(payload.states.iscontractsigned)) &&
    Boolean(Number(payload.states.isdocs)) && 
    !Boolean(Number(payload.states.isCommission))
  ) {
    state.isCabinetDealPackageOfDocumentsOrderSigningStep = true;
    state.isFormationPackageOfDocumentsOrder = false;
  } else {
    state.isCabinetDealPackageOfDocumentsOrderSigningStep = false;
  }
  if (
    Boolean(Number(payload?.states?.isCommission))
  ) {
    state.isCommissionStep = true;
    state.isFormationPackageOfDocumentsOrder = false;
  } else {
    state.isCommissionStep = false;
  }
};


