import Image from 'next/legacy/image';
import Link from 'next/link';
import React, {FC} from 'react';
import {
  BtnDisabled,
  BtnFilled,
  BtnOutlined,
  BtnText,
  ButtonWrapper,
  ImageWrapper,
} from './Button.styles';

export interface ButtonProps {
  title?: string | JSX.Element;
  onClick?: (e: any) => void;
  style?: Record<string, string>;
  variant?: 'outlined' | 'filled' | 'text';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  fontSize?: number;
  fontWeight?: number;
  border?: 'green' | 'dark';
  iconSrc?: string;
  className?: string;
  noWrap?: boolean;
  link?: string;
  openLinkBlank?: boolean;
  iconSrcSizes?: {width: number; height: number};
  active?: boolean;
  iconPosition?: 'left' | 'right';
  iconMargin?: string;
  testID?: string;
  fullWidth?: boolean;
}

const Button: FC<ButtonProps> = ({
  title,
  onClick,
  style,
  fullWidth,
  variant = 'outlined',
  type = 'button',
  fontSize,
  noWrap = true,
  fontWeight = 400,
  disabled,
  border = 'green',
  iconSrc,
  className,
  link,
  openLinkBlank = false,
  iconSrcSizes = {width: 15, height: 16},
  active,
  iconPosition = 'right',
  iconMargin,
  testID,
}) => {
  const renderButton = (variant: string) => {
    switch (variant) {
      case 'outlined':
        return (
          <BtnOutlined
            className={className}
            onClick={onClick}
            style={style}
            fullWidth={fullWidth}
            type={type}
            fontSize={fontSize}
            fontWeight={fontWeight}
            border={border}
            noWrap={noWrap}
            data-testid={testID}>
            {iconSrc && iconPosition === 'left' && (
              <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                <Image
                  src={iconSrc}
                  alt={'icon'}
                  width={iconSrcSizes.width}
                  height={iconSrcSizes.height}
                />
              </ImageWrapper>
            )}
            {title}
            {iconSrc && iconPosition === 'right' && (
              <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                <Image
                  src={iconSrc}
                  alt={'icon'}
                  width={iconSrcSizes.width}
                  height={iconSrcSizes.height}
                />
              </ImageWrapper>
            )}
          </BtnOutlined>
        );
      case 'filled':
        return (
          <>
            <BtnFilled
              className={className}
              onClick={onClick}
              style={style}
              type={type}
              fullWidth={fullWidth}
              fontSize={fontSize}
              fontWeight={fontWeight}
              border={border}
              noWrap={noWrap}
              active={active}
              data-testid={testID}>
              {iconSrc && iconPosition === 'left' && (
                <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                  <Image
                    src={iconSrc}
                    alt={'icon'}
                    width={iconSrcSizes.width}
                    height={iconSrcSizes.height}
                  />
                </ImageWrapper>
              )}
              {title}
              {iconSrc && iconPosition === 'right' && (
                <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                  <Image
                    src={iconSrc}
                    alt={'icon'}
                    width={iconSrcSizes.width}
                    height={iconSrcSizes.height}
                  />
                </ImageWrapper>
              )}
            </BtnFilled>
          </>
        );
      case 'text':
        return (
          <BtnText
            className={className}
            onClick={onClick}
            style={style}
            type={type}
            fullWidth={fullWidth}
            fontSize={fontSize}
            fontWeight={fontWeight}
            border={border}
            noWrap={noWrap}
            data-testid={testID}>
            {iconSrc && iconPosition === 'left' && (
              <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                <Image
                  src={iconSrc}
                  alt={'icon'}
                  width={iconSrcSizes.width}
                  height={iconSrcSizes.height}
                />
              </ImageWrapper>
            )}
            {title}
            {iconSrc && iconPosition === 'right' && (
              <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                <Image
                  src={iconSrc}
                  alt={'icon'}
                  width={iconSrcSizes.width}
                  height={iconSrcSizes.height}
                />
              </ImageWrapper>
            )}
          </BtnText>
        );
      case 'text':
        return (
          <BtnText
            className={className}
            onClick={onClick}
            style={style}
            type={type}
            fullWidth={fullWidth}
            fontSize={fontSize}
            fontWeight={fontWeight}
            border={border}
            noWrap={noWrap}
            data-testid={testID}>
            {title}
            {iconSrc && (
              <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
                <Image src={iconSrc} alt={'icon'} width={34} height={24} />
              </ImageWrapper>
            )}
          </BtnText>
        );
    }
  };
  return (
    <>
      {!disabled ? (
        <>
          {link ? (
            <ButtonWrapper onClick={onClick}>
              <Link
                href={link.trim()}
                passHref
                target={openLinkBlank ? '_blank' : ''}
                data-testid={testID}>
                {renderButton(variant)}
              </Link>
            </ButtonWrapper>
          ) : (
            <>{renderButton(variant)}</>
          )}
        </>
      ) : (
        <BtnDisabled
          className={className}
          onClick={onClick}
          variant={variant}
          style={style}
          type={type}
          fullWidth={fullWidth}
          fontSize={fontSize}
          fontWeight={fontWeight}
          border={border}
          noWrap={noWrap}
          data-testid={testID}>
          {iconSrc && iconPosition === 'left' && (
            <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
              <Image
                src={iconSrc}
                alt={'icon'}
                width={iconSrcSizes.width}
                height={iconSrcSizes.height}
              />
            </ImageWrapper>
          )}
          {title}
          {iconSrc && iconPosition === 'right' && (
            <ImageWrapper iconSrcSizes={iconSrcSizes} margin={iconMargin}>
              <Image
                src={iconSrc}
                alt={'icon'}
                width={iconSrcSizes.width}
                height={iconSrcSizes.height}
              />
            </ImageWrapper>
          )}
        </BtnDisabled>
      )}
    </>
  );
};

export default Button;
