import {getExperiments} from '@api/experiments';
import {getFileInfoByCode, getPageIDs} from '@api/main';
import ClientPageIntroduceCabinet from '@components/molecules/ClientPage/ClientPageIntroduceCabinet';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';

interface UIState {
  isModalActive: boolean;
  signModalState: {
    canClose: boolean;
    message: string;
  } | null;
  filesWithCode: null | Record<string, string>;
  pages: {id: string; name: string; url: string; url_redirect: string}[];
  scrollWithoutModal: number;
  experiments: string[] | null;
  experimentsLoading: string;
}

const initialState: UIState = {
  isModalActive: false,
  signModalState: null,
  filesWithCode: null,
  pages: [],
  scrollWithoutModal: 0,
  experiments: null,
  experimentsLoading: IDLE,
};

export const getFileWithCode = createAsyncThunk<
  Record<string, any>,
  {code: string; signal?: AbortSignal},
  {state: RootState}
>('getFileWithCode/fetch', async ({code, signal}, thunkAPI) => {
  const {ui} = thunkAPI.getState();

  if (ui.filesWithCode && ui?.filesWithCode[code]) {
    return ui.filesWithCode[code];
  } else {
    return await getFileInfoByCode(code, signal);
  }
});

export const getPages = createAsyncThunk<
  {id: string; name: string; url: string; url_redirect: string}[],
  undefined,
  {state: RootState}
>('getPages/fetch', async () => {
  const data = await getPageIDs();

  return data.pages;
});

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleModal(state) {
      state.isModalActive = !state.isModalActive;
    },
    setSignModalState(
      state,
      action: PayloadAction<{message: string; canClose: boolean} | null>,
    ) {
      state.signModalState = action.payload;
    },
    setScrollWithoutModal(state, action: PayloadAction<number>) {
      state.scrollWithoutModal = action.payload;
    },
    setExperiments(state, action: PayloadAction<string[]>) {
      state.experiments = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getFileWithCode.fulfilled, (state, {payload, meta: {arg}}) => {
      if (state.filesWithCode && state.filesWithCode[arg.code]) {
        return;
      }
      if (state.filesWithCode && !state.filesWithCode[arg.code]) {
        state.filesWithCode = {...state.filesWithCode, [arg.code]: payload.doc.src};
      }
      if (!state.filesWithCode) {
        state.filesWithCode = {[arg.code]: payload.doc.src};
      }
    });
    builder.addCase(
      getPages.fulfilled,
      (
        state,
        action: PayloadAction<
          {id: string; name: string; url: string; url_redirect: string}[]
        >,
      ) => {
        state.pages = action.payload;
      },
    );
  },
});

export const {toggleModal, setSignModalState, setScrollWithoutModal, setExperiments} = uiSlice.actions;
export default uiSlice.reducer;
