const fullWidthFields = [
  'address_post',
  'is_address_post_eq_legal',
  'address_real',
  'is_address_real_eq_legal',
  'staff_counter',
  'service_maintenance',
];

const addressFields = ['address_post', 'address_real'];

const removeValidator = ['is_address_post_eq_legal','is_address_real_eq_legal'];

export const transformAccreditationFields = (fields: any[], step?: number) => {
  return fields?.reduce((arr: Record<string, any>[], field: Record<string, any>) => {
    if (!field.required && !Boolean(Number(field.isrequired))) {
      delete field.validator;
    }
    if (fullWidthFields.includes(field.field) || (field.field === 'phone' && step === 3)) {
      return [...arr, {...field, size: 100}];
    }
    if (field.field === 'supplier_status') {
      return [
        ...arr,
        {
          type: 'separator',
          title: 'Статус поставщика',
          field: 'separator_supplier_status',
        },
		field
      ];
    }
	if (field.field === 'last_name' && step === 3) {
		return [
			...arr,
			{
			  type: 'separator',
			  title: 'Контактное лицо по вопросам рассмотрения предложений',
			  field: 'separator_supplier_contact',
			  can_clear: true
			},
			field
		]
	}
	
    return [...arr, field];
  }, []);
};

export const transformAccreditationValues = (values: Record<string, any>) => {
  return Object.entries(values).reduce((obj: Record<string, any>, [key, value]) => {
    if (addressFields.includes(key)) {
      return {
        ...obj,
        [key]: JSON.stringify(value),
      };
    }
	if (key.startsWith('multiselect')) {
		const cleanKey = key.split('--').pop();
		return {
			...obj,
			[cleanKey!]: value.map((obj: {value: string}) => obj.value)
		}
	}

	if (key.startsWith('file')) {
		return {
			...obj,
			[key]: value.map((obj: {id: string}) => obj.id)
		}
	}
    return {
      ...obj,
      [key]: value,
    };
  }, {});
};
