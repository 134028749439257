import {cabinetDocumentSign} from '@api/cabinetDocuments';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IDLE, PENDING} from '@constants/loadingState';
import {RootState} from '@store';
import {IDigitalSignInitialState} from '@api/types/DigitalSign';
import {sesAgreement, setCodeFromSms} from '@api/digitalSign';
import {requestWizardAPI} from '@api/cabAppsWizard';

const initialValues: IDigitalSignInitialState = {
  loading: IDLE,
  contactPersons: [],
  selectPerson: null,
  fileId: null,
  src: '',
  signStatus: 'SES',
  cryptoProCertificateThumbprint: '0',
  cryptoProCertificateThumbprintIndex: '0',
  cryptoProCertificate: [],
  code: null,
  canSES: false,
  downloadDocument: null,
  contactsLoading: IDLE,
  signLoading: IDLE,
  cryptoProIsValid: false,
};

export const sesAgreementThunk = createAsyncThunk<
  Record<string, any>,
  {
    step: number;
    id?: string;
  },
  {state: RootState}
>('digitalSign/sesAgreement/fetch', async ({step, id}, thunkAPI) => {
  return await sesAgreement(step, id);
});

export const cabAppSign = createAsyncThunk<
  Record<string, any>,
  {
    method:
      | 'initaction'
      | 'checkreadyaction'
      | 'getpdf64action'
      | 'setsigneddataaction'
      | 'getcontactsaction'
      | 'sesaction'
      | 'signsesaction'
      | 'checkaction'
      | 'SESSignAction';
    id_object: string;
    ses?: string;
    id_contact?: string;
    leasCase?: string;
    sale?: boolean;
  },
  {state: RootState}
>(
  'digitalSign/cabAppSign/fetch',
  async ({method, id_object, ses, id_contact, leasCase, sale}, thunkAPI) => {
    return await requestWizardAPI.cabinetApplicationSign({
      method,
      id_object,
      id_contact,
      sesCode: ses,
      leasCase,
      sale,
    });
  },
);

export const documentSignThunk = createAsyncThunk<
  Record<string, any>,
  {
    method:
      | 'GetDocument'
      | 'GetPDF64Action'
      | 'SetSignedDataAction'
      | 'GetContactsAction'
      | 'SESAction'
      | 'SESSignAction';
    id_object: string;
    entity: string;
    sesCode?: string;
    id_contact?: string;
    setSignProgress?: (val: number) => void;
  },
  {state: RootState}
>(
  'digitalSign/cabDocumentSign/fetch',
  async ({method, id_object, id_contact, sesCode, entity}, thunkAPI) => {
    return await cabinetDocumentSign({
      method,
      id_object,
      id_contact,
      sesCode,
      entity,
    });
  },
);

export const setCodeFromSmsThunk = createAsyncThunk<
  Record<string, any>,
  {
    code: string;
    idObject: string;
  },
  {state: RootState}
>('digitalSign/setSesCode/fetch', async ({code, idObject}, thunkAPI) => {
  return await setCodeFromSms(code, idObject);
});

const digitalSignSlice = createSlice({
  name: 'digitalSign',
  initialState: initialValues,
  reducers: {
    setSelectedContact(state, action) {
      const find = state.contactPersons.find(
        el => String(el.value) === String(action.payload),
      ) as {
        text: string;
        title: string;
        value: string;
      };
      state.selectPerson = find;
    },
    setSignStatus(state, action) {
      state.signStatus = action.payload;
    },
    setCryptoProCertificateThumbprint(state, action) {
      state.cryptoProCertificateThumbprint = action.payload;
    },
    setCryptoProCertificateThumbprintIndex(state, action) {
      state.cryptoProCertificateThumbprintIndex = action.payload;
    },
    setCryptoProCertificate(state, action) {
      state.cryptoProCertificate = action.payload;
    },
    setAcceptCode(state, action) {
      state.code = action.payload;
    },
    cleanContactPersons(state) {
      state.contactPersons = [];
    },
    setCanSES(state, action: PayloadAction<boolean>) {
      state.canSES = action.payload;
    },
    setCryptoProIsValid(state, action: PayloadAction<boolean>) {
      state.cryptoProIsValid = action.payload;
    },
    reset: state => ({...initialValues, canSES: state.canSES}),
  },
  extraReducers: builder => {
    builder.addCase(sesAgreementThunk.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(sesAgreementThunk.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;

      if (payload?.src) state.src = payload?.src;
    });
    builder.addCase(sesAgreementThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(cabAppSign.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(cabAppSign.fulfilled, (state, action) => {
      const {payload} = action;

      state.loading = IDLE;
      if (payload?.contacts) {
        state.contactPersons = payload?.contacts.map(
          (el: {fio: string; id: string; phone: string, title: string}) => ({
            title: el.title,
            value: String(el.id),
            text: el.phone,
          }),
        );
      }
    });
    builder.addCase(documentSignThunk.pending, (state, action) => {
      state.loading = PENDING;
      if (action.meta.arg.method === 'GetContactsAction') {
        state.contactsLoading = PENDING;
      }
      if (action.meta.arg.method === 'SESSignAction') {
        state.signLoading = PENDING;
      }
    });
    builder.addCase(documentSignThunk.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;
      if (action.meta.arg.method === 'GetDocument') {
        state.downloadDocument = {
          dpu: payload.dpu,
          title: payload.title,
          mime_type: payload.mime_type,
        };
      }
      if (action.meta.arg.method === 'SESSignAction') {
        state.signLoading = IDLE;
      }
      if (action.meta.arg.method === 'GetContactsAction') {
        state.contactsLoading = IDLE;
      }
      if (payload?.contacts) {
        state.contactPersons = payload?.contacts.map(
          (el: {fio: any; id: any; phone: any; title: string}) => ({
            title: el.title,
            value: String(el.id),
            text: el.phone,
          }),
        );
      }
    });
    builder.addCase(cabAppSign.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(setCodeFromSmsThunk.pending, (state, action) => {
      state.loading = PENDING;
    });
    builder.addCase(setCodeFromSmsThunk.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;
      if (payload.pdf) {
        state.fileId = payload.pdf.id;
      }
    });
    builder.addCase(setCodeFromSmsThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
  },
});

// Extract the action creators object and the reducer
const {reducer, actions} = digitalSignSlice;

export const {
  setSelectedContact,
  setSignStatus,
  setCryptoProCertificate,
  setCryptoProCertificateThumbprint,
  setCanSES,
  reset,
  setCryptoProCertificateThumbprintIndex,
  setAcceptCode,
  cleanContactPersons,
  setCryptoProIsValid,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
