import {
  AnalyticEventCategories,
  AnalyticEventNames,
  RegistrationStepNames,
} from '@api/types/AnalyticEvents';
import {sendClientParamsNATS} from './common';
import {
  ACCOUNT_TYPE_FOR_ANALYTICS,
  CONFIRMATION_STEP_NAMES,
  SHOWN_FORM_STEP_TO_ANALYTIC_STEP,
  PASSWORD_OPTIONS_FOR_ANALYTICS,
  CHANGED_FORM_STEP_TO_ANALYTIC_STEP,
} from '@constants/analytics';

export const trackEvent = (
  eventCategory: AnalyticEventCategories,
  eventName: AnalyticEventNames,
  params?: {
    [key: string]: string | number | boolean;
  },
) => {
  sendClientParamsNATS(eventName, {
    eventName,
    eventCategory,
    eventParams: {
      ...params,
    },
  });
};

export const trackRegistrationEvent = (
  eventName: AnalyticEventNames,
  params?: {
    [key: string]: string | number | boolean;
  },
) => trackEvent(AnalyticEventCategories.CabinetReg, eventName, params);

export const trackAuthEvent = (
  eventName: AnalyticEventNames,
  params?: {
    [key: string]: string | number | boolean;
  },
) => trackEvent(AnalyticEventCategories.CabinetAuth, eventName, params);

export const trackCabinetInfoEvent = (
  eventName: AnalyticEventNames,
  params?: {
    [key: string]: string | number | boolean;
  },
) => trackEvent(AnalyticEventCategories.CabinetInfo, eventName, params);

export const trackFirstStepEvent = (
  eventName: AnalyticEventNames,
  passOption: string,
  params?: {
    [key: string]: string | number | boolean;
  },
) => {
  const extendedParams = {
    step: 1,
    step_name: RegistrationStepNames.Start,
    password_type: PASSWORD_OPTIONS_FOR_ANALYTICS[passOption],
    ...params,
  };
  trackRegistrationEvent(eventName, extendedParams);
};

export const trackConfirmationStepEvent = (
  eventName: AnalyticEventNames,
  analyticStep: number,
  params?: {
    [key: string]: string | number | boolean;
  },
) => {
  const extendedParams = {
    step: analyticStep,
    step_name: CONFIRMATION_STEP_NAMES[analyticStep],
    ...params,
  };
  trackRegistrationEvent(eventName, extendedParams);
};

export const trackRegistrationStepShownEvent = (
  formStep: number,
  accountType: string,
) => {
  const stepData = SHOWN_FORM_STEP_TO_ANALYTIC_STEP[formStep];
  if (stepData) {
    const commonParams = stepData.params;
    const extendedParams = stepData.withLegalEntity
      ? {...commonParams, legal_entity: ACCOUNT_TYPE_FOR_ANALYTICS[accountType]}
      : commonParams;

    trackRegistrationEvent(stepData.eventName, extendedParams);
  }
};

export const trackRegistrationStepChange = (
  isForward: boolean,
  success = true,
  fromStep: number,
  accountType: string,
) => {
  
  if (CHANGED_FORM_STEP_TO_ANALYTIC_STEP[fromStep]) {
	const stepData = CHANGED_FORM_STEP_TO_ANALYTIC_STEP[fromStep];
    const nameKey = isForward ? 'forwardEventName' : 'backEventName';
    const params = isForward ? {...stepData.params, success} : stepData.params;

    const extendedParams = stepData.withLegalEntity
      ? {...params, legal_entity: ACCOUNT_TYPE_FOR_ANALYTICS[accountType]}
      : params;

    trackRegistrationEvent(stepData[nameKey], extendedParams);
  }
};
