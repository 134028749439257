import {bodyFormDataBuilder} from '@utils/formatters';
import apiClient from './entities/apiClient';

export const contactPersonsCreateAttorney = async (
  params: any,
  postData?: {[key: string]: string | any},
  wrapperKey?: string,
) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    const {data} = await apiClient.post('/CabinetSetAttorneyV2', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log('Cabinet attorney wizard error ', e);
    throw e;
  }
};

export const contactPersonsRevokeAttorney = async (id_contact: string) => {
  const {data} = await apiClient.get('/CabinetRevokeAttorney', {
    params: {
      id_contact,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};
