import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TDeviceType} from '@utils/common';

interface DeviceInfoState {
  windowWidth: number | null;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  deviceType: TDeviceType | null;
}

const initialState: DeviceInfoState = {
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  deviceType: null,
  windowWidth: null,
};

const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    setDeviceInfo(state, action: PayloadAction<{windowWidth: number, deviceType: TDeviceType}>) {
      const {windowWidth, deviceType} = action.payload;
      state.deviceType = deviceType;
      switch (true) {
        case windowWidth > 1200:
          state.isDesktop = true;
          state.isTablet = false;
          state.isMobile = false;
          break;
        case windowWidth < 1200 && windowWidth > 768:
          state.isDesktop = false;
          state.isTablet = true;
          state.isMobile = false;
          break;
        case windowWidth < 768:
          state.isDesktop = false;
          state.isTablet = false;
          state.isMobile = true;
          break;
      }
    },
  },
});

export const {setDeviceInfo} = deviceInfoSlice.actions;
export default deviceInfoSlice.reducer;
