import styled from 'styled-components';
import {Zoom, ToastContainer, ToastTransition} from 'react-toastify';
import {theme} from '@styles/theme';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    z-index: 9999999;
  }
  .Toastify__toast {
    box-shadow: none;
  }
  .Toastify__toast--info {
    background-color: ${theme.colors.lightBG};
    border: 1px solid ${theme.colors.lightGreen};
  }
  .Toastify__toast--error {
    background-color: ${theme.colors.opalRedBG};
    border: 1px solid ${theme.colors.opalRed};
  }
  .Toastify__toast--warning {
    background-color: ${theme.colors.orangeBG};
    border: 1px solid ${theme.colors.orange};
  }
  .Toastify__toast--success {
    background-color: ${theme.colors.brandGreenV2BG};
    border: 1px solid ${theme.colors.brandGreenV2};
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const ImageWrapper = styled.div`
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
`;
