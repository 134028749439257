import {getPageInfo} from '@api/main';
import {
  ICabDashboardRecommendation,
  ICabDashboardRecommendationAnswer,
} from '@api/types/CabinetDashboard';
import {ICabSearchFoundItems} from '@api/types/CabinetSearch';
import {
  CabinetSuppliesFilter,
  CabinetSuppliesItem,
  CabinetSuppliesSortingField,
  GridCollection,
  SupplyGridFilterItems,
  SupplySortItem,
} from '@api/types/CabinetSupplies';
import {AttorneysGridCollection} from '@api/types/CabinetSupplyAttorneys';
import {QueryParam} from '@api/types/Catalog';
import {ProductResponse} from '@api/types/CatalogProduct';
import {Gallery} from '@api/types/Gallery';
import {IPageDetails} from '@api/types/MainPage';
import {IPageMetaData} from '@api/types/Meta';
import {IPagePropertiesData} from '@api/types/Pages';
import {ProductT} from '@api/types/ProductT';
import {ICabUnallocatedPayments} from '@api/types/UnallocatedPayments';
import {Video} from '@api/types/Video';
import content from '@mockData/appData.json';
import {catalogSpecialOfferParamsTypes} from '@mockData/catalogSpecialOfferParamsTypes';
import {dynamicPaths} from '@mockData/dynamicPaths';
import {cleanObject} from './formatters';

// Mapper
export function setDynamicParams(
  obj: QueryParam,
  staticKeys?: string[],
  withoutFormName = false,
) {
  const params = {} as QueryParam;
  const {formName} = obj;

  const filteredQueries = filterObjectByKeys(obj, staticKeys);

  for (const [key, value] of Object.entries(filteredQueries)) {
    if (key !== `form_name` && !withoutFormName) {
      if (Array.isArray(value)) {
        params[`${formName ?? 1}[filter][${key}][from]`] = value[0];
        params[`${formName ?? 1}[filter][${key}][to]`] = value[1];
      } else {
        params[`${formName ?? 1}[filter][${key}]`] = value;
      }
    }
    if (key !== `form_name` && withoutFormName) {
      if (Array.isArray(value)) {
        params[`filter[${key}][from]`] = value[0];
        params[`filter[${key}][to]`] = value[1];
      } else {
        params[`filter[${key}]`] = value;
      }
    }
  }

  return cleanObject(params);
}

function filterObjectByKeys(obj: QueryParam, keyArr?: string[]) {
  const filteredObj = {} as QueryParam;

  if (!keyArr) {
    return obj;
  }

  for (const key in obj) {
    if (!keyArr.includes(key)) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

export async function getPageProperties(
  route: string,
  dynamic = false,
): Promise<IPagePropertiesData> {
  const routeWithoutQuery = route.split('?')[0];
  const urlParams = new URLSearchParams(route.split('?')[1]);
  let pageData;
  if (!dynamic) {
    pageData = {
      url: routeWithoutQuery,
    };
  } else {
    const currentPath = dynamicPaths.find(path =>
      routeWithoutQuery.match(path.idRegExp || path.uriRegExp),
    );

    let currentId, currentUri;
    if (currentPath?.idRegExp) {
      currentId = currentPath?.idRegExp.exec(routeWithoutQuery)?.slice(-1)[0];
    } else {
      currentUri = currentPath?.uriRegExp.exec(routeWithoutQuery)?.slice(1).join('/');
    }

    pageData = {
      url: routeWithoutQuery,
      type: currentPath?.type,
      id_entity: currentId,
      uri_entity: currentUri,
      id_product_filter: urlParams.get('id_product_filter'),
    };
  }

  const pageInfo: {details: IPageDetails; meta: IPageMetaData} = await getPageInfo(
    pageData,
  );

  return {
    title: pageInfo.details?.title ?? '',
    description: pageInfo.details?.description ?? '',
    fullDescription: pageInfo.details?.fullDescription ?? '',
    meta_title: pageInfo.meta?.meta_title ?? pageInfo.meta?.title ?? '',
    meta_description: pageInfo.meta?.meta_description ?? pageInfo.meta?.description ?? '',
    meta_keywords: pageInfo.meta?.meta_keywords ?? pageInfo.meta?.keywords ?? [],
    canonical_url: pageInfo.meta?.canonical ?? '',
    tags: pageInfo.details?.tags ?? [],
    h1: pageInfo.details?.h1 ?? '',
    h2: pageInfo.details?.h2 ?? '',
    content: pageInfo.details?.content ?? '',
    customHTMl: pageInfo.details?.customHTMl ?? '',
  };
}

export function getSpecialOffersParamsTypes(
  items: Partial<ProductT[]> | Partial<ProductResponse[]>,
) {
  const specialOffersParamsTitles: string[] = [];
  items?.map(item => {
    item?.promo_items?.map(item => {
      specialOffersParamsTitles.push(item?.title);
    });
  });
  return specialOffersParamsTitles
    .filter((value, index, array) => array.indexOf(value) === index)
    .map((item: string, idx) => {
      return {title: item, type: catalogSpecialOfferParamsTypes[reduceIdx(idx)]};
    });
}

function reduceIdx(idx: number): number {
  let reducedIdx = idx;
  if (idx >= catalogSpecialOfferParamsTypes.length) {
    reducedIdx -= catalogSpecialOfferParamsTypes.length;
  }
  return reducedIdx < catalogSpecialOfferParamsTypes.length
    ? reducedIdx
    : reduceIdx(reducedIdx);
}

export function galleryMapper(gallery: Partial<Gallery[]>) {
  const mappedGallery = gallery.map(item => {
    return {
      id: item?.id,
      title: item?.title,
      photo: item?.photo?.id_File?.src || item?.main_image_url,
      description: item?.description,
      date: item?.post_date,
    };
  });
  return mappedGallery;
}

export function videoMapper(gallery: Video[]) {
  const mappedGallery = gallery.map(item => {
    return {
      id: item.id,
      title: item.title,
      photo: item.video_frame_url || item?.id_File?.src,
      description: item.announce,
      date: item.post_date,
    };
  });
  return mappedGallery;
}

export function cabSearchEntityMapper(
  entity: string,
  items: ICabSearchFoundItems[],
  entities: string[],
): ICabSearchFoundItems[] {
  let filteredItems = [];
  switch (entity) {
    case 'CabinetApplication':
      filteredItems = items.filter(item => item.entity === 'CabinetApplication');
      return filteredItems.map(item => {
        return {
          ...item,
          title: item.extcode,
          text: `${item?.fields?.OrderStatus}: ${item?.OrderStatus?.title} ${
            item?.fields?.productsSumm
          }: ${item?.productsSumm ?? '—'} ₽`,
          url: ``,
          ux_created: item?.appDate ?? '',
        };
      });
    case 'CabinetDeal':
      filteredItems = items.filter(item => item.entity === 'CabinetDeal');
      return filteredItems.map(item => {
        return {
          ...item,
          title: item.title ?? item.code,
          text: `${item?.fields?.productsSumm}: ${item?.productsSumm ?? '—'} ₽`,
          url: ``,
          ux_created: item?.appDate ?? '',
        };
      });
    case 'CabinetOrder':
      filteredItems = items.filter(item => item.entity === 'CabinetOrder');
      return filteredItems.map(item => {
        return {
          ...item,
          title: item?.contract_name ?? item?.title,
          text: `${item?.fields?.StatusSherp}: ${item?.StatusSherp?.title} ${
            item?.isOverdue ? `${item?.fields?.isOverdue}` : ''
          }`,
          url: `/cabinet/client/orders/${item.id}/`,
          ux_created: item?.contractdate,
        };
      });
    case 'CabinetPayment':
      filteredItems = items.filter(item => item.entity === 'CabinetPayment');
      return filteredItems.map(item => {
        return {
          ...item,
          title: `${item?.payment_sum} ₽`,
          text: `${item?.isOverdue ? item?.fields?.isOverdue : ''}`,
          url: ``,
          ux_created: item?.payment_date,
        };
      });
  }
  const dynamicArrays: Record<string, ICabSearchFoundItems[]> = {};
  entities.forEach(value => {
    dynamicArrays[value] = items.filter(item => item.entity === value);
  });

  return dynamicArrays[`${entity}`];
}

export function cabRecommendationsEntityMapper(
  items: ICabDashboardRecommendation[],
): ICabDashboardRecommendationAnswer[] {
  return items.map(item => {
    if (
      item?.custom_url?.startsWith('/cabinet/v2/') &&
      !item?.custom_url?.startsWith('/cabinet/v2/accreditation/')
    ) {
      return {
        ...item,
        url: item.custom_url,
      };
    }

    if (
      item?.custom_url?.startsWith('/cabinet/v2/accreditation/') ||
      item?.custom_url?.startsWith('/cabinet/accreditation/')
    ) {
      // Кейс uncompleted_accreditation
      return {
        ...item,
        url: '/cabinet/v2/accreditation/',
      };
    }

    switch (item.custom_url) {
      case '/cabinet/info/mr/':
        return {
          ...item,
          url: '/cabinet/v2/info/',
        };
      case '/cabinet/app/':
        return {
          ...item,
          url: '/cabinet/v2/client/apps/',
        };
      // case '/cabinet/v2/':
      //   return {
      //     ...item,
      //     url: '#',
      //   };
      case '/cabinet/docs/beneficiary_questionnaire/questionnaire/':
        return {
          ...item,
          url: '/cabinet/v2/client/documents/',
        };
      case '/cabinet/orders/#T:3':
        return {
          ...item,
          url: '/cabinet/v2/client/orders/',
        };
      case '/cabinet/supplier/content/':
        return {
          ...item,
          url: '/cabinet/v2/supplier/content/',
        };
      case '/cabinet/docs/':
        return {
          ...item,
          url: '/cabinet/v2/client/documents/',
        };
      case '/cabinet/supplier/products/':
        return {
          ...item,
          url: '/cabinet/v2/supplier/products/',
        };
      default:
        return {
          ...item,
          url: '#',
        };
    }
  });
}

export const getSliderBreakpointsByType = (type: string): any => {
  switch (type) {
    case 'CatalogProvidersBlock':
      return {
        '(max-width: 1050px)': {
          slides: {
            perView: 3.5,
            spacing: 30,
          },
        },
        '(max-width: 768px)': {
          slides: {
            perView: 3,
            spacing: 30,
          },
        },
        '(max-width: 520px)': {
          slides: {
            perView: 2,
            spacing: 30,
          },
        },
      };
    case 'SpecialOffersSuppliers':
      return {
        '(max-width: 1125px)': {
          slides: {
            perView: 5,
            spacing: 30,
          },
        },
        '(max-width: 820px)': {
          slides: {
            perView: 3,
            spacing: 30,
          },
        },
        '(max-width: 620px)': {
          slides: {
            perView: 2,
            spacing: 30,
          },
        },
      };
    case 'AdditionalMainBlock':
      return {
        '(max-width: 980px)': {
          slides: {
            perView: content.main.additional.blockItems.length > 2 ? 3 : 2,
            spacing: 30,
          },
        },
        '(max-width: 768px)': {
          slides: {
            perView: 2,
            spacing: 30,
          },
        },
        '(max-width: 470px)': {
          slides: {
            origin: 'center',
            perView: 1,
          },
        },
      };
    default:
      return {
        '(max-width: 1125px)': {
          slides: {
            perView: 3,
            spacing: 30,
          },
        },
        '(max-width: 820px)': {
          slides: {
            perView: 2,
            spacing: 30,
          },
        },
        '(max-width: 620px)': {
          slides: {
            perView: 1,
            spacing: 30,
          },
        },
      };
  }
};

export const removeLeasingFromURI = (uri: string | undefined): any => {
  let URIArr = uri?.split('/');
  URIArr = URIArr?.filter(item => item !== 'leasing');
  return URIArr?.join('/');
};

const isCSPValueHandler = (value: string) => {
  return value === '1' ? 'Подписан' : 'Не подписан';
};

export const cabinetAttorneysTableItemsMapper = (
  headerFieldsList: {field: string; title: string}[],
  RenderEditIcon: (item: any) => () => void,
  data?: GridCollection | AttorneysGridCollection,
) => {
  if (!data) {
    return [];
  }
  const headerTitlesList = headerFieldsList.map(el => el.field);
  const result: any[] = [];

  data?.items?.forEach((item, index) => {
    //Заполняем десктопную версию таблицы полями которые есть в хеадере таблицы
    item.items
      .sort(
        (a, b) => headerTitlesList.indexOf(a.field) - headerTitlesList.indexOf(b.field),
      )
      .forEach(el => {
        if (headerTitlesList.includes(el.field)) {
          if (result[index]) {
            result[index][el.field] =
              el.field !== 'isCSP' ? el.text_value : isCSPValueHandler(el.value);
          } else {
            result[index] = {
              [el.field]:
                el.field !== 'isCSP' ? el.text_value : isCSPValueHandler(el.value),
            };
          }
        }
      });

    //Заполняем таблицу мобильной версии сайта
    if (headerFieldsList.length > 0) {
      const mobileItemList = [
        {
          title: headerFieldsList[0]?.title ?? '',
          value: result[index][headerFieldsList[0].field] ?? '',
        },
        {
          title: headerFieldsList[1]?.title ?? '',
          value: result[index][headerFieldsList[1].field] ?? '',
        },
      ];
      result[index]['mobileItemList'] = mobileItemList;
    }

    const mobileItemGridData = headerFieldsList.slice(2);
    if (mobileItemGridData.length) {
      const mobileItemGrid = mobileItemGridData.map(el => ({
        title: el.title,
        value: result[index][el.field] ?? '',
        tooltip:
          el.field === 'datePlannedShipment'
            ? 'Отгрузка возможна только в рабочие дни'
            : undefined,
      }));
      result[index]['mobileItemGrid'] = mobileItemGrid;
    }

    //Добавляем ячейку с иконками в десктопную и мобильную версию
    result[index]['renderContent'] = RenderEditIcon(item);
    result[index]['renderIcon'] = [RenderEditIcon(item)];
  });
  return result;
};

export const cabinetSuppliesTableItemsMapper = (
  headerFieldsList: {field: string; title: string}[],
  RenderEditIcon: (item: any) => () => void,
  items?: CabinetSuppliesItem[],
) => {
  if (!items?.length) {
    return [];
  }
  const headerTitlesList = headerFieldsList.map(el => el.field);
  const result: any[] = [];

  items
    ?.map(item => ({...item, id_CRMSupplier: item.CRMSupplier}))
    .forEach((item, index) => {
      //Заполняем десктопную версию таблицы полями которые есть в хеадере таблицы
      Object.keys(item)
        .sort((a, b) => headerTitlesList.indexOf(a) - headerTitlesList.indexOf(b))
        .forEach(key => {
          const rawValue = item[key as keyof CabinetSuppliesItem];
          let value = '';
          if (typeof rawValue === 'string') {
            value = rawValue;
          } else if (Array.isArray(rawValue)) {
            value = rawValue[0]?.title;
          } else {
            value = rawValue?.title ?? '';
          }

          if (headerTitlesList.includes(key)) {
            if (result[index]) {
              result[index][key] = key !== 'isCSP' ? value : isCSPValueHandler(value);
            } else {
              result[index] = {
                [key]: key !== 'isCSP' ? value : isCSPValueHandler(value),
              };
            }
          }
        });

      //Заполняем таблицу мобильной версии сайта
      if (headerFieldsList.length > 0) {
        const mobileItemList = [
          {
            title: headerFieldsList[0]?.title ?? '',
            value: result[index][headerFieldsList[0].field] ?? '',
          },
          {
            title: headerFieldsList[1]?.title ?? '',
            value: result[index][headerFieldsList[1].field] ?? '',
          },
        ];
        result[index]['mobileItemList'] = mobileItemList;
      }

      const mobileItemGridData = headerFieldsList.slice(2);
      if (mobileItemGridData.length) {
        const mobileItemGrid = mobileItemGridData.map(el => ({
          title: el.title,
          value: result[index][el.field] ?? '',
          tooltip:
            el.field === 'datePlannedShipment'
              ? 'Отгрузка возможна только в рабочие дни'
              : undefined,
        }));
        result[index]['mobileItemGrid'] = mobileItemGrid;
      }

      //Добавляем ячейку с иконками в десктопную и мобильную версию
      result[index]['renderContent'] = RenderEditIcon(item);
      result[index]['renderIcon'] = [RenderEditIcon(item)];
    });
  return result;
};

export const cabinetSuppliesTableHeaderMapper = (
  requiredFields: string[],
  data?: CabinetSuppliesSortingField[],
) => {
  const mappedItems =
    data
      ?.map(el => ({
        title: el?.title,
        field: el?.field,
        sortable: el?.sortable ?? true,
        sort: el?.sort ?? 'asc',
        tooltip:
          el.field === 'datePlannedShipment'
            ? 'Отгрузка возможна только в рабочие дни'
            : undefined,
      }))
      .sort(
        (a, b) => requiredFields.indexOf(a.field) - requiredFields.indexOf(b.field),
      ) ?? [];
  mappedItems.push({
    title: '',
    field: 'edit',
    tooltip: undefined,
    sortable: false,
    sort: 'asc',
  });
  console.log(mappedItems);
  return mappedItems;
};

export const cabinetAttorneysTableHeaderMapper = (
  requiredFields: string[],
  data?: SupplySortItem[],
) => {
  const mappedItems =
    data
      ?.map(el => ({
        title: el?.title,
        field: el?.field,
        sortable: el?.sortable ?? '1',
        sort: el?.sort ?? 'asc',
        type: el?.type,
        tooltip:
          el.field === 'datePlannedShipment'
            ? 'Отгрузка возможна только в рабочие дни'
            : undefined,
      }))
      .sort(
        (a, b) => requiredFields.indexOf(a.field) - requiredFields.indexOf(b.field),
      ) ?? [];
  mappedItems.push({
    title: '',
    field: 'edit',
    type: 'edit',
    tooltip: undefined,
    sortable: '0',
    sort: 'asc',
  });
  return mappedItems;
};

/*
 * Заменяем поле фильра 'code' на 'supplyCode' что бы параметры двух таблиц не пересекались
 * При отправке на бэк 'attorneyCode' заменяется обратно на 'code' в ф-ии getSupplies
 */
export const cabinetSuppliesFilterItemsMapper = (
  filterItems: CabinetSuppliesFilter[],
) => {
  return filterItems.map(item => ({
    ...item,
    field: item?.field === 'code' ? 'supplyCode' : item?.field,
    placeholder: item?.field === 'code' ? 'Введите номер' : undefined,
  }));
};

export const cabinetAttorneysFilterItemsMapper = (filterItems: SupplyGridFilterItems) => {
  return Object.keys(filterItems)
    .filter(el => el !== 'Button')
    .map(el => ({
      field: filterItems[el]?.field,
      type: filterItems[el]?.type,
      title: filterItems[el]?.title,
      placeholder: filterItems[el]?.field === 'code' ? 'Введите номер' : undefined,
      items: filterItems[el]?.items?.List?.items,
    }));
};

export const CabinetUndistributedPaysTableItemsMapper = (
  unallocatedPayments: Partial<ICabUnallocatedPayments>,
  RenderDistributeButton: (id: string, isActive: boolean) => () => void,
) => {
  const items: any[] = [];
  if (unallocatedPayments.items) {
    unallocatedPayments.items.forEach(el => {
      const isAct = el.isactive
        ? {value: true, color: '#00755A'}
        : {value: false, color: '#EB5E55'};
      const fullItem: Record<string, any> = {mobileItemList: []};
      Object.entries(el).forEach(([key, value]) => {
        if (key === 'isactive') {
          fullItem['isAct'] = isAct;
          return;
        }

        if (typeof value === 'object') {
          fullItem[key] = value?.title ?? '';
        } else {
          fullItem[key] = value;
        }

        const sortField = unallocatedPayments.sort_fields?.find(el => el.field === key);
        if (sortField) {
          fullItem['mobileItemList'] = [
            ...fullItem['mobileItemList'],
            {
              title: sortField.title,
              value: typeof value === 'object' ? value?.title ?? '' : value || '-',
            },
          ];
        }
      });
      fullItem['mobileItemList'] = [
        ...fullItem['mobileItemList'],
        {
          title: '',
          value: RenderDistributeButton(fullItem?.id ?? '', el.isactive),
        },
      ];
      fullItem['mobileItem'] = {
        showIndicator: true,
        indicatorValue: el.isactive,
        indicatorColor: el.isactive ? '#00755A' : '#EB5E55',
      };
      fullItem['renderContent'] = RenderDistributeButton(fullItem?.id ?? '', el.isactive);

      items.push(fullItem);
    });
  }
  return items;
};
