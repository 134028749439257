import { requestWizardMappings } from '@api/mappings/requestWizard';
import { roboDealWizardMappings } from '@api/mappings/roboDealWizard';
import {
  IRoboDealWizardState,
  RoboDealWizardMethodAction,
} from '@api/types/CabRoboDealWizard';
import { IDLE, PENDING } from '@constants/loadingState';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { getTotalRoboAppProductsSum } from '@utils/common';
import { setDynamicParams } from '@utils/mapper';
import { determineCurrentStep } from '@utils/roboDeal';
import { isArray, isNull, isObject, isUndefined } from 'lodash';
import { NextRouter } from 'next/router';
import { roboAgentDealWizardAPI } from '@api/cabAgentRoboDealWizard';

const initialState: IRoboDealWizardState = {
  loading: IDLE,
  filtersLoading: IDLE,
  productLoading: IDLE,
  catalogLoading: IDLE,
  formationLoading: IDLE,
  conditionLoading: IDLE,
  changeConditionsLoading: IDLE,
  updateConditionLoading: IDLE,
  finishRoboInfoLoading: IDLE,
  isCabinetBookIncomeStepFieldSectionNotChecked: false,
  wizard: {
    state: null,
    step: null,
    maxstep: null,
    nextstep: null,
    id_object: null,
    steps: {},
    isavailable: true,
    url: '',
  },
  states: null,
  id_object: null,
  stepNow: null,
  subStep: 0,
  products: [],
  regions: [],
  groupFields: {},
  ctrl: null,
  pagination: {
    paginator: null,
    lastLoadedPage: null,
    totalPages: null,
    totalItems: null,
  },
  code: 'Новая заявка',
  filters: {
    list: [],
    values: {},
  },
  sort: {
    list: [],
    values: { sort: '', by: '' },
  },
  catalogProducts: [],
  complectProducts: [],
  CRMProduct: null,
  meta: {
    totalCount: null,
    formName: '',
  },
  canSES: null,
  agreements: [],
  screens: {},
  applicationDocument: null,
  manualSignDocs: null,
  isOpenSmsModal: false,
  productSumm: null,
  productComplect: null,
  cardBuilderItems: null,
  CSPUI: null,
  agreement_message: null,
  dataIsGrid: false,
  isFormatingConditionsStep: false,
  isCabinetDealCreditDeclarationStep: false,
  isCabinetDealConditionsControlStep: false,
  isCabinetDealManualConditionsControlStep: false,
  isCSPUIStep: false,
  isSignManualStep: false,
  isCabinetBookIncomeExpensesControlStep: false,
  isCabinetDealWizardRoboFinishControl: false,
  isFormatingOrderStep: false,
  isFormationPackageOfDocumentsOrder: false,
  isCabinetDealOrderSigningStep: false,
  isCabinetDealPackageOfDocumentsOrderSigningStep: false,
  isDocumentUploadStep: false,
  isBeneficiaryQuestionnaireStep: false,
  isCommissionStep: false,
  conditions: null,
  roboFinishConditions: null,
  roboFinishDocuments: null,
  roboOrderSigningDocuments: null,
  comissionDocuments: null,
  roboPackageOfDocumentsOrderSigningDocuments: null,
  productDelivery: {
    items: {},
    initialValues: {},
    isCalculated: {},
    cost: {},
    isLoading: {}
  },
  ispackageready: null,
  debug: null,
};

export const roboAgentDealWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params: any[] | Record<string, any>;
    isBack?: boolean;
    postData?: Record<string, unknown>;
    wrapperKey?: string;
    catalogUpdate?: boolean;
    method?: RoboDealWizardMethodAction;
    action?: string;
    withMethodActions?: boolean;
    formationUpdate?: boolean;
    signal?: AbortSignal;
    router?: NextRouter;
  },
  { state: RootState }
>(
  'roboAgentDealWizard/fetch',
  async (
    {
      params,
      isBack,
      postData,
      wrapperKey,
      catalogUpdate,
      method,
      action,
      withMethodActions = true,
      formationUpdate,
      signal,
      router,
    },
    thunkAPI,
  ) => {
    const { roboAgentDealWizard } = thunkAPI.getState();

    let data = await roboAgentDealWizardAPI.roboAgentDealWizard(
      {
        id_object: roboAgentDealWizard?.id_object,
        ...params,
      },
      postData,
      wrapperKey,
      method,
      action,
      signal,
      router,
    );

    const setNoSignActionSuccess = method === 'setNoSignAction' && !!data.success;

    if (data?.wizard?.step || setNoSignActionSuccess) {
      data = await roboAgentDealWizardAPI.roboAgentDealWizard(
        {
          step: setNoSignActionSuccess ? roboAgentDealWizard.stepNow : data?.wizard?.step,
          id_object: data?.wizard?.id_object,
        },
        {},
        wrapperKey,
        undefined,
        undefined,
        signal,
        router,
      );
      data = {
        step: data?.wizard?.step,
        id_object: data?.wizard?.id_object,
        ...data,
      };
    }

    return roboDealWizardMappings.checkFormName({
      ...data,
      isBack: isBack,
    });
  },
);

export const filterRoboAgentDealWizardThunk = createAsyncThunk<
  Record<string, unknown>,
  {
    params?: any[] | Record<string, any>;
    resetPagination?: boolean;
    resetFilters?: boolean;
    resetSorting?: boolean;
    pagination?: boolean;
  },
  { state: RootState }
>(
  'filterRoboAgentDealWizard/fetch',
  async (
    {
      params,
      resetPagination = false,
      resetFilters = false,
      resetSorting = false,
      pagination = false,
    },
    thunkAPI,
  ) => {
    const { roboAgentDealWizard } = thunkAPI.getState();
    const paginationParams = roboDealWizardMappings.getPaginationParams({
      state: roboAgentDealWizard,
      page: !isArray(params) ? params?.page ?? undefined : null,
      resetPagination,
    });
    const filteringParams = setDynamicParams({
      ...roboAgentDealWizard.filters.values,
      formName: roboAgentDealWizard.meta.formName,
    });
    const sortingParams = roboDealWizardMappings.getSortingParams({
      state: roboAgentDealWizard,
      resetSorting,
    });
    const data = await roboAgentDealWizardAPI.roboAgentDealWizard({
      // ...params,
      id_object: roboAgentDealWizard?.id_object,
      step: roboAgentDealWizard.stepNow,
      ...(pagination ? { ...paginationParams } : {}),
      ...filteringParams,
      ...sortingParams,
    });

    return data;
  },
);

export const roboAgentDealWizardConditionsThunk = createAsyncThunk<
  Record<string, any>,
  { params: Record<string, any>; method: RoboDealWizardMethodAction }
>('conditionsRoboAgentDealWizard/fetch', async ({ params, method }) => {
  const data = await roboAgentDealWizardAPI.roboAgentDealWizard(params, {}, '', method);

  return data;
});

export const getRoboAgentFinishConditionsAndDocumentsThunk = createAsyncThunk<
  Record<string, any>,
  {
    params: Record<string, any>;
    method?: RoboDealWizardMethodAction;
    signal?: AbortSignal;
    setSignDocsIsReady?: React.Dispatch<
      React.SetStateAction<{
        isready: boolean;
        docs: any[];
      } | null>
    >;
    docs?: any[];
    firstRender?: boolean;
  },
  { state: RootState }
>(
  'getFinishRoboCondAndDocs/fetch',
  async ({ params, method, signal, setSignDocsIsReady, docs, firstRender }, thunkAPI) => {
    const {
      roboAgentDealWizard: {
        stepNow,
        id_object,
        roboFinishDocuments,
        roboFinishConditions,
      },
    } = thunkAPI.getState();
    let data = null;
    if (!roboFinishConditions && firstRender) {
      roboAgentDealWizardAPI.roboAgentDealWizard(
        {
          id_object,
          step: stepNow,
        },
        {},
        '',
        'setSignModeAction',
        undefined,
        signal,
      );
    }

    if (setSignDocsIsReady) {
      data = (async () => {
        setSignDocsIsReady({ isready: false, docs: [] });
        return {
          ...(isNull(roboFinishDocuments) && isNull(roboFinishConditions)
            ? await roboAgentDealWizardAPI.roboAgentDealWizard(
              params,
              {},
              '',
              method,
              undefined,
              signal,
            )
            : { existCond: roboFinishConditions }),
        };
      })();
    } else {
      data = (async () => {
        return {
          ...(isNull(roboFinishDocuments) && isNull(roboFinishConditions)
            ? await roboAgentDealWizardAPI.roboAgentDealWizard(
              params,
              {},
              '',
              method,
              undefined,
              signal,
            )
            : { existCond: roboFinishConditions }),
          signDocs: docs,
        };
      })();
    }

    return data;
  },
);

export const cabinetDealAgentWizardGetShippingCostThunk = createAsyncThunk<
  Record<string, any>,
  {
    id_shipping: string;
    id_crmproduct: string;
    params: Record<string, any>;
  },
  { state: RootState }
>(
  'cabinetDealAgentWizardGetShippingCostThunk/fetch',
  async ({ id_shipping, id_crmproduct, params }, thunkAPI) => {
    const data = await roboAgentDealWizardAPI.cabinetDealAgentWizardGetShippingCost(id_shipping, params);
    return {...data, id_shipping, id_crmproduct};
  }
);

const roboAgentDealWizardSlice = createSlice({
  name: 'roboAgentDealWizard',
  initialState: initialState,
  reducers: {
    changeAgentStepNow(state, action) {
      state.stepNow! = action.payload;
    },
    changeAgentIdObject(state, action) {
      state.id_object = action.payload;
    },
    changeAgentInitialValue(state, action) {
      const { payload } = action;
      state.screens[payload.step].initialValues = payload.values;
    },
    changeAgentCRMPriceID(state, action) {
      state.CRMProduct!.choosedCRMPriceID = action.payload;
    },
    changeAgentQuantity(state, action) {
      state.CRMProduct!.quantity = action.payload;
    },
    setAgentWizardFilters(state, action) {
      state.filters.values = action.payload;
    },
    setAgentWizardSort(state, action) {
      state.sort.values = action.payload;
    },
    setAgentFormatingConditionsStepValue(state, action: PayloadAction<boolean>) {
      state.isFormatingConditionsStep = action.payload;
    },
    setAgentIsCabinetBookIncomeStepFieldSectionNotChecked(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isCabinetBookIncomeStepFieldSectionNotChecked = action.payload;
    },
    setAgentManualSignDocs(state, action: PayloadAction<Record<string, any>[]>) {
      state.manualSignDocs = action.payload;
    },
    setProductDeliveryCalculatedState(state, action: PayloadAction<Record<string, boolean>>) {
      state.productDelivery.isCalculated = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(roboAgentDealWizardThunk.pending, (state, action) => {
      const {
        payload,
        meta: { arg },
      } = action;

      roboDealWizardMappings.setLoading(state, arg, PENDING);
      if (arg.method) {
        roboDealWizardMappings.methodActions(state, arg.method, payload, 'pending');
        if (isUndefined(arg.withMethodActions) || arg.withMethodActions) {
          return;
        }
      }
    });
    builder.addCase(
      roboAgentDealWizardThunk.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {
          payload,
          meta: { arg },
        } = action;
        if (
          !payload ||
          Object.keys(payload).length === 0 ||
          arg.method === 'setSignModeAction'
        ) {
          return;
        }
        state.wizard = payload?.wizard ?? state.wizard;

        state.code = payload.code ?? state.code;

        if (payload.debug) {
          state.debug = payload.debug;
        }

        if (payload.products) {
          state.products = payload.products;
          state.productSumm = String(getTotalRoboAppProductsSum(payload.products));
        }

        if (payload.id_object) {
          state.id_object = payload.id_object;
          // for testing only
        }
        // else {
        //   state.id_object = localStorage.getItem('id_object');
        // }

        if (payload.step) {
          state.stepNow! = payload.step;
        } else {
          state.stepNow! = payload?.wizard?.maxstep
            ? String(payload?.wizard?.maxstep)
            : state.stepNow!;
        }

        if (payload.states) {
          state.states = payload.states;
        }
        if (Object.hasOwn(payload.app || {}, 'isrobo')) {
          if (isObject(state.states)) {
            state.states.isrobo = payload.app.isrobo;
          }
        }

        state.agreement_message = payload.agreement_message;

        determineCurrentStep(payload, state);

        roboDealWizardMappings.setLoading(state, arg, IDLE);
        if (arg.method) {
          roboDealWizardMappings.methodActions(state, arg.method, payload, 'fulfilled');
          if (isUndefined(arg.withMethodActions) || arg.withMethodActions) {
            return;
          }
        }
        state.regions = payload?.regions;
        state.ctrl = payload?.FormBuilder?.form_name;

        const deliveryItems: any[] = [];
        const allItems: any[] = [];
        payload?.FormBuilder?.items?.forEach((item: any) => {
          if (item.id_shipping) {
            const updatedItem = {...item};
            if (item.to) {
              updatedItem.field = item.to;
              delete updatedItem.to;
            }
            if (updatedItem.field === 'is_shipping_approved') {
              if (Boolean(+updatedItem.value)) {
                state.productDelivery.cost[updatedItem.id_shipping] = {
                  id_crmproduct: updatedItem.id_crmproduct,
                  cost: Number(updatedItem.shipping_cost)
                };
              } else {
                state.productDelivery.cost[updatedItem.id_shipping] = {
                  id_crmproduct: updatedItem.id_crmproduct,
                  cost: 0
                };
              }
              state.productDelivery.isLoading[updatedItem.id_shipping] = false;
              state.productDelivery.isCalculated[updatedItem.id_shipping] = true;
            }
            deliveryItems.push(updatedItem);
          } else {
            allItems.push(item);
          }
        });
        state.productDelivery.items = {};
        deliveryItems.forEach(item => {
          if (state.productDelivery.items[item.id_shipping]) {
            state.productDelivery.items[item.id_shipping].push(item);
          } else {
            state.productDelivery.items[item.id_shipping] = [item];
          }
          if (state.productDelivery.initialValues[item.id_shipping]) {
            state.productDelivery.initialValues[item.id_shipping][item.field] = item.value;
          } else {
            state.productDelivery.initialValues[item.id_shipping] = {[item.field]: item.value};
          }
        });

        const groupFields = [];
        for (const key in allItems) {
          const field = allItems[key];
          if (field.group) {
            groupFields.push(field.group);
          }
        }
        groupFields.map(group => {
          for (const key in allItems) {
            const field = allItems[key];
            if (field.type === 'select') {
              for (const i in field.items?.List?.items) {
                const selectField = field.items?.List?.items[i];
                if (selectField.value === group) {
                  state.groupFields[group] = field.field;
                }
              }
              return;
            }
          }
        });
        if (
          payload.step ||
          !state.screens[state.stepNow!] ||
          Object.keys(state.screens[state.stepNow!].initialValues).length === 0
        ) {
          state.screens[state.stepNow!] = {};
          state.screens[state.stepNow!].ctrl = payload?.ctrl;
          state.screens[state.stepNow!].initialValues =
            requestWizardMappings.initialValuesMapper(allItems) || {};
          state.screens[state.stepNow!].form_name = payload?.FormBuilder?.form_name;
        } else {
          state.screens[state.stepNow!].initialValues =
            requestWizardMappings.changeInitialValuesMapper(
              state.screens[state.stepNow!].initialValues,
              allItems,
            ) || {};
        }
        state.screens[state.stepNow!].items = allItems;
        if (payload?.Grid?.items?.GridCollection?.items) {
          state.meta.formName = payload?.Grid?.form_name;
          state.dataIsGrid = true;
          state.catalogProducts = payload?.Grid?.items?.GridCollection.items.reduce(
            (acc: any, cur: Record<string, any>) => [
              ...acc,
              {
                ...cur,
                priceMin: cur.priceMin,
                idPrice: cur.id,
                src: cur.src_small,
              },
            ],
            [],
          );
        } else {
          state.dataIsGrid = false;
        }
        state.pagination = payload?.pagination;
        if (payload?.filters && payload?.filters.length !== 0) {
          state.filters.list = payload?.filters;
        } else {
          state.filters.list = [];
        }
        if (payload?.sort && payload?.sort.length !== 0) {
          state.sort.list = payload?.sort;
        } else {
          state.sort.list = [];
        }
        if (payload?.Documents?.items) {
          state.applicationDocument = payload?.Documents?.items.Document;
        }
        state.canSES = payload.canSES;
      },
    );
    builder.addCase(roboAgentDealWizardThunk.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(filterRoboAgentDealWizardThunk.pending, (state, action) => {
      const { meta } = action;
      state.filtersLoading = PENDING;
      if (meta.arg.resetPagination) {
        state.catalogProducts = [];
      }
    });
    builder.addCase(
      filterRoboAgentDealWizardThunk.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.filtersLoading = IDLE;
        state.products = payload?.products;
        state.regions = payload?.regions;
        if (payload?.Grid?.items?.GridCollection) {
          state.catalogProducts = payload?.Grid?.items?.GridCollection?.items
            ? payload?.Grid?.items?.GridCollection.items.reduce(
              (acc: any, cur: Record<string, any>) => [
                ...acc,
                {
                  ...cur,
                  priceMin: cur.priceMin,
                  idPrice: cur.id,
                  src: cur.src_small,
                },
              ],
              [],
            )
            : [];
        }
        state.pagination = payload?.pagination;
        if (payload?.filters.length !== 0) {
          state.filters.list = payload?.filters;
        }
        if (payload?.meta?.totalCount) {
          state.meta = payload?.meta;
        }
      },
    );
    builder.addCase(filterRoboAgentDealWizardThunk.rejected, (state, action) => {
      state.filtersLoading = IDLE;
    });
    builder.addCase(roboAgentDealWizardConditionsThunk.pending, (state, action) => {
      const {
        meta: {
          arg: {
            params: { changeConditions, method },
          },
        },
      } = action;
      if (changeConditions) {
        state.changeConditionsLoading = PENDING;
      } else {
        state.conditionLoading = PENDING;
      }

      if (method === 'updateConditionAction') {
        state.updateConditionLoading === PENDING;
        state.changeConditionsLoading = IDLE;
        state.conditionLoading = IDLE;
      }
    });
    builder.addCase(roboAgentDealWizardConditionsThunk.fulfilled, (state, action) => {
      const {
        meta: {
          arg: { params, method },
        },
        payload,
      } = action;

      state.conditionLoading = IDLE;
      state.changeConditionsLoading = IDLE;
      state.updateConditionLoading = IDLE;

      if (payload.debug) {
        state.debug = payload.debug;
      }

      if (payload?.contracts) {
        state.roboOrderSigningDocuments = payload.contracts;
      }

      if (
        (method === 'approveAction' || method === 'setRejectAction') &&
        payload?.products
      ) {
        state.products = payload?.products;
      }

      if (method === 'checkStatusAction') {
        state.conditions = {
          ...(state.conditions ?? {}),
          [params.id_app]: {
            ...(state.conditions?.hasOwnProperty(params.id_app) && {
              ...state.conditions![params.id_app],
            }),
            condIsActive: payload?.isactive,
            condIsRejected: payload?.isrejected,
          },
        };
      }

      if (method === 'isAvailableDelaysAction') {
        state.conditions = {
          ...(state.conditions ?? {}),
          [params.id_app]: {
            ...(state.conditions?.hasOwnProperty(params.id_app) && {
              ...state.conditions![params.id_app],
            }),
            canAdv: payload?.canAdv,
            canAdvToPay: payload?.canAdvToPay,
            canBase: payload?.canBase,
            canPrc: payload?.canPrc,
          },
        };
      }

      if (method === 'DynConditionsAction') {
        state.conditions = {
          ...(state.conditions ?? {}),
          [params.id_app]: {
            ...(state.conditions?.hasOwnProperty(params.id_app) && {
              ...state.conditions![params.id_app],
            }),
            calcs: payload?.calcs,
            pays: payload?.pays,
            minAdvance: payload?.advance,
            minTerm: payload?.term,
          },
        };
      }
    });
    builder.addCase(
      getRoboAgentFinishConditionsAndDocumentsThunk.pending,
      (state, action) => {
        state.finishRoboInfoLoading = PENDING;
      },
    );
    builder.addCase(
      getRoboAgentFinishConditionsAndDocumentsThunk.fulfilled,
      (state, action) => {
        const { payload } = action;

        if (payload.debug) {
          state.debug = payload.debug;
        }

        if (payload?.Conditions || payload?.existCond || payload?.signDocs) {
          state.roboFinishConditions = payload?.Conditions
            ? Object.values(payload?.Conditions.items)
            : payload.existCond;
          state.roboFinishDocuments = payload?.signDocs;
          if (payload?.signDocs) {
            state.finishRoboInfoLoading = IDLE;
          }
        }
      },
    );
    builder.addCase(
      cabinetDealAgentWizardGetShippingCostThunk.pending, 
      (state, action) => {
        const { id_shipping } = action.meta.arg;
        if (id_shipping) {
          state.productDelivery.isLoading[id_shipping] = true;
        }
      }
    );
    builder.addCase(
      cabinetDealAgentWizardGetShippingCostThunk.fulfilled,
      (state, action) => {
        if (action.payload.shipping) {
          const controlCheckbox = state.productDelivery.items[action.meta.arg.id_shipping].find(item => item.field === 'is_shipping_approved');
          if (controlCheckbox) {
            controlCheckbox.title = action.payload.title;
            controlCheckbox.shipping_cost = action.payload.shipping.shipping_cost;
            controlCheckbox.shipping_cost_formatted = action.payload.shipping.shipping_cost_formatted;
          }
          for (const key in action.payload.shipping) {
            const item = state.productDelivery.items[action.meta.arg.id_shipping].find(item => item.field === key);
            if (item) {
              item.value = action.payload.shipping[key];
            }
            state.productDelivery.initialValues[action.meta.arg.id_shipping][key] = action.payload.shipping[key];
          }
          state.productDelivery.cost[action.meta.arg.id_shipping] = {
            id_crmproduct: action.meta.arg.id_crmproduct, 
            cost: Number(action.payload.shipping.shipping_cost)
          };
        }
        state.productDelivery.isLoading[action.meta.arg.id_shipping] = false;
      }
    );
    builder.addCase(
      cabinetDealAgentWizardGetShippingCostThunk.rejected, 
      (state, action) => {
        const { id_shipping } = action.meta.arg;
        if (id_shipping) {
          state.productDelivery.isLoading[id_shipping] = false;
        }
      }
    );
  },
});

const { actions, reducer } = roboAgentDealWizardSlice;

export const {
  changeAgentStepNow,
  changeAgentIdObject,
  changeAgentInitialValue,
  changeAgentCRMPriceID,
  changeAgentQuantity,
  setAgentWizardFilters,
  setAgentWizardSort,
  setAgentFormatingConditionsStepValue,
  setAgentIsCabinetBookIncomeStepFieldSectionNotChecked,
  setAgentManualSignDocs,
  setProductDeliveryCalculatedState
} = actions;

export default reducer;
