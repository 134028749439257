import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

export const useObserver = (
	elements: Element[],
	callback: () => Promise<any>
) => {

	useIsomorphicLayoutEffect(() => {
		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						callback().then(() => {
							observer.unobserve(entry.target);
						});
					}
				});
			},
			{ threshold: 0.5 },
		);

		elements.forEach(elem => {
			if (elem) {
				observer.observe(elem);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [elements]);
}