import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '@store';

interface PageInfoState {
  currentPageTitle: string | null;
  currentTown: string;
  townModalIsOpen: boolean;
  authModalIsOpen: boolean;
  recoverPasswordModalIsOpen: boolean;
}

const initialState: PageInfoState = {
  currentPageTitle: null,
  currentTown: 'Москва',
  townModalIsOpen: false,
  authModalIsOpen: false,
  recoverPasswordModalIsOpen: false,
};

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState,
  reducers: {
    setCurrentPageTitle(state, action: PayloadAction<{currentPageTitle: string}>) {
      const {currentPageTitle} = action.payload;
      if (currentPageTitle) state.currentPageTitle = currentPageTitle;
    },
    setCurrentTown(state, action: PayloadAction<string>) {
      state.currentTown = action.payload;
    },
    setIsOpenTownModal(state, action: PayloadAction<boolean>) {
      state.townModalIsOpen = action.payload;
    },
    setIsOpenAuthModal(state, action: PayloadAction<boolean>) {
      state.authModalIsOpen = action.payload;
    },
    setIsOpenRecoverPasswordModal(state, action: PayloadAction<boolean>) {
      state.recoverPasswordModalIsOpen = action.payload;
    },
  },
});

export const currentPageTitle = (state: RootState) => state.pageInfo.currentPageTitle;
export const currentTown = (state: RootState) => state.pageInfo.currentTown;
export const townModalIsOpen = (state: RootState) => state.pageInfo.townModalIsOpen;
export const authModalIsOpen = (state: RootState) => state.pageInfo.authModalIsOpen;
export const recoverPasswordModalIsOpen = (state: RootState) => state.pageInfo.recoverPasswordModalIsOpen;

export const {setCurrentPageTitle, setCurrentTown, setIsOpenTownModal, setIsOpenAuthModal, setIsOpenRecoverPasswordModal} =
  pageInfoSlice.actions;
export default pageInfoSlice.reducer;
