import {RootState} from '@store';
import {FC} from 'react';
import {useSelector} from 'react-redux';
import {DownloadFileByCodeWrapper} from './DownloadFileByCode.styles';

interface DownloadFileByCodeProps {
  code: string;
  children: string;
  color?: string;
  textDecoration?: string;
}

const DownloadFileByCode: FC<DownloadFileByCodeProps> = ({
  code = 'personal_information',
  children,
  color,
  textDecoration = 'underline',
}) => {
  const {filesWithCode} = useSelector((state: RootState) => state.ui);

  return (
    <>
      {filesWithCode && (
        <DownloadFileByCodeWrapper
          color={color}
          textDecoration={textDecoration}
          target={'_blank'}
          href={filesWithCode[code]}
          onClick={e => e.stopPropagation()}>
          {children}
        </DownloadFileByCodeWrapper>
      )}
    </>
  );
};

export default DownloadFileByCode;
