import { IAppsWizardInitialState } from '@api/types/CabAppsWizard';
import { requestWizardAPI } from '@api/cabAppsWizard';
import { requestWizardMappings } from '@api/mappings/requestWizard';
import { IDLE, PENDING } from '@constants/loadingState';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { setDynamicParams } from '@utils/mapper';
import { NextRouter } from 'next/router';

const initialState: IAppsWizardInitialState = {
  loading: IDLE,
  filtersLoading: IDLE,
  productLoading: IDLE,
  catalogLoading: IDLE,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    id_object: null,
    steps: {},
    isavailable: true,
    url: '',
    state: null,
  },
  id_object: null,
  stepNow: null,
  subStep: 0,
  products: [],
  regions: [],
  groupFields: {},
  pagination: {
    paginator: null,
    lastLoadedPage: null,
    totalPages: null,
    totalItems: null,
  },
  filters: {
    list: [],
    values: {},
  },
  sort: {
    list: [],
    values: { sort: '', by: '' },
  },
  catalogProducts: [],
  complectProducts: [],
  meta: {
    totalCount: null,
    formName: '',
  },
  canSES: null,
  agreements: [],
  screens: {},
  applicationDocument: null,
  isOpenSmsModal: false,
  code: null,
};

export const applicationWizard = createAsyncThunk<
  Record<string, any>,
  {
    params: any[] | Record<string, any>;
    isBack: boolean;
    postData: Record<string, unknown>;
    wrapperKey: string;
    catalogUpdate?: boolean;
    router: NextRouter
  },
  { state: RootState }
>(
  'requestWizard/fetch',
  async ({ params, isBack, postData, wrapperKey, router }, thunkAPI) => {
    const { requestWizard } = thunkAPI.getState();
    let data = await requestWizardAPI.applicationWizard({
      params: {
        id_object: requestWizard?.id_object,
        ...params,
      },
      postData,
      wrapperKey,
      router
    });
    if (data?.wizard?.step) {
      data = {
        step: data?.wizard?.step,
        id_object: data?.wizard?.id_object,
        ...(await requestWizardAPI.applicationWizard({
          params: {
            step: data?.wizard?.step,
            id_object: data?.wizard?.id_object,
          },
          postData,
          wrapperKey,
          router
        })),
      };
    }
    return requestWizardMappings.checkFormName({ ...data, isBack: isBack });
  },
);

export const filterApplicationWizard = createAsyncThunk<
  Record<string, unknown>,
  {
    params?: Record<string, any>;
    resetPagination?: boolean;
    resetFilters?: boolean;
    resetSorting?: boolean;
    router: NextRouter;
  },
  { state: RootState }
>(
  'filterApplicationWizard/fetch',
  async (
    { params, resetPagination = false, resetFilters = false, resetSorting = false, router },
    thunkAPI,
  ) => {
    const { requestWizard } = thunkAPI.getState();
    const paginationParams = requestWizardMappings.getPaginationParams({
      state: requestWizard,
      page: params?.page ?? undefined,
      resetPagination,
    });
    const filteringParams = setDynamicParams({
      ...requestWizard.filters.values,
      formName: requestWizard.meta.formName,
    });
    const sortingParams = requestWizardMappings.getSortingParams({
      state: requestWizard,
      resetSorting,
    });
    const data = await requestWizardAPI.applicationWizard({
      ...params,
      id_object: requestWizard?.id_object,
      step: requestWizard.stepNow,
      ...paginationParams,
      ...filteringParams,
      ...sortingParams,
      router
    });

    return requestWizardMappings.checkFormName(data);
  },
);

export const applicationGetComplect = createAsyncThunk<
  Record<string, any>,
  { id_product: string; id_price: string }
>('applicationGetComplect/fetch', async params => {
  return await requestWizardAPI.getComplect(params);
});

export const applicationSetProducts = createAsyncThunk<
  Record<string, unknown>,
  {
    id?: string;
    quantity: string;
    choosedProducts?: any[];
    replace?: boolean;
  },
  { state: RootState }
>(
  'applicationSetProducts/fetch',
  async ({ id, choosedProducts, quantity, replace }, thunkAPI) => {
    const { requestWizard } = thunkAPI.getState();
    const bodyFormData = new URLSearchParams();
    if (!replace && choosedProducts && choosedProducts.length !== 0) {
      choosedProducts.map(product => {
        bodyFormData.append(`quantity[${product.id}]`, quantity);
        bodyFormData.append(`products[${product.id}]`, product.prices[0].id);
      });
    }
    if (replace) {
      bodyFormData.append('do_replace', String(replace));
      for (const i of requestWizard.products) {
        if (i.id_product === id) {
          bodyFormData.append(`quantity[${i.id_product}]`, String(quantity));
          bodyFormData.append(`products[${i.id_product}]`, i.id_price);
        } else {
          bodyFormData.append(`quantity[${i.id_product}]`, String(i.quantity));
          bodyFormData.append(`products[${i.id_product}]`, i.id_price);
        }
      }
    }
    return await requestWizardAPI.applicationSetProducts(
      { id_object: requestWizard.id_object },
      bodyFormData,
    );
  },
);

export const applicationUnSetProducts = createAsyncThunk<
  Record<string, any>,
  number,
  { state: RootState }
>('applicationUnSetProducts/fetch', async (id_row, thunkAPI) => {
  const { requestWizard } = thunkAPI.getState();
  return await requestWizardAPI.applicationUnSetProducts({
    id_object: requestWizard?.id_object ?? '',
    id_row: [id_row],
  });
});


const requestWizardSlice = createSlice({
  name: 'requestWizard',
  initialState: initialState,
  reducers: {
    setWizardFilters(state, action) {
      state.filters.values = action.payload;
    },
    setWizardSort(state, action) {
      state.sort.values = action.payload;
    },
    resetComplectProducts(state) {
      state.complectProducts = [];
    },
    changeStepNow(state, action) {
      state.stepNow! = action.payload;
    },
    changeSubStep(state, action) {
      state.subStep = action.payload;
    },
    changeIdObject(state, action) {
      state.id_object = action.payload;
    },
    changeIsOpenSmsModal(state, action) {
      state.isOpenSmsModal = action.payload;
    },
    changeInitialValue(state, action) {
      const { payload } = action;
      state.screens[payload.step].initialValues = payload.values;
    },
    clearScreens(state, action) {
      state.screens = {};
      state.id_object = null;
      state.stepNow = null;
      state.subStep = 0;
      state.isOpenSmsModal = false;
    },
    deleteServerError(state, action) {
      const { stepNow, subStep, field } = action.payload;
      const fieldsOnScreen = state.screens[stepNow].items[subStep];

      fieldsOnScreen.map((fieldOnScreen: { field: any; error: any }) => {
        if (fieldOnScreen.field === field) {
          delete fieldOnScreen.error;
        }
      });
      state.screens[stepNow].items[subStep] = fieldsOnScreen;
    },
  },
  extraReducers: builder => {
    builder.addCase(applicationWizard.pending, (state, action) => {
      const {
        meta: { arg },
      } = action;
      if (arg.catalogUpdate) {
        state.catalogLoading = PENDING;
      } else {
        state.loading = PENDING;
      }
    });
    builder.addCase(
      applicationWizard.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {
          payload,
          meta: { arg },
        } = action;
        if (arg.catalogUpdate) {
          state.catalogLoading = IDLE;
        } else {
          state.loading = IDLE;
        }
        state.wizard = payload?.wizard;
        state.products = payload?.products;
        state.regions = payload?.regions;
        if (payload.id_object) {
          state.id_object = payload.id_object;
        }
        if (payload.step) {
          state.stepNow! = payload.step;
        }

        const allItems: any[] = payload?.FormBuilder?.items;

        const groupFields = [];
        for (const key in allItems) {
          const field = allItems[key];
          if (field.group) {
            groupFields.push(field.group);
          }
        }
        groupFields.map(group => {
          for (const key in allItems) {
            const field = allItems[key];
            if (field.type === 'select') {
              for (const i in field.items?.List?.items) {
                const selectField = field.items?.List?.items[i];
                if (selectField.value === group) {
                  state.groupFields[group] = field.field;
                }
              }
            }
          }
        });
        if (!arg.catalogUpdate) {
          if (!state.screens[state.stepNow!]) {
            state.screens[state.stepNow!] = {};
            state.screens[state.stepNow!].ctrl = payload?.ctrl;
            state.screens[state.stepNow!].initialValues =
              requestWizardMappings.initialValuesMapper(allItems) ||
              {};
          } else {
            state.screens[state.stepNow!].initialValues =
              requestWizardMappings.changeInitialValuesMapper(
                state.screens[state.stepNow!]?.initialValues,
                allItems,
              ) || {};
          }
          state.screens[state.stepNow!].items = requestWizardMappings.groupMonths(
            allItems,
          );
        }
        if (payload?.meta?.totalCount) {
          state.meta = payload?.meta;
        }
        if (payload?.pagination) {
          state.pagination = payload?.pagination;
        }
        if (payload?.filters && payload?.filters.length !== 0) {
          state.filters.list = payload?.filters;
        }
        if (payload?.sort && payload?.sort.length !== 0) {
          state.sort.list = payload?.sort;
        }
        if (payload?.PublicGrid?.items?.GridCollection?.items) {
          state.catalogProducts = payload?.PublicGrid?.items?.GridCollection.items.reduce(
            (acc: any, cur: { formatted: string; items: any[] }) => [
              ...acc,
              {
                ...cur,
                priceMin: cur.formatted.replace(/&#160;/g, ''),
                idPrice: cur.items.find(
                  (el: { field: string }) => el.field === 'id_CRMPrice',
                ).value,
                src: cur.items.find((el: { field: string }) => el.field === 'ids_File')
                  ?.items?.Files?.items[0]?.src,
              },
            ],
            [],
          );
        }
        if (payload?.Documents?.items) {
          state.applicationDocument = payload?.Documents?.items.Document;
          state.code = payload?.Documents?.items.Document.application_code;
        }
        state.canSES = payload.canSES;
      },
    );
    builder.addCase(applicationWizard.rejected, (state, action) => {
      state.loading = IDLE;
    });
    builder.addCase(filterApplicationWizard.pending, (state, action) => {
      const { meta } = action;
      state.filtersLoading = PENDING;
      if (meta.arg.resetPagination) {
        state.catalogProducts = [];
      }
    });
    builder.addCase(
      filterApplicationWizard.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { payload } = action;
        state.filtersLoading = IDLE;
        state.products = payload?.products;
        state.regions = payload?.regions;
        state.catalogProducts = payload?.PublicGrid?.items?.GridCollection?.items
          ? [
            ...payload?.PublicGrid?.items?.GridCollection.items.reduce(
              (acc: any, cur: { formatted: string; items: any[] }) => [
                ...acc,
                {
                  ...cur,
                  priceMin: cur.formatted.replace(/&#160;/g, ''),
                  idPrice: cur.items.find(el => el.field === 'id_CRMPrice').value,
                  src: cur.items.find(el => el.field === 'ids_File')?.items?.Files
                    ?.items[0]?.src,
                },
              ],
              [],
            ),
          ]
          : [];
        state.pagination = payload?.pagination;
        if (payload?.filters.length !== 0) {
          state.filters.list = payload?.filters;
        }
        if (payload?.meta?.totalCount) {
          state.meta = payload?.meta;
        }
      },
    );
    builder.addCase(filterApplicationWizard.rejected, (state, action) => {
      state.filtersLoading = IDLE;
    });
    builder.addCase(applicationGetComplect.pending, (state, action) => {
      state.productLoading = PENDING;
    });
    builder.addCase(applicationGetComplect.fulfilled, (state, action) => {
      const { payload } = action;
      state.productLoading = IDLE;
      state.agreements = payload.products[0].prices;
      state.complectProducts = payload.products;
    });
    builder.addCase(applicationGetComplect.rejected, (state, action) => {
      state.productLoading = IDLE;
    });
    builder.addCase(applicationSetProducts.pending, (state, action) => {
      state.productLoading = PENDING;
    });
    builder.addCase(
      applicationSetProducts.fulfilled,
      (state, action: PayloadAction<Record<string, any>>) => {
        const { payload } = action;
        state.productLoading = IDLE;
        state.products = payload.products;
      },
    );
    builder.addCase(applicationSetProducts.rejected, (state, action) => {
      state.productLoading = IDLE;
    });
    builder.addCase(applicationUnSetProducts.pending, (state, action) => {
      state.productLoading = PENDING;
    });
    builder.addCase(applicationUnSetProducts.fulfilled, (state, action) => {
      const { payload } = action;
      state.productLoading = IDLE;
      if (payload?.products) {
        state.products = payload.products;
      }
    });
    builder.addCase(applicationUnSetProducts.rejected, (state, action) => {
      state.productLoading = IDLE;
    });
  },
});

const { actions, reducer } = requestWizardSlice;

export const {
  setWizardFilters,
  setWizardSort,
  changeStepNow,
  clearScreens,
  changeIdObject,
  changeSubStep,
  changeInitialValue,
  deleteServerError,
  changeIsOpenSmsModal,
  resetComplectProducts,
} = actions;

export default reducer;
