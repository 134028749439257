import {IDLE, PENDING} from '@constants/loadingState';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {getStreetCode, getTownCode} from '@api/streetAutocomplete';
import {getRegions} from '@api/clientServices';

interface IStreetAutocompleteInitialState {
  loading: typeof IDLE | typeof PENDING;
  idRequest: number;
  regions: any[];
  towns: any[];
  choosedTown: any;
  streets: {text: string; code: string; guid?: string}[];
  priorityLastIndex: number | null;
}

const streetAutocompleteInitialState: IStreetAutocompleteInitialState = {
  loading: IDLE,
  idRequest: 0,
  regions: [],
  towns: [],
  streets: [],
  priorityLastIndex: null,
  choosedTown: null,
};

export const getRegionsThunk = createAsyncThunk(
  'getRegionsThunk/fetch',
  async thunkAPI => {
    const regions = await getRegions();
    // const sub = indexOfStreet ? await getStreetCode(text, mask) : {items: []};
    // const subFilter = sub.items.filter(
    //   (el: {code: string}) =>
    //     !priority.items.find((find: {code: string}) => find.code === el.code),
    // );
    return {
      regions,
    };
  },
);

export const getTownCodeThunk = createAsyncThunk<
  Record<string, any>,
  {id_region: string; query: string},
  {state: RootState}
>('getTownCodeThunk/fetch', async ({id_region, query}, thunkAPI) => {
  const townsData = await getTownCode(id_region, query);
  // const sub = indexOfStreet ? await getStreetCode(text, mask) : {items: []};
  // const subFilter = sub.items.filter(
  //   (el: {code: string}) =>
  //     !priority.items.find((find: {code: string}) => find.code === el.code),
  // );
  return townsData;
});

// export const getStreetsCode = createAsyncThunk<
//   Record<string, any>,
//   {text: string; mask: string; index: number},
//   {state: RootState}
// >('getStreetsCode/fetch', async ({text, mask, index}, thunkAPI) => {
//   const indexOfStreet = index || '';
//   const streets = await getStreetCode(`${indexOfStreet} ${text}`, mask);
//   // const sub = indexOfStreet ? await getStreetCode(text, mask) : {items: []};
//   // const subFilter = sub.items.filter(
//   //   (el: {code: string}) =>
//   //     !priority.items.find((find: {code: string}) => find.code === el.code),
//   // );
//   return {
//     idRequest: priority.id_request,
//     // priorityLastIndex: priority.items.length - 1,
//     items: [...priority.items, ...subFilter],
//   };
// });

export const getStreetsCode = createAsyncThunk<
  Record<string, any>,
  {id_region: string; query: string},
  {state: RootState}
>('getStreetsCode/fetch', async ({id_region, query}, thunkAPI) => {
  // const indexOfStreet = index || '';
  const streetsData = await getStreetCode(id_region, query);
  // const sub = indexOfStreet ? await getStreetCode(text, mask) : {items: []};
  // const subFilter = sub.items.filter(
  //   (el: {code: string}) =>
  //     !priority.items.find((find: {code: string}) => find.code === el.code),
  // );
  return {
    // idRequest: priority.id_request,
    // priorityLastIndex: priority.items.length - 1,
    streetsData,
  };
});

const streetAutocompliteSlice = createSlice({
  name: 'streetAutocomplite',
  initialState: streetAutocompleteInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRegionsThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(getRegionsThunk.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;
      state.regions = payload.regions.items;
    });
    builder.addCase(getTownCodeThunk.pending, state => {
      state.loading = PENDING;
      state.towns = [];
    });
    builder.addCase(getTownCodeThunk.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;
      state.towns = payload.towns;
    });
    builder.addCase(getStreetsCode.pending, state => {
      state.loading = PENDING;
      state.streets = [];
    });
    builder.addCase(getStreetsCode.fulfilled, (state, action) => {
      const {payload} = action;
      state.streets = payload.streetsData.items ?? [];
      state.loading = IDLE;
    });
    builder.addCase(getStreetsCode.rejected, state => {
      state.loading = IDLE;
    });
  },
});

// Extract the action creators object and the reducer
const {actions, reducer} = streetAutocompliteSlice;

// Export the reducer, either as a default or named export
export default reducer;
