import apiClient from '@api/entities/apiClient';
import {
  ICabContactInitialValues,
  IGetRegData,
  IRegDataProfile,
} from './types/CabinetContacts';
import {AxiosInstance} from 'axios';
import FormData from 'form-data';
import {ISupplierOffersData} from './types/SupplierOffers';

export const CABINET_APPS_PER_PAGE = 20;

export const getCabinetApplications = async (
  query: {
    page?: number;
    items_per_page?: number;
    isfinished?: string;
    status?: number;
    code?: number;
    appdateto?: number;
    appdatefrom?: number;
    sort?: string;
    direction?: string;
    by?: string;
  },
  ssrAPIClient: AxiosInstance,
) => {
  const filteredParams = {
    page: query.page ? Number(query.page) : 1,
    items_per_page: query.items_per_page ?? CABINET_APPS_PER_PAGE,
    isfinished: Number(
      typeof query.isfinished === 'undefined' || query.isfinished === 'true',
    ),
    status: query.status,
    code: query.code,
    appdateto: query.appdateto,
    appdatefrom: query.appdatefrom,
    sort: query.sort ?? null,
    direction: query.by ?? query.direction ?? 'desc',
};

  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/GetCabinetApplications', {
    params: filteredParams,
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const removeUncompletedCabinetApp = async (
  id_object: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('id_object', id_object);

  const {data} = await curAPIClient.post(
    '/CabinetApplicationUncompletedDelete',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      middleware: {
        withAuth: true,
      },
    },
  );

  return data;
};

export const getCabinetMain = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {
    data: {
      account,
      manager,
      charts,
      messages,
      orders,
      payments,
      apps,
      success,
      prelimit,
      accreditation,
      recommendations,
      recommendationsKeyMap,
      promo,
      promotions,
      offers,
      predict
    },
  } = await curAPIClient.get('/CabinetMain', {
    middleware: {
      withAuth: true,
    },
  });

  if (success) {
    return {
      account: account || {},
      manager: manager || {},
      charts: charts || {},
      messages: messages || {},
      orders: orders || {},
      payments: payments || {},
      apps: apps || {},
      prelimit: prelimit || {},
      accreditation: accreditation || [],
      recommendations: recommendations || {},
      recommendationsKeyMap: recommendationsKeyMap || [],
      promo: promo || {},
      success: true,
      promotions: promotions || [],
      offers: offers || [],
      predict: predict || null
    };
  } else {
    return {
      account: {},
      manager: {},
      charts: {},
      messages: {},
      orders: {},
      payments: {},
      apps: {},
      prelimit: {},
      accreditation: [],
      recommendations: {},
      recommendationsKeyMap: [],
      promo: {},
      success: false,
      promotions: [],
    };
  }
};

export const getUserFromCabinet = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {
    data: {data, success},
  } = await curAPIClient.get('/GetUserFromCabinet', {
    middleware: {
      withAuth: true,
    },
  });

  if (success) {
    return {
      account: data ?? {},
      success: true,
    };
  } else {
    return {
      account: null,
      success: false,
    };
  }
};

export const getCabinetMessages = async (
  query: {
    page?: number;
    items_per_page?: number;
    sort?: 'DESC' | 'ASC';
  },
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/GetCabinetMessages', {
    params: {
      page: query.page ?? 1,
      items_per_page: query.items_per_page ?? 5,
      sort: query.sort,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const readCabinetMessage = async (messageID: number) => {
  const {data} = await apiClient.get('/GetCabinetMessage', {
    params: {
      id_message: messageID,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getCabinetMessageDocument = async (messageID: number) => {
  const {data} = await apiClient.get('/GetCabinetMessage/GetDocument', {
    params: {
      id_message: messageID,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getCabinetContacts = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/GetContacts', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const cabinetSearch = async (
  query: {
    page?: string;
    query?: string;
    entity?: string;
  },
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/CabinetSearch', {
    params: {
      page: query.page ?? 1,
      query: query.query,
      [query.entity ? `entity[${query.entity ?? 'all'}]` : ``]: true,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const setCabinetContact = async (contact: ICabContactInitialValues) => {
  const {data} = await apiClient.post('/SetContact', contact, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const setCabinetContactFinish = async (
  contact: Partial<ICabContactInitialValues>,
) => {
  const {data} = await apiClient.post('/SetContact/Finish', contact, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const deleteCabinetContact = async (id_contact: string) => {
  const {data} = await apiClient.get('/ContactDelete', {
    params: {
      id_contact,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const verifyPhone = async ({
  id_contact,
  code,
  resend_code,
}: {
  id_contact: string;
  code: string | null;
  resend_code?: boolean;
}) => {
  const fd = new FormData();
  fd.append('id_contact', id_contact);
  fd.append('sms_code', code);
  resend_code && fd.append('resend_code', resend_code);

  const {data} = await apiClient.get('/VerifyPhone', {
    params: {
      id_contact,
      sms_code: code,
      resend_code,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};
export const verifyEmail = async ({
  id_contact,
  code,
  resend_code,
}: {
  id_contact: string;
  code: string | null;
  resend_code?: boolean;
}) => {
  const fd = new FormData();
  fd.append('id_contact', id_contact);
  fd.append('email_code', code);
  resend_code && fd.append('resend_code', resend_code);

  const {data} = await apiClient.get('/VerifyEmail', {
    params: {
      id_contact,
      email_code: code,
      resend_code,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getRegData = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get<IGetRegData>('/GetRegData', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getRegDataV2 = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get<IGetRegData>('/GetRegDataV2', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getProfile = async (
  ssrAPIClient?: AxiosInstance,
): Promise<IRegDataProfile> => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/Profile', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const cabinetGetUserDataLight = async () => {
  const {data} = await apiClient.get('/CabinetGetUserDataLight', {
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const checkSparkRequest = async () => {
  const {data} = await apiClient.get('/SparkRequestCheck', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const createSparkRequest = async () => {
  const {data} = await apiClient.get('/SparkRequestCreate', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierCRMProducts = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierCRMProducts', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierCRMCheckLists = async (
  query: Record<string, any>,
  ssrAPIClient?: AxiosInstance,
): Promise<ISupplierOffersData> => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierCRMCheckLists', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const createSupplierCheckList = async (
  id_File: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('id_File', id_File);

  const {data} = await curAPIClient.post('/CreateSupplierCheckList', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const sendMailSupplierCheckList = async (
  id_object: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('id_object', id_object);

  const {data} = await curAPIClient.post('/SendMailSupplierCheckList', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const getSupplierCRMProductsXLS = async (ssrAPIClient: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/SupplierCRMProducts/Report', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierApplications = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierApplications', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierApplicationsXLS = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierApplications/Report', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierApplication = async (
  id_object: number,
  page_document: number,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/SupplierApplication', {
    params: {
      id_object,
      page_document,
    },
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const getAgentDashboard = async (ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  const {data} = await curAPIClient.get('/AgentDashboard', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierSpecialReport = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierSpecialReport', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getSupplierSpecialReportXLS = async (
  query: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/SupplierSpecialReport/Report', {
    params: {
      ...query,
    },
    middleware: {
      withAuth: true,
    },
    timeout: 0,
  });
  return data;
};

export const checkAuth = async () => {
  const {data} = await apiClient.get('/CheckAuth', {
    middleware: {
      withAuth: true,
    },
  });
  return data;
};

export const getClientOffer = async (id_object: string, ssrAPIClient: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/RALOffer', {
    params: {
      id_object,
    },
    middleware: {
      withAuth: true,
    },
    timeout: 0,
  });
  return data;
};

export const rejectClientOffer = async (id_object: string) => {
  const {data} = await apiClient.get('/RALOffer/Reject', {
    params: {
      id_object,
    },
    middleware: {
      withAuth: true,
    },
    timeout: 0,
  });
  return data;
};

export const acceptClientOffer = async (id_object: string) => {
  const {data} = await apiClient.get('/RALOffer/Accept', {
    params: {
      id_object,
    },
    middleware: {
      withAuth: true,
    },
    timeout: 0,
  });
  return data;
};



