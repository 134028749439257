import {contactPersonsCreateAttorney} from '@api/contactPersonsAttorney';
import {requestWizardMappings} from '@api/mappings/requestWizard';
import {ICabContactsAttorneyResponse} from '@api/types/CabinetContacts';
import {IWizardField} from '@api/types/Wizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {isNull} from 'lodash';

interface IContactPersonsInitialState {
  loading: typeof IDLE | typeof PENDING;
  id_object: string;
  entity: string;
  stepNow: string;
  canSES: null | boolean;
  wrapperKey: string;
  initialValues: null | Record<string, any>;
  fields: IWizardField[] | null;
  contactID: string | null;
  title: string | null;
  signDocument: null | Record<string, any>;
  error_message: string | null;
}

const initialState: IContactPersonsInitialState = {
  loading: IDLE,
  id_object: '',
  entity: 'Contact',
  stepNow: '1',
  canSES: null,
  wrapperKey: '',
  initialValues: {
    canCSPAttorneyNumber: '',
    canCSPDate: '',
    canCSPDateExpired: '',
    customPosition: '',
  },
  fields: null,
  contactID: null,
  title: null,
  signDocument: null,
  error_message: null,
};

export const contactPersonsCreateAttorneyThunk = createAsyncThunk<
  ICabContactsAttorneyResponse,
  {
    params: any[] | Record<string, any>;
    postData: Record<string, unknown>;
    wrapperKey: string;
  },
  {state: RootState}
>(
  'contactPersonsCreateAttorney/fetch',
  async ({params, postData, wrapperKey}, thunkAPI) => {
    const state = thunkAPI.getState().contactPersonsAttorney;
    const data = await contactPersonsCreateAttorney(
      {
        ...params,
      },
      postData,
      wrapperKey,
    );
    if (
      state.stepNow !== '2' &&
      !isNull(postData) &&
      Object.values(postData).length !== 0 &&
      data.success
    ) {
      thunkAPI.dispatch(changeStepNow('2'));
    }
    return data;
  },
);

const contactPersonsAttorney = createSlice({
  name: 'contactPersonsAttorney',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },
    setInitialValues(state, action: PayloadAction<Record<string, any>>) {
      const {payload} = action;
      state.initialValues = payload.values;
    },
    dropState(state) {
      state.stepNow = initialState.stepNow;
      state.id_object = initialState.id_object;
    },
  },
  extraReducers: builder => {
    builder.addCase(contactPersonsCreateAttorneyThunk.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      contactPersonsCreateAttorneyThunk.fulfilled,
      (state, action: PayloadAction<ICabContactsAttorneyResponse>) => {
        const {
          payload: {
            scans,
            canCSPAttorneyNumber,
            canCSPDate,
            canCSPDateExpired,
            customPosition,
            title,
            id,
            error_message,
          },
        } = action;

        // state.wrapperKey = payload?.FormBuilder?.form_name;

        // if (payload?.FormBuilder) {
        state.title = title;

        state.error_message = error_message || null;

        state.initialValues = {
          scans,
          canCSPAttorneyNumber: canCSPAttorneyNumber ?? '',
          canCSPDate: canCSPDate ?? '',
          canCSPDateExpired: canCSPDateExpired ?? '',
          customPosition: customPosition ?? '',
        };
        state.id_object = id;
        // state.fields = Object.values(payload.FormBuilder.items);
        // state.canSES = payload.canSES;

        // if (payload?.CardBuilder?.items[0]?.items?.value) {
        //   state.signDocument = payload?.CardBuilder?.items[0]?.items?.value;
        // }
        // if (payload?.CSPUI) {
        //   state.id_object = payload.CSPUI.id_object;
        //   state.entity = payload.CSPUI.entity;
        // }

        state.loading = IDLE;
      },
    );
    builder.addCase(contactPersonsCreateAttorneyThunk.rejected, state => {
      state.loading = IDLE;
    });
  },
});

const {actions, reducer} = contactPersonsAttorney;

export const {changeStepNow, setInitialValues, dropState} = actions;

export default reducer;
