import {FC, ReactNode, useEffect, useMemo} from 'react';
import {useAppDispatch, useAppSelector, useWindowDimensions} from '@hooks';
import {setDeviceInfo} from '@store/slices/deviceInfo';
import styled from 'styled-components';
import {Router, useRouter} from 'next/router';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import {useUTMMarks} from '@hooks/useUTMMarks';
import {
  getFilesWithCodeDuringInitialize,
  getPagesDuringInitialize,
  initializeStoreItemsFromLocalStorage,
} from '@utils/initialize';
import {ENABLE_NEW_CABINET} from '@config/cabinet';
import {getDeviceType, sendClientParamsNATS} from '@utils/common';
import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {theme} from '@styles/theme';
import {urlsWithoutLoader} from '@constants/loadingState';
import {getCookie, setCookie} from 'cookies-next';
import {setExperiments} from '@store/slices/ui';
import {EXPERIMENTS} from '@constants/paramNames';

interface MainLayoutProps {
  children: ReactNode | undefined;
}

const StyledWrapper = styled.div<{$isCabinet?: boolean}>`
  ${props => {
    if (props.$isCabinet) {
      return `
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        overflow: hidden;
      `;
    }
  }}
  margin: 0 auto;
  min-height: ${props => (props.$isCabinet ? '100vh' : '250px')};
  background-color: ${props =>
    props.$isCabinet ? `${theme.colors.lightBG}` : `${theme.colors.white}`}; ;
`;

export const MainLayout: FC<MainLayoutProps> = ({children}) => {
  /*  Использование лэйаута обусловлено тем, что нам нужно получать информацию
        о девайсе пользователя в каком-то главном компоненте, а не на страницах.
        Мы бы могли сделать это в _app, но в _app dispatch нам не доступен, так
        как провайдер находится как раз в _app. */

  const dispatch = useAppDispatch();

  const {width, height} = useWindowDimensions();

  const router = useRouter();

  const isCabinet = router.asPath.startsWith('/cabinet/v2') && ENABLE_NEW_CABINET;

  const {pages} = useAppSelector(state => state.ui);

  const pagesWithUrlRedirect = useMemo(() => {
    return pages?.filter(page => page?.url_redirect);
  }, [pages]);

  useUTMMarks();

  NProgress.configure({
    easing: 'ease-out',
    showSpinner: false,
    trickleSpeed: 100,
    trickle: true,
  });

  useEffect(() => {
    // setShowChild(true);
    sendClientParamsNATS(AnalyticEventNames.PageView, {
      eventName: AnalyticEventNames.PageView,
      eventCategory: AnalyticEventCategories.All,
    });

    const loaderHided = urlsWithoutLoader.find(url => url === router.pathname);

    const handleRouteStart = (route: string) => {
      if (loaderHided) return;
      NProgress.start();
      document.documentElement.style.scrollBehavior = 'auto';

      const page = pagesWithUrlRedirect.find(page => page?.url === route);

      if (page && page?.url_redirect && page?.url) {
        router.push(page.url, page.url_redirect);
      }
    };

    const handleRouteDone = () => {
      document.documentElement.style.scrollBehavior = 'smooth';
      NProgress.done();

      sendClientParamsNATS(AnalyticEventNames.PageView, {
        eventName: AnalyticEventNames.PageView,
        eventCategory: AnalyticEventCategories.All,
      });
    };

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);

    return () => {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, [pages]);

  useEffect(() => {
    if (width && height) {
      dispatch(
        setDeviceInfo({
          windowWidth: width,
          deviceType: getDeviceType(),
        }),
      );
    }
  }, [width, height, dispatch]);

  useEffect(() => {
    initializeStoreItemsFromLocalStorage(dispatch);

    getFilesWithCodeDuringInitialize(dispatch, [
      'personal_information',
      'soglasie_na_obrabotku_personalnykh_dannykh',
    ]);

    getPagesDuringInitialize(dispatch);
    const experiments = getCookie(EXPERIMENTS);
    if (typeof experiments === 'string') {
      dispatch(setExperiments(experiments.split(',')));
    }

    const interval = setInterval(() => getPagesDuringInitialize(dispatch), 3600000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (getCookie('authToken') && !getCookie('__at')) {
      setCookie('__at', getCookie('authToken'));
    }
    if (getCookie('__at') && !getCookie('authToken')) {
      setCookie('authToken', getCookie('__at'));
    }
  }, []);

  return (
    <StyledWrapper $isCabinet={isCabinet}>
      {/* <Navigation userStatus={''} paths={PathsManager.getPathsArray()} /> */}
      {children}
    </StyledWrapper>
  );
};
