import {useEffect, useState} from 'react';
import {ToTopButtonWrapper} from './ToTopButton.styles';

const ToTopButton = () => {
  const [showToTop, setShowToTop] = useState(false);
  const listener = () => {
    if (window.scrollY < window.innerHeight * 2) {
      setShowToTop(false);
    } else {
      setShowToTop(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);
  return (
    <>
      {showToTop && (
        <ToTopButtonWrapper
          title="Вверх"
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }}
        />
      )}
    </>
  );
};

export default ToTopButton;
