// import {theme} from '@styles/theme';
import styled from 'styled-components';

export const BottomBtnsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > * {
    pointer-events: auto;
  }
`;
