import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {ICreateAttorneyWizardInitialState} from '@api/types/CreateAttorneyWizard';
import {attorneyWizardAPI} from '@api/suppliesNewAttorney';
import {isArray} from 'lodash';
import { NextRouter } from 'next/router';

const initialState: ICreateAttorneyWizardInitialState = {
  loading: IDLE,
  stepsAmount: 0,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    steps: {},
  },
  id_object: '',
  stepNow: '1',
  sm: '0', //дополнительный параметр. Может быть 0 или 1. Может меняться на 1 шаге (выбор пользователя из списка или создание нового). На остальных шагах всегда 0
  canSES: null,
  ctrl: '',
  formedDocument: null,
  screens: {},
};

export const attorneyCreateWizard = createAsyncThunk<
  Record<string, unknown>,
  {
    params: any[] | Record<string, any>;
    postData: Record<string, unknown>;
    wrapperKey: string;
    nextStepRequest?: boolean;
    id_object?: string;
    router: NextRouter;
  },
  {state: RootState}
>(
  'attorneyCreateWizard/fetch',
  async ({params, postData, wrapperKey, nextStepRequest = true, id_object, router}, thunkAPI) => {
    const {newAttorneyWizard} = thunkAPI.getState();
    const data = await attorneyWizardAPI.attorneyCreateWizard({
      params: {
        id_object: id_object || newAttorneyWizard?.id_object || undefined,
        ...params,
      },
      postData,
      wrapperKey,
      router
    });
    /*
     * Если отправка данных текущего шага выполнилась без ошибок то вернется data.wizard.step,
     * значит можно переходить на следующий шаг.
     * Ниже запрашиваем поля для следующего шага
     */
    if (data?.wizard?.step && nextStepRequest) {
      const newParams = {
        id_object: data?.wizard?.id_object,
        sm: '0',
        step: data?.wizard?.step,
        ...(!isArray(params) && params.id_order && {id_order: params.id_order}),
      };
      thunkAPI.dispatch(
        attorneyCreateWizard({
          params: newParams,
          postData: {},
          wrapperKey: '',
          nextStepRequest: false,
          router
        }),
      );
    }
    return data;
  },
);

const newAttorneyWizardSlice = createSlice({
  name: 'requestWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },

    changeSm(state, action: PayloadAction<'0' | '1'>) {
      state.sm = action.payload;
    },

    changeIdObject(state, action: PayloadAction<string>) {
      state.id_object = action.payload;
    },

    setInitialValues(state, action) {
      const {payload} = action;
      state.screens[state.stepNow].initialValues = payload.values;
    },
    dropState(state) {
      state.stepNow = initialState.stepNow;
      state.id_object = initialState.id_object;
      state.screens = initialState.screens;
      state.sm = initialState.sm;
      state.wizard = initialState.wizard;
      state.ctrl = initialState.ctrl;
    },
  },
  extraReducers: builder => {
    builder.addCase(attorneyCreateWizard.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      attorneyCreateWizard.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        if (payload?.wizard?.step) {
          state.stepNow = payload?.wizard?.step;
        }
        state.wizard = payload?.wizard;
        state.ctrl = payload?.ctrl;
        if (payload.wizard?.id_object) {
          state.id_object = payload.wizard.id_object.toString();
        }
        state.stepsAmount = Object.keys(payload?.wizard?.steps ?? {}).length;

        if (!state.screens[state.stepNow]) {
          state.screens[state.stepNow] = {};
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.initialValuesMapper(payload?.FormBuilder?.items) || {};
        } else {
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.changeInitialValuesMapper(
              state.screens[state.stepNow].initialValues,
              payload?.FormBuilder?.items,
            ) || {};
        }
        state.screens[state.stepNow].ctrl = payload?.ctrl;
        state.screens[state.stepNow].items = payload?.FormBuilder?.items ?? [];

        state.canSES = payload.canSES;

        if (payload?.CardBuilder?.items[0]?.items?.value) {
          const document = payload?.CardBuilder?.items[0]?.items?.value;
          state.formedDocument = {title: document.title, src: document.src};
        }

        if (!payload?.wizard?.step) {
          state.loading = IDLE;
        }
      },
    );
    builder.addCase(attorneyCreateWizard.rejected, state => {
      state.loading = IDLE;
    });
  },
});

// Extract the action creators object and the reducer
const {actions, reducer} = newAttorneyWizardSlice;

// Extract and export each action creator by name
export const {changeStepNow, changeSm, changeIdObject, setInitialValues, dropState} =
  actions;

// Export the reducer, either as a default or named export
export default reducer;
