import {fontStyles, theme} from '@styles/theme';
import styled from 'styled-components';
import {ButtonProps} from './Button';

export const BtnOutlined = styled.button<ButtonProps>`
  font-family: ${theme.fonts.subline};
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'normal')};
  color: ${({border}: ButtonProps) =>
    border === 'green' ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
  border: 1px solid
    ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
  border-radius: ${theme.rounded};
  font-weight: ${({fontWeight}: ButtonProps) => (fontWeight ? `${fontWeight}` : '500')};
  font-size: ${({fontSize}: ButtonProps) => (fontSize ? `${fontSize}px` : '14px')};
  transition: all 0.4s;
  cursor: pointer;
  padding: 10px 24px;
  width: ${({fullWidth}) => (fullWidth ? '100%' : 'fit-content')};
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.lightGreen}` : `transparent`};
    border-color: ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkText}`};
    color: ${({border}: ButtonProps) =>
      border === 'green' ? '#017856' : `${theme.colors.brandGreenV2}`};
  }
  &:active {
    background-color: ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.brandGreenV2}` : `transparent`};
    border-color: ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.brandGreenV2}` : `${theme.colors.darkGreen}`};
    color: ${({border}: ButtonProps) =>
      border === 'green' ? `${theme.colors.white}` : `${theme.colors.darkGreen}`};
  }
`;

export const BtnFilled = styled.button<ButtonProps>`
  font-family: ${theme.fonts.subline};
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'normal')};
  color: ${theme.colors.white};
  border: 1px solid ${theme.colors.brandGreenV2};
  border-radius: ${theme.rounded};
  background-color: ${({active}) =>
    active ? `${theme.colors.darkGreen}` : `${theme.colors.brandGreenV2}`};
  font-weight: ${({fontWeight}: ButtonProps) => (fontWeight ? `${fontWeight}` : '500')};
  font-size: ${({fontSize}: ButtonProps) => (fontSize ? `${fontSize}px` : '14px')};
  transition: all 0.4s;
  cursor: pointer;
  padding: 10px 24px;
  width: ${({fullWidth}) => (fullWidth ? '100%' : 'fit-content')};
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({active}) =>
    !active
      ? `
    &:hover {
        background-color: transparent;
        border: 1px solid ${theme.colors.brandGreenV2};
        color: ${theme.colors.brandGreenV2};
        transition: all 0.4s;
        & > div span {
          filter: invert(0.5);
        }
      }
  `
      : ''}
`;

export const BtnDisabled = styled.button<ButtonProps>`
  font-family: ${theme.fonts.subline};
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'normal')};
  color: ${theme.colors.gray};
  border: ${({theme, variant}) =>
    variant === 'outlined' ? '1px solid' + theme.colors.lightGrayV2 : ''};
  border-radius: ${theme.rounded};
  background-color: ${({theme, variant}) =>
    variant === 'outlined' ? 'transparent' : theme.colors.lightGrayV2};
  font-weight: ${({fontWeight}: ButtonProps) => (fontWeight ? `${fontWeight}` : '500')};
  font-size: ${({fontSize}: ButtonProps) => (fontSize ? `${fontSize}px` : '14px')};
  transition: all 0.4s;
  padding: 10px 24px;
  pointer-events: none;
  width: ${({fullWidth}) => (fullWidth ? '100%' : 'fit-content')};
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div span {
    filter: invert(0.5);
  }
`;

export const ButtonWrapper = styled.div`
  a {
    width: fit-content;
    height: fit-content;
  }
`;

export const BtnText = styled.button`
  ${fontStyles.mainText1}
  font-weight: ${({fontWeight}: ButtonProps) => (fontWeight ? `${fontWeight}` : '500')};
  font-size: ${({fontSize}: ButtonProps) => (fontSize ? `${fontSize}px` : '14px')};
  white-space: ${({noWrap}) => (noWrap ? 'nowrap' : 'normal')};
  color: ${theme.colors.brandGreenV2};
  transition: all 0.4s;
  cursor: pointer;
  width: ${({fullWidth}) => (fullWidth ? '100%' : 'fit-content')};
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
    color: ${({theme}) => theme.colors.brandGreenV2};
    transition: all 0.4s;
  }
`;

export const ImageWrapper = styled.div<{
  iconSrcSizes: {width: number; height: number};
  margin?: string;
}>`
  min-width: 15px;
  min-height: 15px;
  max-width: 32px;
  max-height: 32px;
  width: ${({iconSrcSizes}) => (iconSrcSizes ? `${iconSrcSizes.width}px` : '15px')};
  height: ${({iconSrcSizes}) => (iconSrcSizes ? `${iconSrcSizes.height}px` : '12px')};
  margin: ${({margin}) => margin ?? '0'};
`;
