import { bodyFormDataBuilder } from '@utils/formatters';
import apiClient from './entities/apiClient';

const nrsWizard = async (
  params: any,
  postData?: {[x: string]: string | any},
  wrapperKey?: any,
) => {
  try {
   
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);

    const res = await apiClient.post('/NRSWizard', postData ? bodyFormData : null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });

    const {data} = res;

    if (data.errors_list) {
      throw new Error(data.errors_list[0].error);
    }

    return {
      ...data,
    };
  } catch (e) {
    console.log(e);
  }
};

export const nrsWizardAPI = {
  nrsWizard,
};
