import {AxiosInstance} from 'axios';
import apiClient from './entities/apiClient';
import { NextRouter } from 'next/router';

export const getServicesStructure = async (
  type_name: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  try {
    const {
      data: {success, dictionaries, scheme, orders, contacts, regions},
    } = await curAPIClient.get('/psdscheme', {
      params: {},
      middleware: {
        withAuth: true,
      },
    });
    const orderTypes: any = {};
    if (success) {
      for (let i = 0; i < scheme.length; i++) {
        if (!orderTypes[scheme[i].category.title]) {
          orderTypes[scheme[i].category.title] = [];
        }
        if (type_name) {
          if (scheme[i].type?.title?.toLowerCase().includes(type_name.toLowerCase())) {
            orderTypes[scheme[i].category.title].push(scheme[i]);
          }
        } else {
          orderTypes[scheme[i].category.title].push(scheme[i]);
        }
      }
      return {
        orderTypes: orderTypes || {},
        dictionaries: dictionaries || [],
        orders: orders || [],
        contacts: contacts || [],
        regions: regions || [],
      };
    } else {
      return {
        orderTypes: {},
        dictionaries: [],
        orders: [],
        contacts: [],
        regions: [],
      };
    }
  } catch (e) {
    console.log(e);
    return {
      orderTypes: {},
      dictionaries: [],
      orders: [],
      contacts: [],
      regions: [],
    };
  }
};

export const createOrderDocuments = async ({
  contact_values,
  id_type,
  fields,
  ssrAPICLient,
  router
}: {
  contact_values: any,
  id_type: string,
  fields: any[],
  ssrAPICLient?: AxiosInstance,
  router: NextRouter
}) => {
  const curAPIClient = ssrAPICLient ?? apiClient;
  try {
    const formData = new FormData();
    formData.append('PSDDeliveryType', contact_values.PSDDeliveryType);
    if (
      contact_values.PSDDeliveryType === '1' ||
      contact_values.PSDDeliveryType === '2' ||
      contact_values.PSDDeliveryType === '3'
    ) {
      const dataKladr =
        typeof contact_values.post_addr_kladr === 'string'
          ? JSON.parse(contact_values.post_addr_kladr)
          : JSON.parse(JSON.stringify(contact_values.post_addr_kladr));
      formData.append('post_addr_kladr', JSON.stringify(dataKladr));
    }
    if (
      contact_values.PSDDeliveryType === '2' ||
      contact_values.PSDDeliveryType === '3'
    ) {
      formData.append('delivery_contact', contact_values.delivery_contact);
      formData.append('delivery_contact_phone', contact_values.delivery_contact_phone);
    }
    formData.append('isemail', contact_values.isemail);
    if (contact_values.isemail) {
      formData.append('id_Contact', contact_values.id_Contact);
    }
    formData.append('id_type', id_type);
    for (const [field_key, field_value] of Object.entries(fields)) {
      if (field_key.startsWith('id_CabinetOrder')) {
        for (const item of field_value) {
          formData.append('id_CabinetOrder[]', item.value);
        }
      } else {
        const fieldKeyArray = field_key.split('--');
        if (fieldKeyArray[2] === 'from' || fieldKeyArray[2] === 'to') {
          formData.append(
            fieldKeyArray[0] + '[' + fieldKeyArray[1] + '][' + fieldKeyArray[2] + ']',
            field_value,
          );
        } else {
          if (field_value.id) {
            formData.append(
              fieldKeyArray[0] + '[' + fieldKeyArray[1] + ']',
              field_value.id,
            );
          } else {
            formData.append(fieldKeyArray[0] + '[' + fieldKeyArray[1] + ']', field_value);
          }
        }
      }
    }
    const {
      data: {success, error_message, code, errcode},
    } = await curAPIClient.post('/SetPSD', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      middleware: {
        withAuth: true,
      },
    });
    if (errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
    if (success) {
      return {success, error_message, code};
    } else {
      return {success, error_message, code: ''};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getDocumentOrders = async (
  paginationParams: any,
  filtersParams: any,
  sortingParams: any,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  try {
    const {
      data: {count, pages, items, types, statuses, success},
    } = await curAPIClient.get('/getpsds', {
      params: {
        ...paginationParams,
        ...filtersParams,
        ...sortingParams,
      },
      middleware: {
        withAuth: true,
      },
    });
    if (success) {
      return {
        documentOrders: items || [],
        types: types || [],
        statuses: statuses || [],
        count: count || null,
        pages: pages || null,
        lastLoadedPage: paginationParams.page,
      };
    } else {
      return {
        documentOrders: [],
        types: [],
        statuses: [],
        count: null,
        pages: null,
        lastLoadedPage: null,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      documentOrders: [],
      types: [],
      statuses: [],
      count: null,
      pages: null,
      lastLoadedPage: null,
    };
  }
};

export const getDocuments = async (
  paginationParams: any,
  sortingParams: any,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;
  try {
    const {
      data: {docs, actual_date, success},
    } = await curAPIClient.get('/CabinetPSDResponses', {
      params: {
        ...paginationParams,
        ...sortingParams,
      },
      middleware: {
        withAuth: true,
      },
    });
    if (success) {
      return {
        documents: docs || {},
        actual_date: actual_date || '',
        documentsCount: docs.Grid.count_collection || null,
        documentsPages: docs.Grid.count_collection / docs.Grid.items_pp || null,
        documentsLastLoadedPage: paginationParams.page,
      };
    } else {
      return {
        documents: {},
        actual_date: '',
        documentsCount: null,
        documentsPages: null,
        documentsLastLoadedPage: null,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      documents: {},
      actual_date: '',
      documentsCount: null,
      documentsPages: null,
      documentsLastLoadedPage: null,
    };
  }
};

export const cabinetComplaints = async (query?: any, ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  try {
    const {data} = await curAPIClient.get('/CabinetComplaints', {
      params: {
        '1[by]': query.by || '',
        '1[sort]': query.sort || '',
        page: query.page || 1,
        items_per_page: 20,
      },
      middleware: {
        withAuth: true,
      },
    });

    if (data.success) {
      return {
        complaintDocuments: data.complaints.Grid.items.GridCollection.items || [],
        complaintsCount: data.complaints.Grid.count_collection || null,
        download_url: data.download_url || '',
      };
    } else {
      return {
        complaintDocuments: [],
        complaintsCount: null,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      complaintDocuments: [],
      complaintsCount: null,
    };
  }
};

export const cabinetPostComplaint = async (values: any, ssrAPIClient?: AxiosInstance) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const bodyFormData = new FormData();

  if (values.id_File.length !== 0) {
    bodyFormData.append('id_File', `,${values.id_File[0].base64.split(',')[1]}`);
  }

  const data = await curAPIClient.post('/CabinetPostComplaint', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      text: values.text,
      id_UListItem: values.id_UListItem,
    },
    middleware: {
      withAuth: true,
    },
  });

  return data.data;
};

export const getCabinetComplaint = async (
  id_complaint: string,
  ssrAPIClient?: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  const {data} = await curAPIClient.get('/GetCabinetComplaint', {
    params: {id_complaint},
    middleware: {
      withAuth: true,
    },
  });

  return data;
};
