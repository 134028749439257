import React from 'react';
import ModalWindow from '../ModalWindow';
import {useAppDispatch, useAppSelector} from '@hooks';
import {setConfirmModalActive, setSelectModalActive} from '@store/slices/geolocation';
import {Title, City, Wrapper, Btns, Btn} from './GeoConfirm.styles';

const GeoConfirm = ({isModalVisible}: {isModalVisible: boolean}) => {
  const {geo} = useAppSelector(state => state.geolocation);
  const dispatch = useAppDispatch();
  return (
    <ModalWindow isOpen={isModalVisible} height="none">
      <Wrapper>
        <Title>
          Ваш город <City>{geo?.city}</City>?
        </Title>
        <Btns>
          <Btn
            title="Да"
            variant="filled"
            onClick={() => {
              dispatch(setConfirmModalActive(false));
            }}>
            Да
          </Btn>
          <Btn
            title="Выбрать другой"
            onClick={() => {
              dispatch(setConfirmModalActive(false));
              dispatch(setSelectModalActive(true));
            }}>
            Выбрать другой
          </Btn>
        </Btns>
      </Wrapper>
    </ModalWindow>
  );
};

export default GeoConfirm;
