import {ENABLE_NEW_CABINET} from '@config/cabinet';
import Link from 'next/link';
import {
  FooterMenuWrapper,
  List,
  ListTitle,
  ListItem,
  TextLinks,
} from './FooterMenu.styles';

const FooterMenu = () => {
  return (
    <FooterMenuWrapper itemScope itemType="http://schema.org/SiteNavigationElement">
      <List>
        <ListTitle>
          <Link itemProp="url" href="/company">
            О РОСАГРОЛИЗИНГЕ
          </Link>
        </ListTitle>
        <ListItem>
          <Link itemProp="url" href="/company#directors">
            Руководство
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/tenders">
            Закупка товаров и услуг
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/virtual-office">
            Виртуальный офис
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/championship">
            Чемпионаты по пахоте
          </Link>
        </ListItem>
        <TextLinks>
          <Link itemProp="url" href="/company/contacts">
            Контакты
          </Link>
          <Link itemProp="url" href="/company/contacts#requisites">
            Реквизиты
          </Link>
          <Link
            href="https://www.rosagroleasing.ru/cabinet/v2/client/dashboard/"
            passHref
            target="_blank">
            Кабинет клиента
          </Link>
          <Link itemProp="url" href="/site-map">
            Карта сайта
          </Link>
          <Link
            itemProp="url"
            href="/calculator"
            target="_blank"
            title="Лизинговый калькулятор онлайн">
            Лизинговый калькулятор
          </Link>
        </TextLinks>
      </List>
      <List>
        <ListTitle>
          <Link itemProp="url" href="/investors">
            ИНВЕСТОРАМ
          </Link>
        </ListTitle>
        <ListItem>
          <Link itemProp="url" href="/investors/metrics">
            Ключевые показатели
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/investors/bonds">
            Облигации
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/investors/ratings">
            Кредитные рейтинги
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/investors/events">
            Календарь событий
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/investors/reports">
            Раскрытие информации
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/investors/insiders">
            Инсайдерам
          </Link>
        </ListItem>
      </List>
      <List>
        <ListTitle>
          <Link itemProp="url" href="/providers/catalog">
            ПОСТАВЩИКИ И ПАРТНЁРЫ
          </Link>
        </ListTitle>
        <ListItem>
          <Link itemProp="url" href="/providers/partnership">
            Как стать поставщиком / партнёром
          </Link>
        </ListItem>
        <ListItem>
          <Link
            href="https://www.rosagroleasing.ru/cabinet/v2/client/dashboard/  "
            target={!ENABLE_NEW_CABINET ? '_blank' : '_self'}>
            Личный кабинет поставщика / партнёра
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/providers/catalog">
            Каталог поставщиков / партнёров
          </Link>
        </ListItem>
      </List>
      {/* <List>
        <ListTitle>
          <Link itemProp='url' href="/company/career">РАБОТА В КОМПАНИИ</Link>
        </ListTitle>
        <ListItem>
          <Link itemProp='url' href="/company/career#vacancy">Вакансии</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="/company/career#stories">Истории успеха</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="/company/career#about">О работе в компании</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="/company/career#coop">Корпоративная культура</Link>
        </ListItem>
      </List> */}
      <List>
        <ListTitle>
          <Link itemProp="url" href="/company/smi">
            ПРЕСС-ЦЕНТР
          </Link>
        </ListTitle>
        <ListItem>
          <Link itemProp="url" href="/company/smi/news">
            Новости
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/smi/publications">
            Пресса о нас
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/smi#contacts">
            Контакты для прессы
          </Link>
        </ListItem>
        <ListItem>
          <Link itemProp="url" href="/company/blog">
            Блог
          </Link>
        </ListItem>
      </List>
      {/* <List>
        <ListTitle>
          <Link itemProp='url' href="/insurance">ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ</Link>
        </ListTitle>
        <ListItem>
          <Link itemProp='url' href="/insurance/1">Страхование имущества</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="/insurance/1">Страхование транспортных средств</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="/insurance/1">Страхование жизни</Link>
        </ListItem>
        <ListItem>
          <Link itemProp='url' href="#">Калькулятор удобрений</Link>
        </ListItem>
      </List> */}
    </FooterMenuWrapper>
  );
};

export default FooterMenu;
