import {TechSupportButtonWrapper} from './TechSupportButton.styles';

const TechSupportButton = ({
  setIsModalVisible,
}: {
  setIsModalVisible: {(modalVisible: boolean): void};
}) => {
  return (
    <TechSupportButtonWrapper
      title="Обращение в службу технической поддержки"
      onClick={() => setIsModalVisible(true)}
    />
  );
};

export default TechSupportButton;
