import {AxiosInstance} from 'axios';
import apiClient from './entities/apiClient';
import {IDocumentAdd} from '@api/types/CabinetDocuments';
import {bodyFormDataBuilder} from '@utils/formatters';

// export const cabinetDocuments = async (
//   query: {
//     page?: number;
//     items_per_page?: number;
//     title?: string;
//     isrequired?: string;
//     LastCreateDate?: string;
//     id_package?: string;
//     by?: string;
//     sort?: string;
//   },
//   ssrAPIClient: AxiosInstance,
// ) => {
//   const curAPIClient = ssrAPIClient ?? apiClient;

//   const filteredParams = {
//     nPaginator: 1,
//     page: query.page ? Number(query.page) : 1,
//     items_per_page: query.items_per_page ?? 10,
//     id_package: query?.id_package || 0,
//     '1[filter][title]': query?.title || '',
//     '1[by]': query.by,
//     '1[sort]': query.sort,
//   };
//   try {
//     const {data} = await curAPIClient.get('/CabinetDocuments', {
//       params: filteredParams,
//       middleware: {
//         withAuth: true,
//       },
//     });
//     return data;
//   } catch (e) {
//     console.log(e);
//   }
// };
export const cabinetDocuments = async (
 params: Record<string, any>,
  ssrAPIClient: AxiosInstance,
) => {
  const curAPIClient = ssrAPIClient ?? apiClient;

  try {
    const {data} = await curAPIClient.get('/CabinetDocumentsV2', {
      params,
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const clientDocumentEdit = async ({
  id_document,
  postData,
  wrapperKey,
}: IDocumentAdd) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);

    const data = await apiClient.post('/ClientDocumentEdit', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {id_document},
      middleware: {
        withAuth: true,
      },
    });

    return data.data;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const cabinetDocumentSign = async (params: {
  method:
    | 'GetDocument'
    | 'GetPDF64Action'
    | 'SetSignedDataAction'
    | 'GetContactsAction'
    | 'SESAction'
    | 'SESSignAction';
  id_object: string;
  entity?: string;
  cert?: Array<Record<string, any>>;
  clientSign?: string;
  sesCode?: string;
  id_contact?: string;
}) => {
  const {method, id_object, cert, clientSign, sesCode, id_contact, entity} = params;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('method', method);
  bodyFormData.append('id_object', id_object);
  if (entity) {
    bodyFormData.append('entity', entity);
  }
  if (cert) {
    cert.map(cert => {
      for (const [key, value] of Object.entries(cert)) {
        bodyFormData.append(String(key), String(value));
      }
    });
  }
  if (clientSign) {
    bodyFormData.append('dataCSP', clientSign);
  }
  if (sesCode) {
    bodyFormData.append('ses', sesCode);
  }
  if (id_contact) {
    bodyFormData.append('id_contact', id_contact);
  }

  const data = await apiClient.post('/CabinetDocumentSign', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });

  return data.data;
};
