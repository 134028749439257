import Image from 'next/image';
import React, {FC} from 'react';
import Button from './Button';
import HtmlParser from 'react-html-parser';
import {IconWrapper, Text, Title, ToastData, ToastWrapper} from './Toast.styles';

interface ToastProps {
  type?: 'info' | 'warn' | 'success' | 'error';
  title?: string;
  text?: string;
  btnText?: string;
  btnOnClick?: () => void;
  children?: React.ReactNode;
}

const Toast: FC<ToastProps> = ({
  type = 'info',
  title,
  text,
  btnOnClick,
  btnText,
  children,
}) => {
  return (
    <ToastWrapper>
      <IconWrapper>
        <Image
          src={`/assets/icons/toast_${type}Icon.svg`}
          alt="toastIcon"
          width={18}
          height={18}
        />
      </IconWrapper>
      <ToastData>
        <Title>{HtmlParser(title ?? '')}</Title>
        <Text>{text}</Text>
        {children}
        {btnText && <Button title={btnText} onClick={btnOnClick} variant="text" />}
      </ToastData>
    </ToastWrapper>
  );
};

export default Toast;
