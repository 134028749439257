// import {theme} from '@styles/theme';
import LeaveFeedbackButton from '@components/atoms/LeaveFeedbackButton';
import TechSupportButton from '@components/atoms/TechSupportButton';
import ToTopButton from '@components/atoms/ToTopButton';
import dynamic from 'next/dynamic';
import {useState} from 'react';
import {BottomBtnsWrapper} from './BottomBtns.styles';

const TechSupportModal = dynamic(() => import('@components/molecules/TechSupportModal'));

const BottomBtns = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <BottomBtnsWrapper>
      <ToTopButton />
      <LeaveFeedbackButton />
      <TechSupportButton setIsModalVisible={setIsModalVisible} />
      <TechSupportModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </BottomBtnsWrapper>
  );
};

export default BottomBtns;
