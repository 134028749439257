import {find} from 'lodash';
import apiClient from './entities/apiClient';
import {RoboDealWizardMethodAction} from './types/CabRoboDealWizard';
import {NextRouter} from 'next/router';

const roboDealWizard = async ({
  params,
  postData,
  wrapperKey,
  method,
  signal,
  shouldRedirect,
  router
}: {
  params: any;
  postData?: {[x: string]: string | any};
  wrapperKey?: any;
  method?: RoboDealWizardMethodAction;
  signal?: AbortSignal;
  shouldRedirect?: boolean;
  router: NextRouter;
}) => {
  try {
    const controller = new AbortController();
    const bodyFormData = new URLSearchParams();
    if (postData) {
      for (const field in postData) {
        if (
          field.startsWith('multiselect') ||
          field.startsWith('files') ||
          field.startsWith('file_')
        ) {
          const finalFieldName = field.split('--').pop();
          for (const key in postData[field]) {
            let value = null;
            if (field.startsWith('multiselect')) {
              value = postData[field][key].value;
            }
            if (field.startsWith('files') || field.startsWith('file_')) {
              value = postData[field][key].id;
            }
            bodyFormData.append(`${wrapperKey}[${finalFieldName}][]`, value);
          }
          continue;
        }
        // dyntable mapping
        if (postData[field] instanceof Array) {
          if (postData[field].length === 0) {
            bodyFormData.append(`${wrapperKey}[${field}]`, '[]');
          }
          if (postData[field][0]) {
            for (const i in postData[field]) {
              for (const [key, value] of Object.entries(postData[field][i])) {
                if (!value) {
                  continue;
                }
                bodyFormData.append(
                  `${wrapperKey}[${field}][${key}][${i}]`,
                  Array.isArray(value) ? value[Number(i)] : value,
                );
              }
            }
          }
          continue;
        }
        if (postData[field] === true) {
          bodyFormData.append(`${wrapperKey}[${field}]`, '1');
          continue;
        }
        if (postData[field] === false) {
          bodyFormData.append(`${wrapperKey}[${field}]`, '0');
          continue;
        }
        if (
          typeof postData[field]?.guid !== 'undefined' ||
          typeof postData[field]?.code !== 'undefined'
        ) {
          const dataKladr =
            typeof postData[field] === 'string'
              ? JSON.parse(postData[field])
              : JSON.parse(JSON.stringify(postData[field]));
          if ('text' in dataKladr) {
            delete dataKladr.text;
          }
          bodyFormData.append(`${wrapperKey}[${field}]`, JSON.stringify(dataKladr));
          continue;
        }
        if (wrapperKey) {
          bodyFormData.append(`${wrapperKey}[${field}]`, postData[field]);
        } else {
          bodyFormData.append(`${field}`, postData[field]);
        }
      }
    }
    const res = await apiClient.post(
      '/CabinetDealWizard',
      postData ? bodyFormData : null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        params: {
          ...params,
          method,
        },
        middleware: {
          withAuth: true,
        },
        signal: signal || controller.signal,
      },
    );

    const {data} = res;

    if (data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }

    if (shouldRedirect) {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            step: data?.wizard?.step ?? params.step,
            id_object: data?.wizard?.id_object ?? params.id_object,
          },
        },
        undefined,
        {shallow: true},
      );
    }

    if (data.errors_list) {
      throw new Error(data.errors_list[0].error);
    }

    const currentPage = find(data?.Paginator?.items, {current: '1'});
    const pagination = {
      paginator: data?.Paginator ? data?.Paginator?.nPaginator : null,
      lastLoadedPage: currentPage ? parseInt(currentPage.page) : 1,
      totalPages: data?.Paginator ? parseInt(data?.Paginator?.pages) : 1,
      totalItems: data?.Grid?.count_collection,
    };
    return {
      ...data,
      pagination,
      filters: Object.values(data?.Grid?.items?.GridFilter?.items || {}),
      sort: data?.Grid?.items?.GridHeader?.items || {},
      meta: {
        totalCount: data?.Grid?.count_collection || null,
        formName: data?.Grid?.form_name || null,
      },
    };
  } catch (e) {
    console.log(e);
  }
  
};


const getComissionDocuments = async (
  id_object: string
) => {

  const {data} = await apiClient.get('/CabinetDealWizard/getComissionDocuments', {
    params: {
      id_object,
    },
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

const cabinetDealWizardGetShippingCost = async (id_shipping: string, params: Record<string, string>) => {
  const bodyFormData = new URLSearchParams();
  for (const key in params) {
    bodyFormData.append(key, params[key]);
  }
  const {data} = await apiClient.post(
    '/CabinetDealWizard/GetShippingCost',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        id_shipping
      },
      middleware: {
        withAuth: true,
      },
    },
  );
  return data;
};

export const roboDealWizardAPI = {
  roboDealWizard,
  getComissionDocuments,
  cabinetDealWizardGetShippingCost
};
