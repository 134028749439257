import {CatalogProductCalcResult} from '@api/types/CatalogProduct';
import {CalcResult} from '@api/types/MainPage';
import {IPartnersShipDocument, ISupplierDocument} from '@api/types/Providers';
import {IWizardField} from '@api/types/Wizard';
import {Config} from '@config/api';
import {CabinetServicesDictionary} from '@api/types/CabinetServices';
import {format, parse, isValid} from 'date-fns';
import {ICabAppsWizardField} from '@api/types/CabAppsWizard';
import capitalize from 'lodash/capitalize';
import {addYears, subYears} from 'date-fns';
const limitStr = (str: string, n: number, symbol?: string): string => {
  if (!n && !symbol) return str;
  symbol = symbol || '...';
  if (str?.length <= n) {
    return str;
  }
  return str.substr(0, n - symbol.length) + symbol;
};

const divideNumber = (str: number | string) => {
  if (!str) {
    return '';
  }
  return String(str).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
};

const returnTimesInBetween = (start: string, end: string) => {
  const timesInBetween = [];
  let startH = parseInt(start.split(':')[0]);
  const startM = parseInt(start.split(':')[1]);
  const endH = parseInt(end.split(':')[0]);
  const endM = parseInt(end.split(':')[1]);

  if (startM == 30 || startM === 45) startH++;
  for (let i = startH; i <= endH; i++) {
    timesInBetween.push(i < 10 ? '0' + i + ':00' : i + ':00');
    timesInBetween.push(i < 10 ? '0' + i + ':30' : i + ':30');
  }
  if (endM == 30) timesInBetween.push(endH + ':30');
  if (endM == 45) timesInBetween.push(endH + ':45');

  if (timesInBetween[timesInBetween.length - 1] !== end) {
    timesInBetween.pop();
    timesInBetween[timesInBetween.length - 1] = end;
  }
  return timesInBetween;
};

const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const russianPhoneRegExp =
  /^(\+7|7|8)?[\s\-]?\(?[0-9][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;

const formatPhone = (phone: string) =>
  `${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)}-${phone.slice(
    7,
    9,
  )}-${phone.slice(9, 11)}`;

const formatPhoneV2 = (phone: string) =>
  `${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(5, 8)} ${phone.slice(
    8,
    10,
  )} ${phone.slice(10, 12)}`;

const formatDate = (
  date: string | undefined,
  type: 'all' | 'formBuilder' = 'all',
  formatType?: string,
) => {
  if (!date || date === 'Invalid Date') {
    return '';
  }
  let dateFormated = null;
  if (type === 'all') {
    const newDate = new Date(date);
    dateFormated = new Intl.DateTimeFormat('ru-RU').format(newDate);
  }
  if (type === 'formBuilder') {
    const dateFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];
    try {
      dateFormated = format(
        dateFormats.map(t => parse(date, t, new Date())).find(t => isValid(t))!,
        formatType ?? 'yyyy-MM-dd',
      );
    } catch (e) {
      console.log(e);
      return;
    }
  }

  return dateFormated;
};

const parseDate = (date: string) => {
  const dateFormats = ['yyyy-MM-dd', 'dd.MM.yyyy'];
  let dateParsed = null;
  dateParsed = dateFormats.map(t => parse(date, t, new Date())).find(t => isValid(t))!;

  return dateParsed;
};

const formatStringToDate = (dateString?: string | string[] | boolean) => {
  if (typeof dateString !== 'string') {
    return null;
  }
  return parse(dateString, 'dd.MM.yyyy', new Date());
};

const formatDateWithLongMonth = (dateString: string) => {
  const options = {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
  } as const;
  const date = new Date(dateString);
  return date.toLocaleString('ru-RU', options);
};

const getSizes = () => {
  const mobileSizesArr = [];
  const desktopSizesArr = [];

  for (let i = 500; i < 1200; i++) {
    if (i % 50 === 0) {
      desktopSizesArr.push({currentWidth: i, windowSize: i - 100});
    }
  }
  for (let i = 0; i < 600; i++) {
    if (i === 320 || (i > 270 && i % 50 === 0)) {
      mobileSizesArr.push({currentWidth: i, windowSize: i - 55});
    }
  }

  const allSizesArr: {currentWidth: number; windowSize: number}[] = [
    ...mobileSizesArr,
    ...desktopSizesArr,
  ];
  return allSizesArr;
};

const formatDateTime = (date: string) => {
  const newDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const dateFormated = new Intl.DateTimeFormat('ru-RU', options).format(newDate);

  return dateFormated;
};

const formatDayMonth = (date: string | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };

  try {
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
  } catch (e) {
    return String(date);
  }
};

const formatDayMonthYear = (date: string | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  try {
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
  } catch (e) {
    return String(date);
  }
};

const formatTime = (date: string | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };
  try {
    return new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));
  } catch (e) {
    return '';
  }
};

const formatTimeToMMSS = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const formattedSeconds =
    remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;
  return formattedMinutes + ':' + formattedSeconds;
};

function yearsToStr(age: number) {
  let txt;
  let count;
  count = age % 100;
  if (count >= 5 && count <= 20) {
    txt = 'лет';
  } else {
    count = count % 10;
    if (count == 1) {
      txt = 'год';
    } else if (count >= 2 && count <= 4) {
      txt = 'года';
    } else {
      txt = 'лет';
    }
  }
  return `${txt}`;
}

function dayToStr(number: number | undefined) {
  if (!number) {
    return '';
  }

  if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return 'дней';
  const last_num = number % 10;
  if (last_num == 1) return 'день';
  if ([2, 3, 4].includes(last_num)) return 'дня';
  if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'дней';
}

function photosToStr(number: number) {
  if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return 'фотографий';
  const last_num = number % 10;
  if (last_num == 1) return 'фотография';
  if ([2, 3, 4].includes(last_num)) return 'фотографии';
  if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'фотографий';
}

function calcResultMapper(
  calcResult: CatalogProductCalcResult | CalcResult,
): Partial<CalcResult> {
  return {
    each_payment: +calcResult.each_payment
      ? +calcResult.each_payment
      : +calcResult.payment_from_rub,
    riseInPricePrcY: calcResult.riseInPricePrcY,
    order_summ: calcResult.order_summ
      ? String(calcResult.order_summ)
      : divideNumber(String(calcResult.acquisition_cost_rub ?? '')),
    taxBenefit: calcResult.taxBenefit,
    taxEconomy: calcResult.taxEconomy,
    taxRefund: calcResult.taxRefund,
  };
}

function documentMapper(
  documentsList?: Partial<ISupplierDocument[]> | Partial<IPartnersShipDocument[]>,
) {
  const mappedResult = documentsList?.map(document => {
    return {
      title: document?.title,
      url: document?.document_url
        ? `${Config.BASE_URL}/${document.document_url}`
        : document?.src,
      mimeType: document?.mime_type ? document.mime_type : null,
    };
  });
  return mappedResult;
}

function numWord(value: number, words: string[]) {
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

const getAlphabetFromRegionsList = (townList: any[]) => {
  const alphabet: {title: string; value: string}[] = townList.map(_ => {
    return {title: _.letter, value: _.letter};
  });
  return alphabet;
};

const createShareLink = (socialNetwork: string, contentTitle: string) => {
  const currentShareLink =
    typeof window !== 'undefined' && window.location.href ? window.location.href : '';
  switch (socialNetwork) {
    case 'Telegram':
      return `https://t.me/share/url?url=${currentShareLink}&text=${contentTitle}`;
    case 'Whatsapp':
      return `https://wa.me/?text=${contentTitle}${currentShareLink}`;
    case 'ВКонтакте':
      return `https://vk.com/share.php?url=${currentShareLink}&title=${contentTitle}`;
    case 'Однокласcники':
      return `https://connect.ok.ru/offer?url=${currentShareLink}&title=${contentTitle}`;
    default:
      return '#';
  }
};

const splitArrayBySeparator = (arr: IWizardField[]): IWizardField[][] => {
  const result = [];
  let currentArray = [];

  for (const obj of arr) {
    if (obj.type === 'separator') {
      if (currentArray.length > 0) {
        result.push(currentArray);
        currentArray = [];
      }
    }
    currentArray.push(obj);
  }

  if (currentArray.length > 0) {
    result.push(currentArray);
  }

  return result;
};

const splitArrayByType = (arr: IWizardField[], type: string): IWizardField[][] => {
  const result = [];
  let currentArray = [];

  for (const obj of arr) {
    if (obj.type === type) {
      if (currentArray.length > 0) {
        result.push(currentArray);
        currentArray = [];
      }
    }
    currentArray.push(obj);
  }

  if (currentArray.length > 0) {
    result.push(currentArray);
  }

  return result;
};

const groupGroupableFields = (arr: IWizardField[]): IWizardField[] => {
  const groups = arr.filter(item => item.field && item.field.startsWith('group_'));

  const fields = arr.filter(item => item.field && item.field.startsWith('field_'));

  const groupFields = new Map(
    groups.map(group => {
      const groupIdx = group.field.split('_')[1];
      const inputsKeys = group.field
        .split('_')
        .slice(2)
        .map(key => `field_${groupIdx}_${key}`);
      const inputs = fields.filter(input => inputsKeys.includes(input.field));
      return [
        group.field,
        {type: 'group', fields: [group, ...inputs]} as unknown as IWizardField,
      ];
    }),
  );
  const res = arr
    .map((item: IWizardField) => {
      if (
        !item.field ||
        (!item.field.startsWith('group_') && !item.field.startsWith('field_'))
      )
        return item;
      if (item.field.startsWith('group_') && groupFields.has(item.field))
        return groupFields.get(item.field);
      return null;
    })
    .flatMap(item => item ?? []);

  return res;
};

function getRegDataFieldsArrByTab(arr: IWizardField[], tab: string): IWizardField[] {
  if (tab === 'organization') {
    const index = arr.findIndex(obj => obj.title === 'Руководитель');
    return index !== -1 ? arr.slice(0, index) : arr;
  } else {
    const index = arr.findIndex(obj => obj.title === 'Руководитель');
    return index !== -1 ? arr.slice(index) : arr;
  }
}

function formatNumberToShort(value: number) {
  const suffixes = ['', 'K', 'M', 'B']; // Суффиксы для чисел

  let suffixIndex = 0;
  while (value >= 1000 && suffixIndex < suffixes.length - 1) {
    value /= 1000;
    suffixIndex++;
  }

  return value < 1000000
    ? value + suffixes[suffixIndex]
    : value.toFixed(1) + suffixes[suffixIndex];
}

function getRussianMonth(date: string) {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  const [year, month] = date.split('-');

  const russianMonth =
    months[Number(month) - 1] ||
    'Неверный ввод. Пожалуйста, укажите дату в формате YYYY-MM.';
  const result = `${russianMonth} ${year}`;
  return result;
}

function cleanObject(obj: Record<string, any>) {
  const cleanedObject: Record<string, any> = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      cleanedObject[key] = obj[key];
    }
  }

  return cleanedObject;
}

const formatArrayForSelect = (items: any, value_key = 'id', addDefaultOption = false) => {
  if (!items) return [];

  const formattedItems = items.map((item: any, id: string) => {
    return {title: item.title, value: String(item[value_key])};
  });

  if (addDefaultOption) {
    formattedItems.unshift({title: 'Не выбрано', value: '0'});
  }

  return formattedItems;
};

const formatArrayForSelectFromDictionaries = (
  dictionaries: CabinetServicesDictionary[],
  code: string,
) => {
  for (const key in dictionaries) {
    if (dictionaries[key].code === code) {
      return formatArrayForSelect(dictionaries[key].items, 'code');
    }
  }
  return [];
};

const pluralize = (number: number, labels: {one: string; some: string; many: string}) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return labels.many;
  }
  n %= 10;
  if (n === 1) {
    return labels.one;
  }
  if (n >= 2 && n <= 4) {
    return labels.some;
  }
  return labels.many;
};

const formatRequiredItemsRegData = (
  items?: IWizardField[],
): {type: string; required: boolean | string; field: string}[] => {
  if (!items?.length) {
    return [];
  }
  return items
    ?.filter(item => item.required || item.error)
    .map(item => ({
      field: item.field,
      type: item.type,
      validator: item.validator || false,
      required: item.required || false,
      error: item.error || false,
    }));
};

const bodyFormDataBuilder = (
  postData?: {[x: string]: string | any},
  wrapperKey?: any,
) => {
  if (!postData || !wrapperKey) {
    return null;
  }
  const bodyFormData = new FormData();
  for (const field in postData) {
    if (
      field.startsWith('multiselect') ||
      field.startsWith('files') ||
      field.startsWith('file_')
    ) {
      const finalFieldName = field.split('--').pop();
      for (const key in postData[field]) {
        let value = null;
        if (field.startsWith('multiselect')) {
          value = postData[field][key].value;
        }
        if (field.startsWith('files') || field.startsWith('file_')) {
          value = postData[field][key].id;
        }
        bodyFormData.append(`${wrapperKey}[${finalFieldName}][]`, value);
      }
      continue;
    }
    if ((field.startsWith('Scans') || field.endsWith('Scans')) && field?.length) {
      postData[field].map((scan: {id: string}, idx: number) =>
        bodyFormData.append(`${wrapperKey}[${field}][]`, scan.id),
      );
      continue;
    }
    if (field === 'contract_image') {
      bodyFormData.append(`${wrapperKey}[${field}][id]`, postData[field][0].id);
      continue;
    }
    if (field.startsWith('ids_F') && field?.length) {
      postData[field].map((scan: {id: string}, idx: number) =>
        bodyFormData.append(`${wrapperKey}[${field}][${idx}]`, scan.id),
      );
      continue;
    }
    // dyntable mapping
    if (postData[field] instanceof Array) {
      if (postData[field].length === 0) {
        bodyFormData.append(`${wrapperKey}[${field}]`, '[]');
      }
      if (postData[field][0]) {
        for (const i in postData[field]) {
          for (const [key, value] of Object.entries(postData[field][i])) {
            if (!value) {
              continue;
            }
            bodyFormData.append(
              `${wrapperKey}[${field}][${key}][${i}]`,
              Array.isArray(value) ? value[Number(i)] : value,
            );
          }
        }
      }
      continue;
    }
    if (postData[field] === true) {
      bodyFormData.append(`${wrapperKey}[${field}]`, '1');
      continue;
    }
    if (postData[field] === false) {
      bodyFormData.append(`${wrapperKey}[${field}]`, '0');
      continue;
    }
    if (
      typeof postData[field]?.guid !== 'undefined' ||
      typeof postData[field]?.code !== 'undefined'
    ) {
      const dataKladr =
        typeof postData[field] === 'string'
          ? JSON.parse(postData[field])
          : JSON.parse(JSON.stringify(postData[field]));
      if ('text' in dataKladr) {
        delete dataKladr.text;
      }
      bodyFormData.append(`${wrapperKey}[${field}]`, JSON.stringify(dataKladr));
      continue;
    }
    if (postData[field]?.ischecked) {
      bodyFormData.append(
        `${wrapperKey}[${field}][ischecked]`,
        postData[field].ischecked,
      );
      bodyFormData.append(`${wrapperKey}[${field}][text]`, postData[field].text);
      continue;
    }
    if (postData[field]?.yes) {
      const value = postData[field].yes;
      bodyFormData.append(
        `${wrapperKey}[${field}]`,
        typeof value === 'boolean' ? (value ? '1' : '0') : value,
      );
      continue;
    }
    if (postData[field]?.no) {
      const value = postData[field].no;
      bodyFormData.append(
        `${wrapperKey}[${field}_ne]`,
        typeof value === 'boolean' ? (value ? '1' : '0') : value,
      );
      continue;
    }
    bodyFormData.append(`${wrapperKey}[${field}]`, postData[field]);
  }
  return bodyFormData;
};

const addThousandSeparator = (number: string | number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const cookieExpiredUTC = (dateString: string | null) => {
  const matchResult = dateString?.match(/\d+/g);
  if (!matchResult) {
    // если бека не пришло, то ставим max-age=31536000 (365 дней)
    return 31536000;
  }

  const [day, month, year, hours, minutes, seconds] = matchResult.map(Number);
  const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  // -3 часа, так как с бэка приходит время по МСК
  utcDate.setUTCHours(utcDate.getUTCHours() - 3);

  return Math.round((utcDate.getTime() - Date.now()) / 1000);
};
const addBeneficiaryQuestionnaireSeparators = (
  items: Record<string, any>[],
  step: number,
) => {
  const firstSeparator =
    step === 1
      ? {type: 'separator', title: 'Кто заполняет анкету?', field: 'first_separator'}
      : null;

  let transformedItems = [...items];

  ['qf_02_09', 'qf_02_10', 'qf_02_11', 'qf_10_09', 'qf_10_10', 'qf_10_11'].forEach(
    key => {
      const index = transformedItems.findIndex(item => item.field === key);
      if (index !== -1) {
        const item = transformedItems[index];
        const title = item.title.split('<br/><small>')[0];
        const tooltip = item.title.split('<br/><small>')[1];
        transformedItems = [
          ...transformedItems.slice(0, index),
          {type: 'separator', title, tooltip, field: `${index}_serparator`},
          {...item, title: ''},
          ...transformedItems.slice(index + 1),
        ];
      }
    },
  );

  ['qf_02_29', 'qf_02_30', 'qf_02_31', 'qf_02_32'].forEach(key => {
    const index = transformedItems.findIndex(item => item.field === key);
    if (index !== -1) {
      const item = transformedItems[index];
      transformedItems = [
        ...transformedItems.slice(0, index),
        {
          type: 'separator',
          title: item.title,
          field: `${index}_serparator`,
          no_indicator: true,
        },
        {...item, title: ''},
        ...transformedItems.slice(index + 1),
      ];
    }
  });
  const step2InfoBoxIndex = transformedItems.findIndex(item => item.field === 'qf_02_27');
  if (step2InfoBoxIndex !== -1) {
    const item = transformedItems[step2InfoBoxIndex];
    const title = item.title.split('<br /><small>')[0];
    const infoboxText = item.title.split('<br /><small>')[1];
    transformedItems = [
      ...transformedItems.slice(0, step2InfoBoxIndex),
      {type: 'infobox', title: infoboxText, field: `${step2InfoBoxIndex}_info`},
      {...item, title},
      ...transformedItems.slice(step2InfoBoxIndex + 1),
    ];
  }

  return [firstSeparator, ...transformedItems].filter(item => item);
};
const formatBeneficiaryQuestionnaireField = (field: ICabAppsWizardField) => {
  if (
    ['qf_00_01', 'qf_00_02', 'qf_00_03', 'qf_00_04', 'qf_00_05'].includes(field.field)
  ) {
    return {
      ...field,
      title: capitalize(field.title),
      col: '12',
    };
  }
  if (field.field === 'qf_00_06') {
    return {
      ...field,
      title: 'Данные физического лица',
    };
  }
  if (
    [
      'qf_01_01',
      'qf_01_02',
      'qf_01_03',
      'qf_01_04',
      'qf_01_06_02',
      'qf_01_06_03',
    ].includes(field.field)
  ) {
    return {
      ...field,
      col: '6',
    };
  }

  if (field.field === 'qf_02_01') {
    return {
      ...field,
      title: 'Данные документа',
      text_before: field.title,
    };
  }
  if (field.field === 'qf_10_08') {
    return {
      ...field,
      title: 'Сведения о доменном имени',
      text_before: field.title,
    };
  }

  if (field.type === 'cbtext' && field.value) {
    const readyValue =
      typeof field.value === 'string' ? JSON.parse(field.value) : field.value;
    return {
      ...field,
      value: readyValue,
    };
  }

  if (field.type === 'dyntable') {
    return {
      ...field,
      type: 'alt_dyntable',
      min_date: String(subYears(new Date(), 100)),
      max_date: String(new Date()),
    };
  }

  if (field.field === 'qf_02_28') {
    return {
      ...field,
      type: '',
    };
  }
  return {
    ...field,
    col: '12',
  };
};

const formatCreditDeclarationField = (field: ICabAppsWizardField) => {
  if (field.type === 'dyntable') {
    return {
      ...field,
      type: 'alt_dyntable',
      min_date: String(subYears(new Date(), 50)),
      max_date: String(addYears(new Date(), 50)),
      col: '12',
      noCheckbox: true,
    };
  }

  if (
    [
      'questioning_field_005',
      'questioning_field_006',
      'questioning_field_008',
      'questioning_field_009',
    ].includes(field.field)
  ) {
    return {
      ...field,
      col: '12',
    };
  }

  return {
    ...field,
    col: '12',
  };
};

const groupCreditDeclarationCheckboxes = (fields: ICabAppsWizardField[]) => {
  return fields
    .map(field => {
      if (
        [
          'questioning_field_005',
          'questioning_field_006',
          'questioning_field_008',
          'questioning_field_009',
        ].includes(field.field)
      ) {
        const opposite = fields.find(opField => opField.field === `${field.field}_ne`);
        return {
          ...field,
          type: 'linked_checkboxes',
          value: {
            yes: field.value,
            no: opposite?.value ?? '0',
          },
          opposite,
        };
      }
      if (
        [
          'questioning_field_005_ne',
          'questioning_field_006_ne',
          'questioning_field_008_ne',
          'questioning_field_009_ne',
        ].includes(field.field)
      ) {
        return null;
      }
      return field;
    })
    .filter(field => field);
};

const numberWithSpaces = (sum: number | string, minFractionDigits = 0) => {
  const number = parseFloat(sum.toString());
  return number.toLocaleString('ru-RU', {
    minimumFractionDigits: minFractionDigits,
  });
};

export {
  limitStr,
  divideNumber,
  returnTimesInBetween,
  russianPhoneRegExp,
  formatPhone,
  declOfNum,
  formatDate,
  parseDate,
  formatDateWithLongMonth,
  formatDateTime,
  formatStringToDate,
  yearsToStr,
  dayToStr,
  photosToStr,
  getSizes,
  calcResultMapper,
  numWord,
  documentMapper,
  getAlphabetFromRegionsList,
  createShareLink,
  splitArrayBySeparator,
  splitArrayByType,
  formatDayMonth,
  formatTime,
  getRegDataFieldsArrByTab,
  formatNumberToShort,
  getRussianMonth,
  cleanObject,
  formatPhoneV2,
  formatDayMonthYear,
  pluralize,
  formatRequiredItemsRegData,
  formatArrayForSelect,
  formatArrayForSelectFromDictionaries,
  bodyFormDataBuilder,
  formatTimeToMMSS,
  addThousandSeparator,
  cookieExpiredUTC,
  formatBeneficiaryQuestionnaireField,
  addBeneficiaryQuestionnaireSeparators,
  formatCreditDeclarationField,
  groupCreditDeclarationCheckboxes,
  numberWithSpaces,
  groupGroupableFields,
};
