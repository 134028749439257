import find from 'lodash/find';
import apiClient from '@api/entities/apiClient';
import {Dispatch, SetStateAction} from 'react';
import {bodyFormDataBuilder} from '@utils/formatters';
import { NextRouter } from 'next/router';

const applicationWizard = async ({
  params,
  postData,
  wrapperKey,
  router
}: {
  params: any,
  postData?: {[x: string]: string | any},
  wrapperKey?: any,
  router: NextRouter
}) => {
  try {
    const bodyFormData = bodyFormDataBuilder(postData, wrapperKey);
    const res = await apiClient.post('/CabinetApplicationWizard', bodyFormData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });

    const {data} = res;

    if (data.errcode === 'agreements') {
      router.push('/pd/');
      return;
    }
  
    if (data.errors_list) {
      throw new Error(data.errors_list[0].error);
    }

    const currentPage = find(data?.Paginator?.items, {current: '1'});
    const pagination = {
      paginator: data?.Paginator ? data?.Paginator?.nPaginator : null,
      lastLoadedPage: currentPage ? parseInt(currentPage.page) : 1,
      totalPages: data?.Paginator ? parseInt(data?.Paginator?.pages) : 1,
      totalItems: data?.PublicGrid?.count_collection,
    };
    return {
      ...data,
      pagination,
      filters: Object.values(data?.PublicGrid?.items?.GridFilter?.items || {}),
      sort: data?.PublicGrid?.items?.GridHeader?.items || {},
      meta: {
        totalCount: data?.PublicGrid?.count_collection || null,
        formName: data?.PublicGrid?.form_name || null,
      },
    };
  } catch (e) {
    console.log(e);
  }
};

const getComplect = async (params: any) => {
  try {
    const {data} = await apiClient.get('/GetComplect', {
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

const applicationSetProducts = async (params: any, body: any) => {
  try {
    const {data} = await apiClient.post('/CabinetApplicationSetProducts', body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

const applicationUnSetProducts = async (params: any) => {
  try {
    const {data} = await apiClient.put('/CabinetApplicationUnSetProducts', null, {
      params: {
        ...params,
      },
      middleware: {
        withAuth: true,
      },
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

const uploadFile = async (
  file: {fileName: string | Blob; base64: string | Blob; origBase64?: string | Blob},
  setProgress: Dispatch<SetStateAction<number>>,
) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('filename', file.fileName);
    bodyFormData.append('content', file.base64);
    const {
      data: {id, title, mime_type, success, error_message, src},
    } = await apiClient.post('/SetFile', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      middleware: {
        withAuth: true,
      },
      onUploadProgress: progressEvent => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(progress);
      },
    });
    if (success) {
      return {
        title: title || '',
        id: id || null,
        mime_type: mime_type || '',
        error_message: null,
        src
      };
    } else {
      return {
        title: '',
        id: null,
        mime_type: '',
        error_message
      };
    }
  } catch (e) {
    console.log(e);
    return {
      title: '',
      id: null,
      mime_type: '',
    };
  }
};

const cabinetApplicationWizardGetShippingCost = async (id_shipping: string, params: Record<string, string>) => {
  const bodyFormData = new URLSearchParams();
  for (const key in params) {
    bodyFormData.append(key, params[key]);
  }
  const {data} = await apiClient.post(
    '/CabinetApplicationWizard/GetShippingCost',
    bodyFormData,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        id_shipping,
      },
      middleware: {
        withAuth: true,
      },
    },
  );
  return data;
};

const cabinetApplicationSign = async (params: {
  method:
    | 'initaction'
    | 'checkreadyaction'
    | 'getpdf64action'
    | 'setsigneddataaction'
    | 'getcontactsaction'
    | 'sesaction'
    | 'signsesaction'
    | 'checkaction'
    | 'SESSignAction';
  id_object: string;
  docCode?: string;
  cert?: Array<Record<string, any>>;
  clientSign?: string;
  sesCode?: string;
  id_contact?: string;
  leasCase?: string;
  entity?: string;
  sale?: boolean;
}) => {
  const {
    leasCase,
    method,
    id_object,
    docCode,
    cert,
    clientSign,
    sesCode,
    id_contact,
    entity,
    sale,
  } = params;
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('method', method);
  bodyFormData.append('id_object', id_object);
  if (docCode) {
    bodyFormData.append('code', docCode);
  }
  if (cert) {
    cert.map(cert => {
      if (cert) {
        for (const [key, value] of Object.entries(cert)) {
          bodyFormData.append(String(key), String(value));
        }
      }
    });
  }
  if (clientSign) {
    bodyFormData.append('data', clientSign);
  }
  if (sesCode) {
    bodyFormData.append('ses', sesCode);
  }
  if (id_contact) {
    bodyFormData.append('id_contact', id_contact);
  }
  if (leasCase) {
    bodyFormData.append('case', leasCase);
  }
  if (entity) {
    bodyFormData.append('entity', entity);
  }
  if (sale) {
    bodyFormData.append('sale', 'true');
  }

  const {data} = await apiClient.post('/CabinetApplicationSign', bodyFormData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    middleware: {
      withAuth: true,
    },
  });

  return data;
};

export const requestWizardAPI = {
  applicationWizard,
  getComplect,
  applicationSetProducts,
  applicationUnSetProducts,
  uploadFile,
  cabinetApplicationSign,
  cabinetApplicationWizardGetShippingCost
};
