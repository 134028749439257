import {IWizardField} from '@api/types/Wizard';
import {requestWizardMappings} from '@api/mappings/requestWizard';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IDLE, PENDING} from '@constants/loadingState';

interface RegDataSliceState {
  loading: string;
  regions: {code: string; title: string}[];
  activeTab: string;
  initialValues: Record<string, any>;
  fields: IWizardField[][];
  isPD: boolean;
  errors: Record<string, any>[];
}

export const setRegDataValues2 = createAsyncThunk(
  'regData/setRegDataValues2',
  async (payload: {
    success: boolean;
    errors_list: {prop: string, error: string}[];
    fields: Record<string, IWizardField>;
    isPD: boolean
  }) => payload
);

const initialState: RegDataSliceState = {
  loading: PENDING,
  regions: [],
  initialValues: {},
  activeTab: '',
  fields: [],
  isPD: false,
  errors: [],
};

const regDataSlice = createSlice({
  name: 'regData',
  initialState,
  reducers: {
    setRegions(state, action: PayloadAction<{code: string; title: string}[]>) {
      state.regions = action.payload;
    },
    changeFormInitialValue(state, action) {
      const {payload} = action;
      state.initialValues = payload.values;
    },
  },
  extraReducers: builder => {
    builder.addCase(setRegDataValues2.fulfilled, (state, action) => {
      const {payload} = action;
      state.loading = IDLE;
      if (payload.success) {
        const fields: any[] = Object.entries(payload.fields).map(([id, field]) => field);
        state.fields = [fields];
        state.initialValues = requestWizardMappings.initialValuesRegDataMapper(fields);
        state.isPD = payload.isPD;
      } else {
        state.errors = payload?.errors_list?.map(({prop, error}: any) => 
          ({field: prop, error: error})
        );
      }
    });
  },
});

export const {changeFormInitialValue, setRegions} = regDataSlice.actions;
export default regDataSlice.reducer;
