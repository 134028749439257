import {fontStyles, theme} from '@styles/theme';
import styled from 'styled-components';

export const ToastWrapper = styled.div`
  display: flex;
  gap: 12px;
  position: relative;
  z-index: 9999999999;
`;

export const IconWrapper = styled.div``;

export const ToastData = styled.div``;

export const Title = styled.div`
  ${fontStyles.title5}
  &:empty {
    margin-top: -5px;
  }
`;

export const Text = styled.div`
  ${fontStyles.mainText1}
  color: ${theme.colors.darkText};
  margin-top: 5px;
`;
