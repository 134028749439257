import {requestWizardMappings} from '@api/mappings/requestWizard';
import {IDLE, PENDING} from '@constants/loadingState';
import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '@store';
import {cabOrderConfirmationWizardAPI} from '@api/cabOrderConfirmationWizard';
import {ICabOrderConfirmationWizardInitialState} from '@api/types/CabOrderConfirmationWizard';
import {cloneDeep} from 'lodash';

const initialState: ICabOrderConfirmationWizardInitialState = {
  loading: IDLE,
  stepsAmount: 0,
  wizard: {
    step: null,
    maxstep: null,
    nextstep: null,
    finished: undefined,
    steps: {},
    id_object: '0'
  },
  Grid: {},
  stepNow: '1',
  ctrl: '',
  screens: {},
  csrfToken: undefined,
};

export const cabOrderConfirmationCreateWizard = createAsyncThunk<
  Record<string, unknown>,
  {
    params: any;
    postData?: Record<string, unknown>;
    activeNomenclatureData?: string[];
    wrapperKey: string;
    nextStepRequest?: boolean;
    id_object?: string;
    id_order?: string;
    csrfToken?: {field: string; token: string};
  },
  {state: RootState}
>(
  'cabOrderConfirmationWizard/fetch',
  async (
    {params, postData, wrapperKey, nextStepRequest = true, id_object, id_order},
    thunkAPI,
  ) => {
    const {cabOrderConfirmationWizard} = thunkAPI.getState();
    let data;
    if (postData) {
      data = await cabOrderConfirmationWizardAPI.cabinetSetSubleasingContract(
        {
          id_object: id_object || undefined,
          id_order: id_order || undefined,
          ...params,
        },
        postData,
        wrapperKey,
        cabOrderConfirmationWizard?.csrfToken,
      );
    }

    /*
     * Если отправка данных текущего шага выполнилась без ошибок то вернется data.wizard.step,
     * значит можно переходить на следующий шаг.
     * Ниже запрашиваем поля для следующего шага
     */
    if (data?.wizard?.step && nextStepRequest) {
      const params = {
        id_object: id_object || undefined,
        id_order: id_order || undefined,
        step: data?.wizard?.step,
      };
      thunkAPI.dispatch(
        cabOrderConfirmationCreateWizard({
          params,
          postData: {},
          wrapperKey: '',
          nextStepRequest: false,
        }),
      );
    }
    return data;
  },
);

const cabOrderConfirmationWizardSlice = createSlice({
  name: 'cabOrderConfirmationWizard',
  initialState: initialState,
  reducers: {
    changeStepNow(state, action) {
      state.stepNow = action.payload;
    },

    makeNomenclatureSelected(state, action: PayloadAction<{id: number; value: boolean}>) {
      const item = state.Grid.items.GridCollection.items.find(
        (item: {id: string}) => item.id === String(action.payload.id),
      );
      if (item) {
        item.is_selected = action.payload.value;
      }
    },

    setInitialValues(state, action) {
      const {payload} = action;
      if (!!state.screens[state.stepNow]) {
        state.screens[state.stepNow].initialValues = payload.values;
      }
    },

    dropState(state) {
      return cloneDeep(initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(cabOrderConfirmationCreateWizard.pending, state => {
      state.loading = PENDING;
    });
    builder.addCase(
      cabOrderConfirmationCreateWizard.fulfilled,
      (state, action: PayloadAction<any, string, any>) => {
        const {payload} = action;
        if (payload?.wizard?.step) {
          state.stepNow = payload?.wizard?.step;
        }
        state.wizard = payload?.wizard;
        state.ctrl = payload?.ctrl;
        state.Grid = payload?.Grid;
        state.success = payload?.success;
        state.stepsAmount = Object.keys(payload?.wizard?.steps ?? {}).length;

        if (!state.screens[state.stepNow]) {
          state.screens[state.stepNow] = {};
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.initialValuesMapper(payload?.FormBuilder?.items) || {};
        } else {
          state.screens[state.stepNow].initialValues =
            requestWizardMappings.changeInitialValuesMapper(
              state.screens[state.stepNow].initialValues,
              payload?.FormBuilder?.items,
            ) || {};
        }
        state.screens[state.stepNow].ctrl = payload?.ctrl;
        state.screens[state.stepNow].items = payload?.FormBuilder?.items ?? [];

        const formBuilder = payload?.FormBuilder;
        if (formBuilder) {
          const csrfItem = formBuilder?.items?.find(
            (el: any) => el?.type === 'csfr-token',
          );
          if (csrfItem) {
            state.csrfToken = {field: csrfItem?.field, token: csrfItem?.token};
          }
        }

        if (!payload?.wizard?.step) {
          state.loading = IDLE;
        }
      },
    );
    builder.addCase(cabOrderConfirmationCreateWizard.rejected, state => {
      state.loading = IDLE;
    });
  },
});

const {actions, reducer} = cabOrderConfirmationWizardSlice;

export const {changeStepNow, makeNomenclatureSelected, setInitialValues, dropState} =
  actions;

export default reducer;
