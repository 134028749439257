import DownloadFileByCode from '@components/atoms/DownloadFileByCode';
import {markets} from '@mockData/markets';
import {socialIcons} from '@mockData/socialIcons';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {
  FooterCopyrightWrapper,
  CopyrightInfo,
  AboutText,
  PolicyFiles,
  ContactText,
  CopyrightText,
  Links,
  MobileAppLinks,
  LogoImageWrapper,
  SocialLinks,
  IconImageWrapper,
} from './FooterCopyright.styles';

const FooterCopyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterCopyrightWrapper data-testid={'copyrightContainer'}>
      <CopyrightInfo>
        <AboutText>
          <p>
            АО «Росагролизинг» создано в 2001 году для решения задач, поставленных
            Правительством Российской Федерации по развитию и модернизации отечественного
            АПК, при этом 100% акций компании принадлежит государству.
          </p>
          <PolicyFiles>
            <p>
              <DownloadFileByCode code="personal_information">
                Политика в области обработки и защиты персональных данных
              </DownloadFileByCode>
            </p>
            <p>
              <DownloadFileByCode code="soglasie_na_obrabotku_personalnykh_dannykh">
                Согласие на обработку персональных данных
              </DownloadFileByCode>
            </p>
          </PolicyFiles>
        </AboutText>
        <ContactText>
          <p>125252, г. Москва, а/я 112</p>
          <p>125167, г. Москва, ул. Викторенко, дом 9, стр. 1</p>
          <p>8 800 200 5395 (по РФ бесплатно)</p>
          <Link href="mailto:info@rosagroleasing.ru" passHref>
            info@rosagroleasing.ru
          </Link>
        </ContactText>
        <CopyrightText>{`© 2001—${currentYear}. Все права защищены. АО «Росагролизинг»`}</CopyrightText>
      </CopyrightInfo>
      <Links>
        <MobileAppLinks>
          {markets.map((market, idx) => (
            <LogoImageWrapper key={idx}>
              <Link href={market.link} passHref target="_blank">
                <Image
                  src={market.icon}
                  layout="fill"
                  objectFit="contain"
                  alt={`Изображение ${market.title}`}
                  title={market.title}
                />
              </Link>
            </LogoImageWrapper>
          ))}
        </MobileAppLinks>
        <SocialLinks>
          {socialIcons.map((social, idx) => (
            <IconImageWrapper key={idx}>
              <Link href={social.link} passHref target="_blank">
                <Image
                  src={social.icon}
                  layout="intrinsic"
                  width={16}
                  height={16}
                  objectFit="contain"
                  alt={`Изображение ${social.title}`}
                  title={social.title}
                />
              </Link>
            </IconImageWrapper>
          ))}
        </SocialLinks>
      </Links>
    </FooterCopyrightWrapper>
  );
};

export default FooterCopyright;
