import {AnalyticEventCategories, AnalyticEventNames} from '@api/types/AnalyticEvents';
import {trackEvent} from '@utils/analytics';
import {useEffect} from 'react';

export const useCalltouchAnalytics = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const untypedWindow = window as any;
    if (!untypedWindow?.ct) {
      untypedWindow.ct = function (...args: any[]) {
        untypedWindow?.ct['callbacks'].push(args);
      };
      if (!untypedWindow?.ct['callbacks']) {
        untypedWindow.ct['callbacks'] = [];
      }
    }
    untypedWindow.ct('modules', 'widgets', 'subscribeToEvent', [
      {
        object: 'form',
        action: 'show',
        callback: () => {
          trackEvent(
            AnalyticEventCategories.MainPage,
            AnalyticEventNames.MainPageCalltouchWidgetShown,
            {
              banner_name: 'обратный звонок',
            },
          );
          const iframe = (
            document.getElementById('CalltouchWidgetFrame') as HTMLObjectElement
          )?.contentDocument;
          if (!iframe) return;

          const observer = new MutationObserver(() => {
            const btn = iframe?.querySelector(
              'div[class^="styles__CallButtonContainer"]',
            );
            if (btn) {
              btn.addEventListener('click', () => {
                trackEvent(
                  AnalyticEventCategories.MainPage,
                  AnalyticEventNames.MainPageCalltouchWidgetBtnClicked,
                  {
                    banner_name: 'обратный звонок',
                  },
                );
              });
			  observer.disconnect();
            }
          });
          observer.observe(iframe, {
            attributes: false,
            characterData: false,
            childList: true,
            subtree: true,
          });
        },
      },
    ]);
  }, []);
};
