export const stepNames = {
  stepOne: 'CabinetApplicationWizardStep1Control',
  stepTwo: 'CabinetApplicationWizardStep2Control',
  stepThree: 'CabinetApplicationWizardStep3Control',
  stepFour: 'CabinetApplicationWizardStep4Control',
  stepFive: 'CabinetApplicationWizardStep5Control',
  stepSix: 'CabinetApplicationWizardStep6Control',
  stepSeven: 'CabinetApplicationWizardStep7Control',
  stepEight: 'CabinetApplicationWizardStep8Control',
};

export const leasingOptions = [
  {
    label: 'Лизинг на выгодных условиях',
    value: 0,
    text: 'Низкое удорожание, аванс от 0%, срок лизинга до 8 лет',
  },
  {
    label: 'Быстрый лизинг',
    value: 1,
    text: 'Без предоставления финансовой отчетности. Аванс от 10%, срок до 5 лет',
  },
];

export const requiredFieldDefaultError = 'Поле не может быть пустым';
export const requiredCheckboxDefaultError = 'Обязательное поле';
export const uriRegExp = new RegExp(
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/,
);
export const mobileRegExp = new RegExp(/^((\+7)+([0-9]){10})$/);

export const signSelectOptions = [
  {
    title: 'Подписание через КриптоПро',
    value: 'cryptoPro',
  },
  {
    title: 'Подписание с помощью ПЭП',
    value: 'SES',
  },
];
