import { useEffect, useState } from 'react';
import { AnalyticEventCategories, AnalyticEventNames } from '@api/types/AnalyticEvents';
import { sendClientParamsNATS } from '@utils/common';
import { Breadcrumb } from '@api/types/Breadcrumbs';

export const useHeaderBreadcrumbsAnalytic = (headerCategoryMenuItems: Breadcrumb[]) => {
	const [request, setRequest] = useState(true);
	useEffect(() => {
		if (headerCategoryMenuItems?.length && request) {
			sendClientParamsNATS(AnalyticEventNames.MainPageShowHeaderLink, {
				eventName: AnalyticEventNames.MainPageShowHeaderLink,
				eventCategory: AnalyticEventCategories.MainPage,
				eventParams: {
					items: headerCategoryMenuItems.map(({path}) => path)
				}
			});
			setRequest(false);
		}
	}, [headerCategoryMenuItems]);
}