import apiClient from './entities/apiClient';

export const getGeoByIp = async () => {
  const {data} = await apiClient.get(`/GetGeoByIP`);
  return data;
};

export const getGeoByCity = async (city: string) => {
  const {data} = await apiClient.get(`/GetGeoByCity`, {
    params: {
      city,
    },
  });
  return data;
};
export const getFilialsByCoordinates = async (region_fias_id: string) => {
  const {data} = await apiClient.get(`/GetFilialsByCoordinates`, {
    params: {
      region_fias_id,
    },
  });
  return data;
};
