import { theme } from "@styles/theme";
import styled from "styled-components";



export const Wrapper = styled.div`
  padding: 20px 30px 30px 30px;
  width: 80vw;
  max-width: 933px;
  box-sizing: border-box;
  @media screen and (max-width: 520px) {
    padding: 0;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  padding: 13px 0;
  width: 100%;
  font: inherit;
  font-size: 18px;
  line-height: 21px;
  &:focus {
    outline: none;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  padding: 0 15px;
  margin-bottom: 30px;
`;

export const ResultsWrapper = styled.div`
  height: 174px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    height: 6px;
    width: 4px;
    background: white;
    border-radius: 4px;
    /* border: 1px solid ${theme.colors.lightGrayV2}; */
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    width: 4px;
    background: ${theme.colors.lightGrayV2};
    border-radius: 4px;
    &:hover {
      background-color: ${theme.colors.brandGreenV2};
    }
  }
  @media screen and (max-width: 520px) {
    height: 310px;
  }
`;

export const Results = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 520px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const City = styled.p`
  font-size: 18px;
  left: 21px;
  color: ${theme.colors.gray};
  transition: color 0.17s;
`;
export const Region = styled.p`
  color: ${theme.colors.lightGrayV2};
  font-size: 13px;
  line-height: 15px;
`;

export const Result = styled.button`
  display: block;
  text-align: left;
  cursor: pointer;
  &:hover ${City} {
    color: ${theme.colors.brandGreen};
  }
`;

export const NoResults = styled.div``;

export const NoResultsText = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;