export const socialIcons = [
  {
    icon: '/assets/icons/socialIcons/VK.svg',
    link: 'https://vk.com/rosagroleasing',
    title: 'ВКонтакте',
  },
  {
    icon: '/assets/icons/socialIcons/OK.svg',
    link: 'https://ok.ru/group/52707089907866',
    title: 'Одноклассники',
  },
  {
    icon: '/assets/icons/socialIcons/RSS.svg',
    link: 'https://www.rosagroleasing.ru/rss/news/',
    title: 'RSS',
  },
  {
    icon: '/assets/icons/socialIcons/Dzen.svg',
    link: 'https://dzen.ru/id/628e49389c5f0545bd1156e3',
    title: 'Дзен',
  },
  {
    icon: '/assets/icons/socialIcons/TG.svg',
    link: 'https://t.me/rosagroleasing',
    title: 'Telegram',
  },
  {
    icon: '/assets/icons/socialIcons/Rutube.svg',
    link: 'https://rutube.ru/channel/25267287/',
    title: 'Rutube',
  },
  {
    icon: '/assets/icons/socialIcons/YT.svg',
    link: 'https://www.youtube.com/user/ROSAGROLEASING',
    title: 'YouTube',
  },
];
