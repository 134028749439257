import styled from 'styled-components';

export const FooterWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.lightGray};
  padding: 50px 0 100px 0;

  @media (max-width: 1300px) {
    padding-bottom: 180px;
  }
  @media (max-width: 887px) {
    padding: 30px 0 80px 0;
  }
`;
