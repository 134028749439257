import styled from 'styled-components';
import {theme} from '@styles/theme';

export const DownloadFileByCodeWrapper = styled.a<{
  color: string | undefined;
  textDecoration: string;
}>`
  color: ${({color}) => (color ? color : theme.colors.brandGreenV2)};
  text-decoration: ${({textDecoration}) => textDecoration};
`;
